import React from 'react'
import { gql } from '@apollo/client'
import SearchInput from './SearchInput'

const EmployeeJobSearchInput = (props) => {
  const {
    formik,
    disabled,
    formikValue,
    searchDescription,
    setAdditionalFields,
    error,
    variables,
    dropdown,
  } = props
  return (
    <SearchInput
      formik={formik}
      nodeName="employeeJob"
      searchDescription={
        searchDescription ? searchDescription : 'employee jobs'
      }
      nodeNamePlural="employeeJobs"
      formikValue={formikValue}
      disabled={disabled}
      error={error}
      setAdditionalFields={setAdditionalFields}
      variables={variables}
      dropdown={dropdown}
      gql={gql`
        query EmployeeJobs($first: Int, $after: String, $search: String) {
          employeeJobs(
            first: $first
            after: $after
            search: $search
            orderBy: "job__start_date_time"
          ) {
            nodeCount
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                job {
                  id
                  name
                }
              }
            }
          }
        }
      `}
      formatDescription={(node) => {
        return node.job.name
      }}
    />
  )
}

export default EmployeeJobSearchInput
