import React, { useState } from 'react'
import { useQuery, gql, useReactiveVar } from '@apollo/client'
import { settingsVar } from '../../libs/apollo'
import { Row, Col, Tab } from 'react-bootstrap'
import {
  Dot,
  CalendarDate,
  CalendarWeek,
  FileEarmarkText,
  PersonWorkspace,
  Briefcase,
  CardChecklist,
  Bell,
  ClockHistory,
} from 'react-bootstrap-icons'
import { useParams } from 'react-router-dom'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import EditEmployeeModal from './EditEmployeeModal'
import Notifications from '../notifications/Notifications'
import JobDetailModal from '../schedule/JobDetailModal'
import EmployeeSchedule from './employee_schedule/EmployeeSchedule'
import Schedule from '../schedule/Schedule'
import Loading from '../common/Loading'
import Page from '../layout/Page'
import AuditLog from '../audit_log/AuditLog'
import Tabs from '../common/Tabs'
import ServiceItemModal from '../organization/service_items/ServiceItemModal'
import { loggedInUserVar } from '../../libs/apollo'
import EmployeeDetailEquipment from './EmployeeDetailEquipment'
import { useBoxEnabled } from '../../libs/box'
import BoxFolder from '../files/box/BoxFolder'
import FilesTable from '../files/FilesTable'

const EmployeeDetail = () => {
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const { employeeId } = useParams()
  const boxEnabled = useBoxEnabled()
  const [jobDetailId, setJobDetailId] = useState()
  const [serviceItem, setShowServiceItem] = useState()
  const [showJobDetailModal, setShowJobDetailModal] = useState()
  const [activeTab, setActiveTab] = useState('Schedule')
  const settings = useReactiveVar(settingsVar)
  const EMPLOYEE_QUERY = gql`
    query EmployeeQuery($employeeId: ID!) {
      employee(id: $employeeId) {
        id
        hourlyPay
        boxFolderId
        contentType {
          model
        }
        kanban {
          id
          board
        }
        roles {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          edges {
            node {
              id
              name
            }
          }
        }
        folder {
          id
        }
        serviceItems {
          edges {
            node {
              description
              serviceItemGroup {
                name
                id
              }
              dueDate
              eventDate
              finishedDate
              organization {
                name
                id
              }
              employees {
                edges {
                  node {
                    id
                    gaiaUser {
                      fullName
                      email
                    }
                  }
                }
              }
              contacts {
                edges {
                  node {
                    id
                    fullName
                    email
                  }
                }
              }
              status
            }
          }
        }
        gaiaUser {
          id
          contentType {
            model
          }
          isActive
          lastLogin
          loginBrowser
          loginLocation
          email
          firstName
          lastName
          fullName
          abbreviatedName
          phoneNumber
          emailNotificationsEnabled
          smsNotificationsEnabled
          secondaryPhoneNumber
          secondaryEmail
          addressLineOne
          addressLineTwo
          state
          city
          zipCode
          groups {
            edges {
              node {
                id
                name
              }
            }
          }
        }
        defaultRole {
          id
          name
        }
        salesPercent
        photoPercent
        payCommission
        rainPercent
        taskPercent
        servicePercent
        internalEmployeeId
        smtpHost
        smtpTls
        smtpPort
        smtpUser
        smtpSsl
        smtpPassword
        smtpSendgridSender
        smtpValid
        smtpGmail
      }
    }
  `
  const { error: employeeQueryError, data: employeeQueryData } = useQuery(
    EMPLOYEE_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: {
        employeeId,
      },
    }
  )
  const toggleJobDetailModal = () => {
    if (showJobDetailModal) {
      setJobDetailId(null)
    }
    setShowJobDetailModal(!showJobDetailModal)
  }

  if (!employeeQueryData)
    return (
      <div className="mt-3">
        <Loading message="Loading Employee..." />
      </div>
    )
  if (employeeQueryError) return <>Error loading employee</>
  const { employee } = employeeQueryData
  let roles = '- - -'
  if (employee?.roles) {
    roles = ''
    employee?.roles?.edges.forEach((role) => {
      roles = `${roles} ${role.node.name},`
    })
    roles = roles.slice(0, -1)
  }
  return (
    <>
      <div
        style={
          loggedInUser?.permissions?.group === 'General Staff'
            ? {
                marginTop: '-10px',
              }
            : {}
        }
      >
        <Page
          title={
            loggedInUser?.permissions?.group !== 'General Staff' &&
            `${employee.gaiaUser.firstName} ${employee.gaiaUser.lastName}`
          }
        >
          <div>
            <Tabs
              activeKey={activeTab}
              onSelect={(tab) => {
                setActiveTab(tab)
              }}
            >
              <Tab
                key="Schedule"
                eventKey="Schedule"
                style={{ marginTop: '10px' }}
                title={
                  <b>
                    <CalendarDate className="mr-2" />
                    Schedule
                  </b>
                }
              >
                <Schedule employeeId={employee.id} />
              </Tab>
              <Tab
                key="Work Events"
                eventKey="Work Events"
                style={{ marginTop: '10px' }}
                title={
                  <b>
                    <CalendarWeek className="mr-2" />
                    Work Events
                  </b>
                }
              >
                <EmployeeSchedule employeeNode={employee} />
              </Tab>
              <Tab
                key="Profile"
                eventKey="Profile"
                style={{ marginTop: '10px' }}
                title={
                  <b>
                    <PersonWorkspace className="mr-2" />
                    Profile
                  </b>
                }
              >
                <EditEmployeeModal employee={employee} />
              </Tab>
              <Tab
                key="Equipment"
                eventKey="Equipment"
                style={{ marginTop: '10px' }}
                title={
                  <b>
                    <Briefcase className="mr-2" />
                    Equipment
                  </b>
                }
              >
                <EmployeeDetailEquipment
                  employeeId={employee.id}
                  employeeName={employee.gaiaUser.fullName}
                />
              </Tab>
              <Tab
                key="Tasks"
                eventKey="Tasks"
                style={{ marginTop: '10px' }}
                title={
                  <b>
                    <CardChecklist className="mr-2" />
                    Tasks
                  </b>
                }
              >
                <Row className="mt-3 mb-3">
                  <Col>
                    <>
                      {employee.serviceItems && (
                        <SortableInfiniteTable
                          onRowClick={(row) => {
                            setShowServiceItem(row.original.node)
                          }}
                          rowPointer
                          tableData={employee.serviceItems.edges}
                          tableColumns={[
                            {
                              Header: 'Organization',
                              accessor: 'node.organization.name',
                            },
                            {
                              Header: 'Group',
                              accessor: 'node.serviceItemGroup.name',
                            },
                            {
                              Header: 'Description',
                              accessor: 'node.description',
                            },
                            {
                              Header: 'Status',
                              accessor: 'node.status',
                            },
                            {
                              Header: 'Event Date',
                              accessor: 'node.eventDate',
                            },
                            {
                              Header: 'Due Date',
                              accessor: 'node.dueDate',
                            },
                            {
                              Header: 'Finished Date',
                              accessor: 'node.finishedDate',
                            },
                            {
                              Header: 'Employees',
                              accessor: (row) => (
                                <>
                                  {row.node.employees.edges.map((employee) => (
                                    <div key={employee.node.id}>
                                      <p>
                                        <Dot className="mr-1" />
                                        <span
                                          style={{
                                            fontSize: '12px',
                                          }}
                                        >
                                          {employee.node.gaiaUser.fullName},{' '}
                                          {employee.node.gaiaUser.email}
                                        </span>
                                      </p>
                                    </div>
                                  ))}
                                </>
                              ),
                            },
                            {
                              Header: 'Contact',
                              accessor: (row) => (
                                <>
                                  {row.node.contacts.edges.map((contact) => (
                                    <div key={contact.node.id}>
                                      <p>
                                        <Dot className="mr-1" />
                                        <span
                                          style={{
                                            fontSize: '12px',
                                          }}
                                        >
                                          {contact.node.fullName},{' '}
                                          {contact.node.email}
                                        </span>
                                      </p>
                                    </div>
                                  ))}
                                </>
                              ),
                            },
                          ]}
                          hasMoreTableData={false}
                          tableHeight={500}
                        />
                      )}
                    </>
                  </Col>
                </Row>
              </Tab>
              <Tab
                key="Files"
                eventKey="Files"
                style={{ marginTop: '10px' }}
                title={
                  <b>
                    <FileEarmarkText className="mr-2" />
                    Files
                  </b>
                }
              >
                {activeTab === 'Files' && (
                  <>
                    {boxEnabled && (
                      <BoxFolder rootBoxFolderId={employee.boxFolderId} />
                    )}
                    {!boxEnabled && (
                      <div className="mt-4">
                        <FilesTable
                          employeeId={employee.id}
                          employeeDescription={employee.gaiaUser.fullName}
                          folderId={employee.folder.id}
                        />
                      </div>
                    )}
                  </>
                )}
              </Tab>

              {['Administrator', 'Scheduling Manager'].includes(
                loggedInUser?.permissions?.group
              ) && (
                <Tab
                  key="Notifications"
                  eventKey="Notifications"
                  style={{ marginTop: '10px' }}
                  title={
                    <b>
                      <Bell className="mr-2" />
                      Notifications
                    </b>
                  }
                >
                  {activeTab === 'Notifications' && (
                    <Notifications employee={employee} />
                  )}
                </Tab>
              )}
              {['Administrator', 'Scheduling Manager'].includes(
                loggedInUser?.permissions?.group
              ) && (
                <Tab
                  key="History"
                  eventKey="History"
                  style={{ marginTop: '10px' }}
                  title={
                    <b>
                      <ClockHistory className="mr-2" />
                      History
                    </b>
                  }
                >
                  <Row>
                    <Col md={12}>
                      <AuditLog
                        contentTypesRelayIds={{
                          [employee.contentType.model]: [employee.id],
                          [employee.gaiaUser.contentType.model]: [
                            employee.gaiaUser.id,
                          ],
                        }}
                      />
                    </Col>
                  </Row>
                </Tab>
              )}
            </Tabs>
            <JobDetailModal
              jobId={jobDetailId}
              showModal={showJobDetailModal}
              toggleModal={toggleJobDetailModal}
            />
            {serviceItem && (
              <ServiceItemModal
                employeeDetail
                showModal={serviceItem}
                organizationId={serviceItem.organization.id}
                toggleModal={setShowServiceItem}
                updateServiceItem={serviceItem}
              />
            )}
          </div>
        </Page>
      </div>
    </>
  )
}
export default EmployeeDetail
