import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Badge, Tab } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useQuery, gql, useReactiveVar, useMutation } from '@apollo/client'
import SubjectSubjectGroupsTable from './SubjectSubjectGroupsTable'
import AdminSubjectSessionsTable from './AdminSubjectSessionsTable'
import SubjectForm from '../subject/SubjectForm'
import Subjects from './Subjects'
import {
  FolderPlus,
  ExclamationCircle,
  Camera,
  Person,
  People,
  Cash,
  Receipt,
  GeoAlt,
  Bell,
  FileEarmarkText,
  ClockHistory,
} from 'react-bootstrap-icons'
import Notifications from '../notifications/Notifications'
import { loggedInUserVar } from '../../libs/apollo'
import toast from 'react-hot-toast'
import Loading from '../common/Loading'
import AuditLog from '../audit_log/AuditLog'
import Tabs from '../common/Tabs'
import InvoiceTable from '../payments/InvoiceTable'
import Locations from '../location/Locations'
import ESignRequests from '../files/box/ESignRequests'
import { useFotomerchantEnabled } from '../../libs/fotomerchant'
import FotomerchantOrders from '../fotomerchant/FotomerchantOrders'
import PaymentsTable from '../payments/PaymentsTable'
import { useBoxEnabled } from '../../libs/box'
import BoxFolder from '../files/box/BoxFolder'
import NotificationsTable from '../notifications/NotificationsTable'
import FilesTable from '../files/FilesTable'

const SubjectDetail = (props) => {
  const { bookSession } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const { subjectId, contact } = useParams()
  const fotomerchantEnabled = useFotomerchantEnabled()
  const subjectIdProps = props.subjectId
  const boxEnabled = useBoxEnabled()
  const subjectIdQuery = subjectId ? subjectId : subjectIdProps
  const [submittingCreateBoxFolder, setSubmittingCreateBoxFolder] =
    useState(false)
  const [hideCardElement, setHideCardElement] = useState(false)
  const cantSeePayments = ['General Staff', 'Equipment Manager'].includes(
    loggedInUser?.permissions?.group
  )
  const [activeTab, setActiveTab] = useState('Sessions')
  const [currentBoxFolderId, setCurrentBoxFolderId] = useState()
  const [activeBillingTab, setActiveBillingTab] = useState('Payments')
  const [hideSchoolContactTabs, setHideSchoolContactTabs] = useState(null)
  const [queriesLoading, setQueriesLoading] = useState({
    subject: true,
    sessions: true,
    subjectGroupsNotBooked: true,
    notifications: true,
  })

  const canCreateBoxFolder = [
    'Scheduling Analyst',
    'Administrator',
    'Scheduling Manager',
  ].includes(loggedInUser?.permissions?.group)
  const canSeeHistory = ![
    'Subject',
    'Organization Contact',
    'General Staff',
  ].includes(loggedInUser?.permissions?.group)
  const [activeFilesTab, setActiveFilesTab] = useState('Files')

  const {
    loading: subjectQueryLoading,
    error: subjectQueryError,
    data: subjectQueryData,
  } = useQuery(
    gql`
      query SubjectDetailQuery($subjectId: ID!) {
        subject(id: $subjectId) {
          id
          addressLineOne
          addressLineTwo
          city
          rootBoxFolderId
          sharedBoxFolderId
          sharedCanSeeFiles
          sharedCanCreateFiles
          sharedCanCreateFolders
          state
          folder {
            id
          }
          staffSharedFolder {
            id
          }
          sharedFolder {
            id
          }
          zipCode
          children {
            nodeCount
          }
          gaiaUser {
            id
            contentType {
              model
            }
            metadata
            loginBrowser
            loginLocation
            emailConfirmed
            isActive
            lastLogin
            dummyUsername
            firstName
            lastName
            fullName
            phoneNumber
            secondaryPhoneNumber
            secondaryEmail
            email
            emailNotificationsEnabled
            smsNotificationsEnabled
            stripeCustomer {
              stripePaymentMethods(primary: true) {
                edges {
                  node {
                    stripeResource
                    created
                  }
                }
              }
            }
          }
          organization {
            id
            name
            category
          }
          subjectGroups {
            edges {
              node {
                id
                name
              }
            }
          }
          contentType {
            model
          }
          sessions {
            edges {
              node {
                id
                startDateTime
                cancelled
                subjectGroup {
                  name
                  id
                }
                sessionPackage {
                  title
                }
                job {
                  name
                }
              }
            }
          }
          studentId
          parents {
            edges {
              node {
                id
                gaiaUser {
                  id
                  lastLogin
                  firstName
                  lastName
                  fullName
                  phoneNumber
                  email
                  emailNotificationsEnabled
                  smsNotificationsEnabled
                }
              }
            }
          }
          notes
          city
          state
          zipCode
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      variables: {
        subjectId: subjectIdQuery,
      },
      onCompleted: () => {
        setQueriesLoading((prevState) => ({ ...prevState, subject: false }))
      },
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    if (contact && subjectQueryData) {
      let _hideSchoolContactTabs = false
      loggedInUser.organizationContacts.edges.forEach((edge) => {
        if (
          edge.node.id === subjectQueryData.subject.organization?.id &&
          edge.node.category === 'SCHOOL'
        ) {
          if (!_hideSchoolContactTabs) {
            _hideSchoolContactTabs = true
          }
        }
      })
      setHideSchoolContactTabs(_hideSchoolContactTabs)
    }
  }, [contact, subjectQueryData])

  useEffect(() => {
    if (subjectQueryData?.subject?.rootBoxFolderId) {
      setCurrentBoxFolderId(subjectQueryData?.subject?.rootBoxFolderId)
    }
  }, [subjectQueryData])

  const [createBoxFolder] = useMutation(
    gql`
      mutation CreateSubjectBoxFolder($input: CreateSubjectBoxFolderInput!) {
        createSubjectBoxFolder(input: $input) {
          created
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmittingCreateBoxFolder(false)
        toast.success('Folder Created')
      },
      errorPolicy: 'all',
      refetchQueries: ['SubjectDetailQuery'],
    }
  )

  if (
    subjectQueryLoading ||
    !subjectQueryData ||
    (contact && hideSchoolContactTabs === null)
  ) {
    return (
      <Row className="mt-3">
        <Col>
          <Loading />
        </Col>
      </Row>
    )
  }
  if (subjectQueryError) return <p>Error loading subject</p>
  const { subject } = subjectQueryData
  const { gaiaUser } = subject
  return (
    <>
      <div>
        {subjectIdProps ? (
          <>
            <Row>
              <Col className="d-flex align-items-center">
                <h4>
                  {bookSession && <>Confirm </>} {gaiaUser.fullName}
                  {bookSession && <> Information</>}{' '}
                  {subject.gaiaUser.isActive ? (
                    <></>
                  ) : (
                    <Badge
                      style={{ fontSize: 16 }}
                      variant="danger"
                      className="ml-3"
                    >
                      Login Access Disabled
                    </Badge>
                  )}
                </h4>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-between mb-4 align-items-center">
              <div>
                <h1 className="mb-0 mt-3">
                  {gaiaUser.fullName}
                  {subject.gaiaUser.isActive ? (
                    <></>
                  ) : (
                    <Badge
                      style={{ fontSize: 16 }}
                      variant="danger"
                      className="ml-3"
                    >
                      Login Access Disabled
                    </Badge>
                  )}
                </h1>
              </div>
            </div>
          </>
        )}
        {bookSession && (
          <>
            <h4 className="mt-2">Profile</h4>
            <SubjectForm
              subjectId={subject.id}
              subjectViewing={false}
              displayBilling
              subjectDetail
              allowDelete={true}
              hideCardElement={hideCardElement}
            />
          </>
        )}
        {!bookSession && (
          <Tabs
            activeKey={activeTab}
            onSelect={(tab) => {
              setActiveTab(tab)
            }}
          >
            <Tab
              key="Sessions"
              eventKey="Sessions"
              style={{ marginTop: '10px' }}
              title={
                <b>
                  <Camera className="mr-2" />
                  Sessions
                </b>
              }
            >
              <AdminSubjectSessionsTable
                techFlow
                subjectId={subject.id}
                setQueriesLoading={setQueriesLoading}
                queriesLoading={queriesLoading}
                setHideParentCardElement={setHideCardElement}
              />
            </Tab>
            <Tab
              key="Profile"
              eventKey="Profile"
              style={{ marginTop: '10px' }}
              title={
                <b>
                  <Person className="mr-2" />
                  Profile
                </b>
              }
            >
              <SubjectForm
                subjectId={subject.id}
                subjectViewing={false}
                displayBilling
                subjectDetail
                displayMerge
                allowDelete={true}
                hideCardElement={hideCardElement}
              />
              {subject.children.nodeCount > 0 && (
                <>
                  <h5 className="mb-3">Contact For Subjects</h5>
                  <Subjects contactId={subject.id} />
                </>
              )}
            </Tab>
            <Tab
              key="Subject Groups"
              eventKey="Subject Groups"
              style={{ marginTop: '10px' }}
              title={
                <b>
                  <People className="mr-2" />
                  Subject Groups
                </b>
              }
            >
              <SubjectSubjectGroupsTable subjectId={subject.id} />
            </Tab>
            {!hideSchoolContactTabs && !cantSeePayments && (
              <Tab
                key="Billing"
                eventKey="Billing"
                style={{ marginTop: '10px' }}
                title={
                  <b>
                    <Cash className="mr-2" />
                    Billing
                  </b>
                }
              >
                <Tabs
                  activeKey={activeBillingTab}
                  onSelect={(tab) => setActiveBillingTab(tab)}
                >
                  <Tab
                    key="Payments"
                    eventKey="Payments"
                    style={{ marginTop: '10px' }}
                    title={
                      <b>
                        <Cash className="mr-2" />
                        Payments
                      </b>
                    }
                  >
                    <PaymentsTable subjectId={subject.id} />
                  </Tab>
                  <Tab
                    key="Invoices"
                    eventKey="Invoices"
                    style={{ marginTop: '10px' }}
                    title={
                      <b>
                        <Receipt className="mr-2" />
                        Invoices
                      </b>
                    }
                  >
                    <InvoiceTable
                      gaiaUserId={gaiaUser.id}
                      invoiceRecipient={
                        !gaiaUser.dummyUsername && gaiaUser.email
                      }
                    />
                  </Tab>
                </Tabs>
              </Tab>
            )}
            {!hideSchoolContactTabs && fotomerchantEnabled && (
              <Tab
                key="Fotomerchant Orders"
                eventKey="Fotomerchant Orders"
                style={{ marginTop: '10px' }}
                title={<b>Fotomerchant Orders</b>}
              >
                <FotomerchantOrders subjectId={subjectId} onlyTable />
              </Tab>
            )}
            <Tab
              key="Locations"
              eventKey="Locations"
              style={{ marginTop: '10px' }}
              title={
                <b>
                  <GeoAlt className="mr-2" />
                  Locations
                </b>
              }
            >
              <Locations subjectId={subjectId} />
            </Tab>
            {(!contact || boxEnabled || subject.sharedCanSeeFiles) && (
              <Tab
                key="Files"
                eventKey="Files"
                style={{ marginTop: '10px' }}
                title={
                  <b>
                    <FileEarmarkText className="mr-2" />
                    Files
                  </b>
                }
              >
                {activeTab === 'Files' && (
                  <>
                    {boxEnabled && (
                      <Tabs
                        activeKey={activeFilesTab}
                        onSelect={setActiveFilesTab}
                      >
                        <Tab
                          key="Files"
                          eventKey="Files"
                          style={{ marginTop: '10px' }}
                          title={<b>Files</b>}
                        >
                          {!subject.rootBoxFolderId && canCreateBoxFolder && (
                            <>
                              {!submittingCreateBoxFolder ? (
                                <Row>
                                  <Col
                                    md={3}
                                    className="d-flex align-items-center mb-3"
                                  >
                                    <Button
                                      variant="link"
                                      className="mb-2 ml-1"
                                      disabled={submittingCreateBoxFolder}
                                      onClick={() => {
                                        setSubmittingCreateBoxFolder(true)
                                        createBoxFolder({
                                          variables: {
                                            input: {
                                              subjectId: subjectId,
                                            },
                                          },
                                        })
                                      }}
                                    >
                                      <FolderPlus className="mr-2" />
                                      Create Folder
                                    </Button>
                                  </Col>
                                </Row>
                              ) : (
                                <Row>
                                  <Col md={2}>
                                    <Loading />
                                  </Col>
                                </Row>
                              )}
                            </>
                          )}
                          {subject.rootBoxFolderId && (
                            <BoxFolder
                              canDelete={!contact}
                              canRename={!contact}
                              rootBoxFolderId={
                                contact
                                  ? subject.sharedBoxFolderId
                                  : subject.rootBoxFolderId
                              }
                              setCurrentBoxFolderId={setCurrentBoxFolderId}
                            />
                          )}
                        </Tab>
                        <Tab
                          key="Sign Requests"
                          eventKey="Sign Requests"
                          style={{ marginTop: '10px' }}
                          title={<b>Sign Requests</b>}
                        >
                          {subject.rootBoxFolderId && (
                            <ESignRequests
                              currentBoxFolderId={currentBoxFolderId}
                              subjectId={subject.id}
                              contact={contact}
                            />
                          )}
                          {!subject.rootBoxFolderId && (
                            <Row>
                              <Col>
                                <div className="text-danger">
                                  <ExclamationCircle className="mr-2" />
                                  Subject requires a folder to send sign
                                  requests
                                </div>
                              </Col>
                            </Row>
                          )}
                        </Tab>
                      </Tabs>
                    )}
                    {!boxEnabled && subject?.folder?.id && (
                      <div className="mt-4">
                        <FilesTable
                          subjectId={subject.id}
                          subjectDescription={subject.gaiaUser.fullName}
                          organizationSubject={subject.organization}
                          sharedCanCreateFiles={subject.sharedCanCreateFiles}
                          sharedCanCreateFolders={
                            subject.sharedCanCreateFolders
                          }
                          folderId={
                            contact
                              ? subject.sharedFolder.id
                              : loggedInUser.permissions.group ===
                                'General Staff'
                              ? subject.staffSharedFolder.id
                              : subject.folder.id
                          }
                        />
                      </div>
                    )}
                  </>
                )}
              </Tab>
            )}
            {(canSeeHistory || contact) && (
              <Tab
                key="Notifications"
                eventKey="Notifications"
                style={{ marginTop: '10px' }}
                title={
                  <b>
                    <Bell className="mr-2" />
                    Notifications
                  </b>
                }
              >
                {contact && activeTab === 'Notifications' && (
                  <NotificationsTable subject={subject} />
                )}
                {!contact && activeTab === 'Notifications' && (
                  <Notifications subject={subject} />
                )}
              </Tab>
            )}
            {canSeeHistory && (
              <Tab
                key="History"
                eventKey="History"
                style={{ marginTop: '10px' }}
                title={
                  <b>
                    <ClockHistory className="mr-2" />
                    History
                  </b>
                }
              >
                <AuditLog
                  contentTypesRelayIds={{
                    [subject.contentType.model]: [subject.id],
                    [subject.gaiaUser.contentType.model]: [subject.gaiaUser.id],
                  }}
                />
              </Tab>
            )}
          </Tabs>
        )}
      </div>
    </>
  )
}

export default SubjectDetail
