import { gql, useQuery } from '@apollo/client'

export const useFolderConfiguration = () => {
  const { data } = useQuery(
    gql`
      query UseFolderConfiguration {
        folderConfigurations {
          edges {
            node {
              sessionSharedCanSeeFiles
              jobSharedCanSeeFiles
              organizationSharedCanSeeFiles
              subjectSharedCanSeeFiles
              subjectGroupSharedCanSeeFiles
              serviceItemSharedCanSeeFiles
              serviceItemSharedCanCreateFiles
              serviceItemSharedCanCreateFolders
              sessionSharedCanCreateFiles
              sessionSharedCanCreateFolders
              jobSharedCanCreateFiles
              jobSharedCanCreateFolders
              organizationSharedCanCreateFiles
              organizationSharedCanCreateFolders
              subjectGroupSharedCanCreateFiles
              subjectGroupSharedCanCreateFolders
              subjectSharedCanCreateFiles
              subjectSharedCanCreateFolders
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )
  if (!data) return
  return data.folderConfigurations.edges[0].node
}
