import React, { useState, useEffect } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { DateTime } from 'luxon'
import { useHistory } from 'react-router-dom'
import { useMutation, useLazyQuery, gql } from '@apollo/client'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../libs/apollo'
import JobPage from './job/Job'
import { useAWSS3 } from '../../libs/aws'
import toast from 'react-hot-toast'
import { CaretRight, CaretDown } from 'react-bootstrap-icons'
import './JobDetailSessionsTable.css'
import AdminSessionDetailModal from '../sessions/AdminSessionDetailModal'
import FotomerchantGalleryPasswordModal from '../fotomerchant/FotomerchantGalleryPasswordModal'
import Loading from '../common/Loading'
import SessionDetailModal from '../subject_sessions/SessionDetailModal'
import SessionDetailFormModal from '../sessions/SessionDetailFormModal'
import { useDownloadAndDeleteFile } from '../../libs/downloadAndDeleteFile'
import NoShowModal from './NoShowModal'

const JobDetailSessionsTable = (props) => {
  const { jobId, sessionsOnly } = props
  const history = useHistory()
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const [sessions, setSessions] = useState([])
  const [display, setDisplay] = useState(true)
  const [fotomerchantGalleryPassword, setFotomerchantGalleryPassword] =
    useState()
  const awsS3 = useAWSS3()
  const { downloadAndDeleteFile, downloadFile } = useDownloadAndDeleteFile()
  const canAddSessions = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)
  const [savingNoShow, setSavingNoShow] = useState(false)
  const [savingNotNoShow, setSavingNotNoShow] = useState(false)
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [noShowSession, setNoShowSession] = useState()
  const [downloadingFile, setDownloadingFile] = useState()
  const [savingComplete, setSavingComplete] = useState(false)
  const [savingNotComplete, setSavingNotComplete] = useState(false)
  const [savingCancel, setSavingCancel] = useState(false)
  const [editRowNode, setEditRowNode] = useState(null)
  const [detailSessionId, setDetailSessionId] = useState(null)
  const [showSessionDetailModal, setShowSessionDetailModal] = useState(false)
  const [selectedSessionId, setSelectedSessionId] = useState(null)
  const orgContact = loggedInUser.permissions.isOrgContact
  const [downloadSessionsReport] = useMutation(
    gql`
      mutation DownloadSessions($input: DownloadSessionsInput!) {
        downloadSessions(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        if (data?.downloadSessions?.file?.fileName) {
          downloadAndDeleteFile(
            data.downloadSessions.file.fileName,
            data.downloadSessions.file.displayName,
            data.downloadSessions.file.id,
            () => {
              toast.success(`Session Ticket Downloaded`)
              setDownloadingFile(false)
            }
          )
        }
      },
      errorPolicy: 'all',
    }
  )

  const [updateSessionMutation] = useMutation(
    gql`
      mutation UpdateSession($updateSessionInput: UpdateSessionInput!) {
        updateSession(input: $updateSessionInput) {
          session {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        if (savingCancel) {
          toast.success('Session Cancelled')
          setSavingCancel(false)
        } else {
          if (savingNoShow) {
            setSavingNoShow(false)
          }
          if (savingComplete) {
            setSavingComplete(false)
          }
          if (savingNotComplete) {
            setSavingNotComplete(false)
          }
          if (savingNotNoShow) {
            setSavingNotNoShow(false)
          }
          toast.success('Session Saved')
        }
      },
      errorPolicy: 'all',
      refetchQueries: ['JobDetailSessionsQuery'],
    }
  )

  const tableColumns = [
    {
      Header: 'Id',
      id: 'id',
      accessor: 'recordId',
    },
    {
      Header: 'Time',
      accessor: 'time',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Session Package',
      accessor: 'sessionPackage',
    },
    {
      Header: 'Subject',
      id: 'subject',
      accessor: (row) => {
        if (row.subject) {
          return <div className="btn-link">{row.subject}</div>
        }
      },
    },
    {
      Header: 'Subject Phone',
      accessor: 'subjectPhone',
    },
    {
      Header: 'Subject Email',
      accessor: 'subjectEmail',
    },
    {
      Header: 'Subject Group',
      accessor: 'subjectGroup',
    },
    {
      Header: 'Organization',
      accessor: 'organization',
    },
    {
      Header: 'Resit Reason',
      accessor: 'resitReason',
    },
    {
      Header: '',
      id: 'actions',
      accessor: (row) => {
        const { node } = row
        return (
          <>
            {!node.cancelled && !node.rescheduled && (
              <>
                <div className="job-sessions-buttons">
                  {!node.noShow && (
                    <>
                      <Button
                        type="submit"
                        variant="link"
                        className="mt-2"
                        onClick={() => {
                          setNoShowSession(node.id)
                        }}
                      >
                        No Show
                      </Button>
                    </>
                  )}
                  {node.noShow && (
                    <>
                      <Button
                        type="submit"
                        variant="link"
                        className="mt-2"
                        onClick={() => {
                          setSavingNotNoShow(true)
                          updateSessionMutation({
                            variables: {
                              updateSessionInput: {
                                sessionInput: {
                                  id: node.id,
                                  noShow: false,
                                },
                              },
                            },
                          })
                        }}
                      >
                        Not No Show
                      </Button>
                    </>
                  )}
                  <br />
                  {!node.completed && (
                    <>
                      <Button
                        type="submit"
                        variant="link"
                        className="mt-2"
                        onClick={() => {
                          setSavingComplete(true)
                          updateSessionMutation({
                            variables: {
                              updateSessionInput: {
                                sessionInput: {
                                  id: node.id,
                                  completed: true,
                                },
                              },
                            },
                          })
                        }}
                      >
                        Complete
                      </Button>
                    </>
                  )}
                  {node.completed && (
                    <>
                      <Button
                        type="submit"
                        variant="link"
                        className="mt-2 btn-sm"
                        onClick={() => {
                          setSavingNotComplete(true)
                          updateSessionMutation({
                            variables: {
                              updateSessionInput: {
                                sessionInput: {
                                  id: node.id,
                                  completed: false,
                                },
                              },
                            },
                          })
                        }}
                      >
                        Not Complete
                      </Button>
                    </>
                  )}
                  <br />
                  <Button
                    type="submit"
                    variant="link"
                    className="mt-2"
                    disabled={downloadingFile}
                    onClick={() => {
                      setDownloadingFile(true)
                      downloadSessionsReport({
                        variables: {
                          input: {
                            sessionIds: [node.id],
                            jobId: null,
                            fileType: 'pdf',
                            file: 'sessions_sitting',
                          },
                        },
                      })
                    }}
                  >
                    Download Ticket
                  </Button>
                  <Button
                    type="submit"
                    variant="link"
                    className="mt-2"
                    onClick={() => {
                      setShowSessionDetailModal(true)
                      setSelectedSessionId(node.id)
                    }}
                  >
                    Employee Details
                  </Button>
                </div>
              </>
            )}
          </>
        )
      },
    },
  ]

  if (orgContact) {
    tableColumns.splice(tableColumns.length - 1, 1)
  }

  const [
    query,
    {
      loading: sessionsQueryLoading,
      error: sessionsQueryError,
      data: sessionsQueryData,
      fetchMore: sessionsQueryFetchMore,
    },
  ] = useLazyQuery(
    gql`
      query JobDetailSessionsQuery(
        $sessionsJob: ID
        $cursor: String
        $contact: Boolean
      ) {
        sessions(
          first: 20
          after: $cursor
          contact: $contact
          job: $sessionsJob
          cancelled: false
          rescheduled: false
          sessionPackageChanged: false
          orderBy: "start_date_time"
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              contentType {
                model
                id
              }
              recordId
              waiveBookingFee
              price
              completed
              noShow
              startDateTime
              endDateTime
              subjectGroup {
                id
                name
                organization {
                  id
                  name
                }
              }
              organizationPackageBackground {
                backgroundColor
              }
              stripePaymentIntents(orderBy: "created", first: 1) {
                edges {
                  node {
                    id
                    stripeId
                    refunded
                    refundedBySystem
                    refundedBy {
                      fullName
                    }
                  }
                }
              }
              organization {
                id
                name
              }
              subject {
                id
                gaiaUser {
                  firstName
                  lastName
                  email
                  fullName
                  secondaryEmail
                  phoneNumber
                  dummyUsername
                  secondaryPhoneNumber
                }
              }
              resitPreviousSession {
                id
                resitReason {
                  reason
                }
              }
              sessionPackage {
                id
                title
              }
              resitReason {
                reason
              }
              noShow
              cancelled
              completed
              resitScheduled
              sessionPackageChanged
              rescheduled
              billSubject
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      variables: {
        sessionsJob: jobId,
        contact: orgContact,
      },
      onCompleted: (data) => {
        data.sessions.edges.forEach((edge) => {
          const { node } = edge
          if (editRowNode && node.id === editRowNode.id) {
            setEditRowNode(node)
          }
        })
      },
    }
  )

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query()
    }
  }, [initialQueryRun])

  const handleSessionsQueryData = (sessions) => {
    const currentSessions = []
    sessions.edges.forEach((session) => {
      const sessionNode = session.node
      let status
      if (sessionNode.noShow) {
        if (
          !sessionNode.resitScheduled ||
          sessionNode.futuristSession?.cancelled
        ) {
          status = 'No Show'
        } else {
          status = 'Resit Scheduled'
        }
      } else if (sessionNode.completed) {
        if (
          !sessionNode.resitScheduled ||
          (sessionNode.futuristSession &&
            sessionNode.futuristSession?.cancelled)
        ) {
          status = 'Complete'
        } else {
          status = 'Resit Scheduled'
        }
      } else if (sessionNode.cancelled) {
        status = 'Canceled'
      } else if (sessionNode.sessionPackageChanged) {
        status = 'Session Package Changed'
      } else if (
        sessionNode.rescheduled &&
        !sessionNode.futuristSession?.cancelled
      ) {
        status = 'Rescheduled'
      } else {
        status = 'Upcoming'
      }
      const startDateTime = DateTime.fromISO(sessionNode.startDateTime)
      const endDateTime = DateTime.fromISO(sessionNode.endDateTime)
      const gaiaUserNode = sessionNode.subject?.gaiaUser
        ? sessionNode.subject?.gaiaUser
        : { firstName: '', lastName: '' }
      let phone
      if (gaiaUserNode.phoneNumber) {
        phone = gaiaUserNode.phoneNumber
      }
      if (gaiaUserNode.secondaryPhoneNumber) {
        if (phone) {
          phone = `${phone}, ${gaiaUserNode.secondaryPhoneNumber}`
        } else {
          phone = gaiaUserNode.secondaryPhoneNumber
        }
      }
      let email
      if (!gaiaUserNode.dummyUsername) {
        if (gaiaUserNode.email) {
          email = gaiaUserNode.email
        }
      }
      if (gaiaUserNode.secondaryEmail) {
        if (email) {
          email = `${email}, ${gaiaUserNode.secondaryEmail}`
        } else {
          email = gaiaUserNode.secondaryEmail
        }
      }
      let paid
      if (sessionNode.waiveBookingFee) {
        paid = '$0'
      } else {
        paid = `$${sessionNode.price}`
      }
      let paymentIntent
      if (sessionNode.stripePaymentIntents.edges.length > 0) {
        paymentIntent = sessionNode.stripePaymentIntents.edges[0].node
        if (paymentIntent.refundedBySystem) {
          paid = `${paid} refunded by Airstudio`
        } else if (paymentIntent.refundedBy) {
          paid = `${paid} refunded by ${paymentIntent.refundedBy?.fullName}`
        }
      }
      let sessionPackage = sessionNode.sessionPackage.title
      if (sessionNode.organizationPackageBackground) {
        sessionPackage = `${sessionPackage} - ${sessionNode.organizationPackageBackground.backgroundColor}`
      }
      let organizationName
      let organizationId
      if (sessionNode.subjectGroup?.organization) {
        organizationName = sessionNode.subjectGroup.organization.name
        organizationId = sessionNode.subjectGroup.organization.id
      } else if (sessionNode.organization) {
        organizationName = sessionNode.organization.name
        organizationId = sessionNode.organization.id
      }
      let resitReason
      if (
        sessionNode.resitPreviousSession &&
        sessionNode.resitPreviousSession.id != sessionNode.id
      ) {
        resitReason = sessionNode.resitPreviousSession.resitReason.reason
      }
      currentSessions.push({
        status,
        time: `${startDateTime.toFormat('h:mma')} - ${endDateTime.toFormat(
          'h:mma'
        )}`,
        recordId: sessionNode.recordId,
        sessionPackage,
        subject: `${gaiaUserNode?.firstName} ${gaiaUserNode?.lastName}`,
        subjectGroup: sessionNode.subjectGroup?.name
          ? sessionNode.subjectGroup.name
          : '',
        organization: organizationName,
        subjectId: sessionNode.subject?.id,
        subjectGroupId: sessionNode.subjectGroup?.id,
        subjectEmail: email,
        subjectPhone: phone,
        organizationId: organizationId,
        paid: paid,
        resitReason: resitReason,
        id: sessionNode.id,
        contentType: sessionNode.contentType,
        billSubject: sessionNode.billSubject ? 'Subject' : 'Organization',
        node: sessionNode,
      })
    })
    setSessions((prevState) => {
      const currentIds = new Set(currentSessions.map((item) => item.id))
      const filteredPrevState = prevState.filter(
        (item) => !currentIds.has(item.id)
      )
      return [...filteredPrevState, ...currentSessions]
    })
    if (sessions.pageInfo.hasNextPage) {
      sessionsQueryFetchMore({
        variables: {
          sessionsJob: jobId,
          cursor: sessions.pageInfo.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev
          handleSessionsQueryData(fetchMoreResult?.sessions)
          return fetchMoreResult
        },
      })
    }
  }

  useEffect(() => {
    if (sessionsQueryData?.sessions) {
      handleSessionsQueryData(sessionsQueryData.sessions)
    }
  }, [sessionsQueryData])

  if (sessionsQueryError) return <>Error loading sessions</>
  return (
    <>
      {noShowSession && (
        <NoShowModal
          showModal={true}
          toggleModal={setNoShowSession}
          id={noShowSession}
        />
      )}
      <div className="sessions mt-3">
        <>
          {canAddSessions && sessionsOnly && (
            <JobPage jobDetailSessions={true} jobId={jobId} />
          )}
          {!sessionsOnly && (
            <>
              <Row>
                <Col className="d-flex align-items-center">
                  <button
                    type="button"
                    onClick={() => setDisplay(!display)}
                    className="px-0 btn-link mr-1"
                    style={{ marginTop: '-10px' }}
                  >
                    <>
                      {display ? (
                        <>
                          <CaretDown size={20} />
                        </>
                      ) : (
                        <>
                          <CaretRight size={20} />
                        </>
                      )}
                    </>
                  </button>
                  <h5 className="mb-1">Sessions</h5>
                </Col>
              </Row>
            </>
          )}
          {display && (
            <>
              {sessionsQueryLoading ||
              !sessionsQueryData ||
              !awsS3?.client ||
              !awsS3?.bucket ? (
                <Loading message="Loading Sessions..." />
              ) : (
                <>
                  {sessions.length > 0 ? (
                    <Row className="mt-2">
                      <Col md={12}>
                        <SortableInfiniteTable
                          tableData={sessions}
                          tableColumns={tableColumns}
                          tableHeight={400}
                          hasMoreTableData={false}
                          onTdClicks={{
                            subjectGroup: (cell) => {
                              setDetailSessionId(cell.row.original.id)
                            },
                            id: (cell) => {
                              setDetailSessionId(cell.row.original.id)
                            },
                            organization: (cell) => {
                              setDetailSessionId(cell.row.original.id)
                            },
                            subject: (cell) => {
                              if (cell.row.original.node.subject) {
                                if (orgContact) {
                                  history.push(
                                    `/subject/${cell.row.original.node.subject.id}/contact`
                                  )
                                } else {
                                  history.push(
                                    `/subject/${cell.row.original.node.subject.id}`
                                  )
                                }
                              } else {
                                setDetailSessionId(cell.row.original.id)
                              }
                            },
                            subjectEmail: (cell) => {
                              setDetailSessionId(cell.row.original.id)
                            },
                            subjectPhone: (cell) => {
                              setDetailSessionId(cell.row.original.id)
                            },
                            status: (cell) => {
                              setDetailSessionId(cell.row.original.id)
                            },
                            time: (cell) => {
                              setDetailSessionId(cell.row.original.id)
                            },
                            sessionPackage: (cell) => {
                              setDetailSessionId(cell.row.original.id)
                            },
                            billSubject: (cell) => {
                              setDetailSessionId(cell.row.original.id)
                            },
                          }}
                          rowPointer
                        />
                      </Col>
                    </Row>
                  ) : (
                    <span className="text-secondary">- - -</span>
                  )}
                </>
              )}
            </>
          )}
        </>
        {orgContact && detailSessionId && (
          <SessionDetailModal
            showModal={detailSessionId}
            toggleModal={setDetailSessionId}
            sessionId={detailSessionId}
          />
        )}
        {!orgContact && detailSessionId && (
          <AdminSessionDetailModal
            toggleModal={setDetailSessionId}
            sessionId={detailSessionId}
            showModal={detailSessionId}
          />
        )}

        <FotomerchantGalleryPasswordModal
          showModal={fotomerchantGalleryPassword}
          toggleModal={setFotomerchantGalleryPassword}
          fotomerchantPassword={fotomerchantGalleryPassword}
        />

        <SessionDetailFormModal
          jobId={jobId}
          sessionId={selectedSessionId}
          showModal={showSessionDetailModal}
          toggleModal={() => {
            setShowSessionDetailModal(false)
            setSelectedSessionId(null)
          }}
        />
      </div>
    </>
  )
}
export default JobDetailSessionsTable
