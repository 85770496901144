import React, { useEffect, useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import DateFilter from '../../common/DateFilter'
import Loading from '../../common/Loading'
import { CloudArrowDown } from 'react-bootstrap-icons'
import toast from 'react-hot-toast'
import { useDownloadAndDeleteFile } from '../../../libs/downloadAndDeleteFile'

const EmployeeHours = (props) => {
  const { showModal } = props
  const { downloadAndDeleteFile } = useDownloadAndDeleteFile()

  const [employeeSchedule, setEmployeeSchedule] = useState()
  const [finalSchedule, setFinalSchedule] = useState()
  const [startDateFilter, setStartDateFilter] = useState()
  const [endDateFilter, setEndDateFilter] = useState()
  const [downloadPdfLoading, setDownloadPdfLoading] = useState(false)
  const [employeeIdList, setEmployeeIdList] = useState([])

  const [getEmployees, { data: employees, loading: employeesLoading }] =
    useLazyQuery(
      gql`
        query EmployeesQuery($employeeIds: [ID]) {
          employees(id: $employeeIds, orderBy: "gaia_user__full_name") {
            edges {
              node {
                id
                gaiaUser {
                  fullName
                }
              }
            }
          }
        }
      `,
      {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
          const _employeeSchedule = {}
          const employeeIds = []
          data.employees.edges.forEach((node) => {
            employeeIds.push(node.node.id)
            _employeeSchedule[node.node.id] = {
              minutes: 0,
              jobs: [],
              employee: node.node,
            }
          })
          setEmployeeIdList(employeeIds)
          setEmployeeSchedule(_employeeSchedule)
          getJobs({
            variables: {
              employeeIds: employeeIds,
              startDateTime: startDateFilter.toISO(),
              endDateTime: endDateFilter.toISO(),
            },
          })
        },
      }
    )

  useEffect(() => {
    if (startDateFilter && endDateFilter) {
      const variables = {}
      getEmployees({
        variables,
      })
    }
  }, [startDateFilter, endDateFilter])
  useEffect(() => {
    if (!showModal) {
      setFinalSchedule()
      setEmployeeSchedule()
      setStartDateFilter()
      setEndDateFilter()
    }
  }, [showModal])

  const [getJobs, { data: jobs, loading: jobsLoading }] = useLazyQuery(
    gql`
      query EmployeeJobsQuery(
        $startDateTime: DateTime!
        $endDateTime: DateTime!
        $employees: [ID]
      ) {
        jobs(
          startDateTime_Gte: $startDateTime
          endDateTime_Lte: $endDateTime
          employees: $employees
        ) {
          edges {
            node {
              id
              employeeJobs {
                edges {
                  node {
                    minutesLong
                    employee {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        data.jobs.edges.forEach((node) => {
          node.node.employeeJobs.edges.forEach((employeeJob) => {
            if (
              employeeJob.node.employee &&
              employeeSchedule[employeeJob.node.employee.id]
            ) {
              employeeSchedule[employeeJob.node.employee.id].minutes +=
                employeeJob.node.minutesLong
            }
          })
        })
        setFinalSchedule(employeeSchedule)
      },
    }
  )

  const [downloadEmployeeHours] = useMutation(
    gql`
      mutation DownloadEmployeeHours($input: DownloadEmployeeHoursInput!) {
        downloadEmployeeHours(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        if (data?.downloadEmployeeHours?.file?.fileName) {
          downloadAndDeleteFile(
            data.downloadEmployeeHours.file.fileName,
            data.downloadEmployeeHours.file.displayName,
            data.downloadEmployeeHours.file.id,
            () => {
              toast.success(`Employee Hours Downloaded`)
              setDownloadPdfLoading(false)
            }
          )
        }
      },
      errorPolicy: 'all',
    }
  )

  return (
    <>
      <Row className="mt-4 mb-1">
        <Col>
          <DateFilter
            startDateFilter={startDateFilter}
            setStartDateFilter={setStartDateFilter}
            endDateFilter={endDateFilter}
            setEndDateFilter={setEndDateFilter}
            placeholderStart="Start Date"
            placeholderEnd="End Date"
          />
        </Col>
        {startDateFilter &&
          endDateFilter &&
          employees &&
          jobs &&
          finalSchedule && (
            <Col style={{ textAlign: 'end' }}>
              <Button
                variant="link"
                disabled={downloadPdfLoading}
                onClick={() => {
                  setDownloadPdfLoading(true)
                  downloadEmployeeHours({
                    variables: {
                      input: {
                        employeeIds: employeeIdList,
                        startDate: startDateFilter.toISO(),
                        endDate: endDateFilter.toISO(),
                      },
                    },
                  })
                }}
              >
                <CloudArrowDown className="mr-2" />
                Download PDF
              </Button>
            </Col>
          )}
      </Row>
      {(jobsLoading || employeesLoading) && (
        <Row>
          <Col>
            <Loading message="Loading Employee Hours..." />
          </Col>
        </Row>
      )}
      {startDateFilter &&
        endDateFilter &&
        employees &&
        jobs &&
        finalSchedule && (
          <Row className="mt-4">
            <Col>
              <SortableInfiniteTable
                tableData={Object.entries(finalSchedule)}
                tableColumns={[
                  {
                    Header: 'Employee',
                    id: 'employee',
                    accessor: (row) => {
                      return row[1].employee.gaiaUser.fullName
                    },
                  },
                  {
                    Header: 'Assigned Time',
                    id: 'time',
                    accessor: (row) => {
                      const hours = row[1].minutes / 60
                      const rhours = Math.floor(hours)
                      const minutes = (hours - rhours) * 60
                      const rminutes = Math.round(minutes)
                      let message = ''
                      if (rhours == 1) {
                        message = '1 hour'
                      } else {
                        message = `${rhours} hours`
                      }
                      if (rminutes == 1) {
                        if (message === '0 hours') {
                          message = '1 minute'
                        } else {
                          message += ' and 1 minute'
                        }
                      } else if (rminutes > 1) {
                        if (message === '0 hours') {
                          message = `${rminutes} minutes`
                        } else {
                          message += ` and ${rminutes} minutes`
                        }
                      }
                      if (message === '0 hours') {
                        message = 'No time assigned'
                      }
                      return message
                    },
                  },
                ]}
                tableHeight={350}
              />
            </Col>
          </Row>
        )}
    </>
  )
}

export default EmployeeHours
