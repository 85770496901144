import React, { useState } from 'react'
import { gql, useMutation, useReactiveVar } from '@apollo/client'
import {
  Form,
  Button,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import {
  InfoCircle,
  Trash,
  PlusCircle,
  CaretDown,
  CaretRight,
} from 'react-bootstrap-icons'
import AuditLog from '../../audit_log/AuditLog'
import { settingsVar } from '../../../libs/apollo'

const ItemTypeForm = (props) => {
  const { itemType, afterSubmit } = props

  const [submitting, setSubmitting] = useState(false)
  const [displayAuditLog, setDisplayAuditLog] = useState(false)
  const settings = useReactiveVar(settingsVar)
  const [deleteEquipmentItemType] = useMutation(
    gql`
      mutation DeleteEquipmentItemType(
        $deleteEquipmentItemTypeInput: DeleteEquipmentItemTypeInput!
      ) {
        deleteEquipmentItemType(input: $deleteEquipmentItemTypeInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Item Type Deleted`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: [
        'EquipmentBags',
        'EquipmentBagTypes',
        'EquipmentCategories',
        'EquipmentItems',
        'EquipmentItemTypes',
      ],
    }
  )

  const [createEquipmentItemType] = useMutation(
    gql`
      mutation CreateEquipmentItemType($input: CreateEquipmentItemTypeInput!) {
        createEquipmentItemType(input: $input) {
          equipmentItemType {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Item Type Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: [
        'EquipmentBags',
        'EquipmentBagTypes',
        'EquipmentCategories',
        'EquipmentItems',
        'EquipmentItemTypes',
      ],
    }
  )

  const [updateEquipmentItemType] = useMutation(
    gql`
      mutation UpdateEquipmentItemType($input: UpdateEquipmentItemTypeInput!) {
        updateEquipmentItemType(input: $input) {
          equipmentItemType {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Item Type Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: [
        'EquipmentBags',
        'EquipmentBagTypes',
        'EquipmentCategories',
        'EquipmentItems',
        'EquipmentItemTypes',
      ],
    }
  )
  const formik = useFormik({
    initialValues: itemType
      ? {
          name: itemType.name,
          miscellaneous: itemType.miscellaneous,
        }
      : {
          name: '',
          miscellaneous: false,
        },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('required'),
      miscellaneous: Yup.boolean().nullable(),
    }),
    validateOnChange: true,
    onSubmit: (values) => {
      setSubmitting(true)
      if (itemType) {
        updateEquipmentItemType({
          variables: {
            input: {
              equipmentItemTypeInput: {
                id: itemType.id,
                name: values.name,
                miscellaneous: values.miscellaneous,
              },
            },
          },
        })
      } else {
        createEquipmentItemType({
          variables: {
            input: {
              equipmentItemTypeInput: {
                name: values.name,
                miscellaneous: values.miscellaneous,
              },
            },
          },
        })
      }
    },
  })

  const handleDelete = () => {
    setSubmitting(true)
    deleteEquipmentItemType({
      variables: {
        deleteEquipmentItemTypeInput: {
          equipmentItemTypeIds: itemType.id,
        },
      },
    })
  }

  return (
    <>
      <div id="equipmentItemTypeForm">
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group as={Row}>
            <Col md={3}>
              <Form.Label column sm="12" md="auto">
                Name
              </Form.Label>
            </Col>
            <Col sm="12" md={6}>
              <Form.Control
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                isInvalid={formik.errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col md={3}>
              <Form.Label column sm="12" md="auto">
                Miscellaneous
              </Form.Label>
            </Col>
            <Col md={1}>
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip>
                    <p>
                      Miscellaneous item types represent items that do not need
                      individual items created for each like combs
                    </p>
                  </Tooltip>
                }
              >
                <InfoCircle className="ml-1" style={{ fontSize: '14px' }} />
              </OverlayTrigger>
            </Col>
            <Col sm="12" md={1}>
              <Form.Check
                type="checkbox"
                name="miscellaneous"
                checked={formik.values.miscellaneous}
                onChange={() => {
                  formik.setFieldValue(
                    'miscellaneous',
                    !formik.values.miscellaneous
                  )
                }}
                isInvalid={formik.errors.miscellaneous}
                feedback={formik.errors.miscellaneous}
              />
            </Col>
          </Form.Group>
          {itemType && (
            <>
              <Form.Group as={Row} className="">
                <Col md={3} className="d-flex align-items-center">
                  <button
                    type="button"
                    onClick={() => setDisplayAuditLog(!displayAuditLog)}
                    className="px-0 btn-link mr-1"
                  >
                    <>
                      {displayAuditLog ? (
                        <CaretDown size={17} />
                      ) : (
                        <CaretRight size={17} />
                      )}
                    </>
                  </button>
                  <Form.Label column sm="12" md="auto">
                    History
                  </Form.Label>
                </Col>
              </Form.Group>
              {displayAuditLog && (
                <Form.Group as={Row} className="">
                  <Col md={12}>
                    <AuditLog
                      contentType={itemType.contentType.model}
                      id={itemType.id}
                    />
                  </Col>
                </Form.Group>
              )}
            </>
          )}
          <Form.Row className="mt-2">
            <Col md={3}>
              <Button
                type="submit"
                variant="outline-primary"
                block
                disabled={submitting}
              >
                <PlusCircle className="mr-2" />
                Save
              </Button>
            </Col>

            {itemType && (
              <Col md={3}>
                <Button
                  variant="outline-danger"
                  onClick={handleDelete}
                  block
                  disabled={submitting}
                >
                  <Trash className="mr-2" />
                  Delete
                </Button>
              </Col>
            )}
          </Form.Row>
        </Form>
      </div>
    </>
  )
}

export default ItemTypeForm
