import React, { useEffect, useState } from 'react'
import { useLazyQuery, gql, useMutation } from '@apollo/client'
import { Row, Col, Form, ButtonGroup, Tab, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { Clock, Envelope, PlusCircle } from 'react-bootstrap-icons'
import EmployeeRoles from './EmployeeRoles'
import Timesheet from './timesheet/Timesheet'
import NewEmployeeModal from './NewEmployeeModal'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import BulkImportModal from './BulkImportModal'
import { loggedInUserVar } from '../../libs/apollo'
import { useReactiveVar } from '@apollo/client'
import EmployeeSchedule from './employee_schedule/EmployeeSchedule'
import {
  PersonWorkspace,
  Lock,
  Trash,
  CloudArrowDown,
  CalendarWeek,
  PersonBadge,
  Alarm,
} from 'react-bootstrap-icons'
import Page from '../layout/Page'
import PermissionsModal from './PermissionsModal'
import DeleteEmployeeModal from './DeleteEmployeeModal'
import toast from 'react-hot-toast'
import Tabs from '../common/Tabs'
import Loading from '../common/Loading'
import { useDownloadAndDeleteFile } from '../../libs/downloadAndDeleteFile'
import EmployeeRoleSearchInput from '../common/node_search_input/EmployeeRoleSearchInput'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const Employees = () => {
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const adminManager = ['Administrator', 'Scheduling Manager'].includes(
    loggedInUser?.permissions?.group
  )
  const defaultOrderBy = 'gaia_user__full_name'
  const [orderBy, setOrderBy] = useState(defaultOrderBy)
  const [hasMoreEmployees, setHasMoreEmployees] = useState(true)
  const [cursor, setCursor] = useState()
  const [searchText, setSearchText] = useState()
  const [employees, setEmployees] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [checkedEmployeeIds, setCheckedEmployeeIds] = useState([])
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [showNewEmployeeModal, setShowEmployeeModal] = useState(false)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [showImportEmployeeModal, setShowImportEmployeeModal] = useState(false)
  const [showPermissions, setShowPermissions] = useState(false)
  const [showDeleteEmployeesModal, setShowDeleteEmployeesModel] =
    useState(false)
  const history = useHistory()
  const [activeTab, setActiveTab] = useState('Employees')
  const { downloadAndDeleteFile } = useDownloadAndDeleteFile()

  const formikRoles = useFormik({
    initialValues: {
      roles: [],
    },
    validationSchema: Yup.object().shape({
      roles: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().nullable(),
          description: Yup.string().nullable(),
        })
      ),
    }),
  })

  const tableColumns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: (row) => formatEmployeeName(row),
      serverSort: true,
      orderBy: 'gaia_user__full_name',
    },
    {
      Header: 'Email',
      id: 'email',
      accessor: (row) => {
        let email
        if (row.node.gaiaUser.email) {
          email = row.node.gaiaUser.email
        }
        if (
          !loggedInUser?.permissions?.group === 'General Staff' &&
          row.node.gaiaUser.secondaryEmail
        ) {
          if (email) {
            email = `${email}, ${row.node.gaiaUser.secondaryEmail}`
          } else {
            email = row.node.gaiaUser.secondaryEmail
          }
        }
        return email
      },
      serverSort: true,
      orderBy: 'gaia_user__email',
    },
    {
      Header: 'Phone Number',
      id: 'phone',
      serverSort: true,
      orderBy: 'gaia_user__phone_number',
      accessor: (row) => {
        let phone
        if (row.node.gaiaUser.phoneNumber) {
          phone = row.node.gaiaUser.phoneNumber
        }
        if (row.node.gaiaUser.secondaryPhoneNumber) {
          if (phone) {
            phone = `${phone}, ${row.node.gaiaUser.secondaryPhoneNumber}`
          } else {
            phone = row.node.gaiaUser.secondaryPhoneNumber
          }
        }
        return phone
      },
    },
    {
      Header: 'Roles',
      id: 'roles',
      accessor: (row) => {
        let roles = '- - -'
        if (row.node?.roles) {
          roles = ''
          row.node?.roles?.edges.forEach((role) => {
            roles = `${roles} ${role.node.name},`
          })
          roles = roles.slice(0, -1)
        }
        return (
          <>
            <small>{roles}</small>
          </>
        )
      },
    },
  ]

  const handleEmployeeCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedEmployeeIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedEmployeeIds((prevState) =>
        prevState.filter((id) => id !== row.node.id)
      )
    }
  }
  const [query, { error, data, fetchMore }] = useLazyQuery(
    gql`
      query EmployeesQuery(
        $cursor: String
        $searchTerm: String
        $orderBy: String
        $roleIds: String
      ) {
        employees(
          first: 20
          after: $cursor
          search: $searchTerm
          orderBy: $orderBy
          roleIds: $roleIds
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              gaiaUser {
                firstName
                lastName
                abbreviatedName
                email
                groups {
                  edges {
                    node {
                      name
                    }
                  }
                }
                secondaryEmail
                phoneNumber
                secondaryPhoneNumber
                lastLogin
                loginBrowser
                loginLocation
              }
              roles {
                edges {
                  node {
                    name
                  }
                }
              }
              internalEmployeeId
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query({
        variables: {
          orderBy: defaultOrderBy,
        },
      })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (data?.employees) {
      if (loadingSearch) {
        setLoadingSearch(false)
      }
      if (data.employees.pageInfo.endCursor) {
        setCursor(data.employees.pageInfo.endCursor)
      }
      setHasMoreEmployees(data.employees.pageInfo.hasNextPage)
      setEmployees(data.employees.edges)
      let text = 'Search 0 Employees'
      if (data.employees.nodeCount > 0) {
        text = `Search ${data.employees.nodeCount} Employees`
      }
      setSearchText(text)
    }
  }, [data])

  const fetchMoreResults = () => {
    const variables = {
      cursor,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    if (formikRoles.values.roles.length > 0) {
      variables.roleIds = formikRoles.values.roles
        .map((role) => role.id)
        .join(',')
    }
    fetchMore({
      variables,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        const newEmployees = fetchMoreResult.employees.edges
        const pageInfo = fetchMoreResult.employees.pageInfo
        setCursor(pageInfo.endCursor)
        setHasMoreEmployees(pageInfo.hasNextPage)
        setEmployees((prevState) => {
          const currentIds = new Set(newEmployees.map((item) => item.node.id))
          const filteredPrevState = prevState.filter(
            (item) => !currentIds.has(item.node.id)
          )
          return [...filteredPrevState, ...newEmployees]
        })
      },
    })
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    setCursor(null)
    setLoadingSearch(true)
    query({
      variables: {
        searchTerm: currentSearchTerm,
      },
    })
    const variables = {
      searchTerm: currentSearchTerm,
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    if (formikRoles.values.roles.length > 0) {
      variables.roleIds = formikRoles.values.roles
        .map((role) => role.id)
        .join(',')
    }
    query({ variables })
  }

  const handleSortByChange = (currentOrderBy) => {
    if (currentOrderBy === '' && orderBy === defaultOrderBy) return
    currentOrderBy = currentOrderBy ? currentOrderBy : defaultOrderBy
    setOrderBy(currentOrderBy)
    const variables = {
      orderBy: currentOrderBy,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (formikRoles.values.roles.length > 0) {
      variables.roleIds = formikRoles.values.roles
        .map((role) => role.id)
        .join(',')
    }
    query({ variables })
  }

  useEffect(() => {
    if (formikRoles?.values?.roles) {
      setLoadingSearch(true)
      const variables = {}
      if (formikRoles.values.roles.length > 0) {
        variables.roleIds = formikRoles.values.roles
          .map((role) => role.id)
          .join(',')
      }
      if (orderBy) {
        variables.orderBy = orderBy
      }
      if (searchTerm) {
        variables.searchTerm = searchTerm
      }
      query({ variables })
    }
  }, [formikRoles.values.roles])

  const formatEmployeeName = (employee) => {
    const { gaiaUser } = employee.node
    return `${gaiaUser.firstName} ${gaiaUser.lastName}`
  }

  const toggleNewEmployeeModal = () => {
    setShowEmployeeModal((prevState) => !prevState)
  }

  const toggleImportEmployeeModal = () => {
    setShowImportEmployeeModal((prevState) => !prevState)
  }

  const togglePermissionsModal = () => {
    setShowPermissions((prevState) => !prevState)
  }

  const onTdClick = (cell) => {
    if (adminManager) {
      history.push(`/employee/${cell.row.original.node.id}`)
    }
  }

  const [downloadEmployees] = useMutation(
    gql`
      mutation DownloadEmployees($input: DownloadEmployeesInput!) {
        downloadEmployees(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        downloadAndDeleteFile(
          data.downloadEmployees.file.fileName,
          data.downloadEmployees.file.displayName,
          data.downloadEmployees.file.id,
          () => {
            toast.success(`Employees Downloaded`)
          }
        )
      },
      errorPolicy: 'all',
    }
  )

  const onTdClicks = {
    name: onTdClick,
    email: onTdClick,
    phone: onTdClick,
    roles: onTdClick,
    id: onTdClick,
    login: onTdClick,
  }

  if (!initialQueryRun && !data)
    return (
      <Row>
        <Col>
          <Loading message="Loading Employees..." />
        </Col>
      </Row>
    )
  if (error) return <>Error loading employees</>

  let actions = []
  if (
    ['Administrator', 'Scheduling Manager'].includes(
      loggedInUser?.permissions?.group
    )
  ) {
    tableColumns.splice(5, 0, {
      Header: 'Permissions',
      id: 'permissions',
      accessor: (row) => {
        return (
          <>
            <small>{row.node.gaiaUser?.groups?.edges[0]?.node.name}</small>
          </>
        )
      },
      serverSort: true,
      orderBy: 'gaia_user__groups__name',
    })
    tableColumns.push({
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  employees.forEach((employee) => {
                    if (!checkedEmployeeIds.includes(employee.node.id)) {
                      appendIds.push(employee.node.id)
                    }
                  })
                  setCheckedEmployeeIds((prevState) => {
                    return [...prevState, ...appendIds]
                  })
                } else {
                  setCheckedEmployeeIds([])
                }
              }}
            />
            {checkedEmployeeIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedEmployeeIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'actions',
      accessor: (row) => {
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                checked={checkedEmployeeIds.includes(row.node.id)}
                onChange={(e) => handleEmployeeCheck(e, row)}
              />
            </Form.Group>
          </>
        )
      },
    })
  }
  const employeeTableJsx = (
    <>
      <Row className="mt-1">
        <Col md={4}>
          <Form.Group>
            <Form.Control
              size="sm"
              type="text"
              name="searchTerm"
              placeholder={searchText}
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <EmployeeRoleSearchInput
            formik={formikRoles}
            multiple
            placeholder="Select Roles"
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <SortableInfiniteTable
            hideGlobalFilter
            tableData={employees}
            loading={loadingSearch}
            loadingMessage="Loading Employees..."
            tableColumns={tableColumns}
            fetchMoreTableData={fetchMoreResults}
            hasMoreTableData={hasMoreEmployees}
            onTdClicks={onTdClicks}
            rowPointer
            tableHeight={700}
            handleSortByChange={handleSortByChange}
          />
        </Col>
      </Row>
    </>
  )
  return (
    <>
      <Page title="Employees" actions={actions}>
        {!adminManager && <>{employeeTableJsx}</>}
        {adminManager && (
          <>
            <Tabs activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)}>
              <Tab
                key="Employees"
                eventKey="Employees"
                style={{ marginTop: '10px' }}
                title={
                  <b>
                    <PersonWorkspace className="mr-2" />
                    Employees
                  </b>
                }
              >
                <Row className="mb-2">
                  <Col>
                    <div>
                      <Button variant="link" onClick={toggleNewEmployeeModal}>
                        <PlusCircle className="mr-2" />
                        New Employee
                      </Button>
                      <Button variant="link" onClick={togglePermissionsModal}>
                        <Lock className="mr-2" />
                        Permissions
                      </Button>
                      <Button
                        variant="link"
                        onClick={() => {
                          downloadEmployees({
                            variables: {
                              input: {
                                employeeIds: checkedEmployeeIds,
                                search: searchTerm,
                                roleIds: formikRoles.values.roles
                                  ? formikRoles.values.roles.map(
                                      (role) => role.id
                                    )
                                  : null,
                              },
                            },
                          })
                        }}
                      >
                        <CloudArrowDown className="mr-2" />
                        Download {checkedEmployeeIds.length > 0 && <>Excel</>}
                      </Button>
                      {checkedEmployeeIds.length > 0 && (
                        <Button
                          variant="link"
                          onClick={() => {
                            setShowDeleteEmployeesModel(true)
                          }}
                        >
                          <Trash className="mr-2" />
                          {checkedEmployeeIds.length === 1 ? (
                            <>Delete Employee</>
                          ) : (
                            <>Delete Employees</>
                          )}
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
                {employeeTableJsx}
              </Tab>
              <Tab
                key="TimeSheet"
                eventKey="TimeSheet"
                style={{ marginTop: '10px' }}
                title={
                  <b>
                    <Clock className="mr-2" />
                    Timesheet
                  </b>
                }
              >
                <Timesheet />
              </Tab>
              <Tab
                key="Work Schedule"
                eventKey="Work Schedule"
                style={{ marginTop: '10px' }}
                title={
                  <b>
                    <CalendarWeek className="mr-2" />
                    Work Schedule
                  </b>
                }
              >
                <EmployeeSchedule />
              </Tab>
              <Tab
                key="Employee Roles"
                eventKey="Employee Roles"
                style={{ marginTop: '10px' }}
                title={
                  <b>
                    <PersonBadge className="mr-2" />
                    Employee Roles
                  </b>
                }
              >
                <EmployeeRoles />
              </Tab>
            </Tabs>
            {showNewEmployeeModal && (
              <NewEmployeeModal
                showModal={showNewEmployeeModal}
                toggleModal={toggleNewEmployeeModal}
              />
            )}

            <BulkImportModal
              showModal={showImportEmployeeModal}
              toggleModal={toggleImportEmployeeModal}
            />
            <DeleteEmployeeModal
              showModal={showDeleteEmployeesModal}
              toggleModal={() => {
                setShowDeleteEmployeesModel(false)
              }}
              setCheckedEmployeeIds={setCheckedEmployeeIds}
              employeeIds={
                checkedEmployeeIds.length > 1 ? checkedEmployeeIds : null
              }
              employeeId={
                checkedEmployeeIds.length === 1 ? checkedEmployeeIds[0] : null
              }
            />
            <PermissionsModal
              showModal={showPermissions}
              hideModal={() => setShowPermissions(false)}
            />
          </>
        )}
      </Page>
    </>
  )
}
export default Employees
