import React, { useEffect, useState } from 'react'
import { gql, useLazyQuery, useMutation, useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../../libs/apollo'
import DeleteTimesheetModal from './DeleteTimesheetModal'
import { Modal, Form, Col, Row, Button } from 'react-bootstrap'
import { Formik, Field, FieldArray, ErrorMessage } from 'formik'
import EmployeeSearchInput from '../../common/node_search_input/EmployeeSearchInput'
import EmployeeJobSearchInput from '../../common/node_search_input/EmployeeJobSearchInput'
import EmployeeScheduleSearchInput from '../../common/node_search_input/EmployeeScheduleSearchInput'
import toast from 'react-hot-toast'
import { DateTime } from 'luxon'
import * as Yup from 'yup'
import Loading from '../../common/Loading'
import {
  Alarm,
  Buildings,
  Clock,
  PlusCircle,
  Trash,
} from 'react-bootstrap-icons'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export default function TimesheetModal(props) {
  const { showTimesheetModal, updatingTimesheet, timesheet, toggleModal } =
    props

  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)
  const [showDeleteForm, setShowDeleteForm] = useState(false)
  const [submitting, setIsSubmitting] = useState(false)
  const [display, setDisplay] = useState(true)

  const dropDownOptions = [
    {
      key: 'Employee',
      value: 'employee',
    },
    {
      key: 'Employee Job Assignment',
      value: 'employeeJob',
    },
    {
      key: 'Employee Work Event',
      value: 'employeeSchedule',
    },
  ]

  const [createTimesheet, { error: timesheetError }] = useMutation(
    gql`
      mutation CreateTimesheetMutation($input: CreateTimesheetInput!) {
        createTimesheet(input: $input) {
          timesheet {
            id
          }
        }
      }
    `,
    {
      onCompleted(data) {
        setIsSubmitting(false)
        if (!timesheetError) {
          toggleModal()
          toast.success('Timesheet Created')
        }
      },
      errorPolicy: 'all',
      refetchQueries: ['TimesheetsQuery'],
    }
  )

  const [updateTimesheet, { error: updateTimesheetError }] = useMutation(
    gql`
      mutation UpdateTimesheetMutation($input: UpdateTimesheetInput!) {
        updateTimesheet(input: $input) {
          timesheet {
            id
          }
        }
      }
    `,
    {
      onCompleted(data) {
        setIsSubmitting(false)
        if (!updateTimesheetError) {
          toggleModal()
          toast.success('Timesheet Saved')
        }
      },
      onError(error) {
        setIsSubmitting(false)
        toast.error('An Error Occured')
      },
      refetchQueries: ['TimesheetsQuery'],
    }
  )

  const editJobDate = (dateString) => {
    if (dateString) {
      const lDate = DateTime.fromISO(dateString)
      return new Date(lDate.c.year, lDate.c.month - 1, lDate.c.day, 0, 0, 0)
    }
  }

  const getUtcDt = (dateDt, timeDt) => {
    return DateTime.utc(
      dateDt.getFullYear(),
      dateDt.getMonth() + 1,
      dateDt.getDate(),
      timeDt.hour,
      timeDt.minute
    )
  }

  const timeSheetInitialValues = updatingTimesheet
    ? {
        associate: timesheet?.employee?.id
          ? 'employee'
          : timesheet?.employeeJob?.id
          ? 'employeeJob'
          : timesheet?.employeeSchedule?.id
          ? 'employeeSchedule'
          : '',
        employeeId: timesheet?.employee?.id || '',
        employeeDescription: timesheet?.employee?.id
          ? timesheet?.employee.gaiaUser.fullName
          : '',
        employeeJobId: timesheet?.employeeJob?.id || '',
        employeeJobDescription: timesheet?.employeeJob?.id
          ? timesheet?.employeeJob.job.name
          : '',
        employeeScheduleId: timesheet?.employeeSchedule?.id || '',
        employeeScheduleDescription: timesheet?.employeeSchedule?.id
          ? timesheet?.employeeSchedule.employee.gaiaUser.fullName
          : '',
        date: timesheet.startDateTime
          ? editJobDate(timesheet.startDateTime)
          : '',
        startTime: timesheet.startDateTime
          ? DateTime.fromISO(timesheet.startDateTime).toFormat('h:mma')
          : '',
        endTime: timesheet.endDateTime
          ? DateTime.fromISO(timesheet.endDateTime).toFormat('h:mma')
          : '',
        timesheetEvents: timesheet?.timesheetEvents?.edges?.map((edge) => {
          const node = edge.node
          return {
            id: node.id,
            date: editJobDate(node.dateTime),
            time: DateTime.fromISO(node.dateTime).toFormat('h:mma'),
            eventType: node.eventType,
          }
        }),
      }
    : {
        associate: 'employee',
        employeeId: '',
        employeeJobId: '',
        employeeScheduleId: '',
        date: '',
        startTime: '',
        endTime: '',
        timesheetEvents: [],
      }

  return (
    <>
      <Formik
        initialValues={timeSheetInitialValues}
        validationSchema={Yup.object().shape({
          associate: Yup.string().required('required'),
          employeeId: Yup.string().nullable(),
          employeeJobId: Yup.string().nullable(),
          employeeScheduleId: Yup.string().nullable(),
          date: Yup.string()
            .nullable()
            .when('associate', {
              is: (associate) => associate === 'employee',
              then: Yup.string().required('required'),
            }),
          startTime: Yup.string()
            .nullable()
            .when('associate', {
              is: (associate) => associate === 'employee',
              then: Yup.string()
                .matches(
                  /^(?:1[0-2]|0?[0-9]):[0-5][0-9]\s?((?:A|P)\.?M\.?)$/i,
                  'Invalid Format'
                )
                .required('required')
                .test('modFive', 'minutes must be divisible by 5', (value) => {
                  let valid = false
                  if (value) {
                    try {
                      if (
                        value === 0 ||
                        parseInt(value.split(':')[1]) % 5 === 0
                      ) {
                        valid = true
                      }
                    } catch {
                      //
                    }
                  }
                  return valid
                })
                .required('required'),
            }),
          endTime: Yup.string()
            .nullable()
            .when('associate', {
              is: (associate) => associate === 'employee',
              then: Yup.string()
                .matches(
                  /^(?:1[0-2]|0?[0-9]):[0-5][0-9]\s?((?:A|P)\.?M\.?)$/i,
                  'Invalid Format'
                )
                .required('required')
                .test('modFive', 'minutes must be divisible by 5', (value) => {
                  let valid = false
                  if (value) {
                    try {
                      if (
                        value === 0 ||
                        parseInt(value.split(':')[1]) % 5 === 0
                      ) {
                        valid = true
                      }
                    } catch {
                      //
                    }
                  }
                  return valid
                })
                .required('required'),
            }),
          timesheetEvents: Yup.array().of(
            Yup.object().shape({
              date: Yup.string().nullable(),
              time: Yup.string()
                .matches(
                  /^(?:1[0-2]|0?[0-9]):[0-5][0-9]\s?((?:A|P)\.?M\.?)$/i,
                  'Invalid Format'
                )
                .required('required')
                .test('modFive', 'minutes must be divisible by 5', (value) => {
                  let valid = false
                  if (value) {
                    try {
                      if (
                        value === 0 ||
                        parseInt(value.split(':')[1]) % 5 === 0
                      ) {
                        valid = true
                      }
                    } catch {
                      //
                    }
                  }
                  return valid
                })
                .required('required'),
              eventType: Yup.string().nullable(),
            })
          ),
        })}
        onSubmit={async (values) => {
          setIsSubmitting(true)
          let startDateTime
          let endDateTime
          if (values.associate === 'employee') {
            const startTimeToLuxon = DateTime.fromFormat(
              `${values.startTime}`,
              'h:ma'
            )
            const endTimeToLuxon = DateTime.fromFormat(
              `${values.startTime}`,
              'h:ma'
            )
            startDateTime = getUtcDt(values.date, startTimeToLuxon).toISO()
            endDateTime = getUtcDt(values.date, endTimeToLuxon).toISO()
          }
          if (!updatingTimesheet) {
            const timesheetInput = {
              employeeId: values.employeeId,
              employeeJobId: values.employeeJobId,
              employeeScheduleId: values.employeeScheduleId,
              startDateTime,
              endDateTime,
              timesheetEvents: values.timesheetEvents
                ? values.timesheetEvents.map((timesheetEvent) => {
                    const TimeToLuxon = DateTime.fromFormat(
                      `${timesheetEvent.time}`,
                      'h:ma'
                    )
                    return {
                      clockIn:
                        timesheetEvent.eventType === 'CLOCK_IN' ? true : false,
                      dateTime: getUtcDt(
                        timesheetEvent.date,
                        TimeToLuxon
                      ).toISO(),
                    }
                  })
                : [],
            }
            createTimesheet({
              variables: {
                input: {
                  input: timesheetInput,
                },
              },
            })
          } else {
            let employeeId
            if (timesheet.employee?.id != values.employeeId) {
              employeeId = values.employeeId
            }

            let employeeJobId
            if (timesheet.employeeJob?.id != values.employeeJobId) {
              employeeJobId = values.employeeJobId
            }

            let employeeScheduleId
            if (timesheet.employeeSchedule?.id != values.employeeScheduleId) {
              employeeScheduleId = values.employeeScheduleId
            }
            let addTimesheetEvents = []
            let updateTimesheetEvents = []
            let deleteTimesheetEventIds = []
            timesheet.timesheetEvents?.edges?.map((edge) => {
              const node = edge.node
              const timesheetEvent = values.timesheetEvents.find(
                (timesheetEvent) =>
                  timesheetEvent && timesheetEvent?.id === node.id
              )
              if (timesheetEvent) {
                const update = {}
                const dateTime = getUtcDt(
                  timesheetEvent.date,
                  DateTime.fromFormat(`${timesheetEvent.time}`, 'h:ma')
                ).toISO()

                if (
                  editJobDate(node.dateTime).toString() !==
                    timesheetEvent.date.toString() ||
                  timesheetEvent.time !==
                    DateTime.fromISO(node.dateTime).toFormat('h:mma')
                ) {
                  update.dateTime = dateTime
                }

                if (timesheetEvent.eventType !== node.eventType) {
                  update.clockIn =
                    timesheetEvent.eventType === 'CLOCK_IN' ? true : false
                }

                if (Object.keys(update).length > 0) {
                  updateTimesheetEvents.push({
                    ...update,
                    id: node.id,
                  })
                }
              } else {
                deleteTimesheetEventIds.push(node.id)
              }
            })
            values.timesheetEvents?.map((timesheetEvent) => {
              if (!timesheetEvent.id) {
                const TimeToLuxon = DateTime.fromFormat(
                  `${timesheetEvent.time}`,
                  'h:ma'
                )
                addTimesheetEvents.push({
                  clockIn:
                    timesheetEvent.eventType === 'CLOCK_IN' ? true : false,
                  dateTime: getUtcDt(timesheetEvent.date, TimeToLuxon).toISO(),
                })
              }
            })
            const timesheetInput = {
              id: timesheet.id,
              employeeId,
              employeeJobId,
              employeeScheduleId,
              startDateTime,
              endDateTime,
              addTimesheetEvents,
              updateTimesheetEvents,
              deleteTimesheetEventIds,
            }
            updateTimesheet({
              variables: {
                input: {
                  input: timesheetInput,
                },
              },
            })
          }
        }}
      >
        {(formik) => (
          <Modal
            show={showTimesheetModal}
            onHide={() => {
              toggleModal()
              setIsSubmitting(false)
              formik.resetForm()
            }}
            animation={false}
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <Clock className="mr-2" />
                {updatingTimesheet ? (
                  <span>Edit Timesheet</span>
                ) : (
                  <span> New Timesheet</span>
                )}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={formik.handleSubmit}>
                <Form.Row>
                  <Form.Group as={Col} md={4} className="mb-0 pb-0">
                    <Field
                      as="select"
                      name={'associate'}
                      className="form-control-sm form-select"
                      onChange={(e) => {
                        formik.setFormikState((prevState) => ({
                          ...prevState,
                          values: {
                            ...prevState.values,
                            associate: e.target.value,
                            employeeId: '',
                            employeeDescription: '',
                            employeeJobId: '',
                            employeeJobDescription: '',
                            employeeScheduleId: '',
                            employeeScheduleDescription: '',
                          },
                        }))
                      }}
                    >
                      {dropDownOptions.map(({ key, value }) => (
                        <option key={value} value={value}>
                          {key}
                        </option>
                      ))}
                    </Field>
                  </Form.Group>
                  <Form.Group as={Col} md={4} className="mb-2 pb-2">
                    {formik.values.associate === 'employee' && (
                      <EmployeeSearchInput
                        formik={formik}
                        error={formik.errors.employeeId}
                      />
                    )}
                    {formik.values.associate === 'employeeJob' && (
                      <EmployeeJobSearchInput
                        formik={formik}
                        error={formik.errors.employeeJobId}
                      />
                    )}
                    {formik.values.associate === 'employeeSchedule' && (
                      <EmployeeScheduleSearchInput
                        formik={formik}
                        error={formik.errors.employeeScheduleId}
                      />
                    )}
                  </Form.Group>
                </Form.Row>
                {formik.values.associate === 'employee' && (
                  <Form.Row>
                    <Form.Group as={Col} md={3}>
                      <Form.Label className="d-block">Date</Form.Label>
                      <DatePicker
                        name="date"
                        className="form-control form-control-sm"
                        showPopperArrow={false}
                        popperPlacement="bottom-start"
                        selected={formik.values.date}
                        onChange={(date) => {
                          formik.setFieldValue('date', date)
                        }}
                        popperModifiers={{
                          flip: {
                            behavior: ['bottom'],
                          },
                          preventOverflow: {
                            enabled: true,
                          },
                          hide: {
                            enabled: true,
                          },
                        }}
                      />
                      <ErrorMessage name="date">
                        {(msg) => (
                          <span className="text-danger d-block">{msg}</span>
                        )}
                      </ErrorMessage>
                    </Form.Group>
                    <Form.Group as={Col} md={2}>
                      <Form.Label>Start Time</Form.Label>
                      <Field
                        placeholder="12:00am"
                        className="form-control form-control-sm"
                        name="startTime"
                      />
                      <ErrorMessage name="startTime">
                        {(msg) => <span className="text-danger">{msg}</span>}
                      </ErrorMessage>
                    </Form.Group>
                    <Form.Group as={Col} md={2}>
                      <Form.Label>End Time</Form.Label>
                      <Field
                        placeholder="12:00pm"
                        className="form-control form-control-sm"
                        name="endTime"
                      />
                      <ErrorMessage name="endTime">
                        {(msg) => <span className="text-danger">{msg}</span>}
                      </ErrorMessage>
                    </Form.Group>
                  </Form.Row>
                )}
                {formik.values.timesheetEvents.length > 0 && (
                  <Form.Row className="mt-2" style={{ marginBottom: '-10px' }}>
                    <Form.Group as={Col} md={8}>
                      <h5>Clock In / Out</h5>
                    </Form.Group>
                  </Form.Row>
                )}
                <FieldArray
                  name="timesheetEvents"
                  render={(arrayHelpers) => (
                    <div>
                      {display && (
                        <Row>
                          <Col md="6">
                            <div
                              style={
                                formik.values.timesheetEvents.length > 1
                                  ? {
                                      overflowY: 'scroll',
                                      maxHeight: '350px',
                                      overflowX: 'hidden',
                                    }
                                  : null
                              }
                              className={
                                formik.values.timesheetEvents.length > 1
                                  ? 'border p-3 mt-2'
                                  : ''
                              }
                            >
                              {formik.values.timesheetEvents &&
                                formik.values.timesheetEvents.map(
                                  (timesheetEvent, timesheetEventIndex) => {
                                    return (
                                      <Row key={timesheetEventIndex}>
                                        <Col md={12}>
                                          <div className="border border-secondary border-rounded p-3 rounded mb-3">
                                            <Form.Row>
                                              <Form.Group as={Col} md={5}>
                                                <Form.Label className="d-block">
                                                  Date
                                                </Form.Label>
                                                <DatePicker
                                                  name={`timesheetEvents[${timesheetEventIndex}].date`}
                                                  className="form-control form-control-sm"
                                                  showPopperArrow={false}
                                                  popperPlacement="auto"
                                                  selected={
                                                    formik.values
                                                      .timesheetEvents[
                                                      timesheetEventIndex
                                                    ].date
                                                  }
                                                  onChange={(date) =>
                                                    formik.setFieldValue(
                                                      `timesheetEvents[${timesheetEventIndex}].date`,
                                                      date
                                                    )
                                                  }
                                                  popperModifiers={{
                                                    flip: {
                                                      behavior: ['bottom'],
                                                    },
                                                    preventOverflow: {
                                                      enabled: false,
                                                    },
                                                    hide: {
                                                      enabled: false,
                                                    },
                                                  }}
                                                />
                                              </Form.Group>
                                              <Form.Group as={Col} md={5}>
                                                <Form.Label>Time</Form.Label>
                                                <Field
                                                  placeholder="9:00am"
                                                  className="form-control form-control-sm"
                                                  name={`timesheetEvents[${timesheetEventIndex}].time`}
                                                />
                                                <ErrorMessage
                                                  name={`timesheetEvents[${timesheetEventIndex}].time`}
                                                >
                                                  {(msg) => (
                                                    <span className="text-danger">
                                                      {msg}
                                                    </span>
                                                  )}
                                                </ErrorMessage>
                                              </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                              <Form.Group
                                                as={Col}
                                                md={4}
                                                className="mb-2 pb-2"
                                              >
                                                <Field
                                                  as="select"
                                                  name={`timesheetEvents[${timesheetEventIndex}].eventType`}
                                                  className="form-control-sm form-select"
                                                >
                                                  <option value="">----</option>
                                                  <option value="CLOCK_IN">
                                                    Clock In
                                                  </option>
                                                  <option value="CLOCK_OUT">
                                                    Clock Out
                                                  </option>
                                                </Field>
                                              </Form.Group>
                                            </Form.Row>
                                            {canMutate && (
                                              <div
                                                className="d-flex align-items-center btn-link hover"
                                                onClick={() =>
                                                  arrayHelpers.remove(
                                                    timesheetEventIndex
                                                  )
                                                }
                                                role="presentation"
                                                onKeyDown={() =>
                                                  arrayHelpers.remove(
                                                    timesheetEventIndex
                                                  )
                                                }
                                              >
                                                <Trash className="mr-1" />
                                                <small>Remove</small>
                                              </div>
                                            )}
                                          </div>
                                        </Col>
                                      </Row>
                                    )
                                  }
                                )}
                            </div>
                          </Col>
                        </Row>
                      )}
                      {canMutate && (
                        <Button
                          type="button"
                          className={
                            formik.values.timesheetEvents.length > 1
                              ? 'mt-2 p-0 btn-link'
                              : 'mt-1 p-0 btn-link'
                          }
                          onClick={() => {
                            if (!display) {
                              setDisplay(true)
                            }
                            arrayHelpers.push({
                              date: '',
                              time: '',
                              eventType: '',
                            })
                          }}
                        >
                          <PlusCircle className="mr-2" />
                          Add Clock In / Out
                        </Button>
                      )}
                    </div>
                  )}
                />
                {canMutate && (
                  <Form.Row className="mt-5">
                    <Col md={3}>
                      <Button
                        type="submit"
                        block
                        variant="outline-primary"
                        disabled={submitting}
                      >
                        <PlusCircle className="mr-2" />
                        Save
                      </Button>
                    </Col>
                    {/* {updatingTimesheet && (
                      <>
                        <Col md={3}>
                          <Button
                            block
                            variant="outline-danger"
                            onClick={() => setShowDeleteForm(true)}
                            disabled={submitting}
                          >
                            <Trash className="mr-2" />
                            Delete
                          </Button>
                        </Col>
                      </>
                    )} */}
                  </Form.Row>
                )}
                {submitting && <Loading message="Saving Location..." />}
              </Form>
            </Modal.Body>
          </Modal>
        )}
      </Formik>

      {updatingTimesheet && showDeleteForm && (
        <DeleteTimesheetModal
          timesheetId={timesheet.id}
          showModal={showDeleteForm}
          toggleModal={setShowDeleteForm}
          timesheetDeleted={() => {
            toggleModal()
            setIsSubmitting(false)
          }}
        />
      )}
    </>
  )
}
