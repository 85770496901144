import React, { useEffect, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Form, Row, Col, Button, Modal } from 'react-bootstrap'
import { useFormik, FieldArray, FormikProvider } from 'formik'
import * as Yup from 'yup'
import { client } from '../../../libs/apollo'
import toast from 'react-hot-toast'
import { PlusCircle, Trash, CardChecklist } from 'react-bootstrap-icons'

const EditDefaultServiceItemGroupModal = (props) => {
  const { showModal, toggleModal, serviceItemGroup } = props

  const [updateDefaultServiceItemGroup] = useMutation(
    gql`
      mutation UpdateServiceItemGroup(
        $input: UpdateDefaultServiceItemGroupInput!
      ) {
        updateDefaultServiceItemGroup(input: $input) {
          defaultServiceItemGroup {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        toast.success('Task Group Updated')
        toggleModal()
        formik.resetForm()
      },
      errorPolicy: 'all',
      refetchQueries: ['DefaultServiceItemGroupsQuery'],
    }
  )

  const [deleteMutation] = useMutation(
    gql`
      mutation DeleteDefaultServiceItemGroupMutations(
        $deleteDefaultSerivceItemGroupInput: DeleteDefaultServiceItemGroupInput!
      ) {
        deleteDefaultServiceItemGroup(
          input: $deleteDefaultSerivceItemGroupInput
        ) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        toggleModal()
        toast.success('Task Group Deleted')
      },
      errorPolicy: 'all',
      refetchQueries: ['DefaultServiceItemGroupsQuery'],
    }
  )

  const formik = useFormik({
    initialValues: {
      name: '',
      defaultServiceItems: [],
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('required')
        .test('isUnique', 'Task Group Name must be unique', async (value) => {
          const { data } = await client.query({
            query: gql`
              query UniqueNewServiceItemQuery($name: String) {
                defaultServiceItemGroups(name_Iexact: $name) {
                  nodeCount
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            `,
            fetchPolicy: 'network-only',
            variables: {
              name: value,
            },
          })
          if (data.defaultServiceItemGroups.nodeCount === 0) {
            return true
          } else if (
            data.defaultServiceItemGroups.nodeCount === 1 &&
            data.defaultServiceItemGroups.edges[0].node.id ===
              serviceItemGroup.id
          ) {
            return true
          }
          return false
        }),
      defaultServiceItems: Yup.array()
        .of(
          Yup.object().shape({
            description: Yup.string().required('Description is required'),
          })
        )
        .nullable(),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      const input = {
        defaultServiceItemGroupInput: {
          id: serviceItemGroup.id,
          name: values.name,
          defaultServiceItems: values.defaultServiceItems?.map((item) => ({
            id: item.id ? item.id : undefined,
            description: item.description,
          })),
        },
      }
      updateDefaultServiceItemGroup({
        variables: {
          input,
        },
      })
    },
  })

  const innerToggleModal = () => {
    toggleModal()
    formik.resetForm()
  }

  useEffect(() => {
    if (showModal && serviceItemGroup) {
      formik.setValues({
        name: serviceItemGroup.name,
        defaultServiceItems: serviceItemGroup.defaultServiceItems.edges.map(
          (edge) => ({
            id: edge.node.id,
            description: edge.node.description,
          })
        ),
      })
    }
  }, [showModal, serviceItemGroup])

  return (
    <>
      <FormikProvider value={formik}>
        <div className="editServiceItemGroup">
          <Modal
            size="lg"
            show={showModal}
            onHide={innerToggleModal}
            aria-labelledby="editServiceItemgroup"
          >
            <Form onSubmit={formik.handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title id="new-title">
                  <CardChecklist className="mr-2" />
                  Edit Task Collection
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Collection Name
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      className="form-control-sm"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      isInvalid={!!formik.errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.name}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="3">
                    Tasks
                  </Form.Label>
                </Form.Group>

                <FieldArray
                  name="defaultServiceItems"
                  render={(arrayHelpers) => (
                    <>
                      <div
                        style={{
                          maxHeight: '300px',
                          overflowY: 'auto',
                          marginBottom: '10px',
                        }}
                      >
                        {formik.values.defaultServiceItems.map(
                          (item, index) => (
                            <div
                              key={index}
                              style={{
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                padding: '10px',
                                marginBottom: '5px',
                              }}
                            >
                              <Form.Group
                                as={Row}
                                className="mb-3 align-items-center"
                              >
                                <Form.Label column sm="2">
                                  Name
                                </Form.Label>
                                <Col sm="8">
                                  <Form.Control
                                    rows={2}
                                    name={`defaultServiceItems.${index}.description`}
                                    value={item.description}
                                    onChange={formik.handleChange}
                                    isInvalid={
                                      !!(
                                        formik.errors.defaultServiceItems &&
                                        formik.errors.defaultServiceItems[index]
                                          ?.description
                                      )
                                    }
                                    className="form-control-sm"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.defaultServiceItems &&
                                      formik.errors.defaultServiceItems[index]
                                        ?.description}
                                  </Form.Control.Feedback>
                                </Col>
                                <Col sm="2" className="text-left">
                                  <Button
                                    type="button"
                                    variant="link"
                                    size="sm"
                                    style={{ color: '#007bff' }}
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <Trash className="mr-2" />
                                    Remove
                                  </Button>
                                </Col>
                              </Form.Group>
                            </div>
                          )
                        )}
                      </div>
                      <Button
                        type="button"
                        size="sm"
                        className="btn-link"
                        onClick={() => arrayHelpers.push({ description: '' })}
                      >
                        <PlusCircle className="mr-2" />
                        Add Task
                      </Button>
                    </>
                  )}
                />

                <Row className="mt-4">
                  <Col md={3}>
                    <Button
                      type="submit"
                      size="sm"
                      block
                      variant="outline-primary"
                    >
                      <PlusCircle className="mr-2" />
                      Save
                    </Button>
                  </Col>
                  <Col md={3}>
                    <Button
                      variant="outline-danger"
                      size="sm"
                      block
                      onClick={() => {
                        deleteMutation({
                          variables: {
                            deleteDefaultSerivceItemGroupInput: {
                              defaultServiceItemGroupIds: serviceItemGroup.id,
                            },
                          },
                        })
                      }}
                    >
                      <Trash className="mr-2" />
                      Delete
                    </Button>
                  </Col>
                </Row>
              </Modal.Body>
            </Form>
          </Modal>
        </div>
      </FormikProvider>
    </>
  )
}

export default EditDefaultServiceItemGroupModal
