import React, { useState } from 'react'
import { gql, useMutation, useReactiveVar } from '@apollo/client'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import {
  CaretDown,
  CaretRight,
  CloudArrowDown,
  Trash,
  PlusCircle,
} from 'react-bootstrap-icons'
import AuditLog from '../audit_log/AuditLog'
import Loading from '../common/Loading'
import { useAWSS3 } from '../../libs/aws'
import ProgressBar from 'react-bootstrap/ProgressBar'
import TagSearchInput from '../common/node_search_input/TagSeachInput'
import { loggedInUserVar } from '../../libs/apollo'
import { downloadBase64Data } from '../../libs/utils'

const FolderForm = (props) => {
  const { folderId, folder, afterSubmit, parentShared } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const [submitting, setSubmitting] = useState(false)
  const [displayAuditLog, setDisplayAuditLog] = useState(false)
  const [totalSizeTransferred, setTotalSizeTransferred] = useState(0)
  const [totalSize, setTotalSize] = useState(0)
  const [creating, setCreating] = useState()

  const [deleteFolder] = useMutation(
    gql`
      mutation DeleteFolder($input: DeleteFolderInput!) {
        deleteFolder(input: $input) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Folder Deleted`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['Folders'],
    }
  )

  const [createFolder] = useMutation(
    gql`
      mutation CreateFolder($input: CreateFolderInput!) {
        createFolder(input: $input) {
          folder {
            id
          }
        }
      }
    `,
    {
      refetchQueries: ['Folders'],
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Folder Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
    }
  )

  const [updateFolder] = useMutation(
    gql`
      mutation UpdateFolder($input: UpdateFolderInput!) {
        updateFolder(input: $input) {
          folder {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Folder Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['Folders'],
    }
  )

  const [downloadFolder] = useMutation(
    gql`
      mutation DownloadFolder($input: DownloadFolderInput!) {
        downloadFolder(input: $input) {
          folder
        }
      }
    `,
    {
      onCompleted: (data) => {
        downloadBase64Data(data.downloadFolder.folder, `${folder.name}.zip`)
        toast.success(`Folder Downloaded`)
        setSubmitting(false)
      },
      errorPolicy: 'all',
    }
  )

  const formik = useFormik({
    initialValues: folder
      ? {
          tags: folder.tags.map((tag) => {
            return {
              description: tag.name,
              id: tag.id,
            }
          }),
          folderId: folderId,
          name: folder.name,
          cantDelete: folder.cantDelete,
          cantRename: folder.cantRename,
          externalCantDelete: folder.externalCantDelete,
          externalCantRename: folder.externalCantRename,
          externallyVisible: folder.externallyVisible,
        }
      : {
          tags: [],
          displayName: '',
          folderId: folderId,
          externallyVisible: true,
          cantDelete: false,
          cantRename: false,
          externalCantDelete: false,
          externalCantRename: false,
        },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .nullable()
        .test('required', 'Required', (value) => {
          if (folder && !value) {
            return false
          }
          return true
        }),
      tags: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().nullable(),
          description: Yup.string().nullable(),
        })
      ),
      externallyVisible: Yup.mixed(),
    }),
    validateOnChange: false,
    onSubmit: async (values) => {
      setSubmitting(true)
      const variables = {
        name: values.name,
        folderId: values.folderId,
        cantDelete: values.cantDelete,
        cantRename: values.cantRename,
        externalCantDelete: values.externalCantDelete,
        externalCantRename: values.externalCantRename,
        jobId: null,
        organizationId: null,
        subjectGroupId: null,
        subjectId: null,
        employeeId: null,
        serviceItemId: null,
        tags: values.tags.map((tag) => {
          return tag.description
        }),
        externallyVisible: values.externallyVisible,
      }
      if (folder) {
        variables.id = folder.id
        updateFolder({
          variables: {
            input: {
              folderInput: variables,
            },
          },
        })
      } else {
        createFolder({
          variables: {
            input: {
              folderInput: variables,
            },
          },
        })
      }
    },
  })
  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group as={Row}>
          <Col md={6}>
            <>
              <Form.Label>Name</Form.Label>
              <Form.Control
                as="input"
                name="name"
                disabled={
                  folder &&
                  (folder.systemFolder ||
                    formik.values.cantRename ||
                    (!loggedInUser.permissions.isEmployee &&
                      formik.values.externalCantRename))
                }
                className="form-control-sm"
                value={formik.values.name}
                onChange={formik.handleChange}
                isInvalid={formik.errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </>
          </Col>
          {loggedInUser.permissions.isEmployee && (
            <Col md={6}>
              <Form.Label>Tags</Form.Label>
              <TagSearchInput formik={formik} multiple create />
            </Col>
          )}
        </Form.Group>
        {parentShared && loggedInUser.permissions.isEmployee && (
          <Form.Group as={Row}>
            <Col md={2}>
              <Form.Label style={{ fontSize: '12px' }}>
                {!formik.values.externallyVisible ? <>Not </> : <></>}Shared
                Visible
              </Form.Label>
              <Form.Check
                onChange={(e) => {
                  formik.setFieldValue(`externallyVisible`, e.target.checked)
                }}
                checked={formik.values.externallyVisible}
              />
            </Col>
            {formik.values.externallyVisible && (
              <>
                <Col md={2}>
                  <Form.Label style={{ fontSize: '12px' }}>
                    Shared Can
                    {formik.values.externalCantDelete ? <>t</> : <></>} Delete
                  </Form.Label>
                  <Form.Check
                    onChange={(e) => {
                      formik.setFieldValue(
                        `externalCantDelete`,
                        e.target.checked
                      )
                    }}
                    checked={formik.values.externalCantDelete}
                  />
                </Col>

                <Col md={3}>
                  <Form.Label style={{ fontSize: '12px' }}>
                    Shared Can
                    {formik.values.externalCantRename ? <>t</> : <></>} Rename
                  </Form.Label>
                  <Form.Check
                    onChange={(e) => {
                      formik.setFieldValue(
                        `externalCantRename`,
                        e.target.checked
                      )
                    }}
                    checked={formik.values.externalCantRename}
                  />
                </Col>
              </>
            )}
          </Form.Group>
        )}

        {folder && loggedInUser.permissions.isEmployee && (
          <>
            <Row className="mt-3 mb-4">
              <Col>
                <button
                  type="button"
                  onClick={() => setDisplayAuditLog(!displayAuditLog)}
                  className="px-0 btn-link mr-1"
                >
                  <>
                    {displayAuditLog ? (
                      <>
                        <CaretDown size={17} />
                      </>
                    ) : (
                      <>
                        <CaretRight size={17} />
                      </>
                    )}
                  </>
                </button>
                <Form.Label className="mb-0">History</Form.Label>
              </Col>
            </Row>
            {displayAuditLog && (
              <AuditLog
                contentType={folder.contentType.model}
                id={folder.id}
                tableHeight={400}
                fetchPolicy="no-cache"
              />
            )}
          </>
        )}
        <div className="footer-modal">
          <Form.Row className="mt-2">
            {(loggedInUser.permissions.isEmployee ||
              !formik.values.externalCantRename ||
              !folder) && (
              <Col md={3}>
                <Button
                  type="submit"
                  block
                  variant="outline-primary"
                  disabled={submitting}
                >
                  <PlusCircle className="mr-2" />
                  Save
                </Button>
              </Col>
            )}

            {folder && (
              <>
                <Col md={3}>
                  <Button
                    variant="outline-primary"
                    block
                    disabled={submitting}
                    onClick={() => {
                      setSubmitting(true)
                      downloadFolder({
                        variables: {
                          input: {
                            folderIds: [folder.id],
                          },
                        },
                      })
                    }}
                  >
                    <CloudArrowDown className="mr-2" />
                    Download
                  </Button>
                </Col>
                {(loggedInUser.permissions.isEmployee ||
                  !formik.values.externalCantDelete) && (
                  <Col md={3}>
                    <Button
                      variant="outline-danger"
                      block
                      onClick={() => {
                        setSubmitting(true)
                        deleteFolder({
                          variables: {
                            input: {
                              folderIds: folder.id,
                            },
                          },
                        })
                      }}
                      disabled={submitting}
                    >
                      <Trash className="mr-2" />
                      Delete
                    </Button>
                  </Col>
                )}
              </>
            )}
          </Form.Row>
          {submitting && (
            <>
              {creating && (
                <Row className="mt-3">
                  <Col>
                    <ProgressBar
                      now={`${Math.round(
                        100 * (totalSizeTransferred / totalSize)
                      )}`}
                      label={`${Math.round(
                        100 * (totalSizeTransferred / totalSize)
                      )}% Complete Uploading`}
                    />
                  </Col>
                </Row>
              )}
              <div className="mt-2">
                <Loading />
              </div>
            </>
          )}
        </div>
      </Form>
    </>
  )
}

export default FolderForm
