import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  from,
  makeVar,
  gql,
} from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from '@apollo/client/link/error'
import config from '../config'
import { CONSTANTS } from './constant'
import {
  isEmployee,
  isOrgContact,
  isSubject,
  getJwtLocalStorage,
  setLocalStorageObj,
  setJwtLocalStorage,
} from './utils'
import { toast } from 'react-hot-toast'
import { RetryLink } from 'apollo-link-retry'
import Observable from 'zen-observable'
import { setSentryUser, clearSentryUser } from './sentry'

let isRefreshingToken = false
let isLoggingOut = false

export const loggedInUserVar = makeVar(null)
export const timezoneVar = makeVar(null)
export const settingsVar = makeVar(null)

export const setLoginVars = (user) => {
  const userGroup = user.groups.edges[0].node.name
  user.contactInOrganizations = user.organizationContacts?.edges?.map(
    (node) => node.node.id
  )
  let isOrgContact_ = false
  user.groups.edges.forEach((edge) => {
    if (!isOrgContact_ && isOrgContact(edge.node.name)) {
      isOrgContact_ = true
    }
  })
  let isSubject_ = false
  user.groups.edges.forEach((edge) => {
    if (!isSubject_ && isSubject(edge.node.name)) {
      isSubject_ = true
    }
  })
  let isEmployee_ = false
  user.groups.edges.forEach((edge) => {
    if (!isEmployee_ && isEmployee(edge.node.name)) {
      isEmployee_ = true
    }
  })
  user.permissions = {
    group: userGroup,
    isEmployee: isEmployee_,
    isSubject: isSubject_,
    isOrgContact: isOrgContact_,
  }
  loggedInUserVar(user)
  setLocalStorageObj(CONSTANTS.USER_VAR, user)
  setSentryUser(user.id, user.email)
}

export const clearLoginVars = () => {
  loggedInUserVar(null)
  clearSentryUser()
}

const handleTokenRefreshFailure = () => {
  if (!isLoggingOut) {
    isLoggingOut = true
    toast.error('Login Session Has Expired')
    localStorage.clear()
    isLoggingOut = false
    window.location.href = '/'
  }
}

export const getNewToken = async () => {
  try {
    const jwt = getJwtLocalStorage()
    if (!jwt?.jwt) {
      handleTokenRefreshFailure()
    }
    const response = await client.mutate({
      mutation: gql`
        mutation refreshJwtToken($input: RefreshInput!) {
          refreshJwtToken(input: $input) {
            payload
            refreshExpiresIn
            token
            clientMutationId
          }
        }
      `,
      variables: {
        input: {
          token: jwt.jwt,
        },
      },
    })
    if (response?.data?.refreshJwtToken) {
      setJwtLocalStorage(response.data.refreshJwtToken)
    } else {
      handleTokenRefreshFailure()
    }
  } catch (e) {
    handleTokenRefreshFailure()
  }
}

const httpLink = createUploadLink({
  fetch: (_, options) => {
    let uri
    if (config.ENVIRONMENT === 'development') {
      uri = `http://${window.location.hostname}:8000/graphql/`
    } else if (config.ENVIRONMENT === 'qa') {
      uri = 'https://qa-api.airstudio.io/graphql/'
    } else if (['development-deployed', 'srp'].includes(config.ENVIRONMENT)) {
      uri = 'https://api.airstudio.io/graphql/'
    }
    return fetch(uri, options)
  },
})

const authLink = new ApolloLink((operation, forward) => {
  return new Observable((observer) => {
    let handle
    const asyncAuthLink = async () => {
      let jwt = getJwtLocalStorage()
      if (jwt?.jwt) {
        const now = new Date().getTime()
        if (!isRefreshingToken && now > jwt.refreshOn) {
          isRefreshingToken = true
          try {
            await getNewToken()
          } catch (e) {
            observer.error(e)
            return
          } finally {
            isRefreshingToken = false
          }
          jwt = getJwtLocalStorage()
        }
        operation.setContext(({ headers }) => ({
          headers: {
            ...headers,
            Authorization: `JWT ${jwt?.jwt}`,
          },
        }))
      }
      try {
        handle = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        })
      } catch (e) {
        observer.error(e)
      }
    }
    asyncAuthLink()
    return () => {
      if (handle) handle.unsubscribe()
    }
  })
})

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        gaiaUsers: relayStylePagination(['isActive', 'fullName_Icontains']),
        sessionPackages: relayStylePagination([
          'title_Istartswith',
          'title_Icontains',
        ]),
        contentTypes: relayStylePagination(),
        logEntries: relayStylePagination(),
        files: relayStylePagination(),
        locations: relayStylePagination([
          'addressLineTwo_Icontains',
          'name_Icontains',
          'first',
          'addressLineOne_Icontains',
        ]),
        domains: relayStylePagination(['name_Iexact', 'archived']),
        organizationSubjectsNotInSubjectGroup: relayStylePagination(),
        packageGroupJobs: relayStylePagination(),
        imagequixConnectors: relayStylePagination(),
        products: relayStylePagination(),
        equipmentItems: relayStylePagination([
          'name_Icontains',
          'underRepair',
          'retire',
        ]),
        equipmentBags: relayStylePagination(['name_Icontains', 'id']),
        equipmentBagTypes: relayStylePagination(),
        createTenant: relayStylePagination(),
        equipmentBagType: relayStylePagination(),
        equipmentItem: relayStylePagination(),
        equipmentItemTypes: relayStylePagination(),
        equipmentItemType: relayStylePagination(),
        imagequixConnector: relayStylePagination(),
        organizationStages: relayStylePagination(),
        packageGroupJob: relayStylePagination(),
        tags: relayStylePagination(),
        notifications: relayStylePagination(),
        employeeSchedules: relayStylePagination(),
        fileUploadSessions: relayStylePagination(),
        fileUploadSession: relayStylePagination(),
        fotomerchantOrders: relayStylePagination(),
        fotomerchantOrderItems: relayStylePagination(),
        jobs: relayStylePagination([
          'name_Icontains',
          'startDateTime_Gte',
          'endDateTime_Lte',
        ]),
        subjects: relayStylePagination([
          'gaiaUser_FullName_Icontains',
          'gaiaUser_IsActive',
          'subjectGroups',
          'organization',
          'gaiaUser_Employee_Id_Isnull',
        ]),
        subjectGroups: relayStylePagination([
          'name_Icontains',
          'endDateTime_Gt',
        ]),
        organizations: relayStylePagination(['name_Icontains']),
        session: relayStylePagination(),
        serviceItem: relayStylePagination(),
        serviceItems: relayStylePagination(),
        defaultServiceItemGroups: relayStylePagination(),
        defaultServiceItems: relayStylePagination(),
        sessions: relayStylePagination(),
        invoiceSessions: relayStylePagination(),
        employees: relayStylePagination(['gaiaUser_FullName_Icontains']),
        employeeJobs: relayStylePagination([
          'equipmentItems',
          'equipmentBags',
          'job',
        ]),
        stripePaymentIntents: relayStylePagination(),
        stripePaymentIntent: relayStylePagination(),
        stripeCustomer: relayStylePagination(),
        stripeCustomers: relayStylePagination(),
        stripeInvoice: relayStylePagination(),
        stripeInvoices: relayStylePagination(),
        stripeInvoiceItem: relayStylePagination(),
        stripeInvoiceItems: relayStylePagination(),
        packageCategories: relayStylePagination([
          'name_Icontains',
          'archived',
          'jobs_Isnull',
        ]),
        timeOffs: relayStylePagination(),
        settingsVar: {
          read() {
            return settingsVar()
          },
        },
        timezoneVar: {
          read() {
            return timezoneVar()
          },
        },
        loggedInUserVar: {
          read() {
            return loggedInUserVar()
          },
        },
      },
    },
    colleaguesData: {
      keyFields: ['colleagueId', 'colleagueName'],
    },
  },
})

const retryLink = new RetryLink({
  attempts: {
    max: 3,
    retryIf: (error) => !!error,
  },
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
})

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      switch (err.message) {
        case 'Signature has expired': {
          getNewToken()
        }
      }
    }
  }
})

export const client = new ApolloClient({
  cache,
  link: from([errorLink, authLink, retryLink, httpLink]),
  fetchOptions: {
    mode: 'no-cors',
  },
  connectToDevTools: config.ENVIRONMENT === 'development',
})

export const deserializeGraphQLErrors = (graphQLErrors) =>
  JSON.parse(graphQLErrors[0].message)
