import React, { useEffect, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Form, Row, Col, Button, Modal } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import Loading from '../../common/Loading'
import { PlusCircle } from 'react-bootstrap-icons'

const EditFTP = (props) => {
  const { showModal, toggleModal, ftpSetting } = props

  const [submitting, setSubmitting] = useState(false)
  const [update] = useMutation(
    gql`
      mutation UpdateFtp($updateFtpInput: UpdateFTPConnectorInput!) {
        updateFtpConnection(input: $updateFtpInput) {
          ftpConnector {
            id
            valid
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        if (data.updateFtpConnection.ftpConnector.valid) {
          toast.success('FTP Integration Enabled')
        } else {
          toast.error('Invalid FTP Credentials')
        }
        innerToggle()
      },
      refetchQueries: ['FTPConnector'],
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    if (showModal && ftpSetting) {
      formik.setValues({
        host: ftpSetting.host,
        port: ftpSetting.port,
        user: ftpSetting.user,
        password: ftpSetting.password,
        tls: ftpSetting.tls,
        ssl: ftpSetting.ssl,
        rateLimit: ftpSetting.rateLimit,
        rateLimitSecondDelta: ftpSetting.rateLimitSecondDelta,
      })
    }
  }, [showModal, ftpSetting])

  const formik = useFormik({
    initialValues: {
      host: '',
      port: '',
      user: '',
      password: '',
      ssl: true,
      rateLimit: 10,
      rateLimitSecondDelta: 10,
    },
    validationSchema: Yup.object().shape({
      host: Yup.string()
        .nullable()
        .test('isRequired', 'required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        }),
      port: Yup.number()
        .typeError('number required')
        .nullable()
        .test('isRequired', 'required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        }),
      user: Yup.string()
        .nullable()
        .test('isRequired', 'required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        }),
      password: Yup.string()
        .nullable()
        .test('isRequired', 'required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        }),
      tls: Yup.boolean().nullable(),
      ssl: Yup.boolean().nullable(),
      rateLimit: Yup.number()
        .typeError('number required')
        .nullable()
        .test('isRequired', 'required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        })
        .min(1, 'Rate limit too low')
        .max(100, 'Rate limit too high'),
      rateLimitSecondDelta: Yup.number()
        .typeError('number required')
        .nullable()
        .test('isRequired', 'required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        })
        .min(1, 'Rate limit too low')
        .max(100, 'Rate limit too high'),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      update({
        variables: {
          updateFtpInput: {
            ftpConnectorInput: {
              host: values.host,
              port: values.port,
              user: values.user,
              password: values.password,
              tls: values.tls,
              ssl: values.ssl,
              rateLimit: values.rateLimit,
              rateLimitSecondDelta: values.rateLimitSecondDelta,
            },
          },
        },
      })
    },
  })
  const innerToggle = () => {
    toggleModal()
    formik.resetForm()
  }

  return (
    <>
      <div className="newSessionResitReasonModal">
        <Modal
          size="lg"
          show={showModal}
          onHide={innerToggle}
          className="invmodal detail"
        >
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title id="new-title">
                <img
                  className="img-fluid mr-2"
                  src={window.location.origin + '/ftp.png'}
                  style={{ height: '25px' }}
                  alt="Organization logo"
                />
                Configure FTP Server
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  User
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="user"
                    value={formik.values.user}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.user}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.user}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Password
                </Form.Label>
                <Col>
                  <Form.Control
                    type="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.password}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  FTP Server Host
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="host"
                    value={formik.values.host}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.host}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.host}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  FTP Server Port
                </Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    name="port"
                    value={formik.values.port}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.port}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.port}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Use TLS
                </Form.Label>
                <Col>
                  <Form.Check
                    inline
                    label=""
                    checked={formik.values.tls}
                    onChange={(e) => {
                      formik.setFieldValue('tls', e.target.checked)
                      if (e.target.checked && formik.values.ssl) {
                        formik.setFieldValue('ssl', false)
                      }
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Use SSL
                </Form.Label>
                <Col>
                  <Form.Check
                    inline
                    label=""
                    checked={formik.values.ssl}
                    onChange={(e) => {
                      formik.setFieldValue('ssl', e.target.checked)
                      if (e.target.checked && formik.values.tls) {
                        formik.setFieldValue('tls', false)
                      }
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Default FTP Per Batch
                </Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    name="rateLimit"
                    value={formik.values.rateLimit}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.rateLimit}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.rateLimit}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Default Seconds Per Batch
                </Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    name="rateLimitSecondDelta"
                    value={formik.values.rateLimitSecondDelta}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.rateLimitSecondDelta}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.rateLimitSecondDelta}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Row>
                <Col md={3}>
                  <Button
                    disabled={submitting}
                    type="submit"
                    size="sm"
                    block
                    variant="outline-primary"
                    className="mt-2"
                  >
                    <PlusCircle className="mr-2" />
                    Save
                  </Button>
                </Col>
              </Row>

              {submitting && (
                <Loading message={'Validating FTP Server Credentials...'} />
              )}
            </Modal.Body>
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default EditFTP
