import React from 'react'
import { Modal } from 'react-bootstrap'
import JobPage from './Job'

export default function EmployeeAssignmentsModal(props) {
  const { showModal, toggleModal, jobId } = props

  return (
    <>
      <Modal size="xl" show={showModal} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Employee Assignments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <JobPage
            parentJobId={jobId}
            employeeAssignments
            toggleModal={toggleModal}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
