import React, { useState, useEffect } from 'react'
import { useQuery, gql, useReactiveVar } from '@apollo/client'
import { Row, Col, Tab, Tabs } from 'react-bootstrap'
import { loggedInUserVar } from '../../libs/apollo'
import SubjectSessionsTable from './SubjectSessionsTable'

const AllSubjectSessions = () => {
  let managedSubjects = []
  let subjectId
  const user = useReactiveVar(loggedInUserVar)
  const userId = user?.id
  const [subjectTempId, setTempSubjectId] = useState('')
  const GAIA_USER_SUBJECT_CHIDLREN_QUERY = gql`
    query SubjectsChildrenQuery($gaiaUserId: ID!) {
      gaiaUser(id: $gaiaUserId) {
        id
        fullName
        lastName
        subject {
          id
          children {
            edges {
              node {
                id
                gaiaUser {
                  fullName
                }
              }
            }
          }
          colleagues {
            edges {
              node {
                id
                gaiaUser {
                  fullName
                }
              }
            }
          }
        }
      }
    }
  `

  const { error, data } = useQuery(GAIA_USER_SUBJECT_CHIDLREN_QUERY, {
    variables: {
      gaiaUserId: userId,
    },
    errorPolicy: 'all',
  })
  const handleQueryData = () => {
    let managedSubjects = []
    if (data.gaiaUser.subject) {
      subjectId = data.gaiaUser.subject.id
      managedSubjects.push({
        id: data.gaiaUser.subject.id,
        name: data.gaiaUser.fullName,
      })
    }

    data.gaiaUser.subject.children.edges.forEach((subject) => {
      managedSubjects.push({
        id: subject.node.id,
        name: subject.node.gaiaUser.fullName,
      })
    })
    data.gaiaUser.subject.colleagues.edges.forEach((subject) => {
      managedSubjects.push({
        id: subject.node.id,
        name: subject.node.gaiaUser.fullName,
      })
    })
    return managedSubjects
  }

  useEffect(() => {
    if (managedSubjects.length > 0) {
      setTempSubjectId(managedSubjects[0].id)
    }
  }, [])

  const handleClick = (e) => {
    setTempSubjectId(e)
  }
  if (!data) return <></>
  if (error) return <p>Error loading sessions</p>
  managedSubjects = handleQueryData()
  return (
    <>
      <div>
        {managedSubjects.length > 1 ? (
          <div className="mt-4">
            <Tabs
              defaultActiveKey={managedSubjects[0].id}
              className="mb-3"
              onSelect={handleClick}
            >
              {managedSubjects.map((subject) => {
                return (
                  <Tab
                    key={subject.id}
                    eventKey={subject.id}
                    title={subject.name ? subject.name : 'Sessions'}
                  >
                    <SubjectSessionsTable
                      hideName={true}
                      subjectId={
                        subjectTempId ? subjectTempId : managedSubjects[0].id
                      }
                      relation={'child'}
                    />
                  </Tab>
                )
              })}
            </Tabs>
          </div>
        ) : (
          <>
            {subjectId ? (
              <Row className="mt-3 mb-3">
                <Col>
                  <SubjectSessionsTable
                    subjectId={subjectId}
                    relation={'me'}
                    managedSubjects={managedSubjects}
                  />
                </Col>
              </Row>
            ) : (
              <SubjectSessionsTable
                subjectId={
                  subjectTempId ? subjectTempId : managedSubjects[0].id
                }
                relation={'child'}
              />
            )}
          </>
        )}
      </div>
    </>
  )
}

export default AllSubjectSessions
