import React, { useEffect, useState, useRef } from 'react'
import { Form, Row, Col, Button, Badge, Table } from 'react-bootstrap'
import {
  useMutation,
  useLazyQuery,
  gql,
  useReactiveVar,
  useQuery,
} from '@apollo/client'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import {
  CaretDown,
  CaretRight,
  PlusCircle,
  Trash,
  Bell,
  ArrowRepeat,
  Download,
} from 'react-bootstrap-icons'
import { DateTime } from 'luxon'
import EmailNotificationTestForm from './EmailNotificationTestForm'
import SMSNotificationTestForm from './SMSNotificationTestForm'
import NotificationsTable from './NotificationsTable'
import EmailNotificationTemplateBuilder from './EmailNotificationTemplateBuilder'
import SMSNotificationTemplateBuilder from './SMSNotificationTemplateBuilder'
import InfiniteScroll from 'react-infinite-scroll-component'
import NewCustomTriggerModal from './NewCustomTriggerModal'
import DeleteCustomTriggerModal from './DeleteCustomTriggerModal'
import DatePicker from 'react-datepicker'
import { formatDateTimeToString } from '../../libs/utils'
import { loggedInUserVar } from '../../libs/apollo'
import { useHistory, useLocation } from 'react-router-dom'
import Loading from '../common/Loading'
import CopyTriggerModal from './CopyTriggerModal'
import SendNotificationModal from './SendNotificationModal'
import { useDownloadAndDeleteFile } from '../../libs/downloadAndDeleteFile'

const Notifications = (props) => {
  const {
    subject,
    organization,
    subjectGroup,
    job,
    employee,
    copyTrigger,
    contact,
    setCopyTriggerId,
  } = props

  const loggedInUser = useReactiveVar(loggedInUserVar)
  const location = useLocation()
  const history = useHistory()
  const canSend = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
    'General Staff',
    'Equipment Manager',
  ].includes(loggedInUser?.permissions?.group)
  const canMutate = ['Administrator', 'Scheduling Manager'].includes(
    loggedInUser?.permissions?.group
  )
  useEffect(() => {
    if (
      !['Administrator'].includes(loggedInUser?.permissions?.group) &&
      location.pathname === '/notifications'
    ) {
      history.push('/schedule')
    }
  })

  const [notificationTriggers, setNotificationTriggers] = useState({})
  const [notificationRecipientUsers, setNotificationRecipientUsers] = useState(
    []
  )
  const [displayJobResults, setDisplayJobResults] = useState(false)
  const [showCopyTriggerModal, setShowCopyTriggerModal] = useState(false)
  const [showSendNotificationModal, setShowSendNotificationModal] =
    useState(false)
  const [copyAllTriggers, setCopyAllTriggers] = useState(false)
  const [reloadEmailEditorRef, setReloadEmailEditorRef] = useState(false)
  const [canSetCustomStartEndTime, setCanSetCustomStartEndTime] =
    useState(false)
  const [currentNotificationTrigger, setCurrentNotificationTrigger] =
    useState(null)
  const [
    currentNotificationTriggerEmailTemplate,
    setCurrentNotificationTriggerEmailTemplate,
  ] = useState()
  const [
    currentNotificationTriggerSMSTemplate,
    setCurrentNotificationTriggerSMSTemplate,
  ] = useState()
  const [notificationRecipients, setNotificationRecipients] = useState(null)
  const [notificationStartDetail, setNotificationStartDetail] = useState(null)
  const [notificationOccurrenceDetail, setNotificationOccurrenceDetail] =
    useState(null)
  const [showComponent, setShowComponent] = useState(false)
  const [noCurrentTrigger, setNoCurrentTrigger] = useState(false)
  const [showOccurrenceDeltaFields, setShowOccurrenceDeltaFields] =
    useState(false)
  const [showStartDeltaFields, setShowStartDeltaFields] = useState(false)
  const [showRepeatToggle, setShowRepeatToggle] = useState(false)
  const [showSMSTemplate, setShowSMSTemplate] = useState(false)
  const [notificationsLoading, setNotificationsLoading] = useState(true)
  const [resetCurrent, setResetCurrent] = useState(false)
  const [nonDefaultTrigger, setNonDefaultTemplate] = useState(false)
  const [displayTemplateVariables, setDisplayTemplateVariables] =
    useState(false)
  const [showNewTriggerModal, setShowNewTriggerModal] = useState(false)
  const [display, setDisplay] = useState(true)
  const [showDeleteTriggerModal, setShowDeleteTriggerModal] = useState(false)
  const [displayOrganizationResults, setDisplayOrganizationResults] =
    useState(false)
  const [displaySubjectGroupResults, setDisplaySubjectGroupResults] =
    useState(false)
  const [currentTriggerCustom, setCurrentTriggerCustom] = useState(false)
  const emailEditorRef = useRef(null)
  const { downloadFile } = useDownloadAndDeleteFile()

  const { data: twilioConnectorData } = useQuery(
    gql`
      query TwilioConnector {
        twilioConnectors {
          edges {
            node {
              customValid
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const [searchJobs, { data: jobsData, fetchMore: fetchMoreJobs }] =
    useLazyQuery(
      gql`
        query JobsQuery(
          $cursor: String
          $employees: [ID]
          $contacts: [ID]
          $name: String
          $first: Int
        ) {
          jobs(
            orderBy: "name"
            first: $first
            after: $cursor
            name_Icontains: $name
            employees: $employees
            contacts: $contacts
          ) {
            pageInfo {
              endCursor
              hasNextPage
            }
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `,
      {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      }
    )

  const { data: myUser } = useQuery(
    gql`
      query MyUser($id: ID!) {
        myUser(id: $id) {
          employee {
            id
            smtpValid
            smtpUser
          }
        }
      }
    `,
    {
      variables: {
        id: loggedInUser.id,
      },
      fetchPolicy: 'network-only',
    }
  )

  const { data: smtpConnector } = useQuery(
    gql`
      query SMTPConnector {
        smtpConnectors {
          edges {
            node {
              customUser
              customValid
              customGmail
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const toggleNewTriggerModal = () => {
    setShowNewTriggerModal((prevState) => !prevState)
  }

  const toggleDeleteTriggerModal = () => {
    setShowDeleteTriggerModal((prevState) => !prevState)
  }

  const NOTIFICATION_TRIGGER_DELTAS = ['minutes', 'hours', 'days', 'weeks']

  const [updateNotificationTemplateMutation] = useMutation(
    gql`
      mutation UpdateNotificationTemplateMutation(
        $updateNotificationTemplateInput: UpdateNotificationTemplateInput!
      ) {
        updateNotificationTemplate(input: $updateNotificationTemplateInput) {
          notificationTemplate {
            id
          }
        }
      }
    `,
    {
      errorPolicy: 'all',
      refetchQueries: ['NotificationTriggers'],
    }
  )

  const [notificationTriggersQuery, { data: notificationTriggersData }] =
    useLazyQuery(
      gql`
        query NotificationTriggers(
          $default: Boolean
          $organizationId: ID
          $subjectGroupId: ID
          $custom: Boolean
          $orCustom: Boolean
        ) {
          notificationTriggers(
            orderBy: "name"
            default: $default
            custom: $custom
            orCustom: $orCustom
            organization_Id: $organizationId
            subjectGroup_Id: $subjectGroupId
          ) {
            edges {
              node {
                id
                archived
                name
                using
                sendEmailImmediately
                custom
                smtpRateLimit
                smtpRateLimitSecondDelta
                enabled
                smsEnabled
                startDateTime
                endDateTime
                customStartEndTime
                emailEnabled
                recurring
                scheduledTrigger
                startDelta
                startDeltaCount
                occurrenceDelta
                occurrenceDeltaCount
                lastSentAt
                subjectGroup {
                  id
                  organization {
                    id
                  }
                }
                organization {
                  id
                }
                notificationTemplateChannels {
                  edges {
                    node {
                      id
                      channelType
                      notificationTemplate {
                        id
                        name
                        bccEmails
                        file {
                          fileName
                        }
                        ccEmails
                        emailSubject
                        emailTemplateDesign
                        smsContent
                        dynamicContext
                        images
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      {
        fetchPolicy: 'network-only',
        pollInterval: 5000,
      }
    )

  const [updateNotificationTriggerMutation] = useMutation(
    gql`
      mutation UpdateNotificationTriggerMutation(
        $updateNotificationTriggerInput: UpdateNotificationTriggerInput!
      ) {
        updateNotificationTrigger(input: $updateNotificationTriggerInput) {
          notificationTrigger {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        let savingTemplates = true
        if (
          currentNotificationTrigger?.subjectGroup &&
          formik.values.using != 'SUBJECT_GROUP'
        ) {
          savingTemplates = false
        }
        if (
          currentNotificationTrigger?.organization &&
          !currentNotificationTrigger?.subjectGroup &&
          formik.values.using != 'ORGANIZATION'
        ) {
          savingTemplates = false
        }
        if (savingTemplates) {
          toast.success(
            `${notificationTriggerName(
              currentNotificationTrigger
            )} Template Saved`
          )
        } else {
          toast.success('Saved')
        }
      },
      errorPolicy: 'all',
      refetchQueries: ['NotificationTriggers'],
    }
  )

  const formik = useFormik({
    initialValues: {
      name: '',
      custom: '',
      ccEmails: '',
      bccEmails: '',
      enabled: '',
      sendEmailImmediately: false,
      smtpRateLimit: null,
      smtpRateLimitSecondDelta: null,
      emailSubject: '',
      smsEnabled: '',
      emailEnabled: '',
      startDelta: '',
      using: '',
      startDeltaCount: 0,
      occurrenceDelta: '',
      occurrenceDeltaCount: 0,
      recurring: '',
      organizationName: '',
      organizationId: '',
      subjectGroupName: '',
      subjectGroupId: '',
      subjectDetailSubjectGroupId: '',
      jobId: '',
      jobName: '',
      subjectDetailSessionId: '',
      startDateTime: null,
      endDateTime: null,
      customStartEndTime: false,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().test(
        'name',
        'cannot update non custom trigger name',
        (value, context) => {
          if (context.parent.custom === false && value) {
            return false
          } else {
            return true
          }
        }
      ),
      using: Yup.string().nullable(),
      smsEnabled: Yup.bool().required('required'),
      emailEnabled: Yup.bool().required('required'),
      startDeltaCount: Yup.number()
        .nullable()
        .test('isLessThanEndDate', 'must be less than 1000', (value) => {
          let valid = true
          if (value && value > 1000 && showStartDeltaFields) {
            valid = false
          }
          return valid
        })
        .test('isLessThanEndDate', 'must be greater than 0', (value) => {
          let valid = true
          if (value && value < 1 && showStartDeltaFields) {
            valid = false
          }
          return valid
        }),
      occurrenceDeltaCount: Yup.string()
        .nullable()
        .test(
          'isLessThanEndDate',
          'must be less than 1000',
          (value, context) => {
            let valid = true
            if (
              context.parent.recurring &&
              value &&
              value > 1000 &&
              showOccurrenceDeltaFields
            ) {
              valid = false
            }
            return valid
          }
        )
        .test(
          'isLessThanEndDate',
          'must be greater than 0',
          (value, context) => {
            let valid = true
            if (
              context.parent.recurring &&
              value &&
              value < 1 &&
              showOccurrenceDeltaFields
            ) {
              valid = false
            }
            return valid
          }
        ),
      organizationName: Yup.string().nullable(),
      smtpRateLimit: Yup.number()
        .required('required')
        .max(50, 'must be less than 50')
        .min(1, 'must be greater than'),
      smtpRateLimitSecondDelta: Yup.number()
        .required('required')
        .max(1000, 'must be less than 1000')
        .min(1, 'must be greater than'),
      customRateLimitValidation: Yup.mixed().test(
        'custom-validation',
        'The calculated rate limit exceeds the maximum allowed rate of 100 emails per minute.',
        function (value) {
          let valid = true
          const { smtpRateLimit, smtpRateLimitSecondDelta } = this.parent
          if (
            smtpNode.customGmail &&
            (!smtpRateLimit ||
              !smtpRateLimitSecondDelta ||
              (60 / smtpRateLimitSecondDelta) * smtpRateLimit > 100)
          ) {
            valid = false
          }
          return valid
        }
      ),
      startDateTime: Yup.date()
        .nullable()
        .test(
          'isLessThanEndDate',
          'start date must not exceed end date',
          (value, context) => {
            let valid = true
            if (
              value &&
              context.parent.endDateTime &&
              context.parent.recurring
            ) {
              const startDate = new Date(value)
              const endDate = new Date(context.parent.endDateTime)
              endDate.setHours(23)
              endDate.setMinutes(59)
              endDate.setSeconds(59)
              if (endDate < startDate) {
                valid = false
              }
            }
            return valid
          }
        ),
      customStartEndTime: Yup.bool().nullable(),
      endDateTime: Yup.date().nullable(),
      organizationId: Yup.string().nullable(),
      subjectGroupName: Yup.string().nullable(),
      subjectGroupId: Yup.string().nullable(),
      subjectDetailSubjectGroupId: Yup.string().nullable(),
      subjectDetailSessionId: Yup.string().nullable(),
      recurring: Yup.bool().required('requiried'),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      let handleTemplates = true
      if (
        currentNotificationTrigger?.subjectGroup &&
        values.using != 'SUBJECT_GROUP'
      ) {
        handleTemplates = false
      }
      if (
        currentNotificationTrigger?.organization &&
        !currentNotificationTrigger?.subjectGroup &&
        values.using != 'ORGANIZATION'
      ) {
        handleTemplates = false
      }
      if (handleTemplates) {
        emailEditorRef.current.editor.exportHtml((data) => {
          let errors = ''
          let emailTemplateContent
          let emailTemplateDesign
          if (
            currentNotificationTriggerSMSTemplate &&
            handleTemplates &&
            (values.smsEnabled || currentTriggerCustom) &&
            !editSMSNotificationTemplateForm.values.smsContent.includes(
              '{{ unsubscribe_link }}'
            )
          ) {
            errors = 'SMS template must include {{ unsubscribe_link }}'
          }
          emailTemplateContent = data?.html.replace(
            /\{\{%20(.*?)%20\}\}/g,
            '{{ $1 }}'
          )
          emailTemplateDesign = JSON.stringify(data?.design).replace(
            /\{\{%20(.*?)%20\}\}/g,
            '{{ $1 }}'
          )
          if (
            (values.emailEnabled || currentTriggerCustom) &&
            handleTemplates &&
            !currentNotificationTrigger?.name?.includes('Ticket') &&
            !emailTemplateContent.includes('{{ unsubscribe_link }}')
          ) {
            if (errors) {
              errors = `Email and ${errors}`
            } else {
              errors = 'Email template must include {{ unsubscribe_link }}'
            }
          }
          if (errors) {
            toast.error(errors)
          } else {
            const variables = {
              updateNotificationTriggerInput: {
                notificationTriggerInput: {
                  enabled: values.enabled,
                  sendEmailImmediately: values.sendEmailImmediately,
                  smtpRateLimit: values.smtpRateLimit,
                  smtpRateLimitSecondDelta: values.smtpRateLimitSecondDelta,
                  id: currentNotificationTrigger.id,
                  smsEnabled: values.smsEnabled,
                  using: values.using,
                  emailEnabled: values.emailEnabled,
                  emailSubject: values.emailSubject,
                  recurring: values.recurring,
                },
              },
            }
            if (canSetCustomStartEndTime) {
              variables.updateNotificationTriggerInput.notificationTriggerInput.customStartEndTime =
                values.customStartEndTime
              if (values.customStartEndTime) {
                if (values.startDateTime) {
                  variables.updateNotificationTriggerInput.notificationTriggerInput.startDateTime =
                    DateTime.utc(
                      values.startDateTime.getFullYear(),
                      values.startDateTime.getMonth() + 1,
                      values.startDateTime.getDate(),
                      0,
                      0
                    ).toISO()
                } else {
                  variables.updateNotificationTriggerInput.notificationTriggerInput.startDateTime =
                    null
                }
                if (formik.values.endDateTime) {
                  variables.updateNotificationTriggerInput.notificationTriggerInput.endDateTime =
                    DateTime.utc(
                      values.endDateTime.getFullYear(),
                      values.endDateTime.getMonth() + 1,
                      values.endDateTime.getDate(),
                      23,
                      59
                    ).toISO()
                } else {
                  variables.updateNotificationTriggerInput.notificationTriggerInput.endDateTime =
                    null
                }
              }
            }
            if (currentNotificationTrigger.custom) {
              variables.updateNotificationTriggerInput.notificationTriggerInput.name =
                values.name
            }
            if (values.startDelta) {
              variables.updateNotificationTriggerInput.notificationTriggerInput.startDelta =
                values.startDelta
            }
            if (values.startDeltaCount) {
              variables.updateNotificationTriggerInput.notificationTriggerInput.startDeltaCount =
                values.startDeltaCount
            }
            if (values.occurrenceDelta) {
              variables.updateNotificationTriggerInput.notificationTriggerInput.occurrenceDelta =
                values.occurrenceDelta
            }
            if (values.occurrenceDeltaCount) {
              variables.updateNotificationTriggerInput.notificationTriggerInput.occurrenceDeltaCount =
                values.occurrenceDeltaCount
            }
            updateNotificationTriggerMutation({
              variables,
            })
            emailTemplateDesign = emailTemplateDesign
              .replace(/{{%20/g, '{{ ')
              .replace(/%20}}/g, ' }}')
            updateNotificationTemplateMutation({
              variables: {
                updateNotificationTemplateInput: {
                  notificationTemplateInput: {
                    id: currentNotificationTriggerEmailTemplate.id,
                    channel: 'email',
                    emailSubject: values.emailSubject,
                    bccEmails: values.bccEmails,
                    ccEmails: values.ccEmails,
                    emailTemplateContent,
                    emailTemplateDesign,
                  },
                },
              },
            })
            if (currentNotificationTriggerSMSTemplate?.id) {
              updateNotificationTemplateMutation({
                variables: {
                  updateNotificationTemplateInput: {
                    notificationTemplateInput: {
                      id: currentNotificationTriggerSMSTemplate.id,
                      channel: 'sms',
                      smsContent:
                        editSMSNotificationTemplateForm.values.smsContent,
                    },
                  },
                },
              })
            }
          }
        })
      } else {
        const variables = {
          updateNotificationTriggerInput: {
            notificationTriggerInput: {
              enabled: values.enabled,
              id: currentNotificationTrigger.id,
              smsEnabled: values.smsEnabled,
              using: values.using,
              emailEnabled: values.emailEnabled,
              emailSubject: values.emailSubject,
              recurring: values.recurring,
            },
          },
        }
        if (canSetCustomStartEndTime) {
          variables.updateNotificationTriggerInput.notificationTriggerInput.customStartEndTime =
            values.customStartEndTime
          if (values.customStartEndTime) {
            if (values.startDateTime) {
              variables.updateNotificationTriggerInput.notificationTriggerInput.startDateTime =
                DateTime.utc(
                  values.startDateTime.getFullYear(),
                  values.startDateTime.getMonth() + 1,
                  values.startDateTime.getDate(),
                  0,
                  0
                ).toISO()
            } else {
              variables.updateNotificationTriggerInput.notificationTriggerInput.startDateTime =
                null
            }
            if (formik.values.endDateTime) {
              variables.updateNotificationTriggerInput.notificationTriggerInput.endDateTime =
                DateTime.utc(
                  values.endDateTime.getFullYear(),
                  values.endDateTime.getMonth() + 1,
                  values.endDateTime.getDate(),
                  23,
                  59
                ).toISO()
            } else {
              variables.updateNotificationTriggerInput.notificationTriggerInput.endDateTime =
                null
            }
          }
        }
        if (currentNotificationTrigger.custom) {
          variables.updateNotificationTriggerInput.notificationTriggerInput.name =
            values.name
        }
        if (values.startDelta) {
          variables.updateNotificationTriggerInput.notificationTriggerInput.startDelta =
            values.startDelta
        }
        if (values.startDeltaCount) {
          variables.updateNotificationTriggerInput.notificationTriggerInput.startDeltaCount =
            values.startDeltaCount
        }
        if (values.occurrenceDelta) {
          variables.updateNotificationTriggerInput.notificationTriggerInput.occurrenceDelta =
            values.occurrenceDelta
        }
        if (values.occurrenceDeltaCount) {
          variables.updateNotificationTriggerInput.notificationTriggerInput.occurrenceDeltaCount =
            values.occurrenceDeltaCount
        }
        updateNotificationTriggerMutation({
          variables,
        })
      }
    },
  })

  useEffect(() => {
    formik.setFieldValue(`bccEmails`, '')
    formik.setFieldValue(`ccEmails`, '')
    if (!formik.values.organizationId && !formik.values.subjectGroupId) {
      setResetCurrent(false)
      setResetCurrent(true)
      setNonDefaultTemplate(false)
      let variables = {
        default: true,
      }
      if (subject || employee || contact || job) {
        variables.custom = true
      }
      notificationTriggersQuery({
        variables,
      })
    } else if (formik.values.organizationId && !formik.values.subjectGroupId) {
      setResetCurrent(false)
      setResetCurrent(true)
      setNonDefaultTemplate(true)
      let variables = {
        organizationId: formik.values.organizationId,
        subjectGroupId: null,
      }
      if (organization) {
        variables.orCustom = true
      }
      notificationTriggersQuery({
        variables,
      })
    } else if (formik.values.subjectGroupId) {
      setResetCurrent(false)
      setResetCurrent(true)
      setNonDefaultTemplate(true)
      let variables = {
        subjectGroupId: formik.values.subjectGroupId,
      }
      if (organization || subjectGroup) {
        variables.orCustom = true
      }
      notificationTriggersQuery({
        variables,
      })
    }
  }, [formik.values.organizationId, formik.values.subjectGroupId])

  useEffect(() => {
    if (organization && !subjectGroup) {
      formik.setValues({
        organizationId: organization.id,
        organizationName: organization.name,
      })
    } else if (subjectGroup) {
      formik.setValues({
        organizationId: organization?.id,
        organizationName: organization?.name,
        subjectGroupId: subjectGroup.id,
        subjectGroupName: subjectGroup.name,
      })
    }
  }, [organization, subjectGroup])

  const editSMSNotificationTemplateForm = useFormik({
    initialValues: {
      smsContent: '',
    },
    validationSchema: Yup.object().shape({
      smsContent: Yup.string().max(300, 'Too long'),
    }),
    validateOnChange: false,
  })

  useEffect(() => {
    if (notificationTriggersData) {
      const numberMap = {
        One: 1,
        Two: 2,
        Three: 3,
        Four: 4,
        Five: 5,
      }
      const currentNotificationTriggers = {}
      const sortedTriggers = [
        ...notificationTriggersData.notificationTriggers.edges,
      ].sort((a, b) => {
        const isCustomA = a.node.custom
        const isCustomB = b.node.custom

        const numA = (a.node.name.match(/One|Two|Three|Four|Five/g) || [])[0]
        const numB = (b.node.name.match(/One|Two|Three|Four|Five/g) || [])[0]

        if (!isCustomA && !isCustomB) {
          if (numA && numB) {
            return numberMap[numA] - numberMap[numB]
          } else {
            return a.node.name.localeCompare(b.node.name)
          }
        } else if (isCustomA && !isCustomB) {
          return 1
        } else if (!isCustomA && isCustomB) {
          return -1
        } else {
          return a.node.name.localeCompare(b.node.name)
        }
      })

      if (sortedTriggers.length === 0) {
        setNoCurrentTrigger(true)
        setCurrentNotificationTrigger()
        if (setCopyTriggerId) {
          setCopyTriggerId()
        }
      }

      sortedTriggers.forEach((notificationTrigger, index) => {
        if (
          (!currentNotificationTrigger && index === 0) ||
          (resetCurrent && index === 0)
        ) {
          setCurrentNotificationTrigger(notificationTrigger.node)
          if (setCopyTriggerId) {
            setCopyTriggerId(notificationTrigger.node.id)
          }
          setNoCurrentTrigger(false)
          setResetCurrent(false)
          setCurrentTriggerCustom(notificationTrigger.node.custom)
        } else if (
          !showDeleteTriggerModal &&
          currentNotificationTrigger &&
          currentNotificationTrigger.id === notificationTrigger.node.id
        ) {
          setCurrentTriggerCustom(notificationTrigger.node.custom)
          setNoCurrentTrigger(false)
          setCurrentNotificationTrigger(notificationTrigger.node)
          if (setCopyTriggerId) {
            setCopyTriggerId(notificationTrigger.node.id)
          }
        }
        currentNotificationTriggers[notificationTrigger.node.id] =
          notificationTrigger.node
      })
      setReloadEmailEditorRef(true)
      setNotificationTriggers(currentNotificationTriggers)
    }
  }, [notificationTriggersData])

  useEffect(() => {
    if (currentNotificationTrigger) {
      let startDelta = ''
      let startDeltaCount = 0
      if (currentNotificationTrigger.startDelta) {
        startDelta = currentNotificationTrigger.startDelta.toLowerCase()
        startDeltaCount = currentNotificationTrigger.startDeltaCount
      }
      let occurrenceDelta = ''
      let occurrenceDeltaCount = 0
      if (currentNotificationTrigger.occurrenceDelta) {
        occurrenceDelta =
          currentNotificationTrigger.occurrenceDelta.toLowerCase()
        occurrenceDeltaCount = currentNotificationTrigger.occurrenceDeltaCount
      }
      let emailSubject
      let bccEmails
      let ccEmails
      setCurrentNotificationTriggerEmailTemplate()
      setCurrentNotificationTriggerSMSTemplate()
      currentNotificationTrigger.notificationTemplateChannels.edges.forEach(
        (notificationTemplateChannel) => {
          if (notificationTemplateChannel.node.channelType === 'Email') {
            setCurrentNotificationTriggerEmailTemplate(
              notificationTemplateChannel.node.notificationTemplate
            )
            emailSubject =
              notificationTemplateChannel.node.notificationTemplate.emailSubject
            bccEmails =
              notificationTemplateChannel.node.notificationTemplate.bccEmails
            ccEmails =
              notificationTemplateChannel.node.notificationTemplate.ccEmails
          } else if (notificationTemplateChannel.node.channelType === 'SMS') {
            setCurrentNotificationTriggerSMSTemplate(
              notificationTemplateChannel.node.notificationTemplate
            )
          }
        }
      )
      let enabled
      if (nonDefaultTrigger) {
        enabled = currentNotificationTrigger.enabled
      }
      if (
        formik.values.subjectGroupId &&
        [
          'Subject Group Booking Reminder Campaign One',
          'Subject Group Booking Reminder Campaign Two',
          'Subject Group Booking Reminder Campaign Three',
          'Subject Group Booking Reminder Campaign Four',
          'Subject Group Booking Reminder Campaign Five',
          'Subject Group Booking Reminder Campaign Five',
          'Subject Group Session Initial Booking',
        ].includes(currentNotificationTrigger.name)
      ) {
        setCanSetCustomStartEndTime(true)
      } else {
        setCanSetCustomStartEndTime(false)
      }

      formik.setErrors({})
      formik.setValues({
        startDelta,
        startDeltaCount,
        occurrenceDelta,
        occurrenceDeltaCount,
        emailSubject,
        enabled,
        subjectDetailSubjectGroupId: formik.values.subjectDetailSubjectGroupId,
        subjectDetailSessionId: formik.values.subjectDetailSessionId,
        jobId: formik.values.jobId,
        bccEmails,
        sendEmailImmediately: currentNotificationTrigger.sendEmailImmediately,
        ccEmails,
        using: currentNotificationTrigger.using,
        smtpRateLimit: currentNotificationTrigger.smtpRateLimit,
        smtpRateLimitSecondDelta:
          currentNotificationTrigger.smtpRateLimitSecondDelta,
        custom: currentNotificationTrigger.custom,
        smsEnabled: currentNotificationTrigger.smsEnabled,
        emailEnabled: currentNotificationTrigger.emailEnabled,
        recurring: currentNotificationTrigger.recurring,
        organizationName: formik.values.organizationName,
        organizationId: formik.values.organizationId,
        subjectGroupName: formik.values.subjectGroupName,
        subjectGroupId: formik.values.subjectGroupId,
        customStartEndTime:
          currentNotificationTrigger.customStartEndTime != null
            ? currentNotificationTrigger.customStartEndTime
            : false,
        startDateTime: currentNotificationTrigger.startDateTime
          ? new Date(
              DateTime.fromISO(
                currentNotificationTrigger.startDateTime
              ).toLocaleString()
            )
          : null,
        endDateTime: currentNotificationTrigger.endDateTime
          ? new Date(
              DateTime.fromISO(
                currentNotificationTrigger.endDateTime
              ).toLocaleString()
            )
          : null,
      })
      if (currentNotificationTrigger.custom) {
        formik.setFieldValue('name', currentNotificationTrigger.name)
      }
      if (currentNotificationTrigger.name === 'Account Created') {
        setShowSMSTemplate(false)
        setNotificationRecipients('Everyone')
        setNotificationStartDetail('When an account is created')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (currentNotificationTrigger.name === 'Booking Request') {
        setShowSMSTemplate(false)
        setNotificationRecipients('Scheduling managers and analysts')
        setNotificationStartDetail('When a client submits')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (currentNotificationTrigger.name === 'Subject Invoice Sent') {
        setShowSMSTemplate(true)
        setNotificationRecipients('Subjects')
        setNotificationStartDetail('When an invoice is sent')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (currentNotificationTrigger.name === 'Subject Invoice Paid') {
        setShowSMSTemplate(true)
        setNotificationRecipients('Subjects')
        setNotificationStartDetail('When an invoice is paid')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name === 'Subject Invoice Payment Failed'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Subjects')
        setNotificationStartDetail('When an invoice payment fails')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (currentNotificationTrigger.name === 'Subject Invoice Void') {
        setShowSMSTemplate(true)
        setNotificationRecipients('Subjects')
        setNotificationStartDetail('When an invoice is void or deleted')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name === 'Organization Invoice Sent'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Organization Contacts')
        setNotificationStartDetail('When an invoice is sent')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name === 'Organization Invoice Paid'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Organization Contacts')
        setNotificationStartDetail('When an invoice is paid')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name ===
        'Organization Invoice Payment Failed'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Organization Contacts')
        setNotificationStartDetail('When an invoice payment fails')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name === 'Organization Invoice Void'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Organization Contacts')
        setNotificationStartDetail('When an invoice is void or deleted')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name ===
        'Employee Organization Invoice Payment Failed'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Organization Account Managers')
        setNotificationStartDetail('When an invoice payment fails')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name === 'Employee Organization Invoice Void'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Organization Account Managers')
        setNotificationStartDetail('When an invoice is void or deleted')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name === 'Employee Organization Invoice Paid'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Organization Account Managers')
        setNotificationStartDetail('When an invoice is paid')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name === 'Employee Organization Invoice Sent'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Organization Account Managers')
        setNotificationStartDetail('When an invoice is sent')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (currentNotificationTrigger.name === 'Email Confirmation') {
        setShowSMSTemplate(false)
        setNotificationRecipients('Everyone')
        setNotificationStartDetail('When creating an account')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (currentNotificationTrigger.name === 'Password Reset') {
        setShowSMSTemplate(false)
        setNotificationRecipients('Everyone')
        setNotificationStartDetail('When requesting a password reset')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (currentNotificationTrigger.name === 'Session Booked') {
        setShowSMSTemplate(true)
        setNotificationRecipients('Subjects')
        setNotificationStartDetail('When a session is booked')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name === 'Organization Session Ticket'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Subjects or organization contacts')
        setNotificationStartDetail('Downloaded from a job')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name === 'Subject Group Session Ticket'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Subject group subject')
        setNotificationStartDetail('Downloaded from a job')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (currentNotificationTrigger.name === 'Session Ticket') {
        setShowSMSTemplate(true)
        setNotificationRecipients('Subjects')
        setNotificationStartDetail('Downloaded from a job')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (currentNotificationTrigger.name === 'Session Payment Refund') {
        setShowSMSTemplate(true)
        setNotificationRecipients('Subjects')
        setNotificationStartDetail('When a session payment is refunded')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name ===
        'Subject Group Session Payment Refund'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Subjects')
        setNotificationStartDetail('When a session payment is refunded')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (currentNotificationTrigger.name === 'Session Payment') {
        setShowSMSTemplate(true)
        setNotificationRecipients('Subjects')
        setNotificationStartDetail('When a session payment is charged')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name === 'Subject Group Session Payment'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Subjects')
        setNotificationStartDetail('When a session payment is charged')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name === 'Organization Session Booked'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Organization Contacts')
        setNotificationStartDetail('When an organization session is booked')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name === 'Subject Group Session Booked'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Subjects')
        setNotificationStartDetail(
          'When a session is booked for a subject group'
        )
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name === 'Subject Group Session Cancelled'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Subjects')
        setNotificationStartDetail(
          'When a session is cancelled for a subject group'
        )
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name === 'Organization Session Cancelled'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Organization Contacts')
        setNotificationStartDetail('When an organization session is cancelled')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name ===
        'Subject Group Session Initial Booking'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Subjects')
        setNotificationStartDetail("after a subject group's start date")
        setNotificationOccurrenceDetail('until a subject groups end date')
        setShowOccurrenceDeltaFields(true)
        setShowStartDeltaFields(true)
        setShowRepeatToggle(true)
      } else if (
        [
          'Subject Group Booking Reminder Campaign One',
          'Subject Group Booking Reminder Campaign Two',
          'Subject Group Booking Reminder Campaign Three',
          'Subject Group Booking Reminder Campaign Four',
          'Subject Group Booking Reminder Campaign Five',
        ].includes(currentNotificationTrigger.name)
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Subjects')
        setNotificationStartDetail("after subject group's start date")
        setNotificationOccurrenceDetail('until a subject groups end date')
        setShowOccurrenceDeltaFields(true)
        setShowStartDeltaFields(true)
        setShowRepeatToggle(true)
      } else if (currentNotificationTrigger.name === 'Session Cancelled') {
        setShowSMSTemplate(true)
        setNotificationRecipients('Subjects')
        setNotificationStartDetail('When a session is cancelled')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (currentNotificationTrigger.name === 'Session No Show') {
        setShowSMSTemplate(true)
        setNotificationRecipients('Subjects')
        setNotificationStartDetail('When a subject misses a sessions')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name === 'Subject Group Session No Show'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Subjects')
        setNotificationStartDetail('When a subject misses a sessions')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name === 'Organization Session No Show'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Organization Contacts')
        setNotificationStartDetail('When an organization session is missed')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (currentNotificationTrigger.name === 'Session Complete') {
        setShowSMSTemplate(true)
        setNotificationRecipients('Subjects')
        setNotificationStartDetail('after a session is complete')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(true)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name === 'Organization Session Complete'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Organization Contacts')
        setNotificationStartDetail('after a session is complete')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(true)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name === 'Subject Group Session Complete'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Subjects')
        setNotificationStartDetail('after a session is complete')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(true)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name === 'Subject Group Session Rescheduled'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Subjects')
        setNotificationStartDetail('When a session is rescheduled')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name === 'Organization Session Rescheduled'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Organization Contacts')
        setNotificationStartDetail(
          'When an organization session is rescheduled'
        )
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (currentNotificationTrigger.name === 'Session Rescheduled') {
        setShowSMSTemplate(true)
        setNotificationRecipients('Subjects')
        setNotificationStartDetail('When a session is rescheduled')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name ===
        'Subject Group Session Photos Available'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Subjects')
        setNotificationStartDetail('When a jobs photos are available')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (currentNotificationTrigger.name === 'Session Reminder') {
        setShowSMSTemplate(true)
        setNotificationRecipients('Subjects')
        setNotificationStartDetail('before a session starts')
        setNotificationOccurrenceDetail('until a session starts')
        setShowOccurrenceDeltaFields(true)
        setShowStartDeltaFields(true)
        setShowRepeatToggle(true)
      } else if (
        currentNotificationTrigger.name ===
        'Organization Invoice Payment Reminder'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Organization Contacts')
        setNotificationStartDetail('after an invoice is sent')
        setNotificationOccurrenceDetail('until an invoice is paid')
        setShowOccurrenceDeltaFields(true)
        setShowStartDeltaFields(true)
        setShowRepeatToggle(true)
      } else if (
        currentNotificationTrigger.name ===
        'Employee Organization Invoice Payment Reminder'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Organization Account Managers')
        setNotificationStartDetail('after an invoice is sent')
        setNotificationOccurrenceDetail('until an invoice is paid')
        setShowOccurrenceDeltaFields(true)
        setShowStartDeltaFields(true)
        setShowRepeatToggle(true)
      } else if (
        currentNotificationTrigger.name === 'Subject Invoice Payment Reminder'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Subjects')
        setNotificationStartDetail('after an invoice is sent')
        setNotificationOccurrenceDetail('until an invoice is paid')
        setShowOccurrenceDeltaFields(true)
        setShowStartDeltaFields(true)
        setShowRepeatToggle(true)
      } else if (
        currentNotificationTrigger.name === 'Subject Group Session Reminder'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Subjects')
        setNotificationStartDetail('before a session starts')
        setNotificationOccurrenceDetail('until a session starts')
        setShowOccurrenceDeltaFields(true)
        setShowStartDeltaFields(true)
        setShowRepeatToggle(true)
      } else if (
        currentNotificationTrigger.name === 'Organization Session Reminder'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Organization Contacts')
        setNotificationStartDetail('before a session starts')
        setNotificationOccurrenceDetail('until a session starts')
        setShowOccurrenceDeltaFields(true)
        setShowStartDeltaFields(true)
        setShowRepeatToggle(true)
      } else if (
        currentNotificationTrigger.name === 'Organization Contacts Upcoming Job'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Organization Contacts associated to jobs')
        setNotificationStartDetail('before a job starts')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(true)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name === 'Employee Job Assignment'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Employees')
        setNotificationStartDetail('Manually through the job form')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name === 'Employee Job Unassignment'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Employees')
        setNotificationStartDetail('When an employee is unassigned from a job')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (currentNotificationTrigger.name === 'Employee Job Reminder') {
        setShowSMSTemplate(true)
        setNotificationRecipients('Employees')
        setNotificationStartDetail('before a job starts')
        setNotificationOccurrenceDetail('until a job begins')
        setShowOccurrenceDeltaFields(true)
        setShowStartDeltaFields(true)
        setShowRepeatToggle(true)
      } else if (currentNotificationTrigger.name === 'Employee Schedule') {
        setShowSMSTemplate(false)
        setNotificationRecipients('Employees')
        setNotificationStartDetail('When sent from the schedule page')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name === 'Employee Equipment Assignment'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Employees')
        setNotificationStartDetail('Manually through the job form')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name === 'Employee Work Schedule Update'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Employees')
        setNotificationStartDetail('On work schedule event updates')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (currentNotificationTrigger.custom) {
        setShowSMSTemplate(true)
      }
      if (!showComponent) {
        setShowComponent(true)
      }
    }
  }, [currentNotificationTrigger])

  const handleNotificationTriggerChange = (event) => {
    const trigger = notificationTriggers[event.target.value]
    setCurrentNotificationTrigger(trigger)
    if (setCopyTriggerId) {
      setCopyTriggerId(trigger.id)
    }
    if (trigger.custom) {
      setCurrentTriggerCustom(true)
    } else {
      setCurrentTriggerCustom(false)
    }
    formik.setFieldValue('ccEmails', '')
    formik.setFieldValue('bccEmails', '')
  }

  const repeatDetail = () => {
    let detail
    if (showOccurrenceDeltaFields) {
      if (formik.values.recurring) {
        if (formik.values.occurrenceDeltaCount) {
          let _notificationStartDetail = notificationOccurrenceDetail
          if (formik.values.endDateTime) {
            const formatterDate = DateTime.fromJSDate(
              formik.values.endDateTime
            ).toFormat('MMMM d, yyyy')
            _notificationStartDetail = `unit ${formatterDate} 11:59pm`
          }
          detail = `Every ${formik.values.occurrenceDeltaCount} ${formik.values.occurrenceDelta} ${_notificationStartDetail}`
        } else {
          detail = 'Time range required'
        }
      } else {
        detail = 'Does not repeat'
      }
    } else {
      detail = notificationOccurrenceDetail
    }
    return detail
  }

  const startDetail = () => {
    let detail
    if (showStartDeltaFields) {
      if (formik.values.startDeltaCount) {
        let _notificationStartDetail = notificationStartDetail
        if (formik.values.startDateTime) {
          const formatterDate = DateTime.fromJSDate(
            formik.values.startDateTime
          ).toFormat('MMMM d, yyyy')
          _notificationStartDetail = `after ${formatterDate} 12:00am`
        }
        detail = `${formik.values.startDeltaCount} ${formik.values.startDelta} ${_notificationStartDetail}`
      } else {
        detail = 'Time range required'
      }
    } else {
      detail = notificationStartDetail
    }
    return detail
  }

  const [
    searchOrganizations,
    { data: organizationsData, fetchMore: fetchMoreOrganizations },
  ] = useLazyQuery(
    gql`
      query NotificationOrganizations(
        $after: String
        $first: Int
        $nameIcontains: String
      ) {
        organizations(
          after: $after
          first: $first
          name_Icontains: $nameIcontains
          orderBy: "name"
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const [
    searchSubjectGroups,
    { data: subjectGroupsData, fetchMore: fetchMoreSubjectGroups },
  ] = useLazyQuery(
    gql`
      query NotificationSubjectGroups(
        $after: String
        $first: Int
        $nameIcontains: String
        $organizationId: ID!
      ) {
        subjectGroups(
          after: $after
          first: $first
          name_Icontains: $nameIcontains
          organization_Id: $organizationId
          orderBy: "name"
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const handleSubjectGroupChange = (e) => {
    setDisplaySubjectGroupResults(true)
    formik.setFieldValue(`subjectGroupName`, e.target.value)
    searchSubjectGroups({
      variables: {
        after: null,
        first: 10,
        organizationId: formik.values.organizationId,
        nameIcontains: e.target.value,
      },
    })
  }

  const handleSubjectGroupXClick = () => {
    setResetCurrent(false)
    setResetCurrent(true)
    setNonDefaultTemplate(false)
    formik.setFieldValue(`subjectGroupName`, '')
    formik.setFieldValue(`subjectGroupId`, '')
    formik.setFieldValue(`bccEmails`, '')
    formik.setFieldValue(`ccEmails`, '')
  }

  const handleFetchMoreSubjectGroups = () => {
    fetchMoreSubjectGroups({
      variables: {
        after: subjectGroupsData.subjectGroups.pageInfo.endCursor,
        first: 10,
        organizationId: formik.values.organizationId,
        nameIcontains: formik.values.subjectGroupName,
      },
    })
  }

  const handleSubjectGroupBlur = () => {
    setDisplaySubjectGroupResults(false)
    formik.setFieldTouched(`subjectGroupId`, true)
  }

  const handleSubjectGroupClick = (node) => {
    setDisplaySubjectGroupResults(false)
    formik.setFieldValue(`subjectGroupName`, node.name)
    formik.setFieldValue(`subjectGroupId`, node.id)
  }

  const handleOrganizationChange = (e) => {
    setDisplayOrganizationResults(true)
    formik.setFieldValue(`organizationName`, e.target.value)
    searchOrganizations({
      variables: {
        after: null,
        first: 10,
        nameIcontains: e.target.value,
      },
    })
  }

  const handleOrganizationXClick = () => {
    setResetCurrent(false)
    setResetCurrent(true)
    setNonDefaultTemplate(false)
    formik.setFieldValue(`organizationName`, '')
    formik.setFieldValue(`organizationId`, '')
    formik.setFieldValue(`bccEmails`, '')
    formik.setFieldValue(`ccEmails`, '')
  }

  const handleFetchMoreOrganizations = () => {
    fetchMoreOrganizations({
      variables: {
        after: organizationsData.organizations.pageInfo.endCursor,
        first: 10,
        nameIcontains: formik.values.organizationName,
      },
    })
  }

  const handleOrganizationBlur = () => {
    setDisplayOrganizationResults(false)
    formik.setFieldTouched(`organizationId`, true)
  }

  const handleOrganizationClick = (node) => {
    setDisplayOrganizationResults(false)
    formik.setFieldValue(`organizationName`, node.name)
    formik.setFieldValue(`organizationId`, node.id)
  }

  const handleJobChange = (e) => {
    setDisplayJobResults(true)
    formik.setFieldValue(`jobName`, e.target.value)
    const variables = {
      after: null,
      first: 10,
      name: e.target.value,
    }
    if (employee) {
      variables.employees = [employee.id]
    }
    if (contact) {
      variables.contacts = [contact.id]
    }
    searchJobs({ variables })
  }

  const handleJobXClick = () => {
    setResetCurrent(false)
    setResetCurrent(true)
    setNonDefaultTemplate(false)
    formik.setFieldValue(`jobName`, '')
    formik.setFieldValue(`jobId`, '')
    formik.setFieldValue(`bccEmails`, '')
    formik.setFieldValue(`ccEmails`, '')
  }

  const handleFetchMoreJobs = () => {
    fetchMoreJobs({
      variables: {
        after: jobsData.jobs.pageInfo.endCursor,
        first: 10,
        employees: [employee.id],
        name: formik.values.jobName,
      },
    })
  }

  const handleJobBlur = () => {
    setDisplayJobResults(false)
    formik.setFieldTouched(`jobId`, true)
  }

  const handleJobClick = (node) => {
    setDisplayJobResults(false)
    formik.setFieldValue(`jobName`, node.name)
    formik.setFieldValue(`jobId`, node.id)
  }

  const notificationTriggerName = (trigger) => {
    const defaultTriggers = [
      'Account Created',
      'Password Reset',
      'Email Confirmation',
      'Session Booked',
      'Subject Group Session Booked',
      'Subject Group Session Initial Booking',
      'Subject Group Booking Reminder Campaign One',
      'Subject Group Booking Reminder Campaign Two',
      'Subject Group Booking Reminder Campaign Three',
      'Subject Group Booking Reminder Campaign Four',
      'Subject Group Booking Reminder Campaign Five',
      'Session Reminder',
      'Subject Group Session Reminder',
      'Subject Group Session Photos Available',
      'Session Photos Available',
      'Session Cancelled',
      'Subject Group Session Cancelled',
      'Subject Group Session No Show',
      'Session No Show',
      'Subject Group Session Complete',
      'Session Complete',
      'Session Rescheduled',
      'Subject Group Session Rescheduled',
      'Organization Session Booked',
      'Organization Session Cancelled',
      'Organization Session Complete',
      'Organization Session Reminder',
      'Organization Session No Show',
      'Organization Session Rescheduled',
    ]
    let currentNotificationTriggerDescription = `${trigger.name}`
    if (!subject && !contact && defaultTriggers.includes(trigger.name)) {
      if (!formik.values.organizationName) {
        currentNotificationTriggerDescription = trigger.name
      } else if (
        formik.values.organizationName &&
        formik.values.organizationId &&
        !formik.values.subjectGroupId &&
        !organization &&
        !subjectGroup
      ) {
        currentNotificationTriggerDescription = `${trigger.name} ${formik.values.organizationName} `
      } else if (
        formik.values.organizationName &&
        formik.values.organizationId &&
        formik.values.subjectGroupName &&
        formik.values.subjectGroupId &&
        !organization &&
        !subjectGroup
      ) {
        currentNotificationTriggerDescription = `${trigger.name} ${formik.values.subjectGroupName} `
      }
    } else if (!subject && trigger.custom) {
      currentNotificationTriggerDescription = `${trigger.name} Custom`
    }
    return currentNotificationTriggerDescription
  }

  const sendEmployeeEmail = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      sendNotificationTrigger({
        variables: {
          sendNotificationTriggerInput: {
            notificationTriggerInput: {
              id: currentNotificationTrigger.id,
              sendToEmployeeId: employee.id,
              channel: 'Email',
              ccEmails: formik.values.ccEmails,
              bccEmails: formik.values.bccEmails,
              sendToEmployeeJobId: formik.values.jobId,
              modifiedTemplate: data.html.replace(
                /\{\{%20(.*?)%20\}\}/g,
                '{{$1}}'
              ),
            },
          },
        },
      })
    })
  }

  const sendEmployeeEmailFromMe = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      sendNotificationTrigger({
        variables: {
          sendNotificationTriggerInput: {
            notificationTriggerInput: {
              id: currentNotificationTrigger.id,
              senderEmployeeId: myUser.myUser.employee.id,
              sendToEmployeeId: employee.id,
              channel: 'Email',
              ccEmails: formik.values.ccEmails,
              bccEmails: formik.values.bccEmails,
              sendToEmployeeJobId: formik.values.jobId,
              modifiedTemplate: data.html.replace(
                /\{\{%20(.*?)%20\}\}/g,
                '{{$1}}'
              ),
            },
          },
        },
      })
    })
  }

  const sendEmployeeSMS = () => {
    sendNotificationTrigger({
      variables: {
        sendNotificationTriggerInput: {
          notificationTriggerInput: {
            id: currentNotificationTrigger.id,
            sendToEmployeeId: employee.id,
            channel: 'SMS',
            sendToEmployeeJobId: formik.values.jobId,
            modifiedTemplate: editSMSNotificationTemplateForm.values.smsContent,
          },
        },
      },
    })
  }

  const sendSubjectEmail = () => {
    if (
      formik.values.subjectDetailSubjectGroupId &&
      formik.values.subjectDetailSessionId
    ) {
      formik.setFieldError(
        'subjectDetailSubjectGroupId',
        'cannot choose subject group and session'
      )
    } else {
      if (formik.errors.subjectDetailSubjectGroupId) {
        formik.setFieldError('subjectDetailSubjectGroupId', null)
      }
      emailEditorRef.current.editor.exportHtml((data) => {
        sendNotificationTrigger({
          variables: {
            sendNotificationTriggerInput: {
              notificationTriggerInput: {
                id: currentNotificationTrigger.id,
                sendToSubjectId: subject.id,
                channel: 'Email',
                ccEmails: formik.values.ccEmails,
                bccEmails: formik.values.bccEmails,
                sendToSubjectSubjectGroupId:
                  formik.values.subjectDetailSubjectGroupId,
                sendToSubjectSessionId: formik.values.subjectDetailSessionId,
                modifiedTemplate: data.html.replace(
                  /\{\{%20(.*?)%20\}\}/g,
                  '{{$1}}'
                ),
              },
            },
          },
        })
      })
    }
  }

  const sendSubjectEmailFromMe = () => {
    if (
      formik.values.subjectDetailSubjectGroupId &&
      formik.values.subjectDetailSessionId
    ) {
      formik.setFieldError(
        'subjectDetailSubjectGroupId',
        'cannot choose subject group and session'
      )
    } else {
      if (formik.errors.subjectDetailSubjectGroupId) {
        formik.setFieldError('subjectDetailSubjectGroupId', null)
      }
      emailEditorRef.current.editor.exportHtml((data) => {
        sendNotificationTrigger({
          variables: {
            sendNotificationTriggerInput: {
              notificationTriggerInput: {
                id: currentNotificationTrigger.id,
                senderEmployeeId: myUser.myUser.employee.id,
                sendToSubjectId: subject.id,
                channel: 'Email',
                ccEmails: formik.values.ccEmails,
                bccEmails: formik.values.bccEmails,
                sendToSubjectSubjectGroupId:
                  formik.values.subjectDetailSubjectGroupId,
                sendToSubjectSessionId: formik.values.subjectDetailSessionId,
                modifiedTemplate: data.html.replace(
                  /\{\{%20(.*?)%20\}\}/g,
                  '{{$1}}'
                ),
              },
            },
          },
        })
      })
    }
  }

  const sendSubjectSMS = () => {
    if (
      formik.values.subjectDetailSubjectGroupId &&
      formik.values.subjectDetailSessionId
    ) {
      formik.setFieldError(
        'subjectDetailSubjectGroupId',
        'cannot choose subject group and session'
      )
    } else {
      if (formik.errors.subjectDetailSubjectGroupId) {
        formik.setFieldError('subjectDetailSubjectGroupId', null)
      }
      sendNotificationTrigger({
        variables: {
          sendNotificationTriggerInput: {
            notificationTriggerInput: {
              id: currentNotificationTrigger.id,
              sendToSubjectId: subject.id,
              channel: 'SMS',
              sendToSubjectSubjectGroupId:
                formik.values.subjectDetailSubjectGroupId,
              sendToSubjectSessionId: formik.values.subjectDetailSessionId,
              modifiedTemplate:
                editSMSNotificationTemplateForm.values.smsContent,
            },
          },
        },
      })
    }
  }

  const sendContactEmail = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      sendNotificationTrigger({
        variables: {
          sendNotificationTriggerInput: {
            notificationTriggerInput: {
              id: currentNotificationTrigger.id,
              sendToUserId: contact.id,
              channel: 'Email',
              ccEmails: formik.values.ccEmails,
              sendToSubjectJobId: formik.values.jobId,
              bccEmails: formik.values.bccEmails,
              sendToUserJobId: formik.values.jobId,
              modifiedTemplate: data.html.replace(
                /\{\{%20(.*?)%20\}\}/g,
                '{{$1}}'
              ),
            },
          },
        },
      })
    })
  }

  const sendContactEmailFromMe = () => {
    if (
      formik.values.subjectDetailSubjectGroupId &&
      formik.values.subjectDetailSessionId
    ) {
      formik.setFieldError(
        'subjectDetailSubjectGroupId',
        'cannot choose subject group and session'
      )
    } else {
      if (formik.errors.subjectDetailSubjectGroupId) {
        formik.setFieldError('subjectDetailSubjectGroupId', null)
      }
      emailEditorRef.current.editor.exportHtml((data) => {
        sendNotificationTrigger({
          variables: {
            sendNotificationTriggerInput: {
              notificationTriggerInput: {
                id: currentNotificationTrigger.id,
                senderEmployeeId: myUser.myUser.employee.id,
                sendToUserId: contact.id,
                channel: 'Email',
                ccEmails: formik.values.ccEmails,
                bccEmails: formik.values.bccEmails,
                sendToUserJobId: formik.values.jobId,
                modifiedTemplate: data.html.replace(
                  /\{\{%20(.*?)%20\}\}/g,
                  '{{$1}}'
                ),
              },
            },
          },
        })
      })
    }
  }

  const sendContactSMS = () => {
    if (
      formik.values.subjectDetailSubjectGroupId &&
      formik.values.subjectDetailSessionId
    ) {
      formik.setFieldError(
        'subjectDetailSubjectGroupId',
        'cannot choose subject group and session'
      )
    } else {
      if (formik.errors.subjectDetailSubjectGroupId) {
        formik.setFieldError('subjectDetailSubjectGroupId', null)
      }
      sendNotificationTrigger({
        variables: {
          sendNotificationTriggerInput: {
            notificationTriggerInput: {
              id: currentNotificationTrigger.id,
              sendToUserId: contact.id,
              channel: 'SMS',
              sendToUserJobId: formik.values.jobId,
              modifiedTemplate:
                editSMSNotificationTemplateForm.values.smsContent,
            },
          },
        },
      })
    }
  }

  const sendOrganizationEmailFromMe = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      sendNotificationTrigger({
        variables: {
          sendNotificationTriggerInput: {
            notificationTriggerInput: {
              id: currentNotificationTrigger.id,
              senderEmployeeId: myUser.myUser.employee.id,
              sendToOrganizationId: organization.id,
              userIds: notificationRecipientUsers,
              channel: 'Email',
              ccEmails: formik.values.ccEmails,
              bccEmails: formik.values.bccEmails,
              modifiedTemplate: data.html.replace(
                /\{\{%20(.*?)%20\}\}/g,
                '{{$1}}'
              ),
            },
          },
        },
      })
    })
  }

  const sendOrganizationEmail = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      sendNotificationTrigger({
        variables: {
          sendNotificationTriggerInput: {
            notificationTriggerInput: {
              id: currentNotificationTrigger.id,
              sendToOrganizationId: organization.id,
              userIds: notificationRecipientUsers,
              channel: 'Email',
              ccEmails: formik.values.ccEmails,
              bccEmails: formik.values.bccEmails,
              modifiedTemplate: data.html.replace(
                /\{\{%20(.*?)%20\}\}/g,
                '{{$1}}'
              ),
            },
          },
        },
      })
    })
  }

  const sendOrganizationSMS = () => {
    sendNotificationTrigger({
      variables: {
        sendNotificationTriggerInput: {
          notificationTriggerInput: {
            id: currentNotificationTrigger.id,
            sendToOrganizationId: organization.id,
            userIds: notificationRecipientUsers,
            channel: 'SMS',
            modifiedTemplate: editSMSNotificationTemplateForm.values.smsContent,
          },
        },
      },
    })
  }

  const sendJobContactsEmailFromMe = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      sendNotificationTrigger({
        variables: {
          sendNotificationTriggerInput: {
            notificationTriggerInput: {
              id: currentNotificationTrigger.id,
              senderEmployeeId: myUser.myUser.employee.id,
              sendToJobContactsId: job.id,
              userIds: notificationRecipientUsers,
              channel: 'Email',
              ccEmails: formik.values.ccEmails,
              bccEmails: formik.values.bccEmails,
              modifiedTemplate: data.html,
            },
          },
        },
      })
    })
  }

  const sendJobContactsEmail = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      sendNotificationTrigger({
        variables: {
          sendNotificationTriggerInput: {
            notificationTriggerInput: {
              id: currentNotificationTrigger.id,
              sendToJobContactsId: job.id,
              userIds: notificationRecipientUsers,
              channel: 'Email',
              ccEmails: formik.values.ccEmails,
              bccEmails: formik.values.bccEmails,
              modifiedTemplate: data.html.replace(
                /\{\{%20(.*?)%20\}\}/g,
                '{{$1}}'
              ),
            },
          },
        },
      })
    })
  }

  const sendJobContactsSMS = () => {
    sendNotificationTrigger({
      variables: {
        sendNotificationTriggerInput: {
          notificationTriggerInput: {
            id: currentNotificationTrigger.id,
            sendToJobContactsId: job.id,
            userIds: notificationRecipientUsers,
            channel: 'SMS',
            modifiedTemplate: editSMSNotificationTemplateForm.values.smsContent,
          },
        },
      },
    })
  }

  const sendJobSessionsEmailFromMe = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      sendNotificationTrigger({
        variables: {
          sendNotificationTriggerInput: {
            notificationTriggerInput: {
              id: currentNotificationTrigger.id,
              userIds: notificationRecipientUsers,
              senderEmployeeId: myUser.myUser.employee.id,
              sendToJobSessionsId: job.id,
              channel: 'Email',
              ccEmails: formik.values.ccEmails,
              bccEmails: formik.values.bccEmails,
              modifiedTemplate: data.html.replace(
                /\{\{%20(.*?)%20\}\}/g,
                '{{$1}}'
              ),
            },
          },
        },
      })
    })
  }

  const sendJobSessionsEmail = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      sendNotificationTrigger({
        variables: {
          sendNotificationTriggerInput: {
            notificationTriggerInput: {
              id: currentNotificationTrigger.id,
              userIds: notificationRecipientUsers,
              sendToJobSessionsId: job.id,
              channel: 'Email',
              ccEmails: formik.values.ccEmails,
              bccEmails: formik.values.bccEmails,
              modifiedTemplate: data.html.replace(
                /\{\{%20(.*?)%20\}\}/g,
                '{{$1}}'
              ),
            },
          },
        },
      })
    })
  }

  const sendJobSessionsSMS = () => {
    sendNotificationTrigger({
      variables: {
        sendNotificationTriggerInput: {
          notificationTriggerInput: {
            id: currentNotificationTrigger.id,
            sendToJobSubjectsId: job.id,
            userIds: notificationRecipientUsers,
            channel: 'SMS',
            modifiedTemplate: editSMSNotificationTemplateForm.values.smsContent,
          },
        },
      },
    })
  }

  const sendJobEmployeesEmailFromMe = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      sendNotificationTrigger({
        variables: {
          sendNotificationTriggerInput: {
            notificationTriggerInput: {
              id: currentNotificationTrigger.id,
              senderEmployeeId: myUser.myUser.employee.id,
              sendToJobEmployeesId: job.id,
              userIds: notificationRecipientUsers,
              channel: 'Email',
              ccEmails: formik.values.ccEmails,
              bccEmails: formik.values.bccEmails,
              modifiedTemplate: data.html.replace(
                /\{\{%20(.*?)%20\}\}/g,
                '{{$1}}'
              ),
            },
          },
        },
      })
    })
  }

  const sendJobEmployeesEmail = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      sendNotificationTrigger({
        variables: {
          sendNotificationTriggerInput: {
            notificationTriggerInput: {
              id: currentNotificationTrigger.id,
              sendToJobEmployeesId: job.id,
              userIds: notificationRecipientUsers,
              channel: 'Email',
              ccEmails: formik.values.ccEmails,
              bccEmails: formik.values.bccEmails,
              modifiedTemplate: data.html.replace(
                /\{\{%20(.*?)%20\}\}/g,
                '{{$1}}'
              ),
            },
          },
        },
      })
    })
  }

  const sendJobEmployeesSMS = () => {
    sendNotificationTrigger({
      variables: {
        sendNotificationTriggerInput: {
          notificationTriggerInput: {
            id: currentNotificationTrigger.id,
            sendToJobEmployeesId: job.id,
            userIds: notificationRecipientUsers,
            channel: 'SMS',
            modifiedTemplate: editSMSNotificationTemplateForm.values.smsContent,
          },
        },
      },
    })
  }

  const sendSubjectGroupEmailFromMe = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      sendNotificationTrigger({
        variables: {
          sendNotificationTriggerInput: {
            notificationTriggerInput: {
              id: currentNotificationTrigger.id,
              senderEmployeeId: myUser.myUser.employee.id,
              sendToSubjectGroupId: subjectGroup.id,
              userIds: notificationRecipientUsers,
              channel: 'Email',
              ccEmails: formik.values.ccEmails,
              bccEmails: formik.values.bccEmails,
              modifiedTemplate: data.html.replace(
                /\{\{%20(.*?)%20\}\}/g,
                '{{$1}}'
              ),
            },
          },
        },
      })
    })
  }

  const sendSubjectGroupNotBookedEmailFromMe = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      sendNotificationTrigger({
        variables: {
          sendNotificationTriggerInput: {
            notificationTriggerInput: {
              id: currentNotificationTrigger.id,
              senderEmployeeId: myUser.myUser.employee.id,
              sendToSubjectGroupId: subjectGroup.id,
              userIds: notificationRecipientUsers,
              channel: 'Email',
              subjectGroupSubjectsNotBooked: true,
              ccEmails: formik.values.ccEmails,
              bccEmails: formik.values.bccEmails,
              modifiedTemplate: data.html.replace(
                /\{\{%20(.*?)%20\}\}/g,
                '{{$1}}'
              ),
            },
          },
        },
      })
    })
  }

  const sendSubjectGroupCompletedEmailFromMe = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      sendNotificationTrigger({
        variables: {
          sendNotificationTriggerInput: {
            notificationTriggerInput: {
              id: currentNotificationTrigger.id,
              senderEmployeeId: myUser.myUser.employee.id,
              sendToSubjectGroupId: subjectGroup.id,
              userIds: notificationRecipientUsers,
              channel: 'Email',
              subjectGroupSubjectsSessionComplete: true,
              ccEmails: formik.values.ccEmails,
              bccEmails: formik.values.bccEmails,
              modifiedTemplate: data.html.replace(
                /\{\{%20(.*?)%20\}\}/g,
                '{{$1}}'
              ),
            },
          },
        },
      })
    })
  }

  const sendSubjectGroupBookedEmailFromMe = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      sendNotificationTrigger({
        variables: {
          sendNotificationTriggerInput: {
            notificationTriggerInput: {
              id: currentNotificationTrigger.id,
              senderEmployeeId: myUser.myUser.employee.id,
              sendToSubjectGroupId: subjectGroup.id,
              userIds: notificationRecipientUsers,
              channel: 'Email',
              subjectGroupSubjectsBooked: true,
              ccEmails: formik.values.ccEmails,
              bccEmails: formik.values.bccEmails,
              modifiedTemplate: data.html.replace(
                /\{\{%20(.*?)%20\}\}/g,
                '{{$1}}'
              ),
            },
          },
        },
      })
    })
  }

  const sendSubjectGroupEmail = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      sendNotificationTrigger({
        variables: {
          sendNotificationTriggerInput: {
            notificationTriggerInput: {
              id: currentNotificationTrigger.id,
              sendToSubjectGroupId: subjectGroup.id,
              userIds: notificationRecipientUsers,
              channel: 'Email',
              ccEmails: formik.values.ccEmails,
              bccEmails: formik.values.bccEmails,
              modifiedTemplate: data.html.replace(
                /\{\{%20(.*?)%20\}\}/g,
                '{{$1}}'
              ),
            },
          },
        },
      })
    })
  }

  const sendSubjectGroupNotBookedEmail = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      sendNotificationTrigger({
        variables: {
          sendNotificationTriggerInput: {
            notificationTriggerInput: {
              id: currentNotificationTrigger.id,
              sendToSubjectGroupId: subjectGroup.id,
              userIds: notificationRecipientUsers,
              channel: 'Email',
              subjectGroupSubjectsNotBooked: true,
              ccEmails: formik.values.ccEmails,
              bccEmails: formik.values.bccEmails,
              modifiedTemplate: data.html.replace(
                /\{\{%20(.*?)%20\}\}/g,
                '{{$1}}'
              ),
            },
          },
        },
      })
    })
  }

  const sendSubjectGroupSMS = () => {
    sendNotificationTrigger({
      variables: {
        sendNotificationTriggerInput: {
          notificationTriggerInput: {
            id: currentNotificationTrigger.id,
            sendToSubjectGroupId: subjectGroup.id,
            userIds: notificationRecipientUsers,
            channel: 'SMS',
            modifiedTemplate: editSMSNotificationTemplateForm.values.smsContent,
          },
        },
      },
    })
  }

  const sendSubjectGroupNotBookedSMS = () => {
    sendNotificationTrigger({
      variables: {
        sendNotificationTriggerInput: {
          notificationTriggerInput: {
            id: currentNotificationTrigger.id,
            sendToSubjectGroupId: subjectGroup.id,
            userIds: notificationRecipientUsers,
            channel: 'SMS',
            subjectGroupSubjectsNotBooked: true,
            modifiedTemplate: editSMSNotificationTemplateForm.values.smsContent,
          },
        },
      },
    })
  }

  const sendSubjectGroupBookedSMS = () => {
    sendNotificationTrigger({
      variables: {
        sendNotificationTriggerInput: {
          notificationTriggerInput: {
            id: currentNotificationTrigger.id,
            sendToSubjectGroupId: subjectGroup.id,
            userIds: notificationRecipientUsers,
            channel: 'SMS',
            subjectGroupSubjectsBooked: true,
            modifiedTemplate: editSMSNotificationTemplateForm.values.smsContent,
          },
        },
      },
    })
  }

  const sendSubjectGroupCompletedSMS = () => {
    sendNotificationTrigger({
      variables: {
        sendNotificationTriggerInput: {
          notificationTriggerInput: {
            id: currentNotificationTrigger.id,
            sendToSubjectGroupId: subjectGroup.id,
            userIds: notificationRecipientUsers,
            channel: 'SMS',
            subjectGroupSubjectsSessionComplete: true,
            modifiedTemplate: editSMSNotificationTemplateForm.values.smsContent,
          },
        },
      },
    })
  }

  const sendSubjectGroupCompletedEmail = () => {
    sendNotificationTrigger({
      variables: {
        sendNotificationTriggerInput: {
          notificationTriggerInput: {
            id: currentNotificationTrigger.id,
            sendToSubjectGroupId: subjectGroup.id,
            userIds: notificationRecipientUsers,
            channel: 'Email',
            subjectGroupSubjectsSessionComplete: true,
            modifiedTemplate: editSMSNotificationTemplateForm.values.smsContent,
          },
        },
      },
    })
  }

  const sendSubjectGroupBookedEmail = () => {
    sendNotificationTrigger({
      variables: {
        sendNotificationTriggerInput: {
          notificationTriggerInput: {
            id: currentNotificationTrigger.id,
            sendToSubjectGroupId: subjectGroup.id,
            userIds: notificationRecipientUsers,
            channel: 'Email',
            subjectGroupSubjectsBooked: true,
            modifiedTemplate: editSMSNotificationTemplateForm.values.smsContent,
          },
        },
      },
    })
  }

  const [sendNotificationTrigger] = useMutation(
    gql`
      mutation SendNotificationTrigger(
        $sendNotificationTriggerInput: SendNotificationTriggerInput!
      ) {
        sendNotificationTrigger(input: $sendNotificationTriggerInput) {
          sent
        }
      }
    `,
    {
      errorPolicy: 'all',
      onCompleted: () => {
        toast.success('Sending Notification')
        setShowSendNotificationModal(false)
      },
      refetchQueries: ['NotificationsQuery'],
    }
  )

  useEffect(() => {
    if (props.setDisplayNotifications) {
      props.setDisplayNotifications(display)
    }
  }, [display])

  if (!myUser || !smtpConnector || !canSend || !twilioConnectorData)
    return (
      <Row className="mt-3">
        <Col>
          <Loading message="Loading Notifications..." />
        </Col>
      </Row>
    )
  const smtpNode = smtpConnector.smtpConnectors.edges[0].node
  const twilioConnector = twilioConnectorData.twilioConnectors.edges[0].node
  if (noCurrentTrigger) {
    return (
      <>
        <div>
          {(!smtpNode.customValid || !twilioConnector.customValid) && (
            <Row className="mb-1">
              {!smtpNode.customValid && (
                <Col md={3}>
                  <Badge
                    className="ml-2"
                    style={{ fontSize: '12px' }}
                    variant="danger"
                  >
                    Email Server Credentials Required to Send Email
                  </Badge>
                </Col>
              )}
              {!twilioConnector.customValid && (
                <Col md={1}>
                  <Badge
                    className="ml-2"
                    style={{ fontSize: '12px' }}
                    variant="danger"
                  >
                    Twilio Credentials Required to Send SMS
                  </Badge>
                </Col>
              )}
            </Row>
          )}
          {display && canMutate && (
            <>
              <Row className="mb-4">
                <Col md={3}>
                  <Button variant="link" onClick={toggleNewTriggerModal}>
                    <PlusCircle className="mr-2" />
                    New Notification
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </div>
        {((!subject && !organization && !subjectGroup && !contact) ||
          ((subject || organization || subjectGroup || contact) &&
            display)) && (
          <>
            <h5 className="mt-4">Sent Notifications</h5>
            <NotificationsTable
              subject={subject}
              gaiaUser={contact}
              tableHeight={contact ? 400 : 800}
              organization={organization}
              subjectGroup={subjectGroup}
              notificationsLoading={notificationsLoading}
              setNotificationsLoading={setNotificationsLoading}
            />
          </>
        )}
        <NewCustomTriggerModal
          parentFormik={formik}
          setCurrentNotificationTrigger={setCurrentNotificationTrigger}
          setCurrentTriggerCustom={setCurrentTriggerCustom}
          showModal={showNewTriggerModal}
          toggleModal={toggleNewTriggerModal}
        />
      </>
    )
  }

  let showFields = true
  if (
    currentNotificationTrigger?.subjectGroup &&
    formik.values.using != 'SUBJECT_GROUP'
  ) {
    showFields = false
  }
  if (
    currentNotificationTrigger?.organization &&
    !currentNotificationTrigger?.subjectGroup &&
    formik.values.using != 'ORGANIZATION'
  ) {
    showFields = false
  }
  if (!showComponent)
    return (
      <Row className="mt-3">
        <Col>
          <Loading message="Loading Notifications..." />
        </Col>
      </Row>
    )

  const createObject = (action, label) => {
    return { action: action, value: `${action.name}`, label: label }
  }

  if (showComponent) {
    var sendNotificationOptions = [
      employee &&
        twilioConnector.customValid &&
        createObject(sendEmployeeSMS, 'Send Employee SMS'),
      employee &&
        smtpNode.customValid &&
        createObject(sendEmployeeEmail, 'Send Employee Email'),
      employee &&
        myUser.myUser.employee.smtpValid &&
        createObject(
          sendEmployeeEmailFromMe,
          'Send Employee Email From Your Email'
        ),
      subject &&
        twilioConnector.customValid &&
        createObject(sendSubjectSMS, 'Send Subject SMS'),
      subject &&
        smtpNode.customValid &&
        createObject(sendSubjectEmail, 'Send Subject Email'),
      subject &&
        myUser.myUser.employee.smtpValid &&
        createObject(
          sendSubjectEmailFromMe,
          'Send Subject Email From Your Email'
        ),
      contact &&
        twilioConnector.customValid &&
        createObject(sendContactSMS, 'Send Contact SMS'),
      contact &&
        smtpNode.customValid &&
        createObject(sendContactEmail, 'Send Contact Email'),
      contact &&
        myUser.myUser.employee.smtpValid &&
        createObject(
          sendContactEmailFromMe,
          'Send Contact Email From Your Email'
        ),
      job &&
        twilioConnector.customValid &&
        createObject(sendJobSessionsSMS, 'Send Sessions SMS'),
      job &&
        smtpNode.customValid &&
        createObject(sendJobSessionsEmail, 'Send Sessions Email'),
      job &&
        myUser.myUser.employee.smtpValid &&
        createObject(
          sendJobSessionsEmailFromMe,
          'Send Sessions Email From Your Email'
        ),
      job &&
        twilioConnector.customValid &&
        createObject(sendJobContactsSMS, 'Send Contacts SMS'),
      job &&
        smtpNode.customValid &&
        createObject(sendJobContactsEmail, 'Send Contacts Email'),
      job &&
        myUser.myUser.employee.smtpValid &&
        createObject(
          sendJobContactsEmailFromMe,
          'Send Contacts Email From Your Email'
        ),
      job &&
        twilioConnector.customValid &&
        createObject(sendJobEmployeesSMS, 'Send Employees SMS'),
      job &&
        smtpNode.customValid &&
        createObject(sendJobEmployeesEmail, 'Send Employees Email'),
      job &&
        myUser.myUser.employee.smtpValid &&
        createObject(
          sendJobEmployeesEmailFromMe,
          'Send Employees Email From Your Email'
        ),
    ]
    if (organization && !subjectGroup && currentNotificationTrigger.custom) {
      sendNotificationOptions.push(
        twilioConnector.customValid &&
          createObject(sendOrganizationSMS, 'Send Subjects SMS')
      )
      sendNotificationOptions.push(
        smtpNode.customValid &&
          createObject(sendOrganizationEmail, 'Send Subjects Email')
      )
      sendNotificationOptions.push(
        myUser.myUser.employee.smtpValid &&
          createObject(
            sendOrganizationEmailFromMe,
            'Send Subjects Email From Your Email'
          )
      )
    }
    if (
      subjectGroup &&
      (currentNotificationTrigger.custom ||
        currentNotificationTrigger?.name?.includes(
          'Subject Group Booking Reminder'
        ) ||
        currentNotificationTrigger.name ===
          'Subject Group Session Initial Booking')
    ) {
      sendNotificationOptions.push(
        twilioConnector.customValid &&
          createObject(sendSubjectGroupSMS, 'Send Subjects SMS')
      )
      sendNotificationOptions.push(
        twilioConnector.customValid &&
          createObject(
            sendSubjectGroupNotBookedSMS,
            'Send Not Booked Subjects SMS'
          )
      )
      sendNotificationOptions.push(
        twilioConnector.customValid &&
          createObject(sendSubjectGroupBookedSMS, 'Send Booked Subjects SMS')
      )
      sendNotificationOptions.push(
        twilioConnector.customValid &&
          createObject(
            sendSubjectGroupCompletedSMS,
            'Send Photographed Subjects SMS'
          )
      )
      sendNotificationOptions.push(
        smtpNode.customValid &&
          createObject(sendSubjectGroupEmail, 'Send Subjects Email')
      )
      sendNotificationOptions.push(
        smtpNode.customValid &&
          createObject(
            sendSubjectGroupNotBookedEmail,
            'Send Not Booked Subjects Email'
          )
      )
      sendNotificationOptions.push(
        smtpNode.customValid &&
          createObject(
            sendSubjectGroupBookedEmail,
            'Send Booked Subjects Email'
          )
      )
      sendNotificationOptions.push(
        smtpNode.customValid &&
          createObject(
            sendSubjectGroupCompletedEmail,
            'Send Photographed Subjects Email'
          )
      )
      if (
        !currentNotificationTrigger?.name?.includes(
          'Subject Group Booking Reminder'
        ) &&
        !currentNotificationTrigger.name ===
          'Subject Group Session Initial Booking' &&
        myUser.myUser.employee.smtpValid
      ) {
        sendNotificationOptions.push(
          createObject(
            sendSubjectGroupEmailFromMe,
            'Send Subjects Email From Your Email'
          )
        )
        sendNotificationOptions.push(
          createObject(
            sendSubjectGroupNotBookedEmailFromMe,
            'Send Not Booked Subjects Email From Your Email'
          )
        )
        sendNotificationOptions.push(
          createObject(
            sendSubjectGroupBookedEmailFromMe,
            'Send Booked Subjects Email From Your Email'
          )
        )
        sendNotificationOptions.push(
          createObject(
            sendSubjectGroupCompletedEmailFromMe,
            'Send Photographer Subjects Email From Your Email'
          )
        )
      }
    }

    sendNotificationOptions = sendNotificationOptions.filter(Boolean)
    const dynamicTemplateVariables =
      currentNotificationTriggerEmailTemplate?.dynamicContext
        ? JSON.parse(currentNotificationTriggerEmailTemplate.dynamicContext)
        : {}

    return (
      <>
        <div className="notifications">
          <Form onSubmit={formik.handleSubmit}>
            {!copyTrigger && (
              <>
                <Row className="mb-1">
                  <Col>
                    <h1 className="mt-3 mb-2">
                      {notificationTriggerName(currentNotificationTrigger)}{' '}
                      {!currentNotificationTrigger?.name?.includes(
                        'Ticket'
                      ) && <>Notification</>}
                    </h1>
                  </Col>
                </Row>
                {(!smtpNode.customValid || !twilioConnector.customValid) && (
                  <Row className="mb-1">
                    {!smtpNode.customValid && (
                      <Col md={3}>
                        <Badge
                          className="ml-2"
                          style={{ fontSize: '12px' }}
                          variant="danger"
                        >
                          Email Server Credentials Required to Send Email
                        </Badge>
                      </Col>
                    )}
                    {!twilioConnector.customValid && (
                      <Col md={1}>
                        <Badge
                          className="ml-2"
                          style={{ fontSize: '12px' }}
                          variant="danger"
                        >
                          Twilio Credentials Required to Send SMS
                        </Badge>
                      </Col>
                    )}
                  </Row>
                )}
              </>
            )}
            {(smtpNode.customValid || twilioConnector.customValid) &&
              sendNotificationOptions.length > 0 && (
                <Row className="mb-2">
                  <Col md={2}>
                    <Button
                      variant="link"
                      onClick={() => setShowSendNotificationModal(true)}
                    >
                      <Bell className="mr-2" />
                      Send Notification
                    </Button>
                  </Col>
                </Row>
              )}
            {!subject &&
              !job &&
              !contact &&
              !copyTrigger &&
              !organization &&
              !subjectGroup &&
              !employee && (
                <div>
                  <Row className="mb-3">
                    {canMutate && (
                      <>
                        <Col md={1} className="mr-5">
                          <Button
                            variant="link"
                            onClick={toggleNewTriggerModal}
                          >
                            <PlusCircle className="mr-2" />
                            New Notification
                          </Button>
                        </Col>
                        {(organization || subjectGroup) && canMutate && (
                          <>
                            {!currentTriggerCustom && (
                              <Col md={1}>
                                <Button
                                  variant="link"
                                  onClick={() => {
                                    setShowCopyTriggerModal(true)
                                    setCopyAllTriggers(true)
                                  }}
                                >
                                  <ArrowRepeat className="mr-2" />
                                  Sync All
                                </Button>
                              </Col>
                            )}
                          </>
                        )}
                        <Col md={1} className="mr-5">
                          <Button
                            variant="link"
                            onClick={() => {
                              setShowCopyTriggerModal(true)
                            }}
                          >
                            <ArrowRepeat className="mr-2" />
                            Sync{' '}
                            {(organization || subjectGroup) && (
                              <>Current</>
                            )}{' '}
                            {!organization && !subjectGroup && <>Template</>}
                          </Button>
                        </Col>
                      </>
                    )}
                    <Col md={2}>
                      <Button
                        variant="link"
                        onClick={() => {
                          downloadFile(
                            currentNotificationTriggerEmailTemplate.file
                              .fileName,
                            `${currentNotificationTriggerEmailTemplate.name}.html`,
                            () => {
                              toast.success('Email Template Downloaded')
                            }
                          )
                        }}
                      >
                        <Download className="mr-2" />
                        Download Email Template
                      </Button>
                    </Col>
                    {currentNotificationTriggerSMSTemplate && (
                      <Col md={2}>
                        <Button
                          variant="link"
                          onClick={() => {
                            downloadFile(
                              currentNotificationTriggerSMSTemplate.file
                                .fileName,
                              `${currentNotificationTriggerSMSTemplate.name}.txt`,
                              () => {
                                toast.success('SMS Template Downloaded')
                              }
                            )
                          }}
                        >
                          <Download className="mr-2" />
                          Download SMS Template
                        </Button>
                      </Col>
                    )}
                  </Row>
                </div>
              )}
            {(subject ||
              organization ||
              subjectGroup ||
              employee ||
              contact ||
              job) &&
              display && (
                <>
                  <Row className="mt-2 mb-2">
                    {canMutate && (
                      <>
                        <Col md={1} className="mr-5">
                          <Button
                            variant="link"
                            onClick={toggleNewTriggerModal}
                          >
                            <PlusCircle className="mr-2" />
                            New Notification
                          </Button>
                        </Col>
                        {(organization || subjectGroup) && canMutate && (
                          <>
                            {!currentTriggerCustom && (
                              <Col md={1}>
                                <Button
                                  variant="link"
                                  onClick={() => {
                                    setShowCopyTriggerModal(true)
                                    setCopyAllTriggers(true)
                                  }}
                                >
                                  <ArrowRepeat className="mr-2" />
                                  Sync All
                                </Button>
                              </Col>
                            )}
                          </>
                        )}
                        <Col md={1} className={contact ? 'mr-5 ml-3' : 'mr-5'}>
                          <Button
                            variant="link"
                            onClick={() => {
                              setShowCopyTriggerModal(true)
                            }}
                          >
                            <ArrowRepeat className="mr-2" />
                            Sync{' '}
                            {(organization || subjectGroup) && (
                              <>Current</>
                            )}{' '}
                            {!organization && !subjectGroup && <>Template</>}
                          </Button>
                        </Col>
                      </>
                    )}
                    {!contact && (
                      <>
                        <Col md={2} className="mr-3">
                          <Button
                            variant="link"
                            onClick={() => {
                              downloadFile(
                                currentNotificationTriggerEmailTemplate.file
                                  .fileName,
                                `${currentNotificationTriggerEmailTemplate.name}.html`,
                                () => {
                                  toast.success('Email Template Downloaded')
                                }
                              )
                            }}
                          >
                            <Download className="mr-2" />
                            Download Email Template
                          </Button>
                        </Col>
                        {currentNotificationTriggerSMSTemplate && (
                          <Col md={2}>
                            <Button
                              variant="link"
                              onClick={() => {
                                downloadFile(
                                  currentNotificationTriggerSMSTemplate.file
                                    .fileName,
                                  `${currentNotificationTriggerSMSTemplate.name}.txt`,
                                  () => {
                                    toast.success('SMS Template Downloaded')
                                  }
                                )
                              }}
                            >
                              <Download className="mr-2" />
                              Download SMS Template
                            </Button>
                          </Col>
                        )}
                      </>
                    )}
                  </Row>
                </>
              )}

            {((!subject && !organization && !subjectGroup) ||
              ((subject || organization || subjectGroup) && display)) && (
              <>
                {!subject && (
                  <>
                    {!subjectGroup &&
                      !organization &&
                      !employee &&
                      !contact &&
                      !job && (
                        <Form.Group as={Row}>
                          <Col md={2}>
                            <Form.Label column sm="12" md="auto">
                              Organization
                            </Form.Label>
                          </Col>
                          <Col sm="12" md={copyTrigger ? 8 : 4}>
                            <Form.Control
                              placeholder="search organizations"
                              value={formik.values.organizationName}
                              onBlur={handleOrganizationBlur}
                              onChange={(e) => handleOrganizationChange(e)}
                              readOnly={Boolean(formik.values.organizationId)}
                              className={
                                formik.values.organizationId
                                  ? ' border border-success form-control form-control-sm'
                                  : 'form-control form-control-sm'
                              }
                            />
                            {organizationsData &&
                              displayOrganizationResults && (
                                <InfiniteScroll
                                  height={100}
                                  dataLength={
                                    organizationsData.organizations.edges.length
                                  } //This is important field to render the next data
                                  next={handleFetchMoreOrganizations}
                                  hasMore={
                                    organizationsData?.organizations.pageInfo
                                      .hasNextPage
                                  }
                                  loader={<Loading />}
                                >
                                  <Table size="sm" hover>
                                    <tbody>
                                      {organizationsData.organizations.edges.map(
                                        (org) => {
                                          const { node } = org
                                          return (
                                            <tr
                                              onMouseDown={() =>
                                                handleOrganizationClick(node)
                                              }
                                              key={node.id}
                                              className="hover text-decoration-none"
                                            >
                                              <td>
                                                <small>{node.name}</small>
                                              </td>
                                            </tr>
                                          )
                                        }
                                      )}
                                    </tbody>
                                  </Table>
                                </InfiniteScroll>
                              )}
                          </Col>
                          <Col>
                            {formik.values.organizationId && (
                              <button
                                type="button"
                                className="p-0 mr-1 btn-link"
                                onClick={handleOrganizationXClick}
                              >
                                <Trash />
                              </button>
                            )}
                          </Col>
                        </Form.Group>
                      )}
                    {formik.values.organizationId && !subjectGroup && (
                      <>
                        <Form.Group as={Row}>
                          <Col md={2}>
                            <Form.Label column sm="12" md="auto">
                              Subject Group
                            </Form.Label>
                          </Col>
                          <Col sm="12" md={copyTrigger ? 8 : 4}>
                            <Form.Control
                              placeholder="search subject groups"
                              value={formik.values.subjectGroupName}
                              onBlur={handleSubjectGroupBlur}
                              onChange={(e) => handleSubjectGroupChange(e)}
                              readOnly={Boolean(formik.values.subjectGroupId)}
                              className={
                                formik.values.subjectGroupId
                                  ? ' border border-success form-control form-control-sm'
                                  : 'form-control form-control-sm'
                              }
                            />
                            {subjectGroupsData &&
                              displaySubjectGroupResults && (
                                <InfiniteScroll
                                  height={100}
                                  dataLength={
                                    subjectGroupsData.subjectGroups.edges.length
                                  } //This is important field to render the next data
                                  next={handleFetchMoreSubjectGroups}
                                  hasMore={
                                    subjectGroupsData?.subjectGroups.pageInfo
                                      .hasNextPage
                                  }
                                  loader={<Loading />}
                                >
                                  <Table size="sm" hover>
                                    <tbody>
                                      {subjectGroupsData.subjectGroups.edges.map(
                                        (org) => {
                                          const { node } = org
                                          return (
                                            <tr
                                              onMouseDown={() =>
                                                handleSubjectGroupClick(node)
                                              }
                                              key={node.id}
                                              className="hover text-decoration-none"
                                            >
                                              <td>
                                                <small>{node.name}</small>
                                              </td>
                                            </tr>
                                          )
                                        }
                                      )}
                                    </tbody>
                                  </Table>
                                </InfiniteScroll>
                              )}
                          </Col>
                          <Col>
                            {formik.values.subjectGroupId && !subjectGroup && (
                              <button
                                type="button"
                                className="p-0 mr-1 btn-link"
                                onClick={handleSubjectGroupXClick}
                              >
                                <Trash />
                              </button>
                            )}
                          </Col>
                        </Form.Group>
                      </>
                    )}
                  </>
                )}
                <Form.Group as={Row}>
                  <Col md={2}>
                    <Form.Label column sm="12" md="auto">
                      Template
                    </Form.Label>
                  </Col>
                  <Col sm="12" md={copyTrigger ? 8 : 4}>
                    <Form.Control
                      name="notificationTrigger"
                      as="select"
                      value={currentNotificationTrigger.id}
                      className="form-control form-control-sm"
                      onChange={(event) =>
                        handleNotificationTriggerChange(event)
                      }
                    >
                      {Object.entries(notificationTriggers).map(
                        (notificationTrigger) => {
                          return (
                            <option
                              key={notificationTrigger[0]}
                              value={notificationTrigger[0]}
                            >
                              {notificationTriggerName(notificationTrigger[1])}
                            </option>
                          )
                        }
                      )}
                    </Form.Control>
                  </Col>
                </Form.Group>
                {currentNotificationTrigger.custom &&
                  canMutate &&
                  !copyTrigger && (
                    <>
                      <Form.Group as={Row}>
                        <Col md={2}>
                          <Form.Label column sm="12" md="auto">
                            Template Name
                          </Form.Label>
                        </Col>
                        <Col sm="12" md={4}>
                          <Form.Control
                            type="text"
                            name="name"
                            className="form-control-sm"
                            disabled={!canMutate}
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            isInvalid={formik.errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.name}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </>
                  )}
                {(employee || contact) && (
                  <Form.Group as={Row}>
                    <Col md={2}>
                      <Form.Label column sm="12" md="auto">
                        Job
                      </Form.Label>
                    </Col>
                    <Col sm="12" md={4}>
                      <Form.Control
                        placeholder="search jobs"
                        value={formik.values.jobName}
                        onBlur={handleJobBlur}
                        onChange={(e) => handleJobChange(e)}
                        readOnly={Boolean(formik.values.jobId)}
                        className={
                          formik.values.jobId
                            ? ' border border-success form-control form-control-sm'
                            : 'form-control form-control-sm'
                        }
                      />
                      {jobsData && displayJobResults && (
                        <InfiniteScroll
                          height={100}
                          dataLength={jobsData.jobs.edges.length} //This is important field to render the next data
                          next={handleFetchMoreJobs}
                          hasMore={jobsData?.jobs.pageInfo.hasNextPage}
                          loader={<Loading />}
                        >
                          <Table size="sm" hover>
                            <tbody>
                              {jobsData.jobs.edges.map((job) => {
                                const { node } = job
                                return (
                                  <tr
                                    onMouseDown={() => handleJobClick(node)}
                                    key={node.id}
                                    className="hover text-decoration-none"
                                  >
                                    <td>
                                      <small>{node.name}</small>
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                        </InfiniteScroll>
                      )}
                    </Col>
                    <Col>
                      {formik.values.jobId && (
                        <button
                          type="button"
                          className="p-0 mr-1 btn-link"
                          onClick={handleJobXClick}
                        >
                          <Trash />
                        </button>
                      )}
                    </Col>
                  </Form.Group>
                )}
                {subject && (
                  <>
                    <Form.Group as={Row}>
                      <Col md={2}>
                        <Form.Label column sm="12" md="auto">
                          Subject Group
                        </Form.Label>
                      </Col>
                      <Col sm="12" md={4}>
                        <Form.Control
                          name="subjectDetailSubjectGroupId"
                          as="select"
                          value={formik.values.subjectDetailSubjectGroupId}
                          className="form-control form-control-sm"
                          onChange={formik.handleChange}
                        >
                          <option value="">- - -</option>
                          {subject.subjectGroups.edges.map((subjectGroup) => {
                            return (
                              <option
                                key={subjectGroup.node.id}
                                value={subjectGroup.node.id}
                              >
                                {subjectGroup.node.name}
                              </option>
                            )
                          })}
                        </Form.Control>
                        {formik.errors.subjectDetailSubjectGroupId && (
                          <small style={{ color: 'red' }}>
                            {formik.errors.subjectDetailSubjectGroupId}
                          </small>
                        )}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Col md={2}>
                        <Form.Label column sm="12" md="auto">
                          Session
                        </Form.Label>
                      </Col>
                      <Col sm="12" md={4}>
                        <Form.Control
                          name="subjectDetailSessionId"
                          as="select"
                          value={formik.values.subjectDetailSessionId}
                          className="form-control form-control-sm"
                          onChange={formik.handleChange}
                        >
                          <option value="">- - -</option>
                          {subject.sessions.edges.map((session) => {
                            if (
                              !session.node.cancelled &&
                              (!formik.values.subjectDetailSubjectGroupId ||
                                session.node.subjectGroup?.id ==
                                  formik.values.subjectDetailSubjectGroupId)
                            ) {
                              let detail = `${
                                session.node.sessionPackage.title
                              } at ${formatDateTimeToString(
                                new Date(session.node.startDateTime)
                              )} on ${session.node.job.name}`
                              if (session.node.subjectGroup) {
                                detail = `${detail} for ${session.node.subjectGroup.name}`
                              }
                              return (
                                <option
                                  key={session.node.id}
                                  value={session.node.id}
                                >
                                  {detail}
                                </option>
                              )
                            }
                          })}
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.subjectDetailSessionId}
                          </Form.Control.Feedback>
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  </>
                )}
                {!subject && !copyTrigger && (
                  <>
                    {!currentTriggerCustom && (
                      <>
                        {nonDefaultTrigger && (
                          <>
                            <Form.Group as={Row}>
                              <Col md={2}>
                                <Form.Label column sm="12" md="auto">
                                  Using
                                </Form.Label>
                              </Col>
                              <Col sm="12" md={4}>
                                <Form.Control
                                  name="using"
                                  as="select"
                                  className="form-control form-control-sm"
                                  disabled={!canMutate}
                                  value={formik.values.using}
                                  onChange={formik.handleChange}
                                  isInvalid={formik.errors.using}
                                >
                                  <option value={'DEFAULT'}>
                                    Default Template
                                  </option>
                                  {formik.values.organizationId && (
                                    <option value={'ORGANIZATION'}>
                                      Organization Template
                                    </option>
                                  )}

                                  {currentNotificationTrigger.subjectGroup && (
                                    <option value={'SUBJECT_GROUP'}>
                                      Subject Group Template
                                    </option>
                                  )}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {formik.errors.using}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Col md={2}>
                                <Form.Label column sm="12" md="auto">
                                  Enabled
                                </Form.Label>
                              </Col>
                              <Col sm="12" md={6}>
                                <Form.Check
                                  name="enabled"
                                  type="checkbox"
                                  disabled={!canMutate}
                                  label={formik.values.enabled ? 'Yes' : 'No'}
                                  checked={formik.values.enabled}
                                  onChange={formik.handleChange}
                                  isInvalid={formik.errors.enabled}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {formik.errors.enabled}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>
                          </>
                        )}
                        {!currentNotificationTrigger?.name?.includes(
                          'Ticket'
                        ) &&
                          showFields && (
                            <Form.Group as={Row}>
                              <Col md={2}>
                                <Form.Label column sm="12" md="auto">
                                  Send Emails
                                </Form.Label>
                              </Col>
                              <Col sm="12" md={6}>
                                <Form.Check
                                  name="emailEnabled"
                                  type="checkbox"
                                  disabled={!canMutate}
                                  label={
                                    formik.values.emailEnabled ? 'Yes' : 'No'
                                  }
                                  checked={formik.values.emailEnabled}
                                  onChange={formik.handleChange}
                                  isInvalid={formik.errors.emailEnabled}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {formik.errors.emailEnabled}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>
                          )}

                        {!currentNotificationTrigger?.name?.includes(
                          'Ticket'
                        ) &&
                          showSMSTemplate &&
                          showFields && (
                            <Form.Group as={Row}>
                              <Col md={2}>
                                <Form.Label column sm="12" md="auto">
                                  Send SMS
                                </Form.Label>
                              </Col>
                              <Col sm="12" md={6}>
                                <Form.Check
                                  name="smsEnabled"
                                  type="checkbox"
                                  disabled={!canMutate}
                                  label={
                                    formik.values.smsEnabled ? 'Yes' : 'No'
                                  }
                                  checked={formik.values.smsEnabled}
                                  onChange={formik.handleChange}
                                  isInvalid={formik.errors.smsEnabled}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {formik.errors.smsEnabled}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>
                          )}
                      </>
                    )}
                    {!currentNotificationTrigger?.name?.includes('Ticket') &&
                      showFields &&
                      canMutate && (
                        <>
                          <>
                            <Form.Group as={Row}>
                              <Col md={2}>
                                <Form.Label column sm="12" md="auto">
                                  Send Email Immediately
                                </Form.Label>
                              </Col>
                              <Col sm="12" md={6}>
                                <Form.Check
                                  name="sendEmailImmediately"
                                  type="checkbox"
                                  disabled={!canMutate}
                                  label={
                                    formik.values.sendEmailImmediately
                                      ? 'Yes'
                                      : 'No'
                                  }
                                  checked={formik.values.sendEmailImmediately}
                                  onChange={formik.handleChange}
                                  isInvalid={formik.errors.sendEmailImmediately}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {formik.errors.sendEmailImmediately}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>
                            {!formik.values.sendEmailImmediately && (
                              <>
                                <Form.Group as={Row}>
                                  <Col md={2}>
                                    <Form.Label column sm="12" md="auto">
                                      Emails Per Batch
                                    </Form.Label>
                                  </Col>
                                  <Col sm="12" md={1}>
                                    <Form.Control
                                      name="smtpRateLimit"
                                      type="number"
                                      value={formik.values.smtpRateLimit}
                                      className="form-control-sm"
                                      onChange={formik.handleChange}
                                      isInvalid={formik.errors.smtpRateLimit}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {formik.errors.smtpRateLimit}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                  <Col md={2}>
                                    <Form.Label column sm="12" md="auto">
                                      Seconds Per Batch
                                    </Form.Label>
                                  </Col>
                                  <Col sm="12" md={1}>
                                    <Form.Control
                                      name="smtpRateLimitSecondDelta"
                                      type="number"
                                      value={
                                        formik.values.smtpRateLimitSecondDelta
                                      }
                                      className="form-control-sm"
                                      onChange={formik.handleChange}
                                      isInvalid={
                                        formik.errors.smtpRateLimitSecondDelta
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {formik.errors.smtpRateLimitSecondDelta}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Form.Group>
                              </>
                            )}
                          </>
                        </>
                      )}

                    {!currentTriggerCustom && (
                      <>
                        {showFields && (
                          <Form.Group as={Row}>
                            <Col md={2}>
                              <Form.Label column sm="12" md="auto">
                                Recipients
                              </Form.Label>
                            </Col>
                            <Col sm="12" md={6}>
                              <Form.Control
                                readOnly
                                plaintext
                                value={notificationRecipients}
                              />
                            </Col>
                          </Form.Group>
                        )}

                        {showStartDeltaFields && showFields ? (
                          <>
                            {canSetCustomStartEndTime && (
                              <>
                                <Form.Group as={Row}>
                                  <Col md={2}>
                                    <Form.Label column sm="12" md="auto">
                                      Custom Start End Dates
                                    </Form.Label>
                                  </Col>
                                  <Col sm="12" md={6}>
                                    <>
                                      <Form.Check
                                        className="d-inline-block"
                                        name="customStartEndTime"
                                        type="checkbox"
                                        disabled={!canMutate}
                                        checked={
                                          formik.values.customStartEndTime
                                        }
                                        onChange={() => {
                                          if (
                                            formik.values.customStartEndTime
                                          ) {
                                            formik.setFieldValue(
                                              'startDateTime',
                                              null
                                            )
                                            formik.setFieldValue(
                                              'endDateTime',
                                              null
                                            )
                                            formik.setFieldError(
                                              'startDateTime',
                                              null
                                            )
                                            formik.setFieldError(
                                              'endDateTime',
                                              null
                                            )
                                          }
                                          formik.setFieldValue(
                                            'customStartEndTime',
                                            !formik.values.customStartEndTime
                                          )
                                        }}
                                        isInvalid={
                                          formik.errors.customStartEndTime
                                        }
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {formik.errors.customStartEndTime}
                                      </Form.Control.Feedback>
                                    </>
                                  </Col>
                                  {formik.values.customStartEndTime && (
                                    <Form.Group as={Row} className="mt-3">
                                      <Col md={2} className="ml-1">
                                        <Form.Label column sm="12" md="auto">
                                          Starts
                                        </Form.Label>
                                      </Col>
                                      <Col
                                        className="d-flex align-items-center pr-0"
                                        md={2}
                                      >
                                        {formik.values.startDateTime && (
                                          <div
                                            className="text-danger"
                                            onClick={() => {
                                              formik.setFieldValue(
                                                'startDateTime',
                                                null
                                              )
                                              formik.setFieldError(
                                                'startDateTime',
                                                null
                                              )
                                            }}
                                          >
                                            <Trash className="mr-2 text-danger" />
                                          </div>
                                        )}
                                        <DatePicker
                                          name="startDateTime"
                                          className="form-control"
                                          showPopperArrow={false}
                                          popperPlacement="auto"
                                          disabled={!canMutate}
                                          selected={formik.values.startDateTime}
                                          onChange={(date) =>
                                            formik.setFieldValue(
                                              'startDateTime',
                                              date
                                            )
                                          }
                                          popperModifiers={{
                                            flip: {
                                              behavior: ['bottom'],
                                            },
                                            preventOverflow: {
                                              enabled: false,
                                            },
                                            hide: {
                                              enabled: false,
                                            },
                                          }}
                                        />
                                        {formik.errors.startDateTime && (
                                          <small className="text-danger">
                                            {formik.errors.startDateTime}
                                          </small>
                                        )}
                                      </Col>
                                      {formik.values.recurring && (
                                        <>
                                          <Col md={1}>
                                            <Form.Label
                                              column
                                              sm="12"
                                              md="auto"
                                            >
                                              Ends
                                            </Form.Label>
                                          </Col>
                                          <Col
                                            className="d-flex align-items-center pr-0"
                                            md={2}
                                          >
                                            {formik.values.endDateTime && (
                                              <div
                                                className="text-danger"
                                                onClick={() => {
                                                  formik.setFieldValue(
                                                    'endDateTime',
                                                    null
                                                  )
                                                  formik.setFieldError(
                                                    'endDateTime',
                                                    null
                                                  )
                                                }}
                                              >
                                                <Trash className="mr-2 text-danger" />
                                              </div>
                                            )}
                                            <DatePicker
                                              name="endDateTime"
                                              className="form-control"
                                              showPopperArrow={false}
                                              popperPlacement="auto"
                                              disabled={!canMutate}
                                              selected={
                                                formik.values.endDateTime
                                              }
                                              onChange={(date) =>
                                                formik.setFieldValue(
                                                  'endDateTime',
                                                  date
                                                )
                                              }
                                              popperModifiers={{
                                                flip: {
                                                  behavior: ['bottom'],
                                                },
                                                preventOverflow: {
                                                  enabled: false,
                                                },
                                                hide: {
                                                  enabled: false,
                                                },
                                              }}
                                            />
                                            {formik.errors.endDateTime && (
                                              <small className="text-danger">
                                                {formik.errors.endDateTime}
                                              </small>
                                            )}
                                          </Col>
                                        </>
                                      )}
                                    </Form.Group>
                                  )}
                                </Form.Group>
                              </>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                        {showFields && (
                          <Form.Group as={Row}>
                            <Col md={2}>
                              <Form.Label column sm="12" md="auto">
                                Sends
                              </Form.Label>
                            </Col>
                            <Col sm="12" md={6}>
                              <Form.Control
                                readOnly
                                plaintext
                                value={startDetail()}
                              />
                            </Col>
                          </Form.Group>
                        )}
                        {showStartDeltaFields && showFields ? (
                          <>
                            <Row>
                              <Form.Group
                                as={Col}
                                md={{
                                  span: 1,
                                  offset: 2,
                                }}
                              >
                                <Form.Control
                                  type="number"
                                  name="startDeltaCount"
                                  disabled={!canMutate}
                                  value={formik.values.startDeltaCount}
                                  onChange={formik.handleChange}
                                  isInvalid={formik.errors.startDeltaCount}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {formik.errors.startDeltaCount}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group as={Col} md={2}>
                                <Form.Control
                                  name="startDelta"
                                  as="select"
                                  disabled={!canMutate}
                                  value={formik.values.startDelta}
                                  onChange={formik.handleChange}
                                  isInvalid={formik.errors.startDelta}
                                >
                                  {NOTIFICATION_TRIGGER_DELTAS.map((delta) => (
                                    <option key={delta} value={delta}>
                                      {delta}
                                    </option>
                                  ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {formik.errors.startDelta}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                          </>
                        ) : (
                          <></>
                        )}
                        {!currentNotificationTrigger?.name?.includes(
                          'Ticket'
                        ) &&
                          showFields && (
                            <Form.Group as={Row}>
                              <Col md={2}>
                                <Form.Label column sm="12" md="auto">
                                  Repeats
                                </Form.Label>
                              </Col>
                              <Col sm="12" md={6}>
                                {showRepeatToggle ? (
                                  <>
                                    <Form.Check
                                      className="d-inline-block"
                                      name="recurring"
                                      type="checkbox"
                                      label={repeatDetail()}
                                      disabled={!canMutate}
                                      checked={formik.values.recurring}
                                      onChange={formik.handleChange}
                                      isInvalid={formik.errors.recurring}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {formik.errors.recurring}
                                    </Form.Control.Feedback>
                                  </>
                                ) : (
                                  <span style={{ marginTop: '-5px' }}>
                                    {repeatDetail()}
                                  </span>
                                )}
                              </Col>
                            </Form.Group>
                          )}

                        {showOccurrenceDeltaFields &&
                        showFields &&
                        formik.values.recurring ? (
                          <>
                            <Row>
                              <Form.Group
                                as={Col}
                                md={{
                                  span: 1,
                                  offset: 2,
                                }}
                              >
                                <Form.Control
                                  type="number"
                                  name="occurrenceDeltaCount"
                                  disabled={!canMutate}
                                  value={formik.values.occurrenceDeltaCount}
                                  onChange={formik.handleChange}
                                  isInvalid={formik.errors.occurrenceDeltaCount}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {formik.errors.occurrenceDeltaCount}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group as={Col} md={2}>
                                <Form.Control
                                  name="occurrenceDelta"
                                  as="select"
                                  disabled={!canMutate}
                                  value={formik.values.occurrenceDelta}
                                  onChange={formik.handleChange}
                                  isInvalid={formik.errors.occurrenceDelta}
                                >
                                  {NOTIFICATION_TRIGGER_DELTAS.map((delta) => (
                                    <option key={delta} value={delta}>
                                      {delta}
                                    </option>
                                  ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {formik.errors.occurrenceDelta}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </>
                )}
                {subject &&
                  !currentNotificationTrigger?.name?.includes('Ticket') &&
                  showFields &&
                  canMutate && (
                    <>
                      <Form.Group as={Row}>
                        <Col md={2}>
                          <Form.Label column sm="12" md="auto">
                            Send Email Immediately
                          </Form.Label>
                        </Col>
                        <Col sm="12" md={6}>
                          <Form.Check
                            name="sendEmailImmediately"
                            type="checkbox"
                            disabled={!canMutate}
                            label={
                              formik.values.sendEmailImmediately ? 'Yes' : 'No'
                            }
                            checked={formik.values.sendEmailImmediately}
                            onChange={formik.handleChange}
                            isInvalid={formik.errors.sendEmailImmediately}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.sendEmailImmediately}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                      {!formik.values.sendEmailImmediately && (
                        <>
                          <Form.Group as={Row}>
                            <Col md={2}>
                              <Form.Label column sm="12" md="auto">
                                Emails Per Batch
                              </Form.Label>
                            </Col>
                            <Col sm="12" md={1}>
                              <Form.Control
                                name="smtpRateLimit"
                                type="number"
                                value={formik.values.smtpRateLimit}
                                className="form-control-sm"
                                onChange={formik.handleChange}
                                isInvalid={formik.errors.smtpRateLimit}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.smtpRateLimit}
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Col md={2}>
                              <Form.Label column sm="12" md="auto">
                                Seconds Per Batch
                              </Form.Label>
                            </Col>
                            <Col sm="12" md={1}>
                              <Form.Control
                                name="smtpRateLimitSecondDelta"
                                type="number"
                                value={formik.values.smtpRateLimitSecondDelta}
                                className="form-control-sm"
                                onChange={formik.handleChange}
                                isInvalid={
                                  formik.errors.smtpRateLimitSecondDelta
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.smtpRateLimitSecondDelta}
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                        </>
                      )}
                    </>
                  )}
                {!currentNotificationTrigger?.name?.includes('Ticket') &&
                  !copyTrigger &&
                  showFields && (
                    <>
                      <Form.Group as={Row}>
                        <Col md={2}>
                          <Form.Label column sm="12" md="auto">
                            Email CCs
                          </Form.Label>
                        </Col>
                        <Col sm="12" md={4}>
                          <Form.Control
                            type="text"
                            className="form-control-sm"
                            name="ccEmails"
                            value={formik.values.ccEmails}
                            onChange={formik.handleChange}
                            isInvalid={formik.errors.ccEmails}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.ccEmails}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Col md={2}>
                          <Form.Label column sm="12" md="auto">
                            Email BCCs
                          </Form.Label>
                        </Col>
                        <Col sm="12" md={4}>
                          <Form.Control
                            type="text"
                            name="bccEmails"
                            className="form-control-sm"
                            value={formik.values.bccEmails}
                            onChange={formik.handleChange}
                            isInvalid={formik.errors.bccEmails}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.bccEmails}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </>
                  )}
                {!copyTrigger && showFields && (
                  <>
                    <Row className="mt-4">
                      <Col className="d-flex align-items-center">
                        <button
                          type="button"
                          onClick={() =>
                            setDisplayTemplateVariables(
                              !displayTemplateVariables
                            )
                          }
                          className="px-0 btn-link mr-1"
                          style={{ marginTop: '-10px' }}
                        >
                          <>
                            {displayTemplateVariables ? (
                              <>
                                <CaretDown size={20} />
                              </>
                            ) : (
                              <>
                                <CaretRight size={20} />
                              </>
                            )}
                          </>
                        </button>
                        <h5>Template Variables</h5>
                      </Col>
                    </Row>
                    {displayTemplateVariables && (
                      <Row>
                        {Object.keys(dynamicTemplateVariables).map(
                          (dynamicTemplateVariable) => {
                            if (
                              !employee ||
                              (!dynamicTemplateVariable.includes('session') &&
                                !dynamicTemplateVariable.includes(
                                  'subject_group'
                                ))
                            ) {
                              const displayVariable = `{{ ${dynamicTemplateVariable} }}`
                              return (
                                <Col
                                  key={dynamicTemplateVariable}
                                  xs={12}
                                  md={3}
                                >
                                  <Badge
                                    style={{
                                      color: 'black',
                                    }}
                                  >
                                    {displayVariable}
                                  </Badge>
                                </Col>
                              )
                            }
                          }
                        )}
                      </Row>
                    )}
                  </>
                )}

                {!copyTrigger && (
                  <>
                    {showFields && (
                      <>
                        <EmailNotificationTemplateBuilder
                          notificationTemplate={
                            currentNotificationTriggerEmailTemplate
                          }
                          emailEditorRef={emailEditorRef}
                          formik={formik}
                          setReloadEmailEditorRef={setReloadEmailEditorRef}
                          reloadEmailEditorRef={reloadEmailEditorRef}
                        />
                        <SMSNotificationTemplateBuilder
                          notificationTemplate={
                            currentNotificationTriggerSMSTemplate
                          }
                          formik={editSMSNotificationTemplateForm}
                        />
                      </>
                    )}
                    {formik.errors.customRateLimitValidation && (
                      <small className="text-danger">
                        {formik.errors.customRateLimitValidation}
                      </small>
                    )}
                    {canMutate && !loggedInUser.permissions.isOrgContact && (
                      <Row className="mt-3">
                        <Col md={3}>
                          <Button
                            block
                            onClick={formik.handleSubmit}
                            variant="outline-primary"
                          >
                            <PlusCircle className="mr-2" />
                            Save {showFields && <>Notification</>}
                          </Button>
                        </Col>
                      </Row>
                    )}
                    {currentNotificationTrigger.custom &&
                      canMutate &&
                      !contact && (
                        <Row className="mt-3">
                          <Col md={3}>
                            <Button
                              block
                              variant="outline-danger"
                              onClick={toggleDeleteTriggerModal}
                            >
                              <Trash className="mr-2" />
                              Delete Notificaiton
                            </Button>
                          </Col>
                        </Row>
                      )}
                  </>
                )}
              </>
            )}
          </Form>
          {((!subject && !organization && !subjectGroup) ||
            ((subject || organization || subjectGroup) && display)) &&
            !copyTrigger &&
            !currentNotificationTrigger?.name?.includes('Ticket') &&
            showFields && (
              <>
                <Row>
                  <Col>
                    <h5 className="mt-5 mb-4">
                      Test {notificationTriggerName(currentNotificationTrigger)}{' '}
                      Notification
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <EmailNotificationTestForm
                      notificationTrigger={currentNotificationTrigger}
                      subjectId={subject?.id}
                      subjectGroupId={
                        subjectGroup?.id
                          ? subjectGroup.id
                          : formik.values.subjectDetailSubjectGroupId
                      }
                      organizationId={organization?.id}
                      sessionId={formik.values.subjectDetailSessionId}
                    />
                  </Col>
                </Row>
                {showSMSTemplate && (
                  <Row className="mt-2 mb-5">
                    <Col>
                      <SMSNotificationTestForm
                        notificationTrigger={currentNotificationTrigger}
                        subjectId={subject?.id}
                        subjectGroupId={
                          subjectGroup?.id
                            ? subjectGroup.id
                            : formik.values.subjectDetailSubjectGroupId
                        }
                        organizationId={organization?.id}
                        sessionId={formik.values.subjectDetailSessionId}
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}

          {((!subject && !organization && !subjectGroup) ||
            ((subject || organization || subjectGroup) && display)) &&
            !copyTrigger && (
              <>
                <h5 className="mt-4">Sent Notifications</h5>
                <NotificationsTable
                  job={job}
                  employee={employee}
                  subject={subject}
                  organization={organization}
                  subjectGroup={subjectGroup}
                  gaiaUser={contact}
                  notificationsLoading={notificationsLoading}
                  setNotificationsLoading={setNotificationsLoading}
                />
              </>
            )}
        </div>
        <NewCustomTriggerModal
          parentFormik={formik}
          setCurrentNotificationTrigger={setCurrentNotificationTrigger}
          setCurrentTriggerCustom={setCurrentTriggerCustom}
          showModal={showNewTriggerModal}
          toggleModal={toggleNewTriggerModal}
        />
        <DeleteCustomTriggerModal
          notificationTrigger={currentNotificationTrigger}
          showModal={showDeleteTriggerModal}
          toggleModal={toggleDeleteTriggerModal}
          setResetCurrent={setResetCurrent}
          setNonDefaultTemplate={setNonDefaultTemplate}
          notificationTriggersQuery={notificationTriggersQuery}
          parentFormik={formik}
          subject={subject}
          organization={organization}
          subjectGroup={subjectGroup}
          employee={employee}
        />
        <CopyTriggerModal
          showModal={showCopyTriggerModal}
          toggleModal={() => {
            setShowCopyTriggerModal(false)
            setCopyAllTriggers(false)
          }}
          all={copyAllTriggers}
          organization={subjectGroup ? null : organization}
          subjectGroup={subjectGroup}
          notificationTriggerId={currentNotificationTrigger?.id}
        />
        <SendNotificationModal
          showModal={showSendNotificationModal}
          options={sendNotificationOptions}
          toggleModal={setShowSendNotificationModal}
          organizationId={organization?.id}
          subjectGroupId={subjectGroup?.id}
          notificationRecipientUsers={notificationRecipientUsers}
          setNotificationRecipientUsers={setNotificationRecipientUsers}
          jobId={job?.id}
        />
      </>
    )
  }
}

export default Notifications
