import React from 'react'
import { Button, Row, Col } from 'react-bootstrap'

export default function Page(props) {
  const {
    title,
    titleComponent,
    actions,
    actionsStyle,
    actionSpanStyle,
    actionSpanStyleLength,
    children,
    actionsNewRow,
    subtitleActions,
    maxActionsPerRow,
    childrenMt,
  } = props

  const childrenMt_ = childrenMt ? childrenMt : 'mt-3'
  const chunkActions = (actions, maxActionsPerRow) => {
    const chunks = []
    for (let i = 0; i < actions.length; i += maxActionsPerRow) {
      chunks.push(actions.slice(i, i + maxActionsPerRow))
    }
    return chunks
  }

  const determineSpanStyle = (chunkLength) => {
    if (!actionSpanStyleLength) {
      return actionSpanStyle
    }

    for (const range in actionSpanStyleLength) {
      const [min, max] = range.split('-').map(Number)
      if (chunkLength >= min && chunkLength <= max) {
        return actionSpanStyleLength[range]
      }
    }

    return actionSpanStyle
  }

  const actionChunks = maxActionsPerRow
    ? chunkActions(actions, maxActionsPerRow)
    : actions
    ? [actions]
    : []

  const actionsJsx = (
    <div className="ms-auto">
      {actionChunks.map((chunk, chunkIndex) => (
        <div key={chunkIndex} className="d-flex align-items-center">
          {chunk.map((action, index) => {
            let { text, icon, onClick, render, disabled } = action
            if (disabled !== true) {
              disabled = false
            }
            const marginCSS = index > 0 ? 'ms-3' : ''
            const classCSS = ['p-0 btn-link', marginCSS].join(' ')
            const currentSpanStyle = determineSpanStyle(chunk.length)
            if (render || render === null) {
              if (!render) {
                return null
              } else {
                return (
                  <div key={index} className="ms-2">
                    {render}
                  </div>
                )
              }
            } else {
              return (
                <Button
                  size="sm"
                  variant="link"
                  style={actionsStyle ?? actionsStyle}
                  key={text + index}
                  className={classCSS}
                  onClick={onClick}
                  disabled={disabled}
                >
                  <div className="d-flex align-items-center">
                    {icon}
                    <span style={currentSpanStyle} className="ml-2 ms-1">
                      {text}
                    </span>
                  </div>
                </Button>
              )
            }
          })}
        </div>
      ))}
    </div>
  )
  return (
    <div
      className={
        actionsNewRow && !titleComponent && !title ? 'mb-5' : 'mb-5 mt-3'
      }
    >
      <Row>
        <Col>
          <div className="d-flex align-items-center">
            <div>
              {titleComponent && <>{titleComponent}</>}
              {!titleComponent && <h1 className="mb-0 d-inline">{title}</h1>}
              {subtitleActions && subtitleActions.length > 0
                ? subtitleActions.map((subtitle, index) => {
                    return <div key={index}>{subtitle.render}</div>
                  })
                : null}
            </div>
            {!actionsNewRow && <>{actionsJsx}</>}
          </div>
        </Col>
      </Row>
      {actionsNewRow && (
        <Row className="mt-1">
          <Col>{actionsJsx}</Col>
        </Row>
      )}
      <div className={childrenMt_}>{children}</div>
    </div>
  )
}
