import React, { useState, useEffect } from 'react'
import Repairs from './Repair/Repairs'
import ScanModal from './ScanModal'
import ItemTypes from './ItemType/ItemTypes'
import BagTypes from './BagType/BagTypes'
import Bags from './Bag/Bags'
import Items from './Item/Items'
import { loggedInUserVar } from '../../libs/apollo'
import {
  QrCode,
  BagCheck,
  BagX,
  Camera,
  CameraFill,
  Briefcase,
  BriefcaseFill,
  Tools,
} from 'react-bootstrap-icons'
import { useHistory } from 'react-router-dom'
import { useReactiveVar, useMutation, gql } from '@apollo/client'
import Page from '../layout/Page'
import { toast } from 'react-hot-toast'
import { Row, Col, Button, Tab } from 'react-bootstrap'
import Tabs from '../common/Tabs'

const Equipment = () => {
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const history = useHistory()
  const [showScanModal, setShowScanModal] = useState(false)
  const [scannedBags, setScannedBags] = useState([])
  const [scannedItems, setScannedItems] = useState([])
  const [disabledScanSubmitShowLoading, setDisabledScanSubmitShowLoading] =
    useState(false)
  const [activeTab, setActiveTab] = useState('Bags')
  const handleTabSelect = (selectedTab) => {
    setActiveTab(selectedTab)
  }

  const [updateItem] = useMutation(
    gql`
      mutation UpdateEquipmentItemMutations(
        $updateEquipmentItemInput: UpdateEquipmentItemInput!
      ) {
        updateEquipmentItem(input: $updateEquipmentItemInput) {
          equipmentItem {
            id
            name
            returned
          }
        }
      }
    `,
    {
      refetchQueries: [
        'ScanItems',
        'ScanBags',
        'EquipmentBags',
        'EquipmentBagTypes',
        'EquipmentItems',
      ],
    }
  )

  const [updateBag] = useMutation(
    gql`
      mutation UpdateEquipmentBagMutations(
        $updateEquipmentBagInput: UpdateEquipmentBagInput!
      ) {
        updateEquipmentBag(input: $updateEquipmentBagInput) {
          equipmentBag {
            id
            name
            returned
          }
        }
      }
    `,
    {
      refetchQueries: [
        'ScanItems',
        'ScanBags',
        'EquipmentBags',
        'EquipmentBagTypes',
        'EquipmentItems',
      ],
    }
  )

  const renderScanItem = (item) => {
    return (
      <>
        {item.name} {item.serialNumber && <>#{item.serialNumber}</>}
        {item.equipmentItemType && <> | {item.equipmentItemType.name}</>}{' '}
        {item.returned ? (
          <>
            {' '}
            <span className="ml-1" style={{ color: 'green', fontSize: '17px' }}>
              <BagCheck />
            </span>{' '}
          </>
        ) : (
          <>
            {' '}
            <span className="btn-link ml-1" style={{ fontSize: '17px' }}>
              <BagX />
            </span>{' '}
          </>
        )}
      </>
    )
  }

  const renderScanBag = (bag) => {
    return (
      <>
        {bag.name} {bag.equipmentBagType && <>| {bag.equipmentBagType.name}</>}{' '}
        {bag.returned ? (
          <>
            {' '}
            <span className="ml-1" style={{ color: 'green', fontSize: '17px' }}>
              <BagCheck />
            </span>{' '}
          </>
        ) : (
          <>
            {' '}
            <span className="btn-link ml-1" style={{ fontSize: '17px' }}>
              <BagX />
            </span>{' '}
          </>
        )}
      </>
    )
  }

  const showCheckInValidation = () => {
    let show = false
    scannedBags.forEach((bag) => {
      if (!show && !bag.returned) {
        show = true
      }
    })
    scannedItems.forEach((item) => {
      if (!show && !item.returned) {
        show = true
      }
    })
    return show
  }

  useEffect(() => {
    if (
      !['Scheduling Manager', 'Administrator', 'Equipment Manager'].includes(
        loggedInUser?.permissions?.group
      )
    ) {
      history.push('/schedule')
    }
  })

  const showCheckoutValidation = () => {
    let show = false
    scannedBags.forEach((bag) => {
      if (!show && bag.returned) {
        show = true
      }
    })
    scannedItems.forEach((item) => {
      if (!show && item.returned) {
        show = true
      }
    })
    return show
  }

  return (
    <>
      <Page
        title="Equipment"
        actions={[
          {
            text: 'Scan Equipment',
            icon: <QrCode />,
            onClick: () => setShowScanModal(true),
          },
        ]}
      >
        <Tabs onSelect={handleTabSelect} activeKey={activeTab}>
          <Tab
            eventKey="Bags"
            title={
              <b>
                <Briefcase className="mr-2" />
                Bags
              </b>
            }
          >
            <div className="mt-4">
              <Bags />
            </div>
          </Tab>
          <Tab
            eventKey="Items"
            title={
              <b>
                <Camera className="mr-2" />
                Items
              </b>
            }
          >
            <div className="mt-4">
              <Items />
            </div>
          </Tab>
          <Tab
            eventKey="BagTypes"
            title={
              <b>
                <BriefcaseFill className="mr-2" />
                Bag Types
              </b>
            }
          >
            <div className="mt-4">
              <BagTypes />
            </div>
          </Tab>
          <Tab
            eventKey="ItemTypes"
            title={
              <b>
                <CameraFill className="mr-2" />
                Item Types
              </b>
            }
          >
            <div className="mt-4">
              <ItemTypes />
            </div>
          </Tab>
          <Tab
            eventKey="Repairs"
            title={
              <b>
                <Tools className="mr-2" />
                Repairs
              </b>
            }
          >
            <div className="mt-4">
              <Repairs />
            </div>
          </Tab>
        </Tabs>
        <ScanModal
          showModal={showScanModal}
          toggleModal={() => {
            setShowScanModal((prevModal) => !prevModal)
            setScannedBags([])
            setScannedItems([])
            setShowScanModal()
          }}
          submitModal={() => {
            setDisabledScanSubmitShowLoading(true)
            scannedBags.forEach((bag) => {
              updateBag({
                variables: {
                  updateEquipmentBagInput: {
                    equipmentBagInput: {
                      id: bag.id,
                      returned: false,
                    },
                  },
                },
              })
            })
            setScannedBags((prevState) => {
              let bags = [...prevState]
              return bags.map((bag) => {
                bag.returned = false
                return bag
              })
            })
            setScannedItems((prevState) => {
              let items = [...prevState]
              return items.map((item) => {
                item.returned = false
                return item
              })
            })
            scannedItems.forEach((item) => {
              updateItem({
                variables: {
                  updateEquipmentItemInput: {
                    equipmentItemInput: {
                      id: item.id,
                      returned: false,
                    },
                  },
                },
              })
            })
            toast.success('Equipment Checkout Out')
            setDisabledScanSubmitShowLoading(false)
          }}
          renderItem={renderScanItem}
          renderBag={renderScanBag}
          setScannedBags={setScannedBags}
          scannedItems={scannedItems}
          scannedBags={scannedBags}
          disabledSubmitShowLoading={disabledScanSubmitShowLoading}
          showSubmitValidation={showCheckoutValidation}
          submitButtonMessage={'Checkout'}
          setScannedItems={setScannedItems}
          otherButtons={() => {
            if (showCheckInValidation()) {
              return (
                <>
                  <Row className="mt-3">
                    <Col md={{ span: 6, offset: 3 }}>
                      <Button
                        block
                        variant="outline-primary"
                        onClick={() => {
                          setDisabledScanSubmitShowLoading(true)
                          scannedBags.forEach((bag) => {
                            updateBag({
                              variables: {
                                updateEquipmentBagInput: {
                                  equipmentBagInput: {
                                    id: bag.id,
                                    returned: true,
                                  },
                                },
                              },
                            })
                          })
                          scannedItems.forEach((item) => {
                            updateItem({
                              variables: {
                                updateEquipmentItemInput: {
                                  equipmentItemInput: {
                                    id: item.id,
                                    returned: true,
                                  },
                                },
                              },
                            })
                          })
                          setScannedBags((prevState) => {
                            let bags = [...prevState]
                            return bags.map((bag) => {
                              bag.returned = true
                              return bag
                            })
                          })
                          setScannedItems((prevState) => {
                            let items = [...prevState]
                            return items.map((item) => {
                              item.returned = true
                              return item
                            })
                          })
                          toast.success('Equipment Checked In')
                          setDisabledScanSubmitShowLoading(false)
                        }}
                      >
                        Check In
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={{ span: 6, offset: 3 }}>
                      <Button
                        block
                        variant="outline-primary"
                        onClick={() => {
                          setDisabledScanSubmitShowLoading(true)
                          scannedBags.forEach((bag) => {
                            updateBag({
                              variables: {
                                updateEquipmentBagInput: {
                                  equipmentBagInput: {
                                    id: bag.id,
                                    returned: true,
                                    unassign: true,
                                  },
                                },
                              },
                            })
                          })
                          scannedItems.forEach((item) => {
                            updateItem({
                              variables: {
                                updateEquipmentItemInput: {
                                  equipmentItemInput: {
                                    id: item.id,
                                    returned: true,
                                    unassign: true,
                                  },
                                },
                              },
                            })
                          })
                          setScannedBags((prevState) => {
                            let bags = [...prevState]
                            return bags.map((bag) => {
                              bag.returned = true
                              bag.lastEmployeeJob = null
                              return bag
                            })
                          })
                          setScannedItems((prevState) => {
                            let items = [...prevState]
                            return items.map((item) => {
                              item.returned = true
                              item.lastEmployeeJob = null
                              return item
                            })
                          })
                          toast.success('Equipment Checked In')
                          setDisabledScanSubmitShowLoading(false)
                        }}
                      >
                        Check In and Unassign
                      </Button>
                    </Col>
                  </Row>
                </>
              )
            } else {
              return <></>
            }
          }}
        />
      </Page>
    </>
  )
}

export default Equipment
