import React, { useEffect, useState } from 'react'
import Loading from './Loading'
import InfiniteScroll from 'react-infinite-scroll-component'
import SortableTable from './SortableTable'
import TableFilter from './TableFilter'

const SortableInfiniteTable = (props) => {
  let {
    tableData,
    fetchMoreTableData,
    hasMoreTableData,
    tableColumns,
    onRowClick,
    loading,
    infiniteTableId,
    tdStyle,
    tdStyleGenerator,
    trStyle,
    trStyleGenerator,
    thStyle,
    onTdClicks,
    tableSize,
    displayHeader,
    tableHeight,
    rowPointer,
    hideGlobalFilter,
    searchWidth,
    loadingMessage,
    linkTds,
    discludeTrHover,
    handleSortByChange,
  } = props

  let hideInfinteGlobalFilter
  if (hideGlobalFilter === undefined || false) {
    hideInfinteGlobalFilter = false
  } else if (hideGlobalFilter === true) {
    hideInfinteGlobalFilter = true
  }
  let sWidth = 4
  if (searchWidth) {
    sWidth = searchWidth
  }
  let height = 300
  if (tableHeight) {
    height = tableHeight
  }
  let tableId = 'infiniteTable'
  if (infiniteTableId) {
    tableId = infiniteTableId
  }
  const [preGlobalFilteredRows, setPreGlobalFilteredRows] = useState()
  const [tableState, setTableState] = useState()
  const [setGlobalFilter, setSetGlobalFilter] = useState()

  let loader = loading ? <></> : <Loading message={loadingMessage} />
  return (
    <>
      {!hideInfinteGlobalFilter &&
        preGlobalFilteredRows &&
        tableState &&
        setGlobalFilter && (
          <TableFilter
            sWidth={sWidth}
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={tableState.globalFilter}
            setGlobalFilter={setGlobalFilter.fn}
            hasMoreTableData={hasMoreTableData}
            dataLength={tableData.length}
          />
        )}
      <div
        id="infinite-wrapper"
        style={{ overflowY: 'auto', maxHeight: '1000px' }}
      >
        <div>
          <InfiniteScroll
            height={height}
            dataLength={tableData.length}
            next={fetchMoreTableData}
            hasMore={hasMoreTableData}
            loader={loader}
            scrollableTarget="infinite-wrapper"
          >
            <SortableTable
              tableSize={tableSize}
              tdStyle={tdStyle}
              loading={loading}
              tdStyleGenerator={tdStyleGenerator}
              trStyle={trStyle}
              thStyle={thStyle}
              data={tableData}
              trStyleGenerator={trStyleGenerator}
              columns={tableColumns}
              onRowClick={onRowClick}
              onTdClicks={onTdClicks}
              discludeTrHover={discludeTrHover}
              displayHeader={displayHeader}
              rowPointer={rowPointer}
              searchWidth={searchWidth}
              setPreGlobalFilteredRows={setPreGlobalFilteredRows}
              setTableState={setTableState}
              setSetGlobalFilter={setSetGlobalFilter}
              hideInfinteGlobalFilter={hideInfinteGlobalFilter}
              linkTds={linkTds}
              handleSortByChange={handleSortByChange}
            />
          </InfiniteScroll>
        </div>
      </div>
    </>
  )
}

export default SortableInfiniteTable
