import React, { useEffect, useState } from 'react'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { Form, Row, Col, Button, Modal, Table } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import DatePicker from 'react-datepicker'
import InfiniteScroll from 'react-infinite-scroll-component'
import { objFull } from '../../../libs/utils'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../../libs/apollo'
import { PlusCircle, Trash, CaretDown, CaretRight } from 'react-bootstrap-icons'
import Loading from '../../common/Loading'
import FilesTable from '../../files/FilesTable'

const ServiceItemModal = (props) => {
  const {
    showModal,
    toggleModal,
    organizationId,
    organizationName,
    updateServiceItem,
    employeeDetail,
  } = props

  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = ['Scheduling Manager', 'Administrator'].includes(
    loggedInUser?.permissions?.group
  )
  const [employeeValue, setEmployeeValue] = useState('')
  const [originalName, setOriginalName] = useState('')
  const [displayFiles, setDisplayFiles] = useState(false)
  const [displayCompletedEmployeeResults, setDisplayCompletedEmployeeResults] =
    useState(false)

  const [displayEmployeeResults, setDisplayEmployeeResults] = useState(false)
  const [contactsOptionValue, setContactsOptionValue] = useState('dash')
  const [contactsArr, setContactsArr] = useState([])
  const [newTaskGroup, setNewTaskGroup] = useState(false)
  const statusChoices = ['To Do', 'In Progress', 'Done']
  const [deleteMutation] = useMutation(
    gql`
      mutation DeleteServiceItem($input: DeleteServiceItemInput!) {
        deleteServiceItem(input: $input) {
          deleted
        }
      }
    `,
    {
      onCompleted: (data) => {
        toast.success('Task Deleted')
        innerToggle()
      },
      errorPolicy: 'all',
      refetchQueries: ['Organization', 'OrganizationDetail', 'ServiceItems'],
      variables: {
        input: {
          serviceItemIds: [updateServiceItem.id],
        },
      },
    }
  )
  const [getGroups, { data: groupsData }] = useLazyQuery(
    gql`
      query DefaultServiceItemGroupsQuery {
        defaultServiceItemGroups(orderBy: "name") {
          edges {
            node {
              id
              name
              default
            }
          }
        }
      }
    `,
    { fetchPolicy: 'network-only' }
  )

  const [create] = useMutation(
    gql`
      mutation CreateServiceItem($input: CreateServiceItemInput!) {
        createServiceItem(input: $input) {
          serviceItem {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        toast.success('Task Saved')
        innerToggle()
      },
      errorPolicy: 'all',
      refetchQueries: ['Organization', 'Tasks', 'OrganizationDetail'],
    }
  )

  const [update] = useMutation(
    gql`
      mutation UpdateServiceItem($input: UpdateServiceItemInput!) {
        updateServiceItem(input: $input) {
          serviceItem {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        toast.success('Task Saved')
        innerToggle()
      },
      errorPolicy: 'all',
      refetchQueries: ['Organization', 'Tasks', 'OrganizationDetail'],
    }
  )

  const [searchEmployee, { data: employeesData }] = useLazyQuery(
    gql`
      query EmployeesQuery($cursor: String, $searchTerm: String) {
        employees(
          first: 20
          after: $cursor
          gaiaUser_FullName_Icontains: $searchTerm
          orderBy: "gaia_user__full_name"
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              gaiaUser {
                email
                fullName
              }
            }
          }
        }
      }
    `,
    {
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    }
  )

  const [searchCompletedEmployee, { data: completedEmployeesData }] =
    useLazyQuery(
      gql`
        query CompletedEmployeesQuery($cursor: String, $searchTerm: String) {
          employees(
            first: 20
            after: $cursor
            gaiaUser_FullName_Icontains: $searchTerm
            orderBy: "gaia_user__full_name"
          ) {
            pageInfo {
              endCursor
              hasNextPage
            }
            edges {
              node {
                id
                gaiaUser {
                  email
                  fullName
                }
              }
            }
          }
        }
      `,
      {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      }
    )

  const [contactsQuery, { data: contactsData }] = useLazyQuery(
    gql`
      query Organization($organizationId: ID!) {
        organization(id: $organizationId) {
          serviceItemSharedCanCreateFiles
          serviceItemSharedCanCreateFolders
          serviceItemSharedCanSeeFiles
          contacts(archived: false) {
            edges {
              node {
                id
                fullName
                email
              }
            }
          }
        }
      }
    `,
    {
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      variables: {
        organizationId: organizationId ? organizationId : '',
      },
    }
  )

  const handleCompletedEmployeeClick = (employeeNode) => {
    formik.setFieldValue('completedEmployeeId', employeeNode.node.id)
    formik.setFieldValue(
      'completedEmployeeName',
      employeeNode.node.gaiaUser.fullName
    )
  }

  const handleCompletedEmployeeChange = (e) => {
    if (e.target.value != '') {
      searchCompletedEmployee({
        variables: {
          searchTerm: e.target.value,
        },
        fetchPolicy: 'network-only',
      })
      setDisplayCompletedEmployeeResults(true)
    } else {
      setDisplayCompletedEmployeeResults(false)
    }
  }

  const handleCompletedEmployeeBlur = () => {
    setDisplayCompletedEmployeeResults(false)
    formik.setFieldTouched('completedEmployeeId', true)
  }

  const handleCompletedEmployeeXClick = () => {
    formik.setFieldValue(`completedEmployeeName`, '')
    formik.setFieldValue(`completedEmployeeId`, '')
  }

  useEffect(() => {
    if (contactsData && contactsData.organization) {
      setContactsArr(contactsData.organization.contacts.edges)
    }
  }, [contactsData])

  const handleEmployeeBlur = () => {
    setDisplayEmployeeResults(false)
    formik.setFieldTouched('employees', true)
    setEmployeeValue('')
  }

  const handleContactsClick = (e) => {
    const index = contactsArr.findIndex((x) => x.node.id === e.target.value)
    if (index > -1) {
      const currentContacts = [...formik.values.contacts]
      currentContacts.push(contactsArr[index])
      formik.setFieldValue('contacts', currentContacts)
      setContactsOptionValue('dash')
    }
  }

  const handleEmployeeClick = (employeeNode) => {
    const newEmployees = [employeeNode, ...formik.values.employees]
    formik.setFieldValue('employees', newEmployees)
  }

  const handleEmployeeChange = (e) => {
    setEmployeeValue(e.target.value)
    if (e.target.value != '') {
      searchEmployee({
        variables: {
          searchTerm: e.target.value,
        },
        fetchPolicy: 'network-only',
      })
      setDisplayEmployeeResults(true)
    } else {
      setDisplayEmployeeResults(false)
    }
  }

  const handleRemoveContacts = (contact) => {
    const currentContacts = [...formik.values.contacts]
    currentContacts.splice(currentContacts.indexOf(contact), 1)
    formik.setFieldValue('contacts', currentContacts)
  }

  const handleRemoveEmployee = (employee) => {
    formik.setFieldValue(
      'employees',
      formik.values.employees.filter((emp) => emp.node.id !== employee.node.id)
    )
  }

  const formik = useFormik({
    initialValues: {
      group: '',
      newGroup: '',
      description: '',
      status: 'To Do',
      eventDate: null,
      dueDate: null,
      finishedDate: null,
      contacts: [],
      employees: [],
      notes: '',
      sharedCanSeeFiles:
        contactsData?.organization?.serviceItemSharedCanSeeFiles,
      sharedCanCreateFiles:
        contactsData?.organization?.serviceItemSharedCanCreateFiles,
      sharedCanCreateFolders:
        contactsData?.organization?.serviceItemSharedCanCreateFolders,
    },
    validationSchema: Yup.object().shape({
      group: Yup.string().test(
        'valid',
        'existing or new group is required',
        (value, context) => {
          let valid = true
          if ((!value || value === 'none') && !context.parent.newGroup) {
            valid = false
          }
          return valid
        }
      ),
      newGroup: Yup.string()
        .test(
          'valid',
          'cannot choose an existing group and new group',
          (value, context) => {
            let valid = true
            if (
              value &&
              context.parent.group &&
              context.parent.group !== 'none'
            ) {
              valid = false
            }
            return valid
          }
        )
        .test('valid', 'group already exists', (value) => {
          let valid = true
          if (groupsData?.defaultServiceItemGroups?.edges) {
            groupsData.defaultServiceItemGroups.edges.forEach((group) => {
              if (group.node.name === value) {
                valid = false
              }
            })
          }
          return valid
        }),
      description: Yup.string().nullable(),
      status: Yup.string()
        .required('required')
        .test('isStatus', 'invalid status', (value) => {
          let valid = false
          if (value && ['In Progress', 'To Do', 'Done'].includes(value)) {
            valid = true
          }
          return valid
        }),
      eventDate: Yup.string().nullable(),
      dueDate: Yup.string().nullable(),
      finishedDate: Yup.string().nullable(),
      notes: Yup.string().nullable(),
      sharedCanSeeFiles: Yup.boolean().required('Required'),
      sharedCanCreateFiles: Yup.boolean().required('Required'),
      sharedCanCreateFolders: Yup.boolean().required('Required'),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      const addContactsIds = values.contacts.map((contactsData) => {
        return contactsData.node.id
      })
      const addEmployeesIds = values.employees.map((employeesData) => {
        return employeesData.node.id
      })
      let serviceItemGroupId
      if (values.group && values.group !== 'none') {
        serviceItemGroupId = values.group
      }
      let newGroup
      if (values.newGroup) {
        newGroup = values.newGroup
      }
      if (objFull(updateServiceItem)) {
        update({
          variables: {
            input: {
              serviceItemInput: {
                id: updateServiceItem.id,
                description: values.description,
                notes: values.notes,
                status: values.status,
                dueDate: values.dueDate,
                serviceItemGroupId,
                completedByEmployeeId: values.completedEmployeeId,
                serviceItemGroupName: newGroup,
                eventDate: values.eventDate,
                finishedDate: values.finishedDate,
                contactIds: addContactsIds,
                employeeIds: addEmployeesIds,
                sharedCanSeeFiles: values.sharedCanSeeFiles,
                sharedCanCreateFiles: values.sharedCanCreateFiles,
                sharedCanCreateFolders: values.sharedCanCreateFolders,
              },
            },
          },
        })
      } else {
        create({
          variables: {
            input: {
              serviceItemInput: {
                organizationId: organizationId,
                description: values.description,
                notes: values.notes,
                completedByEmployeeId: values.completedEmployeeId,
                status: values.status,
                dueDate: values.dueDate,
                serviceItemGroupId,
                serviceItemGroupName: newGroup,
                eventDate: values.eventDate,
                finishedDate: values.finishedDate,
                contactIds: addContactsIds,
                employeeIds: addEmployeesIds,
                sharedCanSeeFiles: values.sharedCanSeeFiles,
                sharedCanCreateFiles: values.sharedCanCreateFiles,
                sharedCanCreateFolders: values.sharedCanCreateFolders,
              },
            },
          },
        })
      }
    },
  })

  useEffect(() => {
    if (objFull(updateServiceItem)) {
      formik.setValues({
        group: updateServiceItem.serviceItemGroup?.id || '',
        newGroup: '',
        description: updateServiceItem.description || '',
        completedEmployeeId:
          updateServiceItem.completedByEmployee?.id &&
          updateServiceItem.completedByEmployee?.id,
        completedEmployeeName:
          updateServiceItem.completedByEmployee?.gaiaUser?.fullName &&
          updateServiceItem.completedByEmployee?.gaiaUser?.fullName,
        status: updateServiceItem.status || 'To Do',
        eventDate: updateServiceItem.eventDate
          ? new Date(updateServiceItem?.eventDate)
          : null,
        dueDate: updateServiceItem.dueDate
          ? new Date(updateServiceItem?.dueDate)
          : null,
        finishedDate: updateServiceItem.finishedDate
          ? new Date(updateServiceItem?.finishedDate)
          : null,
        contacts: updateServiceItem.contacts.edges,
        employees: updateServiceItem.employees.edges,
        notes: updateServiceItem.notes || '',
        sharedCanSeeFiles: updateServiceItem.sharedCanSeeFiles,
        sharedCanCreateFiles: updateServiceItem.sharedCanCreateFiles,
        sharedCanCreateFolders: updateServiceItem.sharedCanCreateFolders,
      })
    }
  }, [updateServiceItem])

  useEffect(() => {
    if (contactsData && contactsData.organization) {
      formik?.setFieldValue(
        'sharedCanSeeFiles',
        contactsData?.organization?.serviceItemSharedCanSeeFiles
      )
      formik?.setFieldValue(
        'sharedCanCreateFiles',
        contactsData?.organization?.serviceItemSharedCanCreateFiles
      )
      formik?.setFieldValue(
        'sharedCanCreateFolders',
        contactsData?.organization?.serviceItemSharedCanCreateFolders
      )
    }
  }, [contactsData, showModal])

  useEffect(() => {
    if (showModal && organizationId) {
      getGroups()
      contactsQuery()
    }
  }, [showModal, organizationId])

  const innerToggle = () => {
    formik.resetForm()
    toggleModal()
  }
  useEffect(() => {
    if (formik.values.description) {
      setOriginalName(formik.values.description)
    }
  }, [formik.values.description])
  if (!groupsData || !contactsData) return <></>
  return (
    <>
      <div className="newSessionResitReasonModal">
        <Modal
          size="lg"
          show={showModal}
          onHide={innerToggle}
          aria-labelledby="newResitReason"
          className="invmodal detail"
        >
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title id="new-title">
                {objFull(updateServiceItem) ? (
                  <>
                    {employeeDetail ? (
                      <>Task</>
                    ) : (
                      <>
                        Update Task{' '}
                        {organizationName && <>For {organizationName} </>}
                      </>
                    )}
                  </>
                ) : (
                  <>Create Task</>
                )}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Name
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    type="text"
                    name="description"
                    disabled={!canMutate}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.description}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                {!newTaskGroup && (
                  <>
                    <Form.Label column sm="2">
                      Task Collection
                    </Form.Label>
                    <Col sm="6">
                      <select
                        className="form-control-sm form-select"
                        name="group"
                        disabled={!canMutate}
                        onChange={formik.handleChange}
                        value={formik.values.group}
                      >
                        <option value="none">- - -</option>
                        {groupsData.defaultServiceItemGroups.edges.map(
                          (group) => {
                            let descr = group.node.name
                            return (
                              <option key={group.node.id} value={group.node.id}>
                                {descr}
                              </option>
                            )
                          }
                        )}
                      </select>
                      {formik.errors.group && (
                        <span style={{ color: 'red' }}>
                          {formik.errors.group}
                        </span>
                      )}
                    </Col>
                    {canMutate && (
                      <>
                        <Form.Label column sm="2">
                          New Task Collection
                        </Form.Label>
                        <Col>
                          <Form.Check
                            type="checkbox"
                            checked={newTaskGroup}
                            onChange={() => setNewTaskGroup(!newTaskGroup)}
                          />
                        </Col>
                      </>
                    )}
                  </>
                )}
              </Form.Group>
              {canMutate && newTaskGroup && (
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Task Collection Name
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      type="text"
                      name="newGroup"
                      value={formik.values.newGroup}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.newGroup}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.newGroup}
                    </Form.Control.Feedback>
                  </Col>
                  <Form.Label column sm="2">
                    New Task Collection
                  </Form.Label>
                  <Col>
                    <Form.Check
                      type="checkbox"
                      checked={newTaskGroup}
                      onChange={() => setNewTaskGroup(!newTaskGroup)}
                    />
                  </Col>
                </Form.Group>
              )}
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Notes
                </Form.Label>
                <Col>
                  <Form.Control
                    as="textarea"
                    name="notes"
                    style={{ height: 100 }}
                    value={formik.values.notes}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.notes}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.notes}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Status
                </Form.Label>
                <Col sm="6">
                  <select
                    className="form-control-sm form-select"
                    name="status"
                    disabled={!canMutate}
                    onChange={formik.handleChange}
                    value={formik.values.status}
                  >
                    {statusChoices.map((status) => (
                      <option key={status} value={status}>
                        {status}
                      </option>
                    ))}
                  </select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.status}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row}></Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Event Date
                </Form.Label>
                <Col>
                  <DatePicker
                    name="eventDate"
                    className="form-control form-control-sm"
                    showPopperArrow={false}
                    popperPlacement="auto"
                    disabled={!canMutate}
                    selected={formik.values.eventDate}
                    onChange={(date) => formik.setFieldValue('eventDate', date)}
                    popperModifiers={{
                      flip: {
                        behavior: ['bottom'],
                      },
                      preventOverflow: {
                        enabled: false,
                      },
                      hide: {
                        enabled: false,
                      },
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.description}
                  </Form.Control.Feedback>
                </Col>
                <Form.Label column sm="2">
                  Due Date
                </Form.Label>
                <Col>
                  <DatePicker
                    name="dueDate"
                    className="form-control form-control-sm"
                    showPopperArrow={false}
                    popperPlacement="auto"
                    disabled={!canMutate}
                    selected={formik.values.dueDate}
                    onChange={(date) => formik.setFieldValue('dueDate', date)}
                    popperModifiers={{
                      flip: {
                        behavior: ['bottom'],
                      },
                      preventOverflow: {
                        enabled: false,
                      },
                      hide: {
                        enabled: false,
                      },
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.description}
                  </Form.Control.Feedback>
                </Col>
                <Form.Label column sm="2">
                  Finished Date
                </Form.Label>
                <Col>
                  <DatePicker
                    name="finishedDate"
                    className="form-control form-control-sm"
                    showPopperArrow={false}
                    popperPlacement="auto"
                    disabled={!canMutate}
                    selected={formik.values.finishedDate}
                    onChange={(date) =>
                      formik.setFieldValue('finishedDate', date)
                    }
                    popperModifiers={{
                      flip: {
                        behavior: ['bottom'],
                      },
                      preventOverflow: {
                        enabled: false,
                      },
                      hide: {
                        enabled: false,
                      },
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.description}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Contacts
                </Form.Label>
                <Col>
                  {contactsArr.length > 0 ? (
                    <>
                      <Form.Control
                        name="contacts"
                        as="select"
                        className="form-control-sm"
                        disabled={!canMutate}
                        value={contactsOptionValue}
                        onChange={(e) => handleContactsClick(e)}
                      >
                        <option value="dash">Select contacts</option>
                        {contactsArr
                          .filter((contact) => {
                            let show = true
                            formik.values.contacts.forEach((selected) => {
                              if (selected?.node.id === contact?.node.id) {
                                show = false
                              }
                            })
                            return show
                          })
                          .map((data) => (
                            <option key={data.node.id} value={data.node.id}>
                              {data.node.fullName}, {data.node.email}
                            </option>
                          ))}
                      </Form.Control>
                      <div>
                        {formik.values.contacts.map((contact, index) => (
                          <div
                            key={index}
                            className="p-3 border border-dark rounded mt-2"
                          >
                            <p className="mb-0">
                              {contact.node.fullName}, {contact.node.email}
                            </p>
                            {canMutate && (
                              <div
                                className="d-flex align-items-center hover btn-link"
                                onClick={() => {
                                  handleRemoveContacts(contact)
                                }}
                              >
                                <Trash size={14} className="mr-2" />
                                <small>Remove</small>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <>
                      {canMutate && (
                        <span className="ml-1">
                          No contacts assoicated to organization
                        </span>
                      )}
                    </>
                  )}
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Employees
                </Form.Label>
                <Col sm="6">
                  {canMutate && (
                    <Form.Control
                      placeholder="Search Employee"
                      className="form-control-sm"
                      value={employeeValue}
                      disabled={!canMutate}
                      onBlur={handleEmployeeBlur}
                      onChange={(e) => handleEmployeeChange(e)}
                    />
                  )}

                  {employeesData && displayEmployeeResults ? (
                    <InfiniteScroll
                      height={100}
                      dataLength={employeesData?.employees?.edges.length}
                      hasMore={employeesData?.employees?.pageInfo.hasNextPage}
                      loader={<Loading />}
                    >
                      <Table size="sm" hover>
                        <tbody>
                          {employeesData?.employees?.edges.map((employee) => {
                            let show = true
                            formik.values.employees.forEach((selected) => {
                              if (selected?.node.id === employee.node.id) {
                                show = false
                              }
                            })
                            if (show) {
                              return (
                                <tr
                                  onMouseDown={() =>
                                    handleEmployeeClick(employee)
                                  }
                                  key={employee.node.id}
                                  className="hover text-decoration-none"
                                >
                                  <td>
                                    <small>
                                      {employee.node.gaiaUser.fullName},{' '}
                                      {employee.node.gaiaUser.email}
                                    </small>
                                  </td>
                                </tr>
                              )
                            }
                          })}
                        </tbody>
                      </Table>
                    </InfiniteScroll>
                  ) : null}
                  <div>
                    {formik.values.employees.map((employee, index) => (
                      <div
                        key={index}
                        className="p-3 border border-dark rounded mt-2"
                      >
                        <Row>
                          <Col>
                            {employee.node.gaiaUser.fullName},{' '}
                            {employee.node.gaiaUser.email}
                          </Col>
                          {canMutate && (
                            <Col>
                              <spam
                                className="d-flex align-items-center hover btn-link"
                                onClick={() => {
                                  handleRemoveEmployee(employee)
                                }}
                              >
                                <Trash size={14} className="mr-2" />
                                <small>Remove</small>
                              </spam>
                            </Col>
                          )}
                        </Row>
                        <p className="mb-0"></p>
                      </div>
                    ))}
                  </div>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Completed By
                </Form.Label>
                <Col sm="6">
                  {canMutate && (
                    <Form.Control
                      placeholder="Search Employee"
                      value={formik.values.completedEmployeeName}
                      onBlur={handleCompletedEmployeeBlur}
                      onChange={(e) => handleCompletedEmployeeChange(e)}
                      readOnly={Boolean(formik.values.completedEmployeeId)}
                      className={
                        formik.values.completedEmployeeId
                          ? ' border border-success form-control form-control-sm'
                          : 'form-control form-control-sm'
                      }
                    />
                  )}

                  {completedEmployeesData && displayCompletedEmployeeResults ? (
                    <InfiniteScroll
                      height={100}
                      dataLength={
                        completedEmployeesData?.employees?.edges.length
                      }
                      hasMore={
                        completedEmployeesData?.employees?.pageInfo.hasNextPage
                      }
                      loader={<Loading />}
                    >
                      <Table size="sm" hover>
                        <tbody>
                          {completedEmployeesData?.employees?.edges.map(
                            (employee) => {
                              let show = true
                              formik.values.employees.forEach((selected) => {
                                if (selected?.node.id === employee.node.id) {
                                  show = false
                                }
                              })
                              if (show) {
                                return (
                                  <tr
                                    onMouseDown={() =>
                                      handleCompletedEmployeeClick(employee)
                                    }
                                    key={employee.node.id}
                                    className="hover text-decoration-none"
                                  >
                                    <td>
                                      <small>
                                        {employee.node.gaiaUser.fullName},{' '}
                                        {employee.node.gaiaUser.email}
                                      </small>
                                    </td>
                                  </tr>
                                )
                              }
                            }
                          )}
                        </tbody>
                      </Table>
                    </InfiniteScroll>
                  ) : null}
                </Col>
                {formik.values.completedEmployeeId && (
                  <Col>
                    <span
                      type="button"
                      className="btn-link"
                      onClick={handleCompletedEmployeeXClick}
                    >
                      <Trash />
                    </span>
                  </Col>
                )}
              </Form.Group>
              {canMutate && (
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Files
                  </Form.Label>
                  <Col>
                    <Form.Group>
                      <Form.Check
                        name="sharedCanSeeFiles"
                        type="checkbox"
                        label="Contacts Can See Files"
                        checked={formik.values.sharedCanSeeFiles}
                        onChange={(e) => {
                          formik?.setFieldValue(
                            `sharedCanSeeFiles`,
                            e.target.checked
                          )
                        }}
                      />
                    </Form.Group>
                  </Col>
                  {formik.values.sharedCanSeeFiles && (
                    <>
                      <Col>
                        <Form.Group>
                          <Form.Check
                            name="sharedCanCreateFiles"
                            type="checkbox"
                            label="Contacts Can Create Files"
                            checked={formik.values.sharedCanCreateFiles}
                            onChange={(e) => {
                              formik?.setFieldValue(
                                `sharedCanCreateFiles`,
                                e.target.checked
                              )
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Check
                            name="sharedCanCreateFolders"
                            type="checkbox"
                            label="Contacts Can Create Folders"
                            checked={formik.values.sharedCanCreateFolders}
                            onChange={(e) => {
                              formik?.setFieldValue(
                                `sharedCanCreateFolders`,
                                e.target.checked
                              )
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </>
                  )}
                </Form.Group>
              )}

              {updateServiceItem && (
                <>
                  <Row className="mb-3 mt-3">
                    <Col className="d-flex align-items-center">
                      <button
                        type="button"
                        onClick={() => setDisplayFiles(!displayFiles)}
                        className="px-0 btn-link mr-1"
                      >
                        <>
                          {displayFiles ? (
                            <>
                              <CaretDown size={18} />
                            </>
                          ) : (
                            <>
                              <CaretRight size={18} />
                            </>
                          )}
                        </>
                      </button>
                      <p className="mb-0">Files</p>
                    </Col>
                  </Row>
                  {displayFiles && (
                    <FilesTable
                      serviceItemId={updateServiceItem.id}
                      serviceItemDescription={updateServiceItem.description}
                      sharedCanCreateFiles={
                        updateServiceItem.sharedCanCreateFiles
                      }
                      sharedCanCreateFolders={
                        updateServiceItem.sharedCanCreateFolders
                      }
                      folderId={
                        contactsArr.length
                          ? updateServiceItem.sharedFolder?.id
                          : updateServiceItem.folder?.id
                      }
                      tableHeight={200}
                      fetchPolicy="no-cache"
                    />
                  )}
                </>
              )}
              <Row>
                {canMutate && (
                  <Col sm="3">
                    <Button
                      type="submit"
                      block
                      size="sm"
                      variant="outline-primary"
                      className="mt-2"
                    >
                      <PlusCircle className="mr-2" />
                      Save
                    </Button>
                  </Col>
                )}

                {objFull(updateServiceItem) && canMutate && (
                  <>
                    <Col sm="3">
                      <Button
                        block
                        variant="outline-danger"
                        onClick={deleteMutation}
                      >
                        <Trash className="mr-2" />
                        Delete
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </Modal.Body>
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default ServiceItemModal
