import { gql, useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { useAWSS3 } from './aws'

const DELETE_FILE_MUTATION = gql`
  mutation DeleteFile($deleteFileInput: DeleteFileInput!) {
    deleteFile(input: $deleteFileInput) {
      deleted
    }
  }
`

export const useDownloadAndDeleteFile = () => {
  const awsS3 = useAWSS3()
  const [deleteFile] = useMutation(DELETE_FILE_MUTATION, {
    errorPolicy: 'all',
  })

  const downloadFile = useCallback(
    async (Key, fileName, postDownload = null) => {
      await awsS3.client.getObject(
        { Bucket: awsS3.bucket, Key },
        (error, data) => {
          if (!error) {
            let blob = new Blob([data.Body], { type: data.ContentType })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = fileName
            link.click()
            if (postDownload) {
              postDownload()
            }
          }
        }
      )
    },
    [awsS3]
  )

  const downloadAndDeleteFile = useCallback(
    async (Key, fileName, fileId, postDownload = null) => {
      await downloadFile(Key, fileName, () => {
        if (postDownload) {
          postDownload()
        }
        deleteFile({
          variables: {
            deleteFileInput: {
              fileIds: fileId,
            },
          },
        })
      })
    },
    [downloadFile, deleteFile]
  )

  return { downloadAndDeleteFile, downloadFile }
}
