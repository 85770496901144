import React, { useState } from 'react'
import { Row, Col, Button, Badge } from 'react-bootstrap'
import { useQuery, gql } from '@apollo/client'
import { Plug, CaretRight, CaretDown } from 'react-bootstrap-icons'
import EditEmailSetting from './EditEmailSetting'

const EmailSetting = () => {
  const { data } = useQuery(
    gql`
      query SMTPConnector {
        smtpConnectors {
          edges {
            node {
              customHost
              customPort
              customUser
              customTls
              customSsl
              customPassword
              customValid
              customSendgridSender
              rateLimit
              rateLimitSecondDelta
              customGmail
              customGmailAuth
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )
  const [showEditModal, setShowEditModal] = useState(false)
  const [displayEmail, setDisplayEmail] = useState(false)

  const toggleNewModal = () => {
    setShowEditModal((prevState) => !prevState)
  }
  if (!data) return <></>
  const emailSetting = data.smtpConnectors.edges[0].node
  return (
    <>
      <div className="studioInfo">
        <Row className="mb-1 mt-4">
          <Col className="d-flex align-items-center">
            <button
              type="button"
              onClick={() => setDisplayEmail(!displayEmail)}
              className="px-0 btn-link mr-2"
            >
              <>
                {displayEmail ? (
                  <>
                    <CaretDown size={20} />
                  </>
                ) : (
                  <>
                    <CaretRight size={20} />
                  </>
                )}
              </>
            </button>
            <h4 className="mb-0">
              <img
                className="img-fluid mr-2"
                src={window.location.origin + '/email.png'}
                style={{ height: '25px' }}
                alt="Organization logo"
              />
              Email
            </h4>
          </Col>
        </Row>
        {displayEmail && (
          <>
            <Row className="mt-3">
              <Col>
                {!emailSetting.customHost &&
                  !emailSetting.customPassword &&
                  !emailSetting.customPort &&
                  !emailSetting.customGmail &&
                  !emailSetting.customUser && (
                    <Badge
                      className="ml-2"
                      style={{ fontSize: '12px' }}
                      variant="danger"
                    >
                      Disabled
                    </Badge>
                  )}
                {(emailSetting.customHost ||
                  emailSetting.customPassword ||
                  emailSetting.customPort ||
                  emailSetting.customTls ||
                  emailSetting.customSsl ||
                  emailSetting.customUser ||
                  emailSetting.customGmail) &&
                  emailSetting.customValid === false && (
                    <Badge
                      className="ml-2"
                      style={{ fontSize: '12px' }}
                      variant="danger"
                    >
                      Invalid Credentials
                    </Badge>
                  )}
                {emailSetting.customValid === true && (
                  <Badge
                    className="ml-2"
                    style={{ fontSize: '12px' }}
                    variant="success"
                  >
                    Enabled
                  </Badge>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div>
                  <Button variant="link" onClick={toggleNewModal}>
                    <Plug className="mr-2" />
                    Configure Email
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
        <EditEmailSetting
          emailSetting={emailSetting}
          showModal={showEditModal}
          toggleModal={toggleNewModal}
        />
      </div>
    </>
  )
}

export default EmailSetting
