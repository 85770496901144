import React, { useState } from 'react'
import { useQuery, gql, useMutation } from '@apollo/client'
import { DateTime } from 'luxon'
import { Row, Col, Button } from 'react-bootstrap'
import JobDetailModal from '../schedule/JobDetailModal'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import AdminSessionDetailModal from '../sessions/AdminSessionDetailModal'
import SessionDetailModal from '../subject_sessions/SessionDetailModal'
import RefundPaymentModal from './RefundPaymentModal'
import {
  CaretDown,
  CaretRight,
  CloudArrowDown,
  ArrowCounterclockwise,
} from 'react-bootstrap-icons'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../libs/apollo'
import Loading from '../common/Loading'
import { useDownloadAndDeleteFile } from '../../libs/downloadAndDeleteFile'
import InvoiceModal from './InvoiceModal'

const SubjectPaymentsTable = (props) => {
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = [
    'Scheduling Manager',
    'Administrator',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)
  const { subjectId, adminViewing } = props
  const { downloadAndDeleteFile } = useDownloadAndDeleteFile()
  const [display, setDisplay] = useState(false)
  const [showAdminSessionDetailModal, setShowAdminSessionDetailModal] =
    useState()
  const [showSessionDetailModal, setShowSessionDetailModal] = useState()
  const [showSessionRefundModal, setShowSessionRefundModal] = useState()
  const [paymentId, setPayementId] = useState()
  const [downloadingReceipt, setDownloadingReceipt] = useState()
  const [sessionDetailId, setSessionDetailId] = useState()
  const [viewInvoice, setViewInvoice] = useState()

  const [downloadPayments] = useMutation(
    gql`
      mutation DownloadStripePaymentIntents(
        $input: DownloadStripePaymentIntentsInput!
      ) {
        downloadStripePaymentIntents(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        downloadAndDeleteFile(
          data.downloadStripePaymentIntents.file.fileName,
          data.downloadStripePaymentIntents.file.displayName,
          data.downloadStripePaymentIntents.file.id,
          () => {
            setDownloadingReceipt(false)
          }
        )
      },
      errorPolicy: 'all',
    }
  )

  let hasMorePayments = true
  let paymentsCursor
  const tableColumns = [
    {
      Header: 'Description',
      id: 'description',
      accessor: 'node.description',
    },
    {
      Header: 'Session',
      id: 'session',
      accessor: (row) => {
        if (row.node.session) {
          const sessionDate = DateTime.fromISO(
            row.node.session?.startDateTime
          ).toFormat('LLL dd yyyy')
          const sessionStartTime = DateTime.fromISO(
            row.node.session?.startDateTime
          ).toFormat('h:mma')
          const sessionEndTime = DateTime.fromISO(
            row.node.session?.endDateTime
          ).toFormat('h:mma')
          return `${sessionDate} ${sessionStartTime}-${sessionEndTime}`
        }
      },
    },
    {
      Header: 'Paid',
      id: 'paid',
      accessor: (row) => {
        return `$${row.node.amount / 100}`
      },
    },
    {
      Header: 'Status',
      id: 'status',
      accessor: (row) => {
        const on = DateTime.fromISO(row.node.updated).toFormat(
          'h:mma LLL dd yyyy'
        )
        let status = `Paid on ${on}`
        if (row.node.refunded) {
          status = `Refunded on ${on}`
        }
        return status
      },
    },
    {
      Header: 'Receipt',
      id: 'receipt',
      accessor: (row) => {
        return (
          <Button
            className="btn-link"
            disabled={downloadingReceipt}
            onClick={() => {
              setDownloadingReceipt(true)
              downloadPayments({
                variables: {
                  input: {
                    fileType: 'receipt',
                    stripePaymentIntentId: row.node.id,
                  },
                },
              })
            }}
          >
            <span style={{ fontSize: '20px' }}>
              <CloudArrowDown />
            </span>
          </Button>
        )
      },
    },
  ]
  if (adminViewing && canMutate) {
    tableColumns.splice(2, 0, {
      Header: 'Job',
      id: 'job',
      accessor: 'node.session.job.name',
    })
    tableColumns.push({
      Header: 'Refund',
      accessor: (row) => {
        if (!row.node.refunded) {
          return (
            <Button
              size="sm"
              variant="link"
              onClick={() => handleRefundPayment(row)}
            >
              Refund
            </Button>
          )
        } else if (row.node.refundedBy?.fullName) {
          return `${row.node.refundedBy?.fullName}`
        }
      },
    })
  }
  const {
    error: queryError,
    data: queryData,
    fetchMore: queryFetchMore,
  } = useQuery(
    gql`
      query StripePaymentIntentsQuery($cursor: String, $subjectId: String) {
        stripePaymentIntents(
          first: 20
          after: $cursor
          subject: $subjectId
          orderBy: "-created"
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              stripeId
              refunded
              refundedBySystem
              refundedBy {
                fullName
              }
              stripeInvoice {
                stripeInvoiceId
                id
              }
              amount
              description
              session {
                id
                archived
                startDateTime
                endDateTime
                subject {
                  gaiaUser {
                    fullName
                  }
                }
                job {
                  id
                  name
                }
              }
              updated
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      variables: {
        subjectId,
      },
      errorPolicy: 'all',
    }
  )
  const fetchMoreQueryData = () => {
    queryFetchMore({
      variables: {
        subjectId,
        cursor: paymentsCursor,
      },
    })
  }

  const toggleAdminSessionDetailModal = () => {
    if (showAdminSessionDetailModal) {
      setSessionDetailId(null)
    }
    setShowAdminSessionDetailModal(!showAdminSessionDetailModal)
  }

  const toggleSubjectSessionDetailModal = () => {
    if (showSessionDetailModal) {
      setSessionDetailId(null)
    }
    setShowSessionDetailModal(!showSessionDetailModal)
  }

  const toggleSessionRefundModal = () => {
    if (showSessionDetailModal) {
      setSessionDetailId(null)
    }
    setShowSessionRefundModal(!showSessionRefundModal)
  }

  const handleRefundPayment = (row) => {
    setPayementId(row.node.id)
    toggleSessionRefundModal()
  }

  const onRowClick = (cell) => {
    if (
      cell.row.original.node.session &&
      !cell.row.original.node.session.archived
    ) {
      setSessionDetailId(cell.row.original.node.session.id)
      if (adminViewing) {
        toggleAdminSessionDetailModal()
      } else {
        toggleSubjectSessionDetailModal()
      }
    } else if (cell.row.original.node.stripeInvoice) {
      setViewInvoice(cell.row.original.node.stripeInvoice.id)
    }
  }
  if (queryError) return <>Error loading payments</>
  if (queryData?.stripePaymentIntents) {
    hasMorePayments = queryData.stripePaymentIntents.pageInfo.hasNextPage
    if (queryData.stripePaymentIntents.pageInfo.endCursor) {
      paymentsCursor = queryData.stripePaymentIntents.pageInfo.endCursor
    }
  }
  return (
    <>
      <div className="subjectPayments">
        {!adminViewing && (
          <Row className="mt-3">
            <Col className="d-flex align-items-center">
              <button
                type="button"
                onClick={() => setDisplay(!display)}
                className="px-0 btn-link mr-1"
                style={{ marginTop: '-10px' }}
              >
                <>
                  {display ? (
                    <>
                      <CaretDown size={20} />
                    </>
                  ) : (
                    <>
                      <CaretRight size={20} />
                    </>
                  )}
                </>
              </button>
              <h5>Payments</h5>
            </Col>
          </Row>
        )}
        {!queryData && (display || adminViewing) && <Loading />}
        {queryData && (display || adminViewing) && (
          <>
            <Row className="mt-3">
              <Col xs={12} md={12}>
                {queryData?.stripePaymentIntents && (
                  <SortableInfiniteTable
                    tableData={queryData.stripePaymentIntents.edges}
                    tableColumns={tableColumns}
                    loadingMessage="Loading Payments..."
                    fetchMoreTableData={fetchMoreQueryData}
                    onTdClicks={{
                      description: onRowClick,
                      session: onRowClick,
                      invoice: onRowClick,
                      paid: onRowClick,
                      status: onRowClick,
                      job: onRowClick,
                    }}
                    hasMoreTableData={hasMorePayments}
                    rowPointer
                    height={300}
                  />
                )}
              </Col>
            </Row>
            <AdminSessionDetailModal
              sessionId={sessionDetailId}
              showModal={showAdminSessionDetailModal}
              toggleModal={toggleAdminSessionDetailModal}
            />
            <SessionDetailModal
              sessionId={sessionDetailId}
              showModal={showSessionDetailModal}
              toggleModal={toggleSubjectSessionDetailModal}
            />
            <RefundPaymentModal
              stripePaymentId={paymentId}
              showModal={showSessionRefundModal}
              toggleModal={toggleSessionRefundModal}
            />
            <InvoiceModal
              showModal={viewInvoice}
              toggleModal={setViewInvoice}
              stripeInvoiceId={viewInvoice}
              subjectViewing={!adminViewing}
            />
          </>
        )}
      </div>
    </>
  )
}

export default SubjectPaymentsTable
