import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { gql, useMutation } from '@apollo/client'
import toast from 'react-hot-toast'
import { useAWSS3 } from '../../libs/aws'
import { CloudArrowDown, ArrowLeftCircle } from 'react-bootstrap-icons'
import Loading from '../common/Loading'
import { useDownloadAndDeleteFile } from '../../libs/downloadAndDeleteFile'

const JobReport = (props) => {
  const { jobId, backToDetail } = props
  const awsS3 = useAWSS3()
  const { downloadAndDeleteFile } = useDownloadAndDeleteFile()
  const [loading, setLoading] = useState()

  const [downloadJob] = useMutation(
    gql`
      mutation DownloadJob($input: DownloadJobInput!) {
        downloadJob(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        if (data?.downloadJob?.file?.fileName) {
          downloadAndDeleteFile(
            data.downloadJob.file.fileName,
            data.downloadJob.file.displayName,
            data.downloadJob.file.id,
            () => {
              toast.success(`Job Downloaded`)
              setLoading(false)
            }
          )
        }
      },
      errorPolicy: 'all',
    }
  )

  const [downloadSessionsTickets] = useMutation(
    gql`
      mutation DownloadSessions($input: DownloadSessionsInput!) {
        downloadSessions(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        if (data?.downloadSessions?.file?.fileName) {
          downloadAndDeleteFile(
            data.downloadSessions.file.fileName,
            data.downloadSessions.file.displayName,
            data.downloadSessions.file.id,
            () => {
              toast.success(`Job Downloaded`)
              setLoading(false)
            }
          )
        }
      },
      errorPolicy: 'all',
    }
  )

  const downloadExcel = () => {
    setLoading(true)
    downloadJob({
      variables: {
        input: {
          jobId: jobId,
          fileType: 'xlsx',
        },
      },
    })
  }

  const downloadPdf = () => {
    setLoading(true)
    downloadJob({
      variables: {
        input: {
          jobId: jobId,
          fileType: 'pdf',
        },
      },
    })
  }

  const downloadICS = () => {
    setLoading(true)
    downloadJob({
      variables: {
        input: {
          jobId: jobId,
          fileType: 'ics',
        },
      },
    })
  }

  const downloadSessions = () => {
    setLoading(true)
    downloadSessionsTickets({
      variables: {
        input: {
          jobId: jobId,
          sessionIds: null,
          fileType: 'pdf',
          file: 'sessions_sitting',
        },
      },
    })
  }

  const downloadEquipmentBags = () => {
    setLoading(true)
    downloadJob({
      variables: {
        input: {
          jobId: jobId,
          fileType: 'equipmentBagsPdf',
        },
      },
    })
  }

  const downloadNonLongTermEquipmentBags = () => {
    setLoading(true)
    downloadJob({
      variables: {
        input: {
          jobId: jobId,
          fileType: 'nonLongTermEquipmentBagsPdf',
        },
      },
    })
  }

  if (!awsS3?.client) return <></>
  return (
    <div className="mb-3">
      <Button className="mt-3" variant="link" onClick={backToDetail}>
        <ArrowLeftCircle className="mr-2" />
        Back
      </Button>
      <Button
        onClick={downloadExcel}
        variant="link"
        className="mt-3"
        size="sm"
        disabled={loading}
      >
        <CloudArrowDown className="mr-2" />
        Log Sheet Excel
      </Button>
      <Button
        onClick={downloadPdf}
        variant="link"
        className="mt-3"
        size="sm"
        disabled={loading}
      >
        <CloudArrowDown className="mr-2" />
        Log Sheet PDF
      </Button>
      <Button
        onClick={downloadSessions}
        variant="link"
        className="mt-3"
        size="sm"
        disabled={loading}
      >
        <CloudArrowDown className="mr-2" />
        Tickets
      </Button>
      <Button
        onClick={downloadEquipmentBags}
        variant="link"
        className="mt-3"
        size="sm"
        disabled={loading}
      >
        <CloudArrowDown className="mr-2" />
        All Equipment Bags
      </Button>
      <Button
        onClick={downloadNonLongTermEquipmentBags}
        variant="link"
        className="mt-3"
        size="sm"
        disabled={loading}
      >
        <CloudArrowDown className="mr-2" />
        Non Long Term Equipment Bags
      </Button>
      <Button
        onClick={downloadICS}
        variant="link"
        className="mt-3"
        size="sm"
        disabled={loading}
      >
        <CloudArrowDown className="mr-2" />
        ICS
      </Button>
      {loading && (
        <div className="mt-3">
          <Loading className="mt-2" message="Downloading..." />
        </div>
      )}
    </div>
  )
}

export default JobReport
