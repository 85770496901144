import React, { useEffect, useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { useQuery, gql } from '@apollo/client'
import EditDefaultServiceItemGroupModal from './EditDefaultServiceItemGroupModal'
import NewDefaultServiceItemGroupModal from './NewDefaultServiceItemGroupModal'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import { PlusCircle, Dot } from 'react-bootstrap-icons'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../../libs/apollo'
import Loading from '../../common/Loading'

const DefaultServiceItemGroups = () => {
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = ['Administrator', 'Scheduling Manager'].includes(
    loggedInUser?.permissions?.group
  )
  const [editServiceItemGroup, setEditServiceItemGroup] = useState(null)
  const [showNewModal, setShowNewModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const { loading, error, data } = useQuery(
    gql`
      query DefaultServiceItemGroupsQuery {
        defaultServiceItemGroups(orderBy: "name", first: 250) {
          edges {
            node {
              id
              name
              defaultServiceItems(orderBy: "description") {
                edges {
                  node {
                    id
                    description
                  }
                }
              }
            }
          }
        }
      }
    `,
    { fetchPolicy: 'network-only' }
  )
  const toggleNewModal = () => {
    setShowNewModal((prevState) => !prevState)
  }
  const toggleEditModal = () => {
    if (canMutate) {
      setShowEditModal((prevState) => !prevState)
    }
  }

  useEffect(() => {
    // this useffect trigger on refetch and update the editServiceItem state which we are doing on row click
    let updatedData = { ...data }
    let updatedEditServiceItemGroup =
      updatedData?.defaultServiceItemGroups?.edges.filter((node) => {
        return node.node.id === editServiceItemGroup?.id
      })
    if (updatedEditServiceItemGroup && updatedEditServiceItemGroup.length > 0) {
      setEditServiceItemGroup(updatedEditServiceItemGroup[0].node)
    }
  }, [data])

  if (loading)
    return (
      <Row className="mt-4">
        <Col md={3}>
          <Loading message="Loading Task Templates..." />
        </Col>
      </Row>
    )
  if (error) return <p>Error loading task types</p>
  return (
    <>
      <div className="defaultServiceItems">
        {canMutate && (
          <Row>
            <Col className="mt-2">
              <div>
                <Button variant="link" onClick={toggleNewModal}>
                  <PlusCircle className="mr-2" /> Create Task Collection
                </Button>
              </div>
            </Col>
          </Row>
        )}
        <Row className="mt-3">
          <Col md={4}>
            {data.defaultServiceItemGroups.edges && (
              <SortableInfiniteTable
                tableData={data.defaultServiceItemGroups.edges}
                loadingMessage="Loading Task Collection..."
                tableColumns={[
                  {
                    Header: 'Name',
                    accessor: 'node.name',
                  },
                  {
                    Header: 'Tasks',
                    accessor: (row) => {
                      return (
                        <div
                          style={{
                            maxHeight: '100px',
                            overflowY: 'scroll',
                            overflowX: 'hidden',
                            fontSize: '12px',
                          }}
                          className="mt-2"
                        >
                          {row.node.defaultServiceItems.edges.map((edge, i) => (
                            <>
                              {i > 0 && <br />}
                              <Dot />
                              {edge.node.description}
                            </>
                          ))}
                        </div>
                      )
                    },
                  },
                ]}
                hasMoreTableData={false}
                tableHeight={800}
                onRowClick={(row) => {
                  setEditServiceItemGroup(row.original.node)
                  toggleEditModal()
                }}
                rowPointer={canMutate}
                searchWidth={12}
              />
            )}
          </Col>
        </Row>
        <NewDefaultServiceItemGroupModal
          showModal={showNewModal}
          toggleModal={toggleNewModal}
        />
        <EditDefaultServiceItemGroupModal
          serviceItemGroup={editServiceItemGroup}
          showModal={showEditModal}
          toggleModal={toggleEditModal}
        />
      </div>
    </>
  )
}

export default DefaultServiceItemGroups
