import React, { useState } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { useLazyQuery, useQuery, gql, useMutation } from '@apollo/client'
import { CaretDown, CaretRight, Cash, Plug, Trash } from 'react-bootstrap-icons'
import { Badge } from 'react-bootstrap'
import toast from 'react-hot-toast'

const Stripe = () => {
  const [display, setDisplay] = useState(false)
  const [connectedAccountId, setConnectedAccountId] = useState()

  const [updateStripeConnector] = useMutation(
    gql`
      mutation UpdateStripeConnector(
        $updateStripeConnectorInput: UpdateStripeConnectorInput!
      ) {
        updateStripeConnector(input: $updateStripeConnectorInput) {
          stripeConnector {
            id
            connectedAccountId
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        if (connectedAccountId) {
          if (data.updateStripeConnector.stripeConnector.connectedAccountId) {
            toast.success('Stripe Enabled')
            setConnectedAccountId()
          } else {
            toast.error('Invalid Account ID')
          }
        } else {
          toast.success('Stripe Disabled')
        }
      },
      errorPolicy: 'all',
      refetchQueries: ['StripeConnectorQuery'],
    }
  )
  const [stripeConnectedAccountUrlQuery] = useLazyQuery(
    gql`
      query StripeConnectedAccountQuery {
        stripeConnectedAccountUrl {
          url
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      onCompleted: (data) => {
        window.open(data.stripeConnectedAccountUrl.url, '_blank')
      },
    }
  )
  const [stripeExpressDashboardUrlQuery] = useLazyQuery(
    gql`
      query StripeExpressDashboardQuery {
        stripeExpressDashboardUrl {
          url
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      onCompleted: (data) => {
        window.open(data.stripeExpressDashboardUrl.url, '_blank')
      },
    }
  )
  const { error: stripeConnectorQueryError, data: stripeConnectorQueryData } =
    useQuery(
      gql`
        query StripeConnectorQuery {
          stripeConnectors {
            edges {
              node {
                id
                connectedAccountId
              }
            }
          }
        }
      `,
      {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      }
    )
  const handleConnectStripeClick = () => {
    toast.success('Opening Stripe')
    stripeConnectedAccountUrlQuery()
  }
  const handleStripeDashboardClick = () => {
    toast.success('Opening to Stripe')
    stripeExpressDashboardUrlQuery()
  }
  if (!stripeConnectorQueryData) return <></>
  if (stripeConnectorQueryError) return <>Error loading stripe</>
  const stripeConnector =
    stripeConnectorQueryData.stripeConnectors.edges[0].node
  const stripeEnabled = stripeConnector.connectedAccountId ? true : false
  return (
    <>
      <div className="payments mb-4 mt-4">
        <Row>
          <Col className="d-flex align-items-center">
            <button
              type="button"
              onClick={() => setDisplay(!display)}
              className="px-0 btn-link mr-2"
            >
              <>
                {display ? (
                  <>
                    <CaretDown size={20} />
                  </>
                ) : (
                  <>
                    <CaretRight size={20} />
                  </>
                )}
              </>
            </button>
            <h4 className="mb-0">
              <img
                className="img-fluid mr-2"
                src={window.location.origin + '/stripe-logo.png'}
                style={{ height: '30px' }}
                alt="Organization logo"
              />
            </h4>
          </Col>
        </Row>
        {display && (
          <>
            <Row className="mt-2">
              <Col>
                {stripeEnabled ? (
                  <Badge
                    className="ml-2"
                    style={{ fontSize: '12px' }}
                    variant="success"
                  >
                    Enabled
                  </Badge>
                ) : (
                  <Badge
                    className="ml-2"
                    style={{ fontSize: '12px' }}
                    variant="danger"
                  >
                    Disabled
                  </Badge>
                )}
              </Col>
            </Row>
            <Row>
              {stripeEnabled ? (
                <>
                  <Col>
                    <div>
                      <Button
                        variant="link"
                        onClick={handleStripeDashboardClick}
                      >
                        <Cash className="mr-2" /> Stripe Dashboard
                      </Button>
                    </div>
                  </Col>
                </>
              ) : (
                <>
                  <Col>
                    <Button variant="link" onClick={handleConnectStripeClick}>
                      <Cash className="mr-2" />
                      Connect Stripe
                    </Button>
                  </Col>
                </>
              )}
            </Row>
            {stripeEnabled && (
              <Row>
                <Col>
                  <Button
                    variant="link"
                    onClick={() => {
                      updateStripeConnector({
                        variables: {
                          updateStripeConnectorInput: {
                            stripeConnectorInput: {
                              id: stripeConnector.id,
                              connectedAccountId: null,
                            },
                          },
                        },
                      })
                    }}
                  >
                    <Trash className="mr-2" /> Disable Stripe
                  </Button>
                </Col>
              </Row>
            )}
            {!stripeEnabled && (
              <Row>
                <Col md={3}>
                  <Form.Control
                    type="text"
                    placeholder="Stripe Connected Account ID"
                    value={connectedAccountId}
                    onChange={(e) => setConnectedAccountId(e.target.value)}
                  />
                </Col>
                {connectedAccountId && (
                  <Col style={{ marginTop: '-8px' }}>
                    <Button
                      variant="link"
                      onClick={() => {
                        updateStripeConnector({
                          variables: {
                            updateStripeConnectorInput: {
                              stripeConnectorInput: {
                                id: stripeConnector.id,
                                connectedAccountId: connectedAccountId,
                              },
                            },
                          },
                        })
                      }}
                    >
                      <Plug /> Save
                    </Button>
                  </Col>
                )}
              </Row>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default Stripe
