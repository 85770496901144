import React, { useEffect, useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { gql, useLazyQuery, useQuery } from '@apollo/client'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import DateFilter from '../../common/DateFilter'
import { Trash } from 'react-bootstrap-icons'
import Loading from '../../common/Loading'

const UnassignedEmployees = (props) => {
  const { showModal } = props

  const [startDateFilter, setStartDateFilter] = useState()
  const [selectedRoles, setSelectedRoles] = useState([])

  const { data: roleData } = useQuery(
    gql`
      query Roles {
        roles(orderBy: "name", first: 250) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const [getEmployees, { data: employees, loading }] = useLazyQuery(
    gql`
      query Employees(
        $startDateTime: DateTime!
        $endDateTime: DateTime!
        $roleIds: String
      ) {
        employees(
          first: 250
          unassignedEmployeeJobsStartDate: $startDateTime
          unassignedEmployeeJobsEndDate: $endDateTime
          roleIds: $roleIds
          distinct: true
          orderBy: "gaia_user__full_name"
        ) {
          edges {
            node {
              id
              roles {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              gaiaUser {
                fullName
              }
            }
          }
        }
      }
    `,
    {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    }
  )

  useEffect(() => {
    if (startDateFilter) {
      getEmployees({
        variables: {
          startDateTime: startDateFilter.set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          }),
          endDateTime: startDateFilter.set({
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 999,
          }),
          roleIds: selectedRoles.map((role) => role.id).join(','),
        },
      })
    }
  }, [startDateFilter, selectedRoles])

  useEffect(() => {
    if (!showModal) {
      setStartDateFilter()
      setSelectedRoles()
    }
  }, [showModal])

  if (!roleData) return <></>
  return (
    <>
      <Row className="mt-4 mb-1">
        <Col md={2}>
          <DateFilter
            oneDate
            startDateFilter={startDateFilter}
            setStartDateFilter={setStartDateFilter}
            placeholderStart="Date"
          />
        </Col>
        <Col md={3} className="mt-2">
          <Form.Group>
            <Form.Control
              as="select"
              name="bookSession"
              className="form-control form-control-sm"
              onChange={(e) => {
                if (e.target.value) {
                  const role = roleData.roles.edges.filter(
                    (edge) => edge.node.id === e.target.value
                  )[0]
                  setSelectedRoles((prevState) => [...prevState, role.node])
                }
              }}
            >
              <option value="">Select Roles</option>
              {roleData.roles.edges.map((role) => {
                const selected = selectedRoles.some(
                  (roleItem) => roleItem.id === role.node.id
                )
                if (!selected) {
                  return (
                    <option key={role.node.id} value={role.node.id}>
                      {role.node.name}
                    </option>
                  )
                }
              })}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <div style={{ fontSize: '18px' }} className="mb-2">
            {selectedRoles?.map(({ name, id }) => (
              <span className={'mr-2 badge badge-secondary'} key={id}>
                <Trash
                  className="mr-2"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    const updatedRoles = []
                    selectedRoles.forEach((role) => {
                      if (role.id !== id) {
                        updatedRoles.push(role)
                      }
                    })
                    setSelectedRoles(updatedRoles)
                  }}
                />
                <span>{name}</span>
              </span>
            ))}
          </div>
        </Col>
      </Row>
      {loading && (
        <Row>
          <Col>
            <Loading message="Loading Unassigned Employees..." />
          </Col>
        </Row>
      )}
      {employees && startDateFilter && (
        <Row className="mt-2">
          <Col>
            <SortableInfiniteTable
              tableData={employees.employees.edges}
              tableColumns={[
                {
                  Header: 'Employee',
                  id: 'employee',
                  accessor: (row) => {
                    return row.node.gaiaUser.fullName
                  },
                },
                {
                  Header: 'Roles',
                  id: 'roles',
                  accessor: (row) => {
                    return (
                      <>
                        {row.node.roles.edges.map((role, i) => (
                          <span
                            key={role.node.id}
                            style={{
                              fontSize: '12px',
                            }}
                          >
                            {i > 0 && <>, </>}
                            {role.node.name}
                          </span>
                        ))}
                      </>
                    )
                  },
                },
              ]}
              tableHeight={350}
            />
          </Col>
        </Row>
      )}
    </>
  )
}

export default UnassignedEmployees
