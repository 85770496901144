import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Button,
  Modal,
  Badge,
  Form,
  Accordion,
  Card,
} from 'react-bootstrap'
import { useLazyQuery, useMutation, gql, useQuery } from '@apollo/client'
import { DateTime } from 'luxon'
import { Dot } from 'react-bootstrap-icons'
import { useHistory, useLocation } from 'react-router-dom'
import toast from 'react-hot-toast'
import JobDetailSubjectGroupsTable from './JobDetailSubjectGroupsTable'
import JobDetailSessionsTable from './JobDetailSessionsTable'
import JobDetailEmployeeAssignments from './JobDetailEmployeeAssignments'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../libs/apollo'
import JobReport from './JobReport'
import AuditLog from '../audit_log/AuditLog'
import {
  CaretRight,
  CaretDown,
  ArrowLeftCircle,
  SlashCircle,
  Trash,
  Bell,
} from 'react-bootstrap-icons'
import Notifications from '../notifications/Notifications'
import { useBoxEnabled } from '../../libs/box'
import PaymentsTable from '../payments/PaymentsTable'
import InvoiceTable from '../payments/InvoiceTable'
import BoxFolder from '../files/box/BoxFolder'
import { useFtpEnabled } from '../../libs/ftp'
import FTPUpload from '../studio_settings/ftp/FtpUpload'
import FilesTable from '../files/FilesTable'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import MapModal from '../location/MapModal'
import Loading from '../common/Loading'

const JobDetailModal = (props) => {
  const { contact } = useParams()
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const [displayFiles, setDisplayFiles] = useState(false)
  const [displayFtp, setDisplayFtp] = useState(false)
  const [displayMapModal, setDisplayMapModal] = useState(false)
  const canMutate = ['Administrator', 'Scheduling Manager'].includes(
    loggedInUser?.permissions?.group
  )
  const history = useHistory()
  const location = useLocation()
  const boxEnabled = useBoxEnabled()
  const ftpEnabled = useFtpEnabled()
  let redirectAfterJobSave
  if (
    location?.pathname?.includes('/organization/') ||
    location?.pathname?.includes('/subject-group')
  ) {
    redirectAfterJobSave = location.pathname
  }
  const { jobId, showModal, toggleModal, sessionsOnly } = props
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [showEmailSessionsReport, setShowEmailSessionsReport] = useState(false)
  const [showEmailJobReport, setShowEmailJobReport] = useState(false)
  const [showOrganizationNotes, setShowOrganizationNotes] = useState(false)
  const [showSubjectGroupNotes, setShowSubjectGroupNotes] = useState(false)
  const [showNofity, setShowNotify] = useState(false)
  const [showCancelConfirm, setShowCancelConfirm] = useState(false)
  const [savingPicsNotUploaded, setSavingPicsNotUploaded] = useState(false)
  const [showUnCancelConfirm, setShowUnCancelConfirm] = useState(false)
  const [savingJobComplete, setSavingJobComplete] = useState(false)
  const [savingJobNotComplete, setSavingJobNotComplete] = useState(false)
  const [savingJobCancelled, setSavingJobCancelled] = useState(false)
  const [savingJobUncancelled, setSavingJobUncancelled] = useState(false)
  const [savingPicsUploaded, setSavingPicsUploaded] = useState(false)
  const [displayAuditLog, setDisplayAuditLog] = useState(false)
  const [displayJobNotes, setDisplayJobNotes] = useState(false)
  const [displaySubjectNotes, setDisplaySubjectNotes] = useState(false)
  const [displayNotes, setDisplayNotes] = useState(false)

  const [displayBuffers, setDisplayBuffers] = useState(false)
  const [displayBagTypes, setDisplayBagTypes] = useState(false)
  const [displayPackageCategories, setDisplayPackageCategories] =
    useState(false)
  const [displayOrgContacts, setDisplayOrgContacts] = useState(false)
  const [displayContacts, setDisplayContact] = useState(false)
  const [displayPayments, setDisplayPayments] = useState(false)
  const [displayInvoices, setDisplayInvoices] = useState(false)

  const { data: settingsData } = useQuery(
    gql`
      query StudioSettingsQuery {
        settings {
          edges {
            node {
              id
              jobStages
            }
          }
        }
      }
    `,
    { fetchPolicy: 'network-only' }
  )
  const settings = settingsData?.settings?.edges[0].node

  const [sendNotificationTrigger] = useMutation(
    gql`
      mutation SendNotificationTrigger(
        $sendNotificationTriggerInput: SendNotificationTriggerInput!
      ) {
        sendNotificationTrigger(input: $sendNotificationTriggerInput) {
          sent
        }
      }
    `,
    {
      errorPolicy: 'all',
      onCompleted: () => {
        toast.success('Sending Notification')
      },
      refetchQueries: ['NotificationsQuery'],
    }
  )

  const [jobQuery, { error: jobQueryError, data: jobQueryData }] = useLazyQuery(
    gql`
      query JobDetailQuery($jobId: ID!) {
        job(id: $jobId) {
          id
          cancelled
          stage {
            id
            name
            color
          }
          jobType {
            id
            name
            color
          }
          folder {
            id
          }
          sharedCanSeeFiles
          sharedCanCreateFiles
          sharedCanCreateFolders
          sharedFolder {
            id
          }
          sessions {
            nodeCount
          }
          jobEquipmentBagTypes {
            edges {
              node {
                id
                equipmentBagType {
                  name
                }
                requiredQuantity
                includedQuantity
              }
            }
          }
          jobEquipmentItemTypes {
            edges {
              node {
                id
                equipmentItemType {
                  name
                }
                requiredQuantity
                includedQuantity
              }
            }
          }
          recordId
          resitsAvailable
          bookingStartDateTime
          bookingEndDateTime
          notificationsEnabled
          updated
          created
          bookable
          createdBy {
            fullName
            email
          }
          modifiedBy {
            fullName
            email
          }
          location {
            id
            archived
            name
            addressLineOne
            latitude
            longitude
            fullAddress
          }
          contentType {
            model
          }
          startDateTime
          endDateTime
          name
          breaks {
            edges {
              node {
                startDateTime
                endDateTime
              }
            }
          }
          notes
          subjectNotes
          subjectGroups {
            nodeCount
            edges {
              node {
                id
                name
                notes
              }
            }
          }
          packageGroupJobs {
            nodeCount
          }
          packageCategoryJobs {
            nodeCount
            edges {
              node {
                packageCategory {
                  id
                  name
                }
                sessionPackages {
                  edges {
                    node {
                      id
                      title
                    }
                  }
                }
              }
            }
          }
          contacts {
            edges {
              node {
                fullName
                email
                phoneNumber
                jobTitle
                secondaryPhoneNumber
                secondaryEmail
              }
            }
          }
          organizations {
            id
            notes
            name
          }
          rootBoxFolderId
          adHocContacts {
            edges {
              node {
                email
                firstName
                lastName
                secondaryEmail
                phoneNumber
                secondaryPhoneNumber
                notes
              }
            }
          }
          setups
          completed
          employeeJobs(archived: false) {
            edges {
              node {
                role {
                  name
                }
                employee {
                  gaiaUser {
                    firstName
                    lastName
                  }
                }
              }
            }
          }
          metadata
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )

  const [deleteJobMutation] = useMutation(
    gql`
      mutation DeleteJob($deleteJobInput: DeleteJobInput!) {
        deleteJob(input: $deleteJobInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        innerToggleModal()
        toast.success('Job Deleted')
      },
      refetchQueries: ['ScheduleJobsQuery'],
      errorPolicy: 'all',
    }
  )
  const [updateJobMutation] = useMutation(
    gql`
      mutation UpdateJob($updateJobInput: UpdateJobInput!) {
        updateJob(input: $updateJobInput) {
          job {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        if (savingJobComplete) {
          toast.success('Job Complete')
          setSavingJobComplete(false)
        }
        if (savingPicsNotUploaded) {
          toast.success('Resits Not Available')
          setSavingPicsNotUploaded(false)
        }
        if (savingJobNotComplete) {
          toast.success('Job Not Complete')
          setSavingJobNotComplete(false)
        }
        if (savingJobCancelled) {
          toast.success('Job Cancelled')
          setSavingJobCancelled(false)
          setShowCancelConfirm(false)
        }
        if (savingJobUncancelled) {
          toast.success('Job Uncancelled')
          setSavingJobUncancelled(false)
          setShowUnCancelConfirm(false)
        }
        if (savingPicsUploaded) {
          toast.success('Resits Available')
          setSavingPicsUploaded(false)
        }
      },
      errorPolicy: 'all',
      refetchQueries: [
        'JobDetailQuery',
        'ScheduleJobsQuery',
        'JobDetailSessionsQuery',
        'JobDetailPackageGroupJobsQuery',
      ],
    }
  )
  useEffect(() => {
    if (jobId && jobQuery) {
      jobQuery({
        variables: {
          jobId,
        },
      })
    }
  }, [jobId, jobQuery])

  const handleJobComplete = () => {
    setSavingJobComplete(true)
    updateJobMutation({
      variables: {
        updateJobInput: {
          jobInput: {
            id: jobId,
            completed: true,
          },
        },
      },
    })
  }

  const handleJobNotComplete = () => {
    setSavingJobNotComplete(true)
    updateJobMutation({
      variables: {
        updateJobInput: {
          jobInput: {
            id: jobId,
            completed: false,
          },
        },
      },
    })
  }

  const handleDeleteJobConfirmClick = () => {
    deleteJobMutation({
      variables: {
        deleteJobInput: {
          jobIds: job.id,
        },
      },
    })
  }

  const backToDetail = () => {
    setShowDeleteConfirm(false)
    setShowEmailSessionsReport(false)
    setShowEmailJobReport(false)
    setShowCancelConfirm(false)
    setShowUnCancelConfirm(false)
    setShowNotify(false)
  }

  const handleCancelJobConfirmClick = () => {
    setSavingJobCancelled(true)
    updateJobMutation({
      variables: {
        updateJobInput: {
          jobInput: {
            id: jobId,
            cancelled: true,
          },
        },
      },
    })
  }

  const handleUncancelJobConfirmClick = () => {
    setSavingJobUncancelled(true)
    updateJobMutation({
      variables: {
        updateJobInput: {
          jobInput: {
            id: jobId,
            cancelled: false,
          },
        },
      },
    })
  }

  const toggleShowNotify = () => {
    setShowNotify((prevState) => !prevState)
    setShowDeleteConfirm(false)
    setShowEmailSessionsReport(false)
    setShowEmailJobReport(false)
    setShowCancelConfirm(false)
  }

  const toggleShowDeleteConfirm = () => {
    setShowDeleteConfirm((prevState) => !prevState)
    setShowEmailSessionsReport(false)
    setShowEmailJobReport(false)
    setShowCancelConfirm(false)
    setShowNotify(false)
  }
  const toggleShowEmailJobReport = () => {
    setShowEmailJobReport((prevState) => !prevState)
    setShowUnCancelConfirm(false)
    setShowEmailSessionsReport(false)
    setShowDeleteConfirm(false)
    setShowCancelConfirm(false)
    setShowNotify(false)
  }
  const toggleShowCancelConfirm = () => {
    setShowCancelConfirm((prevState) => !prevState)
    setShowUnCancelConfirm(false)
    setShowDeleteConfirm(false)
    setShowEmailSessionsReport(false)
    setShowEmailJobReport(false)
    setShowNotify(false)
  }
  const toggleShowUnCancelConfirm = () => {
    setShowUnCancelConfirm((prevState) => !prevState)
    setShowCancelConfirm(false)
    setShowDeleteConfirm(false)
    setShowEmailSessionsReport(false)
    setShowEmailJobReport(false)
    setShowNotify(false)
  }
  if (jobQueryError) return <>Error loading job</>
  if (!showModal) return <></>
  if (showModal && !jobQueryData?.job)
    return (
      <Modal show={true} size={'xl'}>
        <Modal.Body>
          <Row
            className="justify-content-center align-items-center"
            style={{ height: '100%' }}
          >
            <Col className="text-center">
              <Loading />
              <div className="mt-2" style={{ fontSize: '14px' }}>
                Loading {sessionsOnly ? <>Sessions</> : <>Job</>}...
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    )

  const { job } = jobQueryData

  const innerToggleModal = () => {
    toggleModal()
    setTimeout(() => {
      if (showEmailSessionsReport) {
        setShowEmailSessionsReport(false)
      }
      if (showNofity) {
        setShowNotify(false)
      }
      if (showEmailJobReport) {
        setShowEmailJobReport(false)
      }
      if (showDeleteConfirm) {
        setShowDeleteConfirm(false)
      }
      if (showUnCancelConfirm) {
        setShowUnCancelConfirm(false)
      }
      if (showCancelConfirm) {
        setShowCancelConfirm(false)
      }
      if (displayFiles) {
        setDisplayFiles(false)
      }
      if (displayAuditLog) {
        setDisplayAuditLog(false)
      }
    }, 200)
  }
  const start = DateTime.fromISO(job?.startDateTime)
  const end = DateTime.fromISO(job?.endDateTime)
  let bookingStart
  let bookingEnd
  if (job?.bookingStartDateTime) {
    bookingStart = DateTime.fromISO(job?.bookingStartDateTime)
  }
  if (job?.bookingEndDateTime) {
    bookingEnd = DateTime.fromISO(job?.bookingEndDateTime)
  }

  return (
    <>
      <div className="jobDetailModal">
        <Modal
          show={showModal}
          onHide={innerToggleModal}
          size={!showNofity && 'xl'}
          className={showNofity && 'full-screen-modal'}
        >
          <Modal.Header closeButton className="pb-0">
            <Modal.Title>
              <div className="d-flex align-items-center d-block">
                <>
                  <h1>
                    {sessionsOnly ? `${job?.name} Sessions` : <>{job?.name}</>}
                  </h1>
                  {job?.jobType && (
                    <h6 className="ml-2 mt-1">
                      <Badge variant="success">{job?.jobType.name}</Badge>
                    </h6>
                  )}
                  {!sessionsOnly && job?.completed && (
                    <h6 className="ml-2 mt-1">
                      <Badge variant="success">Complete</Badge>
                    </h6>
                  )}
                  {!sessionsOnly && !job?.notificationsEnabled && (
                    <h6 className="ml-2 mt-1">
                      <Badge
                        style={{
                          backgroundColor: 'red',
                        }}
                      >
                        Notifications Disabled
                      </Badge>
                    </h6>
                  )}
                  {!sessionsOnly && job?.cancelled && (
                    <h6 className="mt-2 ml-2 mt-1">
                      <Badge
                        style={{
                          backgroundColor: 'red',
                        }}
                      >
                        Canceled
                      </Badge>
                    </h6>
                  )}
                </>
              </div>

              {!sessionsOnly && (
                <div className="hstack gap-3 d-block">
                  {canMutate && (
                    <>
                      <Button
                        variant="link"
                        className="ps-0 ms-0"
                        onClick={() =>
                          history.push({
                            pathname: `/job/${job.id}`,
                            state: {
                              jobFormType: 'manual',
                              redirectAfterJobSave,
                            },
                          })
                        }
                      >
                        Edit
                      </Button>
                      <span className="light-line">|</span>
                      <Button
                        variant="link"
                        onClick={() => {
                          history.push({
                            pathname: `/job/${job.id}`,
                            state: {
                              jobFormType: 'manual',
                              copyJob: true,
                              redirectAfterJobSave,
                            },
                          })
                        }}
                      >
                        Copy
                      </Button>

                      {!job.completed && !job.cancelled && (
                        <>
                          <span className="light-line">|</span>

                          <Button variant="link" onClick={handleJobComplete}>
                            Complete
                          </Button>
                          <span className="light-line">|</span>
                        </>
                      )}

                      {job.completed && (
                        <>
                          <span className="light-line">|</span>
                          <Button variant="link" onClick={handleJobNotComplete}>
                            Not Complete
                          </Button>
                          <span className="light-line">|</span>
                        </>
                      )}
                      {job.cancelled && !job.completed && (
                        <>
                          <span className="light-line">|</span>
                          <Button
                            size="sm"
                            variant="link"
                            onClick={toggleShowUnCancelConfirm}
                          >
                            Uncancel
                          </Button>
                          <span className="light-line">|</span>
                        </>
                      )}
                    </>
                  )}

                  {!contact && (
                    <Button variant="link" onClick={toggleShowEmailJobReport}>
                      <span>Download</span>
                    </Button>
                  )}

                  {canMutate && (
                    <>
                      {job.cancelled && !job.resitsAvailable && (
                        <span className="light-line">|</span>
                      )}
                      {!job.cancelled && (
                        <>
                          <span className="light-line">|</span>
                          <Button
                            variant="link"
                            onClick={toggleShowCancelConfirm}
                          >
                            Cancel
                          </Button>
                          <span className="light-line">|</span>
                        </>
                      )}
                      {job.resitsAvailable && job.cancelled && (
                        <span className="light-line">|</span>
                      )}
                      <Button
                        className="btn-danger"
                        variant="link"
                        onClick={toggleShowDeleteConfirm}
                      >
                        Delete
                      </Button>
                    </>
                  )}

                  {!contact && (
                    <>
                      <span className="light-line">|</span>
                      <Button
                        className="btn-danger"
                        variant="link"
                        onClick={toggleShowNotify}
                      >
                        Notifications
                      </Button>
                    </>
                  )}
                </div>
              )}
            </Modal.Title>
          </Modal.Header>
          {!showDeleteConfirm &&
            !showCancelConfirm &&
            !showUnCancelConfirm &&
            !showEmailSessionsReport &&
            !showNofity &&
            !showEmailJobReport && (
              <>
                <Modal.Body>
                  <Row className="mt-3">
                    <Col>
                      <h5 className="mb-0">Location</h5>
                      <span
                        style={{ cursor: 'pointer' }}
                        className="btn-link mb-0"
                        onClick={() => {
                          setDisplayMapModal(true)
                        }}
                      >
                        {!jobQueryData.job?.location.archived && (
                          <>{jobQueryData.job?.location?.fullAddress}</>
                        )}
                        {jobQueryData.job?.location.archived && <>- - -</>}
                      </span>
                    </Col>
                    <Col>
                      <h5 className="mb-0">Date and Time</h5>
                      <span className="mb-0">
                        {start.toFormat('MMMM dd, yyyy')}{' '}
                        {start.toFormat('h:mma')} - {end.toFormat('h:mma')}
                      </span>
                    </Col>
                    {bookingStart &&
                      bookingEnd &&
                      jobQueryData.job?.bookable &&
                      !loggedInUser?.permissions?.group === 'General Staff' &&
                      !loggedInUser.permissions.isOrgContact && (
                        <Col>
                          <h5 className="mb-0">Bookable From</h5>
                          <span className="mb-0">
                            {bookingStart.toFormat('MMMM dd, yyyy')} -{' '}
                            {bookingEnd.toFormat('MMMM dd, yyyy')}
                          </span>
                        </Col>
                      )}
                    <Col sm={2}>
                      <h5 className="mb-1">Setups</h5>
                      <span className="mb-0">{jobQueryData.job.setups}</span>
                    </Col>
                    {!contact && !sessionsOnly && (
                      <Col sm={2}>
                        <h5 className="mb-1">Job ID</h5>
                        <span className="mb-0">
                          {jobQueryData.job.recordId}
                        </span>
                      </Col>
                    )}
                    {settings?.jobStages && (
                      <Col sm={2}>
                        <h5 className="mb-1">Stage</h5>
                        {jobQueryData?.job?.stage && (
                          <>
                            <span
                              style={{
                                backgroundColor: jobQueryData.job.stage.color,
                              }}
                            >
                              {jobQueryData.job.stage.name}
                            </span>
                          </>
                        )}
                      </Col>
                    )}
                  </Row>
                  <JobDetailSessionsTable
                    sessionsOnly={sessionsOnly}
                    toggleModal={toggleModal}
                    jobId={job.id}
                  />
                  {((!sessionsOnly && loggedInUser.permissions.isEmployee) ||
                    boxEnabled ||
                    job.sharedCanSeeFiles) && (
                    <>
                      <Row className="mt-3">
                        <Col md={12} className="d-flex align-items-center">
                          <button
                            type="button"
                            onClick={() => setDisplayFiles(!displayFiles)}
                            className="px-0 btn-link mr-1"
                            style={{ marginTop: '-10px' }}
                          >
                            <>
                              {displayFiles ? (
                                <>
                                  <CaretDown size={20} />
                                </>
                              ) : (
                                <>
                                  <CaretRight size={20} />
                                </>
                              )}
                            </>
                          </button>
                          <h5>Files</h5>
                        </Col>
                      </Row>
                      {displayFiles && boxEnabled && (
                        <BoxFolder rootBoxFolderId={job.rootBoxFolderId} />
                      )}
                      {displayFiles && !boxEnabled && job?.folder?.id && (
                        <div className="mt-3">
                          <FilesTable
                            jobId={job.id}
                            jobDescription={job.name}
                            sharedCanCreateFiles={job.sharedCanCreateFiles}
                            sharedCanCreateFolders={job.sharedCanCreateFolders}
                            tableHeight={300}
                            folderId={
                              contact ? job.sharedFolder.id : job.folder.id
                            }
                          />
                        </div>
                      )}
                    </>
                  )}
                  {!sessionsOnly && !contact && (
                    <>
                      <Row className="mt-3">
                        <Col className="d-flex align-items-center">
                          <button
                            type="button"
                            onClick={() => setDisplayNotes(!displayNotes)}
                            className="px-0 btn-link mr-1"
                            style={{ marginTop: '-10px' }}
                          >
                            <>
                              {displayNotes ? (
                                <>
                                  <CaretDown size={20} />
                                </>
                              ) : (
                                <>
                                  <CaretRight size={20} />
                                </>
                              )}
                            </>
                          </button>
                          <h5 className="mb-1">Notes</h5>
                        </Col>
                      </Row>
                      {displayNotes && (
                        <div className="ml-3">
                          <Row className="mt-3">
                            <Col className="d-flex align-items-center">
                              <button
                                type="button"
                                onClick={() =>
                                  setDisplayJobNotes(!displayJobNotes)
                                }
                                className="px-0 btn-link mr-1"
                                style={{ marginTop: '-10px' }}
                              >
                                <>
                                  {displayJobNotes ? (
                                    <>
                                      <CaretDown size={20} />
                                    </>
                                  ) : (
                                    <>
                                      <CaretRight size={20} />
                                    </>
                                  )}
                                </>
                              </button>
                              <h5 className="mb-1">Job Notes</h5>
                            </Col>
                          </Row>
                          {displayJobNotes && (
                            <Row>
                              <Col>
                                {job.notes ? (
                                  <Form.Control
                                    as="textarea"
                                    style={{
                                      height: '150px',
                                      backgroundColor: 'white',
                                    }}
                                    disabled={true}
                                    value={job.notes}
                                  />
                                ) : (
                                  <span className="text-secondary">- - -</span>
                                )}
                              </Col>
                            </Row>
                          )}

                          <Row className="mt-3">
                            <Col className="d-flex align-items-center">
                              <button
                                type="button"
                                onClick={() =>
                                  setDisplaySubjectNotes(!displaySubjectNotes)
                                }
                                className="px-0 btn-link mr-1"
                                style={{ marginTop: '-10px' }}
                              >
                                <>
                                  {displaySubjectNotes ? (
                                    <>
                                      <CaretDown size={20} />
                                    </>
                                  ) : (
                                    <>
                                      <CaretRight size={20} />
                                    </>
                                  )}
                                </>
                              </button>
                              <h5 className="mb-1">Subject Notes</h5>
                            </Col>
                          </Row>
                          {displaySubjectNotes && (
                            <Row>
                              <Col>
                                {job.subjectNotes ? (
                                  <Form.Control
                                    style={{
                                      height: '150px',
                                      backgroundColor: 'white',
                                    }}
                                    as="textarea"
                                    disabled={true}
                                    value={job.subjectNotes}
                                  />
                                ) : (
                                  <span className="text-secondary">- - -</span>
                                )}
                              </Col>
                            </Row>
                          )}
                          {job.organizations.length > 0 && (
                            <>
                              <Row className="mt-3">
                                <Col className="d-flex align-items-center">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      setShowOrganizationNotes(
                                        !showOrganizationNotes
                                      )
                                    }
                                    className="px-0 btn-link mr-1"
                                    style={{ marginTop: '-10px' }}
                                  >
                                    <>
                                      {showOrganizationNotes ? (
                                        <>
                                          <CaretDown size={20} />
                                        </>
                                      ) : (
                                        <>
                                          <CaretRight size={20} />
                                        </>
                                      )}
                                    </>
                                  </button>
                                  <h5 className="mb-1">Organization Notes</h5>
                                </Col>
                              </Row>
                              {showOrganizationNotes && (
                                <Row>
                                  <Col>
                                    <Card.Body className="p-0 mt-2">
                                      <Accordion>
                                        {job.organizations.map(
                                          (organization) => (
                                            <Card key={organization.id}>
                                              <Card.Header className="m-n2">
                                                <Accordion.Toggle
                                                  eventKey={organization.id}
                                                  className="w-100 btn-link accordion-toggle-left"
                                                >
                                                  {organization.name}
                                                </Accordion.Toggle>
                                              </Card.Header>
                                              <Accordion.Collapse
                                                eventKey={organization.id}
                                              >
                                                <Card.Body
                                                  style={{
                                                    maxHeight: '200px',
                                                    overflowY: 'scroll',
                                                  }}
                                                >
                                                  <Form.Control
                                                    style={{
                                                      height: '150px',
                                                      backgroundColor: 'white',
                                                    }}
                                                    as="textarea"
                                                    disabled={true}
                                                    value={organization.notes}
                                                  />
                                                </Card.Body>
                                              </Accordion.Collapse>
                                            </Card>
                                          )
                                        )}
                                      </Accordion>
                                    </Card.Body>
                                  </Col>
                                </Row>
                              )}
                            </>
                          )}
                          {job.subjectGroups.nodeCount > 0 && (
                            <>
                              <Row className="mt-3">
                                <Col className="d-flex align-items-center">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      setShowSubjectGroupNotes(
                                        !showSubjectGroupNotes
                                      )
                                    }
                                    className="px-0 btn-link mr-1"
                                    style={{ marginTop: '-10px' }}
                                  >
                                    <>
                                      {showSubjectGroupNotes ? (
                                        <>
                                          <CaretDown size={20} />
                                        </>
                                      ) : (
                                        <>
                                          <CaretRight size={20} />
                                        </>
                                      )}
                                    </>
                                  </button>
                                  <h5 className="mb-1">Subject Group Notes</h5>
                                </Col>
                              </Row>
                              {showSubjectGroupNotes && (
                                <Row>
                                  <Col>
                                    <Card.Body className="p-0 mt-2">
                                      <Accordion>
                                        {job.subjectGroups &&
                                          job.subjectGroups.edges &&
                                          job.subjectGroups.edges.map(
                                            ({ node }) => (
                                              <Card key={node.id}>
                                                <Card.Header className="m-n2">
                                                  <Accordion.Toggle
                                                    eventKey={node.id}
                                                    className="w-100 btn-link accordion-toggle-left"
                                                  >
                                                    {node.name}
                                                  </Accordion.Toggle>
                                                </Card.Header>
                                                <Accordion.Collapse
                                                  eventKey={node.id}
                                                >
                                                  <Card.Body
                                                    style={{
                                                      maxHeight: '200px',
                                                      overflowY: 'scroll',
                                                    }}
                                                  >
                                                    <Form.Control
                                                      style={{
                                                        height: '150px',
                                                        backgroundColor:
                                                          'white',
                                                      }}
                                                      as="textarea"
                                                      disabled={true}
                                                      value={node.notes}
                                                    />
                                                  </Card.Body>
                                                </Accordion.Collapse>
                                              </Card>
                                            )
                                          )}
                                      </Accordion>
                                    </Card.Body>
                                  </Col>
                                </Row>
                              )}
                            </>
                          )}
                        </div>
                      )}

                      <Row className="mt-3">
                        <Col className="d-flex align-items-center">
                          <button
                            type="button"
                            onClick={() => setDisplayBuffers(!displayBuffers)}
                            className="px-0 btn-link mr-1"
                            style={{ marginTop: '-10px' }}
                          >
                            <>
                              {displayBuffers ? (
                                <>
                                  <CaretDown size={20} />
                                </>
                              ) : (
                                <>
                                  <CaretRight size={20} />
                                </>
                              )}
                            </>
                          </button>
                          <h5 className="mb-1">Buffers</h5>
                        </Col>
                      </Row>
                      {displayBuffers && (
                        <Row>
                          <Col>
                            {job.breaks.edges.length > 0 ? (
                              <>
                                {job.breaks.edges.map((jobBreak, index) => {
                                  const startBreak = DateTime.fromISO(
                                    jobBreak.node.startDateTime
                                  )
                                  const endBreak = DateTime.fromISO(
                                    jobBreak.node.endDateTime
                                  )
                                  return (
                                    <span
                                      key={index}
                                      style={{ margin: 0, padding: 0 }}
                                      className="d-block d-flex align-items-center"
                                    >
                                      <Dot className="mr-2" />
                                      {startBreak.toFormat('h:mma')} -{' '}
                                      {endBreak.toFormat('h:mma')}
                                    </span>
                                  )
                                })}
                              </>
                            ) : (
                              <span className="text-secondary">- - -</span>
                            )}
                          </Col>
                        </Row>
                      )}
                      <JobDetailEmployeeAssignments
                        toggleModal={toggleModal}
                        jobId={job.id}
                        jobDetailModal={true}
                        jobName={job.name}
                      />
                      <>
                        <Row>
                          <Col className="d-flex align-items-center">
                            <button
                              type="button"
                              onClick={() =>
                                setDisplayBagTypes(!displayBagTypes)
                              }
                              className="px-0 btn-link mr-1"
                              style={{ marginTop: '-10px' }}
                            >
                              <>
                                {displayBagTypes ? (
                                  <>
                                    <CaretDown size={20} />
                                  </>
                                ) : (
                                  <>
                                    <CaretRight size={20} />
                                  </>
                                )}
                              </>
                            </button>
                            <h5 className="mb-2">Required Equipment</h5>
                          </Col>
                        </Row>
                        {displayBagTypes && (
                          <div className="ml-4">
                            <Row className="mt-3">
                              <Col>
                                <h5 className="mb-2">Required Bag Types</h5>
                              </Col>
                            </Row>
                            <Row className="mt-2">
                              <Col md={12}>
                                {job.jobEquipmentBagTypes?.edges.length ===
                                0 ? (
                                  <span className="text-secondary">- - -</span>
                                ) : (
                                  <>
                                    <SortableInfiniteTable
                                      tableData={job.jobEquipmentBagTypes.edges}
                                      tdStyleGenerator={(cell) => {
                                        let tdStyle = {}
                                        if (cell.column.Header === 'Included') {
                                          if (
                                            cell.row.original.node
                                              .includedQuantity >=
                                            cell.row.original.node
                                              .requiredQuantity
                                          ) {
                                            tdStyle.backgroundColor =
                                              'rgba(192,255,192,0.5)'
                                          } else {
                                            tdStyle.backgroundColor =
                                              'rgb(255,192,192)'
                                          }
                                        }
                                        return tdStyle
                                      }}
                                      tableColumns={[
                                        {
                                          Header: 'Bag Type',
                                          accessor:
                                            'node.equipmentBagType.name',
                                        },
                                        {
                                          Header: 'Required',
                                          accessor: 'node.requiredQuantity',
                                        },
                                        {
                                          Header: 'Included',
                                          accessor: 'node.includedQuantity',
                                        },
                                      ]}
                                      hasMoreTableData={false}
                                      rowPointer
                                      tableHeight={150}
                                    />
                                  </>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <h5 className="mb-2">Required Item Types</h5>
                              </Col>
                            </Row>
                            <Row className="mt-2">
                              <Col md={12}>
                                {job.jobEquipmentItemTypes?.edges.length ===
                                0 ? (
                                  <span className="text-secondary">- - -</span>
                                ) : (
                                  <>
                                    <SortableInfiniteTable
                                      tableData={
                                        job.jobEquipmentItemTypes.edges
                                      }
                                      tdStyleGenerator={(cell) => {
                                        let tdStyle = {}
                                        if (cell.column.Header === 'Included') {
                                          if (
                                            cell.row.original.node
                                              .includedQuantity >=
                                            cell.row.original.node
                                              .requiredQuantity
                                          ) {
                                            tdStyle.backgroundColor =
                                              'rgba(192,255,192,0.5)'
                                          } else {
                                            tdStyle.backgroundColor =
                                              'rgb(255,192,192)'
                                          }
                                        }
                                        return tdStyle
                                      }}
                                      tableColumns={[
                                        {
                                          Header: 'Item Type',
                                          accessor:
                                            'node.equipmentItemType.name',
                                        },
                                        {
                                          Header: 'Required',
                                          accessor: 'node.requiredQuantity',
                                        },
                                        {
                                          Header: 'Included',
                                          accessor: 'node.includedQuantity',
                                        },
                                      ]}
                                      hasMoreTableData={false}
                                      rowPointer
                                      tableHeight={150}
                                    />
                                  </>
                                )}
                              </Col>
                            </Row>
                          </div>
                        )}
                      </>

                      <JobDetailSubjectGroupsTable
                        toggleModal={toggleModal}
                        jobId={job.id}
                      />
                      <Row>
                        <Col className="d-flex align-items-center">
                          <button
                            type="button"
                            onClick={() =>
                              setDisplayPackageCategories(
                                !displayPackageCategories
                              )
                            }
                            className="px-0 btn-link mr-1"
                            style={{ marginTop: '-10px' }}
                          >
                            <>
                              {displayPackageCategories ? (
                                <>
                                  <CaretDown size={20} />
                                </>
                              ) : (
                                <>
                                  <CaretRight size={20} />
                                </>
                              )}
                            </>
                          </button>
                          <h5 className="mb-2">Package Categories</h5>
                        </Col>
                      </Row>
                      {displayPackageCategories && (
                        <Row>
                          <Col md={12}>
                            {job.packageCategoryJobs?.edges.length === 0 ? (
                              <span className="text-secondary">- - -</span>
                            ) : (
                              <>
                                <SortableInfiniteTable
                                  tableData={job.packageCategoryJobs.edges}
                                  tableColumns={[
                                    {
                                      Header: 'Name',
                                      accessor: 'node.packageCategory.name',
                                    },
                                    {
                                      Header: 'Session Packages',
                                      accessor: (row) => {
                                        const sessionPackages = []
                                        row.node.sessionPackages?.edges.forEach(
                                          (sessionPackage) => {
                                            sessionPackages.push(
                                              sessionPackage.node.title
                                            )
                                          }
                                        )
                                        const sessionPackageDisplay = (
                                          <>
                                            {sessionPackages.map(
                                              (sessionPackage, index) => (
                                                <p
                                                  key={index}
                                                  style={{
                                                    margin: 0,
                                                    padding: 0,
                                                  }}
                                                >
                                                  {sessionPackage}
                                                </p>
                                              )
                                            )}
                                          </>
                                        )
                                        return sessionPackageDisplay
                                      },
                                    },
                                  ]}
                                  hasMoreTableData={false}
                                  rowPointer
                                  tableHeight={150}
                                  hideGlobalFilter
                                />
                              </>
                            )}
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                  {!sessionsOnly && !contact && (
                    <div className="sessions mt-3">
                      <Row>
                        <Col className="d-flex align-items-center">
                          <button
                            type="button"
                            onClick={() =>
                              setDisplayOrgContacts(!displayOrgContacts)
                            }
                            className="px-0 btn-link mr-1"
                            style={{ marginTop: '-8px' }}
                          >
                            <>
                              {displayOrgContacts ? (
                                <>
                                  <CaretDown size={20} />
                                </>
                              ) : (
                                <>
                                  <CaretRight size={20} />
                                </>
                              )}
                            </>
                          </button>
                          <h5 className="mb-1">Organization Contacts</h5>
                        </Col>
                      </Row>
                      {displayOrgContacts && (
                        <Row>
                          <Col md={12}>
                            {job?.contacts.edges.length > 0 ? (
                              <SortableInfiniteTable
                                tableData={job.contacts.edges}
                                tableColumns={[
                                  {
                                    Header: 'Name',
                                    accessor: 'node.fullName',
                                  },
                                  {
                                    Header: 'Job Title',
                                    accessor: 'node.jobTitle',
                                  },
                                  {
                                    Header: 'Email',
                                    accessor: (row) => {
                                      let emails
                                      if (row.node.email) {
                                        emails = `${row.node.email}`
                                      }
                                      if (row.node.secondaryEmail) {
                                        if (emails) {
                                          emails = `${emails}, ${row.node.secondaryEmail}`
                                        } else {
                                          emails = `${row.node.secondaryEmail}`
                                        }
                                      }
                                      return emails
                                    },
                                  },
                                  {
                                    Header: 'Phone Number',
                                    accessor: (row) => {
                                      let phones
                                      if (row.node.phoneNumber) {
                                        phones = `${row.node.phoneNumber}`
                                      }
                                      if (row.node.secondaryPhoneNumber) {
                                        if (phones) {
                                          phones = `${phones}, ${row.node.secondaryPhoneNumber}`
                                        } else {
                                          phones = `${row.node.secondaryPhoneNumber}`
                                        }
                                      }
                                      return phones
                                    },
                                  },
                                ]}
                                tableHeight={150}
                              />
                            ) : (
                              <span className="text-secondary">- - -</span>
                            )}
                          </Col>
                        </Row>
                      )}

                      <Row>
                        <Col className="d-flex align-items-center">
                          <button
                            type="button"
                            onClick={() => setDisplayContact(!displayContacts)}
                            className="px-0 btn-link mr-1"
                            style={{ marginTop: '8px' }}
                          >
                            <>
                              {displayContacts ? (
                                <>
                                  <CaretDown size={20} />
                                </>
                              ) : (
                                <>
                                  <CaretRight size={20} />
                                </>
                              )}
                            </>
                          </button>
                          <h5 className="mb-1 mt-3">Contacts</h5>
                        </Col>
                      </Row>
                      {displayContacts && (
                        <Row>
                          <Col md={12}>
                            {job?.adHocContacts.edges.length > 0 ? (
                              <SortableInfiniteTable
                                tableData={job.adHocContacts.edges}
                                tableColumns={[
                                  {
                                    Header: 'Name',
                                    accessor: (row) => {
                                      return `${row.node.firstName} ${row.node.lastName}`
                                    },
                                  },
                                  {
                                    Header: 'Email',
                                    accessor: (row) => {
                                      let emails
                                      if (row.node.email) {
                                        emails = `${row.node.email}`
                                      }
                                      if (row.node.secondaryEmail) {
                                        if (emails) {
                                          emails = `${emails}, ${row.node.secondaryEmail}`
                                        } else {
                                          emails = `${row.node.secondaryEmail}`
                                        }
                                      }
                                      return emails
                                    },
                                  },
                                  {
                                    Header: 'Phone Number',
                                    accessor: (row) => {
                                      let phones
                                      if (row.node.phoneNumber) {
                                        phones = `${row.node.phoneNumber}`
                                      }
                                      if (row.node.secondaryPhoneNumber) {
                                        if (phones) {
                                          phones = `${phones}, ${row.node.secondaryPhoneNumber}`
                                        } else {
                                          phones = `${row.node.secondaryPhoneNumber}`
                                        }
                                      }
                                      return phones
                                    },
                                  },
                                  {
                                    Header: 'Notes',
                                    accessor: 'node.notes',
                                  },
                                ]}
                                tableHeight={150}
                              />
                            ) : (
                              <span className="text-secondary">- - -</span>
                            )}
                          </Col>
                        </Row>
                      )}
                      <Row className="mt-3">
                        <Col className="d-flex align-items-center">
                          <button
                            type="button"
                            onClick={() => setDisplayPayments(!displayPayments)}
                            className="px-0 btn-link mr-1"
                            style={{ marginTop: '-8px' }}
                          >
                            <>
                              {displayPayments ? (
                                <>
                                  <CaretDown size={20} />
                                </>
                              ) : (
                                <>
                                  <CaretRight size={20} />
                                </>
                              )}
                            </>
                          </button>
                          <h5 className="mb-1">Payments</h5>
                        </Col>
                      </Row>
                      {displayPayments && (
                        <PaymentsTable
                          jobId={job.id}
                          tableHeight={400}
                          fetchPolicy={'no-cache'}
                        />
                      )}
                      <Row className="mt-3">
                        <Col className="d-flex align-items-center">
                          <button
                            type="button"
                            onClick={() => setDisplayInvoices(!displayInvoices)}
                            className="px-0 btn-link mr-1"
                            style={{ marginTop: '-8px' }}
                          >
                            <>
                              {displayInvoices ? (
                                <>
                                  <CaretDown size={20} />
                                </>
                              ) : (
                                <>
                                  <CaretRight size={20} />
                                </>
                              )}
                            </>
                          </button>
                          <h5 className="mb-1">Invoices</h5>
                        </Col>
                      </Row>
                      {displayInvoices && (
                        <InvoiceTable
                          jobId={job.id}
                          tableHeight={400}
                          fetchPolicy={'no-cache'}
                        />
                      )}
                      {[
                        'Administrator',
                        'Scheduling Manager',
                        'Scheduling Analyst',
                        'Equipment Manager',
                      ].includes(loggedInUser?.permissions?.group) && (
                        <>
                          <Row className="mt-3 mb-2">
                            <Col md={12} className="d-flex align-items-center">
                              <button
                                type="button"
                                onClick={() =>
                                  setDisplayAuditLog(!displayAuditLog)
                                }
                                style={{ marginTop: '-10px' }}
                                className="px-0 btn-link mr-1"
                              >
                                <>
                                  {displayAuditLog ? (
                                    <>
                                      <CaretDown size={20} />
                                    </>
                                  ) : (
                                    <>
                                      <CaretRight size={20} />
                                    </>
                                  )}
                                </>
                              </button>
                              <h5>History</h5>
                            </Col>
                          </Row>
                          {displayAuditLog && (
                            <>
                              <Row className="mt-2 mb-4">
                                <Col md={4}>
                                  <p className="font-weight-bold d-block">
                                    Created By
                                  </p>
                                  <small className="d-block">{` ${job?.createdBy?.fullName}, 
                            ${job?.createdBy?.email}
                              `}</small>
                                  <small>
                                    {DateTime.fromISO(job?.created).toFormat(
                                      'MMMM dd, yyyy HH:mm'
                                    )}
                                  </small>
                                </Col>
                                <Col md={4}>
                                  <p className="font-weight-bold">
                                    Last Modified By
                                  </p>
                                  {job?.modifiedBy ? (
                                    <>
                                      <small className="d-block">{` ${job?.modifiedBy?.fullName}, ${job?.modifiedBy?.email}`}</small>
                                      <small>
                                        {DateTime.fromISO(
                                          job?.updated
                                        ).toFormat('MMMM dd, yyyy HH:mm')}
                                      </small>
                                    </>
                                  ) : (
                                    <small className="text-secondary d-block">
                                      -
                                    </small>
                                  )}
                                </Col>
                              </Row>
                              <Row className="mt-3">
                                <Col md={12}>
                                  <AuditLog
                                    contentType={job.contentType.model}
                                    id={job.id}
                                  />
                                </Col>
                              </Row>
                            </>
                          )}
                        </>
                      )}
                      {ftpEnabled && (
                        <>
                          <Row className="mt-3">
                            <Col md={12} className="d-flex align-items-center">
                              <button
                                type="button"
                                onClick={() => setDisplayFtp(!displayFtp)}
                                className="px-0 btn-link mr-1"
                                style={{ marginTop: '-10px' }}
                              >
                                <>
                                  {displayFtp ? (
                                    <>
                                      <CaretDown size={20} />
                                    </>
                                  ) : (
                                    <>
                                      <CaretRight size={20} />
                                    </>
                                  )}
                                </>
                              </button>
                              <h5>FTP</h5>
                            </Col>
                          </Row>
                          {displayFtp && <FTPUpload />}
                        </>
                      )}
                    </div>
                  )}
                </Modal.Body>
              </>
            )}
          {showDeleteConfirm && (
            <>
              <Modal.Body>
                <Row>
                  <Col md={6}>
                    <p>
                      Are you sure you want to delete the job? All sessions
                      associated to the job will be deleted. Subjects with
                      upcoming sessions on the job will be notified of the
                      cancellation and refunded.
                    </p>
                  </Col>
                </Row>

                <Button
                  className="mt-2"
                  variant="link"
                  onClick={() => backToDetail()}
                >
                  <ArrowLeftCircle className="mr-2" />
                  Back
                </Button>
                <Button
                  className="mt-2"
                  variant="link"
                  onClick={() => handleDeleteJobConfirmClick()}
                >
                  <Trash className="mr-2" />
                  Delete
                </Button>
              </Modal.Body>
            </>
          )}
          {showCancelConfirm && (
            <>
              <Modal.Body>
                <Row>
                  <Col md={6}>
                    <p>
                      Are you sure you want to cancel the job? All sessions
                      associated to the job will be canceled. Subjects with
                      upcoming sessions will be notified of the cancellation and
                      refunded. Employees will be removed from employee
                      assignments associated to the job.
                    </p>
                  </Col>
                </Row>
                <Button
                  className="mt-2"
                  variant="link"
                  onClick={() => backToDetail()}
                >
                  <ArrowLeftCircle className="mr-2" />
                  Back
                </Button>
                <Button
                  className="mt-2"
                  variant="link"
                  onClick={() => handleCancelJobConfirmClick()}
                >
                  <SlashCircle className="mr-2" />
                  Cancel
                </Button>
              </Modal.Body>
            </>
          )}
          {showEmailJobReport && (
            <>
              <Modal.Body>
                <JobReport jobId={job.id} backToDetail={backToDetail} />
              </Modal.Body>
            </>
          )}
          {showUnCancelConfirm && (
            <>
              <Modal.Body>
                <p>
                  Are you sure you want to uncancel the job? Subjects will be
                  able to book on the job if there are subject groups or package
                  categories associated.
                </p>
                <Button className="mt-2" variant="link" onClick={backToDetail}>
                  <ArrowLeftCircle className="mr-2" />
                  Back
                </Button>
                <Button
                  className="mt-2"
                  variant="link"
                  onClick={() => handleUncancelJobConfirmClick()}
                >
                  <SlashCircle className="mr-2" />
                  Uncancel
                </Button>
              </Modal.Body>
            </>
          )}
          {showNofity && (
            <>
              <Modal.Body>
                <Button className="mt-2" variant="link" onClick={backToDetail}>
                  <ArrowLeftCircle className="mr-2" />
                  Back
                </Button>
                {canMutate && (
                  <>
                    <Button
                      className="mt-2"
                      variant="link"
                      onClick={() => {
                        sendNotificationTrigger({
                          variables: {
                            sendNotificationTriggerInput: {
                              notificationTriggerInput: {
                                contactReminderJobId: job.id,
                              },
                            },
                          },
                        })
                      }}
                    >
                      <Bell className="mr-2" />
                      Send Contact Reminders
                    </Button>
                    <Button
                      className="mt-2"
                      variant="link"
                      onClick={() => {
                        sendNotificationTrigger({
                          variables: {
                            sendNotificationTriggerInput: {
                              notificationTriggerInput: {
                                sessionReminderJobId: job.id,
                              },
                            },
                          },
                        })
                      }}
                    >
                      <Bell className="mr-2" />
                      Send Session Reminders
                    </Button>
                  </>
                )}
                {showNofity && <Notifications job={job} />}
              </Modal.Body>
            </>
          )}
        </Modal>
        <MapModal
          showModal={displayMapModal}
          toggleModal={setDisplayMapModal}
          location={job.location}
        />
      </div>
    </>
  )
}
export default JobDetailModal
