import React, { useState } from 'react'
import { Row, Col, Button, Form, Card } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useParams, useHistory } from 'react-router-dom'
import { useMutation, gql } from '@apollo/client'
import toast from 'react-hot-toast'
import { Lock, BoxArrowInRight } from 'react-bootstrap-icons'

export default function NewPassword() {
  const history = useHistory()
  const [submitting, setSubmitting] = useState(false)
  const { token } = useParams()
  const [mutation] = useMutation(
    gql`
      mutation UpdatePassword(
        $updateGaiaUserPasswordInput: UpdateGaiaUserPasswordInput!
      ) {
        updateGaiaUserPassword(input: $updateGaiaUserPasswordInput) {
          resetPassword
        }
      }
    `,
    {
      onCompleted: (data) => {
        formik.resetForm()
        setSubmitting(false)
        toast.success('Password Reset')
        history.push('/')
      },
      onError: (error) => {
        setSubmitting(false)
        toast.error('Password Reset Expired')
      },
    }
  )
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters long')
        .max(100, 'Password is too long')
        .required('Password is required')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
          'Password must contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character'
        ),
      confirmPassword: Yup.string()
        .min(5, 'Confirm password is too short')
        .max(100, 'Confirm password is too long')
        .required('Confirm password is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      mutation({
        variables: {
          updateGaiaUserPasswordInput: {
            password: values.password,
            passwordResetToken: token,
          },
        },
      })
    },
  })
  return (
    // <Card
    //       className="p-4 shadow-lg border-0 mt-5"
    //       style={{ maxWidth: '600px', margin: '0 auto' }}
    //     >
    //       <Card.Body>
    //         <Row className={'mt-2 mb-3'}>
    //           <Col md={{ span: 8, offset: 2 }} className="text-center">
    //             <h1>Book Session</h1>
    //           </Col>
    //         </Row>
    //         <Row>
    //           <Col md={{ span: 8, offset: 2 }}>
    //             <StudentLogin handleLoggedInStudent={handleStudentLogin} />
    //           </Col>
    //         </Row>
    //       </Card.Body>
    //     </Card>
    <>
      <Card
        className="p-4 shadow-lg border-0 mt-5"
        style={{ maxWidth: '600px', margin: '0 auto' }}
      >
        <Card.Body>
          <Row className="mt-4">
            <Col md={{ span: 8, offset: 2 }} className="text-center">
              <h1>Reset Password</h1>
            </Col>
          </Row>
          <Form onSubmit={formik.handleSubmit} className="mt-3">
            <Row>
              <Col md={{ span: 8, offset: 2 }}>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.password}
                </Form.Control.Feedback>
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 8, offset: 2 }}>
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.confirmPassword}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.confirmPassword}
                </Form.Control.Feedback>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={{ span: 8, offset: 2 }}>
                <Button variant="link" type="submit" disabled={submitting}>
                  <Lock className="mr-2" />
                  Reset Password
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 8, offset: 2 }}>
                <Button onClick={() => history.push('/')} variant="link">
                  <BoxArrowInRight className="mr-2" />
                  Back to Login
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  )
}
