import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { Row, Col, Form } from 'react-bootstrap'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import { Dot } from 'react-bootstrap-icons'
import Loading from '../../common/Loading'
import ServiceItemModal from './ServiceItemModal'

const Tasks = () => {
  const defaultOrderBy = 'description'
  const [orderBy, setOrderBy] = useState(defaultOrderBy)
  const [searchText, setSearchText] = useState()
  const [searching, setSearching] = useState(false)
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [hasMoreData, setHasMoreData] = useState(false)
  const [showServiceModal, setShowServiceModal] = useState(false)
  const [organizationId, setOrganizationId] = useState()
  const [organizationName, setOrganizationName] = useState()
  const [serviceData, setServiceData] = useState('')
  const [tasks, setTasks] = useState([])

  const toggleModalShow = () => {
    setShowServiceModal(!showServiceModal)
    if (serviceData) {
      setOrganizationId()
      setOrganizationName()
      setServiceData({})
    }
  }

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query Tasks($cursor: String, $searchTerm: String, $orderBy: String) {
        serviceItems(
          first: 50
          after: $cursor
          orderBy: $orderBy
          search: $searchTerm
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              description
              notes
              dueDate
              completedByEmployee {
                id
                gaiaUser {
                  fullName
                }
              }
              eventDate
              finishedDate
              status
              organization {
                id
                name
              }
              serviceItemGroup {
                name
                id
              }
              employees {
                edges {
                  node {
                    id
                    gaiaUser {
                      id
                      fullName
                      email
                    }
                  }
                }
              }
              contacts {
                edges {
                  node {
                    id
                    fullName
                    email
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      // pollInterval: 30000,
    }
  )

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query({ orderBy: defaultOrderBy })
    }
  }, [initialQueryRun, setInitialQueryRun])

  const handleQueryData = (queryData) => {
    if (queryData?.serviceItems) {
      setHasMoreData(queryData?.serviceItems?.pageInfo?.hasNextPage)
      const currentTasks = []
      queryData.serviceItems.edges.forEach((task) => {
        const taskNode = task.node
        if (searching || !tasks.some((tasks) => tasks.id === taskNode.id)) {
          currentTasks.push(taskNode)
        }
      })
      if (searching) {
        setTasks(currentTasks)
        setSearching(false)
      } else {
        setTasks((prevState) => {
          const currentIds = new Set(currentTasks.map((item) => item.id))
          const filteredPrevState = prevState.filter(
            (item) => !currentIds.has(item.id)
          )
          return [...filteredPrevState, ...currentTasks]
        })
      }
    }
  }
  useEffect(() => {
    if (queryData?.serviceItems) {
      if (loadingSearch) {
        setLoadingSearch(false)
      }
      handleQueryData(queryData)
      let text = 'Search 0 Tasks'
      if (queryData.serviceItems.nodeCount > 0) {
        text = `Search ${queryData.serviceItems.nodeCount} Tasks`
      }
      setSearchText(text)
    }
  }, [queryData])

  const fetchMore = () => {
    const variables = {
      cursor: queryData?.serviceItems?.pageInfo?.endCursor,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    queryFetchMore({
      variables,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        handleQueryData(fetchMoreResult)
        return fetchMoreResult
      },
    })
  }

  const handleSortByChange = (currentOrderBy) => {
    if (currentOrderBy === '' && orderBy === defaultOrderBy) return

    currentOrderBy = currentOrderBy ? currentOrderBy : defaultOrderBy
    setOrderBy(currentOrderBy)
    const variables = {
      orderBy: currentOrderBy,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    query({ variables })
  }

  const handleSearchTermChange = (event) => {
    setSearching(true)
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    setLoadingSearch(true)
    const variables = {
      searchTerm: currentSearchTerm,
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    query({ variables })
  }

  if (!initialQueryRun)
    return (
      <Row>
        <Col>
          <Loading message="Loading Tasks..." />
        </Col>
      </Row>
    )
  if (queryError) return <>Error loading</>
  return (
    <>
      <div className="mt-4">
        <Row>
          <Col md={4}>
            <Form.Group>
              <Form.Control
                type="text"
                name="searchTerm"
                className="form-control-sm"
                placeholder={searchText}
                value={searchTerm}
                onChange={handleSearchTermChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md="12">
            <SortableInfiniteTable
              rowPointer
              loading={loadingSearch}
              hideGlobalFilter
              fetchMoreTableData={fetchMore}
              loadingMessage="Loading Tasks..."
              trStyleGenerator={(row) => {
                let style = {}
                if (row.original.status === 'Done') {
                  style = {
                    cursor: 'pointer',
                    backgroundColor: 'rgba(0, 128, 0, 0.2)',
                  }
                } else if (row.original.status === 'In Progress') {
                  style = {
                    cursor: 'pointer',
                    backgroundColor: 'rgba(255, 255, 0, 0.2)',
                  }
                } else if (row.original.status === 'To Do') {
                  style = {
                    cursor: 'pointer',
                    backgroundColor: 'rgba(255, 0, 0, 0.2)',
                  }
                }
                return style
              }}
              onRowClick={(row) => {
                toggleModalShow()
                setOrganizationId(row.original.organization.id)
                setOrganizationName(row.original.organization.name)
                setServiceData(row.original)
              }}
              tableData={tasks}
              tableColumns={[
                {
                  Header: 'Organization',
                  accessor: 'organization.name',
                  serverSort: true,
                  orderBy: 'organization__name',
                },
                {
                  Header: 'Status',
                  accessor: 'status',
                  serverSort: true,
                },
                {
                  Header: 'Collection',
                  accessor: 'serviceItemGroup.name',
                  filter: 'includes',
                  serverSort: true,
                  orderBy: 'service_item_group__name',
                },
                {
                  Header: 'Description',
                  accessor: 'description',
                  serverSort: true,
                },
                {
                  Header: 'Event Date',
                  accessor: 'eventDate',
                  serverSort: true,
                  orderBy: 'event_date',
                },
                {
                  Header: 'Due Date',
                  accessor: 'dueDate',
                  serverSort: true,
                  orderBy: 'due_date',
                },
                {
                  Header: 'Finished Date',
                  accessor: 'finishedDate',
                  serverSort: true,
                  orderBy: 'finished_date',
                },
                {
                  Header: 'Completed By',
                  accessor: (row) => {
                    if (row.completedByEmployee) {
                      return row.completedByEmployee.gaiaUser.fullName
                    }
                  },
                  serverSort: true,
                  orderBy: 'completed_by_employee__gaia_user__full_name',
                },
                {
                  Header: 'Employees',
                  accessor: (row) => (
                    <>
                      {row.employees.edges.map((employee) => (
                        <div key={employee.node.id}>
                          <p>
                            <Dot className="mr-1" />
                            <span
                              style={{
                                fontSize: '12px',
                              }}
                            >
                              {employee.node.gaiaUser.fullName},{' '}
                              {employee.node.gaiaUser.email}
                            </span>
                          </p>
                        </div>
                      ))}
                    </>
                  ),
                },
                {
                  Header: 'Contacts',
                  accessor: (row) => (
                    <>
                      {row.contacts.edges.map((contact) => (
                        <div key={contact.node.id}>
                          <p>
                            <Dot className="mr-1" />
                            <span
                              style={{
                                fontSize: '12px',
                              }}
                            >
                              {contact.node.fullName}, {contact.node.email}
                            </span>
                          </p>
                        </div>
                      ))}
                    </>
                  ),
                },
              ]}
              hasMoreTableData={hasMoreData}
              tableHeight={600}
              handleSortByChange={handleSortByChange}
            />
          </Col>
        </Row>
      </div>
      <ServiceItemModal
        showModal={showServiceModal}
        toggleModal={toggleModalShow}
        updateServiceItem={serviceData}
        organizationId={organizationId}
        organizationName={organizationName}
      />
    </>
  )
}
export default Tasks
