import React, { useState, useEffect } from 'react'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {
  ClockHistory,
  Camera,
  Trash,
  CheckCircle,
  SlashCircle,
  ArrowLeft,
  PlusCircle,
  CaretDown,
  CaretRight,
} from 'react-bootstrap-icons'
import { DateTime } from 'luxon'
import { Formik, Field, ErrorMessage } from 'formik'
import {
  Form,
  Button,
  Col,
  Modal,
  Badge,
  Row,
  ButtonGroup,
} from 'react-bootstrap'
import {
  gql,
  useMutation,
  useQuery,
  useLazyQuery,
  useReactiveVar,
} from '@apollo/client'
import toast from 'react-hot-toast'
import JobSessions from './JobSessions'
import EmployeeAssignments from './EmployeeAssignments'
import Buffer from './Buffer'
import Bookable from './Bookable'
import JobTypeModal from './JobType'
import { schema } from './schema'
import OrgContacts from './OrgContacts'
import AdHocContacts from './AdHocContacts'
import Loading from '../../common/Loading'
import { loggedInUserVar } from '../../../libs/apollo'
import { v4 as uuidv4 } from 'uuid'
import { client } from '../../../libs/apollo'
import LocationField from '../../location/LocationField'
import JobHistoryModal from './JobHistoryModal'
import JobEquipmentBagTypes from './job_equipment_bag_types/JobEquipmentBagTypes'
import JobEquipmentItemTypes from './job_equipment_item_types/JobEquipmentItemTypes'
import MultiDayJob from './MultiDayJob'
import JobStageSearchInput from '../../common/node_search_input/JobStageSearchInput'
import JobStage from './JobStage'
import SortableTable from '../../common/SortableTable'
import { useFolderConfiguration } from '../../../libs/folderConfiguration'

export default function Job(props) {
  const location = useLocation()
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const folderConfiguration = useFolderConfiguration()
  const jobFormType = location.state?.jobFormType
    ? location.state.jobFormType
    : 'manual'
  const schedulingAnalystCreating =
    jobFormType === 'subject' &&
    loggedInUser?.permissions?.group === 'Scheduling Analyst'
  const canMutate =
    ['Scheduling Manager', 'Administrator'].includes(
      loggedInUser?.permissions?.group
    ) || schedulingAnalystCreating
  const canSeeRequiredBagTypes = [
    'Administrator',
    'Scheduling Manager',
    'Equipment Manager',
  ].includes(loggedInUser?.permissions?.group)
  let bookingFlowSubjectId
  let bookingFlowPackageId
  let bookingFlowSubjectGroupId
  let bookingFlowPackageCategoryId
  let bookingFlowRescheduleSessionId
  let bookingFlowChangePackageSessionId
  let bookingFlowResitSessionId
  let bookingFlowResitReasonId
  let redirectAfterJobSave
  let bookingFlowReschedulingNoShow
  if (props.history?.location?.state) {
    redirectAfterJobSave = props.history.location.state.redirectAfterJobSave
    bookingFlowSubjectId = props.history.location.state.bookingFlowSubjectId
    bookingFlowPackageId = props.history.location.state.bookingFlowPackageId
    bookingFlowSubjectGroupId =
      props.history.location.state.bookingFlowSubjectGroupId
    bookingFlowPackageCategoryId =
      props.history.location.state.bookingFlowPackageCategoryId
    bookingFlowRescheduleSessionId =
      props.history.location.state.bookingFlowRescheduleSessionId
    bookingFlowChangePackageSessionId =
      props.history.location.state.bookingFlowChangePackageSessionId
    bookingFlowResitSessionId =
      props.history.location.state.bookingFlowResitSessionId
    bookingFlowResitReasonId =
      props.history.location.state.bookingFlowResitReasonId
    bookingFlowReschedulingNoShow =
      props.history.location.state.bookingFlowReschedulingNoShow
  }
  const { jobDetailSessions } = props
  const [updateFromJobDetail, setUpdateFromJobDetail] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [delayCreateSessions, setDelayCreateSessions] = useState(false)
  const [updateFromJobDetailLoading, setUpdateFromJobDetailLoading] =
    useState(false)
  // poor workaround for jobSessionsAvailable errors not maintaining in formik state
  const [conflictingTimesErrors, setConflictingTimesErrors] = useState({})
  const [displayCancelledSessions, setDisplayCancelled] = useState(false)
  const [initialSessionQueryRun, setInitialSessionsQueryRun] = useState(false)
  const [displayNoShowSessions, setDisplayNoShowSessions] = useState(false)
  const [applyToAllJobs, setApplyToAllJobs] = useState(false)
  const { employeeAssignments, parentJobId, toggleModal } = props
  let { jobId } = useParams()
  if (!jobId && props.jobId) {
    jobId = props.jobId
  }
  const [checkedMultiDayJobs, setCheckedMultiDayJobs] = useState([])
  const [makeManualJob, setMakeManualJob] = useState(false)
  const [initialJobDate, setInitialJobDate] = useState(new Date())
  const [showSessionSetupError, setShowSessionSetupError] = useState(false)
  const [displaySessions, setDisplaySessions] = useState(true)
  const [displayStaff, setDisplayStaff] = useState(true)
  const [displayBuffers, setDisplayBuffers] = useState(true)
  const [showJobTypeModal, setShowJobTypeModal] = useState(false)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [showCancelConfirm, setShowCancelConfirm] = useState(false)
  const [showUnCancelConfirm, setShowUnCancelConfirm] = useState(false)
  const [savingJobComplete, setSavingJobComplete] = useState(false)
  const [savingJobNotComplete, setSavingJobNotComplete] = useState(false)
  const [savingJobCancelled, setSavingJobCancelled] = useState(false)
  const [displayFiles, setDisplayFiles] = useState(false)
  const [displayNotes, setDisplayNotes] = useState(false)

  const [displayBookable, setDisplayBookable] = useState(false)
  const [savingJobUncancelled, setSavingJobUncancelled] = useState(false)
  const [isEditJob, setIsEditJob] = useState(false)
  const history = useHistory()
  const [showJobHistoryModal, setShowJobHistoryModal] = useState(false)
  const [showJobStageModal, setShowJobStageModal] = useState(false)
  const [showUncompleteConfirm, setShowUncompleteConfirm] = useState(false)
  const [showCompleteConfirm, setShowCompleteConfirm] = useState(false)

  const [deleteJobMutation] = useMutation(
    gql`
      mutation DeleteJob($deleteJobInput: DeleteJobInput!) {
        deleteJob(input: $deleteJobInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: (data) => {
        toast.success('Job Deleted')
        history.push('/schedule')
      },
      refetchQueries: ['ScheduleJobsQuery'],
      errorPolicy: 'all',
    }
  )

  const [getJob, { data: editJobData }] = useLazyQuery(
    gql`
      query Job($jobId: ID!) {
        job(id: $jobId) {
          updated
          bookingStartDateTime
          bookingEndDateTime
          created
          sharedCanSeeFiles
          sharedCanCreateFiles
          sharedCanCreateFolders
          sessionSharedCanSeeFiles
          sessionSharedCanCreateFiles
          sessionSharedCanCreateFolders
          cancelled
          completed
          stage {
            id
          }
          multiDayJob {
            id
            copySessions
            jobs {
              edges {
                node {
                  id
                  name
                  location {
                    fullAddress
                  }
                  endDateTime
                  startDateTime
                }
              }
            }
          }
          jobEquipmentBagTypes {
            edges {
              node {
                id
                equipmentBagType {
                  name
                  id
                }
                requiredQuantity
                includedQuantity
              }
            }
          }
          jobEquipmentItemTypes {
            edges {
              node {
                id
                equipmentItemType {
                  name
                  id
                }
                requiredQuantity
                includedQuantity
              }
            }
          }
          notificationsEnabled
          contacts {
            edges {
              node {
                id
                fullName
                email
                phoneNumber
              }
            }
          }
          contentType {
            model
          }
          modifiedBy {
            email
            fullName
          }
          createdBy {
            email
            fullName
          }
          adHocContacts {
            edges {
              node {
                id
                email
                secondaryEmail
                phoneNumber
                secondaryPhoneNumber
                firstName
                lastName
                notes
              }
            }
          }
          completed
          location {
            state
            archived
            addressLineOne
            addressLineTwo
            city
            salesTax
            id
            name
            organization {
              id
              name
              subjectGroups {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
            studio
            zipCode
          }
          breaks {
            edges {
              node {
                id
                endDateTime
                startDateTime
              }
            }
          }
          employeeJobs {
            edges {
              node {
                hourlyPay
                confirmed
                endDateTime
                startDateTime
                equipmentPickupArea
                equipmentBags {
                  edges {
                    node {
                      id
                      name
                      returned
                      equipmentItemTypeIds
                      equipmentBagType {
                        name
                        id
                      }
                      employee {
                        id
                      }
                    }
                  }
                }
                equipmentItems {
                  edges {
                    node {
                      id
                      name
                      returned
                      equipmentItemType {
                        id
                        name
                      }
                      employee {
                        id
                      }
                    }
                  }
                }
                id
                role {
                  id
                }
                employee {
                  id
                  gaiaUser {
                    fullName
                  }
                }
              }
            }
          }
          endDateTime
          id
          jobType {
            id
          }
          name
          notes
          subjectNotes
          packageCategoryJobs {
            edges {
              node {
                id
                packageCategory {
                  name
                  id
                }
                sessionPackages {
                  edges {
                    node {
                      id
                      title
                    }
                  }
                }
              }
            }
            nodeCount
          }
          packageGroupJobs {
            edges {
              node {
                id
                sessionPackages {
                  edges {
                    node {
                      id
                      title
                    }
                  }
                }
                subjectGroup {
                  name
                  id
                  startDateTime
                  endDateTime
                  organization {
                    subjectGroups {
                      edges {
                        node {
                          id
                          name
                        }
                      }
                    }
                    contacts {
                      edges {
                        node {
                          id
                          fullName
                          email
                          phoneNumber
                        }
                      }
                    }
                  }
                }
              }
            }
            nodeCount
          }
          setups
          startDateTime
          metadata
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const [updateJob, { loading: loadingJobData }] = useMutation(
    gql`
      mutation UpdateJob($input: UpdateJobInput!) {
        updateJob(input: $input) {
          job {
            id
          }
        }
      }
    `,
    {
      onCompleted(data) {
        setSubmitting(false)
        if (showUnCancelConfirm) {
          setShowUnCancelConfirm(false)
        }
        if (showCancelConfirm) {
          setShowCancelConfirm(false)
        }
        if (showCompleteConfirm) {
          setShowCompleteConfirm(false)
        }
        if (showUncompleteConfirm) {
          setShowUncompleteConfirm(false)
        }
        if (showUnCancelConfirm) {
          setShowUnCancelConfirm(false)
        }
        setCheckedMultiDayJobs([])
        if (updateFromJobDetail) {
          setUpdateFromJobDetail(false)
          setUpdateFromJobDetailLoading(false)
        }
        if (savingJobComplete) {
          toast.success('Job Complete')
          setSavingJobComplete(false)
        } else if (savingJobNotComplete) {
          toast.success('Job Not Complete')
          setSavingJobNotComplete(false)
        } else if (savingJobCancelled) {
          toast.success('Job Cancelled')
          setSavingJobCancelled(false)
          setShowCancelConfirm(false)
        } else if (savingJobUncancelled) {
          toast.success('Job Uncancelled')
          setSavingJobUncancelled(false)
          setShowUnCancelConfirm(false)
        } else if (jobDetailSessions) {
          if (delayCreateSessions) {
            setDelayCreateSessions(false)
            toast.success('Sessions Saving')
          } else {
            toast.success('Sessions Saved')
          }
        } else {
          if (delayCreateSessions) {
            setDelayCreateSessions(false)
          }
          toast.success('Job Saved')
          if (toggleModal) {
            toggleModal()
          } else if (redirectAfterJobSave) {
            history.push(redirectAfterJobSave)
          } else {
            history.push('/schedule')
          }
        }
      },
      refetchQueries: [
        'ScheduleJobsQuery',
        'Job',
        'JobDetailSessionsQuery',
        'LocationSchedule',
      ],
    }
  )

  const { data: settingsData, loading: settingsLoading } = useQuery(
    gql`
      query StudioSettingsQuery {
        settings {
          edges {
            node {
              id
              noShowFee
              applyNoShowPolicyFree
              applyNoShowPolicyPaid
              applyPolicyFree
              applyPolicyPaid
              refundPolicy
              chargeStateSalesTax
              timeRefundHours
              timeRefundFee
              timeRefundSessionPackageCost
              sessionStages
              jobStages
            }
          }
        }
      }
    `,
    { fetchPolicy: 'network-only' }
  )

  const [createJob, { loading: loadingJobCreate }] = useMutation(
    gql`
      mutation CreateJob($input: CreateJobInput!) {
        createJob(input: $input) {
          job {
            id
            name
          }
        }
      }
    `,
    {
      onCompleted(data) {
        setSubmitting(false)
        if (updateFromJobDetail) {
          setUpdateFromJobDetail(false)
          setUpdateFromJobDetailLoading(false)
        }
        if (delayCreateSessions) {
          setDelayCreateSessions(false)
          toast.success('Job Saved and Sessions Will Complete Saving Shortly')
        } else {
          toast.success('Job Saved')
        }
        if (bookingFlowSubjectId) {
          history.push(`/subject/${bookingFlowSubjectId}`)
        } else if (redirectAfterJobSave) {
          history.push(redirectAfterJobSave)
        } else {
          history.push('/schedule')
        }
      },
      refetchQueries: ['ScheduleJobsQuery'],
    }
  )

  const { data: jobTypeData, loading: jobTypeLoading } = useQuery(
    gql`
      query JobTypes {
        jobTypes(orderBy: "name") {
          edges {
            node {
              id
              name
              color
            }
          }
        }
      }
    `,
    { fetchPolicy: 'network-only' }
  )

  const [getSessions, { data: getSessionData, fetchMore: fetchMoreSessions }] =
    useLazyQuery(
      gql`
        query Sessions($after: String, $job: ID, $first: Int) {
          sessions(
            after: $after
            first: $first
            job: $job
            cancelled: false
            rescheduled: false
            sessionPackageChanged: false
            orderBy: "start_date_time"
          ) {
            nodeCount
            pageInfo {
              endCursor
              hasNextPage
            }
            edges {
              cursor
              node {
                id
                stage {
                  id
                }
                sharedCanSeeFiles
                sharedCanCreateFiles
                sharedCanCreateFolders
                stripePaymentIntents {
                  nodeCount
                  edges {
                    node {
                      stripePaymentMethod {
                        id
                        stripeResource
                      }
                    }
                  }
                }
                sessionPackagePrice
                subjectGroupSubject {
                  metadata
                }
                sessionPackagePrice
                salesTax
                couponSavings
                price
                completed
                allPreviousSessions {
                  id
                  job {
                    id
                    name
                    startDateTime
                  }
                  rescheduled
                  waiveRescheduleCancelFee
                  sessionPackageChanged
                  sessionPackage {
                    id
                    title
                  }
                  resitReason {
                    reason
                  }
                  resitScheduled
                }
                allFutureSessions {
                  id
                  job {
                    id
                    name
                  }
                  rescheduled
                  waiveRescheduleCancelFee
                  resitScheduled
                }
                futureSession {
                  id
                  resitScheduled
                  rescheduled
                  job {
                    id
                  }
                }
                billSubject
                coupon {
                  id
                  code
                  dollarSavings
                  percentSavings
                }
                notes
                organization {
                  id
                  name
                  subjectGroups {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                  contacts {
                    edges {
                      node {
                        id
                        fullName
                        email
                        phoneNumber
                      }
                    }
                  }
                }
                subject {
                  id
                  subjectGroups {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                  organization {
                    id
                    name
                    contacts {
                      edges {
                        node {
                          id
                          email
                          fullName
                          phoneNumber
                        }
                      }
                    }
                  }
                  subjectGroupsNotBooked {
                    subjectGroup {
                      id
                      name
                    }
                  }
                  gaiaUser {
                    fullName
                    stripeCustomer {
                      stripePaymentMethods(primary: true) {
                        edges {
                          node {
                            stripeResource
                            id
                          }
                        }
                      }
                    }
                  }
                  studentId
                }
                startDateTime
                endDateTime
                endDateTime
                subjectGroup {
                  id
                  name
                }
                cancelled
                waiveNoShowFee
                waiveBookingFee
                waiveRescheduleCancelFee
                noShow
                endDateTime
                metadata
                rescheduled
                resitScheduled
                frameNumber
                subjectDescription
                employee {
                  id
                }
                sessionPackage {
                  id
                  customPriceAndDuration
                  durationHighMinutes
                  durationLowMinutes
                  price
                  title
                }
              }
            }
          }
        }
      `,
      {
        fetchPolicy: 'network-only',
      }
    )
  useEffect(() => {
    if (jobId) {
      getJob({
        variables: {
          jobId,
        },
      })
      getSessions({
        variables: {
          after: null,
          first: 10,
          job: jobId,
        },
      })
      setInitialSessionsQueryRun(true)
    }
  }, [jobId])

  useEffect(() => {
    if (parentJobId) {
      getJob({
        variables: {
          jobId: parentJobId,
        },
      })
    }
  }, [parentJobId])

  useEffect(() => {
    if (
      editJobData &&
      !editJobData?.job?.location.organization &&
      !editJobData?.job?.location.studio
    ) {
      setIsEditJob(true)
    }
  }, [editJobData])

  useEffect(() => {
    if (editJobData) {
      setIsEditJob(true)
    }
  }, [editJobData])

  // variables determining display
  const copyJob = location ? location.state?.copyJob : false

  const isBookableJob =
    makeManualJob || jobFormType === 'manual' || jobFormType === 'bookable'

  const displaySessionSection = !(jobFormType === 'bookable') || makeManualJob

  if (
    !folderConfiguration ||
    settingsLoading ||
    jobTypeLoading ||
    !settingsData?.settings ||
    ((jobId || parentJobId) && !editJobData)
  ) {
    return (
      <div className="mt-4">
        {!jobDetailSessions && <Loading message="Loading Job..." />}
      </div>
    )
  }

  const settings = settingsData.settings.edges[0].node

  const handleFetchMoreSessions = () => {
    fetchMoreSessions({
      variables: {
        after: getSessionData.sessions.pageInfo.endCursor,
        first: 20,
        job: jobId,
      },
    })
  }

  const formatAddress = (locationNode) => {
    let addressName = ''
    if (locationNode.studio === true || locationNode.organization) {
      addressName = locationNode.name ? `${locationNode.name}, ` : ''
    }
    const orgName = locationNode.organization?.name
      ? `, ${locationNode.organization.name}`
      : ''
    const addressLineTwo = locationNode.addressLineTwo
      ? `${locationNode.addressLineTwo}, `
      : ''
    const displayAddress = `${addressName}${locationNode.addressLineOne}, ${addressLineTwo}${locationNode.city}, ${locationNode.state}, ${locationNode.zipCode} ${orgName}`

    return displayAddress
  }

  const handleJobStartChange = (e, formik) => {
    if (
      formik.values.sessions.length === 1 &&
      !(formik.touched?.sessions && formik.touched?.sessions[0].startTime)
    ) {
      formik.setFieldValue('sessions[0].startTime', e.target.value)
    }
    formik.setFieldValue('jobStartTime', e.target.value)
  }

  const jobTypeModalShowChange = () => {
    setShowJobTypeModal((prevState) => !prevState)
  }

  const toggleJobHistoryModal = () => {
    setShowJobHistoryModal((prevState) => !prevState)
  }

  const toggleShowDeleteConfirm = () => {
    setShowDeleteConfirm((prevState) => !prevState)
  }
  const toggleShowCancelConfirm = () => {
    setShowCancelConfirm((prevState) => !prevState)
  }
  const toggleShowUnCancelConfirm = () => {
    setShowUnCancelConfirm((prevState) => !prevState)
  }

  const toggleShowCompleteConfirm = () => {
    setShowCompleteConfirm((prevState) => !prevState)
  }

  const toggleShowUncompleteConfirm = () => {
    setShowUncompleteConfirm((prevState) => !prevState)
  }

  const handleJobComplete = () => {
    setSavingJobComplete(true)
    updateJob({
      variables: {
        input: {
          jobInput: {
            id: jobId,
            multiDayJobIds: checkedMultiDayJobs,
            completed: true,
          },
        },
      },
    })
  }

  const handleJobNotComplete = () => {
    setSavingJobNotComplete(true)
    updateJob({
      variables: {
        input: {
          jobInput: {
            id: jobId,
            multiDayJobIds: checkedMultiDayJobs,
            completed: false,
          },
        },
      },
    })
  }

  const handleDeleteJobConfirmClick = () => {
    deleteJobMutation({
      variables: {
        deleteJobInput: {
          jobIds: [jobId, ...checkedMultiDayJobs],
        },
      },
    })
  }

  const handleCancelJobConfirmClick = () => {
    setSavingJobCancelled(true)
    updateJob({
      variables: {
        input: {
          jobInput: {
            id: jobId,
            multiDayJobIds: checkedMultiDayJobs,
            cancelled: true,
          },
        },
      },
    })
  }

  const handleUncancelJobConfirmClick = () => {
    setSavingJobUncancelled(true)
    updateJob({
      variables: {
        input: {
          jobInput: {
            id: jobId,
            multiDayJobIds: checkedMultiDayJobs,
            cancelled: false,
          },
        },
      },
    })
  }

  // + Add Session values<
  const newSessionValues = {
    newSession: true,
    subjectSession: true,
    metadata: {},
    stripePaymentMethodResource: {},
    subjectId: '',
    codesToDisplay: [],
    subjectGroupId: '',
    subjectName: '',
    organizationId: '',
    organizationName: '',
    finalDollarAmount: 0,
    ogFinalDollarAmount: 0,
    sessionPackageId: '',
    sessionPackageName: '',
    sessionPackagePrice: 0,
    displayPromo: false,
    sessionOrgId: '',
    sessionOrgName: '',
    couponId: '',
    startTime: '',
    ogStartTime: '',
    billSubject: true,
    subjectGroupsNotBooked: [],
    subjectOrgName: '',
    subjectOrgId: '',
    ogSubjectOrgName: '',
    ogSubjectOrgId: '',
    waiveBookingFee: false,
    waiveRescheduleCancelFee: false,
    waiveNoShowFee: false,
    ogSeshPackageId: '',
    ogSeshPackageName: '',
    ogSeshPackagePrice: 0,
    ogPromoCodeId: '',
    ogbillSubject: false,
    futureSession: null,
    previousSession: null,
    cancelled: false,
    noShow: false,
    rescheduled: false,
    resitScheduled: false,
    ogStripeResource: null,
    ogWaiveBookingFee: false,
    ogBillSubject: false,
    allFutureSessions: [],
    sharedCanSeeFiles: editJobData?.job
      ? editJobData.job?.sessionSharedCanSeeFiles
      : folderConfiguration.sessionSharedCanSeeFiles,
    sharedCanCreateFiles: editJobData?.job
      ? editJobData.job?.sessionSharedCanCreateFiles
      : folderConfiguration.sessionSharedCanCreateFiles,
    sharedCanCreateFolders: editJobData?.job
      ? editJobData.job?.sessionSharedCanCreateFolders
      : folderConfiguration.sessionSharedCanCreateFolders,
    allPreviousSessions: [],
    employeeId: '',
    frameNumber: '',
    subjectDescription: '',
    stageId: '',
  }

  const initialSessionsValue =
    jobFormType === 'manual' || jobFormType === 'bookable'
      ? []
      : [newSessionValues]

  const transformedEmployeeJobs =
    editJobData &&
    editJobData.job &&
    editJobData.job.employeeJobs?.edges.length > 0
      ? editJobData.job.employeeJobs.edges.map((empJob) => {
          return {
            id: empJob.node.id,
            hourlyPay: empJob.node.hourlyPay,
            sendNotification: false,
            sendEquipmentNotification: false,
            confirmed: empJob.node.confirmed,
            roleId: empJob.node.role?.id,
            empJobEndTime: empJob.node.endDateTime
              ? DateTime.fromISO(empJob.node.endDateTime).toFormat('h:mma')
              : '',
            empJobStartTime: empJob.node.startDateTime
              ? DateTime.fromISO(empJob.node.startDateTime).toFormat('h:mma')
              : '',
            employeeId: copyJob ? '' : empJob.node.employee?.id ?? '',
            employeeName: copyJob
              ? ''
              : empJob.node.employee?.gaiaUser?.fullName ?? '',
            equipmentPickupArea: copyJob
              ? ''
              : empJob.node.equipmentPickupArea ?? '',
            equipmentBags: empJob.node.equipmentBags.edges.map((edge) => {
              return {
                id: edge.node.id,
                returned: edge.node.returned,
                name: edge.node.name,
                longTermAssignment: edge.node.employee ? true : false,
                equipmentBagType: edge.node.equipmentBagType,
                equipmentItemTypeIds: edge.node.equipmentItemTypeIds,
              }
            }),
            equipmentItems: empJob.node.equipmentItems.edges.map((edge) => {
              return {
                id: edge.node.id,
                name: edge.node.name,
                returned: edge.node.returned,
                longTermAssignment: edge.node.employee ? true : false,
                equipmentItemType: edge.node.equipmentItemType,
              }
            }),
          }
        })
      : []

  const transformedPackageGroupJobs =
    editJobData &&
    editJobData.job &&
    editJobData.job.packageGroupJobs?.edges.length > 0
      ? editJobData.job.packageGroupJobs.edges.map((pgj) => {
          const sessionPackages = pgj.node.sessionPackages.edges.map(
            (seshPackage) => ({
              id: seshPackage.node.id,
              title: seshPackage.node.title,
            })
          )
          return {
            node: pgj.node.subjectGroup,
            subjectGroupId: pgj.node.subjectGroup.id,
            name: pgj.node.subjectGroup.name,
            startDateTime: pgj.node.subjectGroup.startDateTime,
            endDateTime: pgj.node.subjectGroup.endDateTime,
            packages: sessionPackages,
            orgContacts: pgj.node.subjectGroup.organization.contacts
              ? pgj.node.subjectGroup.organization.contacts.edges.map(
                  (contact) => ({
                    id: contact.node.id,
                    email: contact.node.email,
                    fullName: contact.node.fullName,
                    phoneNumber: contact.node.phoneNumber
                      ? contact.node.phoneNumber
                      : '',
                  })
                )
              : [],
          }
        })
      : []

  const transformedBreaks =
    editJobData && editJobData.job && editJobData.job.breaks?.edges.length > 0
      ? editJobData.job.breaks.edges.map((jobBreak) => {
          const startDateTime = DateTime.fromISO(
            jobBreak.node.startDateTime
          ).toFormat('h:mma')
          const endDateTime = DateTime.fromISO(
            jobBreak.node.endDateTime
          ).toFormat('h:mma')
          return { id: jobBreak.node.id, startDateTime, endDateTime }
        })
      : []

  const transformedCategories =
    editJobData &&
    editJobData.job &&
    editJobData.job.packageCategoryJobs?.edges.length > 0
      ? editJobData.job.packageCategoryJobs.edges.map((catNode) => {
          const { node } = catNode
          const sessionPackages = node.sessionPackages.edges.map(
            (seshPackage) => ({
              id: seshPackage.node.id,
              title: seshPackage.node.title,
            })
          )
          return {
            id: node.packageCategory.id,
            name: node.packageCategory.name,
            packages: sessionPackages,
          }
        })
      : []

  const transformedAdHocContacts =
    editJobData &&
    editJobData.job &&
    editJobData.job.adHocContacts?.edges.length > 0
      ? editJobData.job.adHocContacts.edges.map((contactNode) => {
          const { node } = contactNode
          return {
            id: node.id,
            primaryEmail: node.email,
            secondaryEmail: node.secondaryEmail,
            primaryPhone: node.phoneNumber,
            secondaryPhone: node.secondaryPhoneNumber,
            firstName: node.firstName,
            lastName: node.lastName,
            notes: node.notes,
          }
        })
      : []

  const transformedSessions =
    getSessionData && getSessionData.sessions.edges.length > 0
      ? getSessionData.sessions.edges.map((session) => {
          const { node } = session
          let stripePaymentMethodResource
          if (node.subject?.gaiaUser?.stripeCustomer) {
            const stripePaymentMethodNodes =
              node.subject.gaiaUser.stripeCustomer.stripePaymentMethods
            if (stripePaymentMethodNodes.edges.length > 0) {
              stripePaymentMethodResource = JSON.parse(
                stripePaymentMethodNodes.edges[
                  stripePaymentMethodNodes.edges.length - 1
                ].node.stripeResource
              )
            }
          }
          let ogStripeResource
          const lastIntent =
            node?.stripePaymentIntents &&
            node.stripePaymentIntents.nodeCount > 0
              ? node.stripePaymentIntents.edges[
                  node.stripePaymentIntents.nodeCount - 1
                ]
              : null
          if (lastIntent) {
            const stripeResource =
              lastIntent.node.stripePaymentMethod.stripeResource
            ogStripeResource = JSON.parse(stripeResource)
          }
          const startTime = DateTime.fromISO(node.startDateTime).toFormat(
            'h:mma'
          )
          const endTime = DateTime.fromISO(node.endDateTime).toFormat('h:mma')

          const packageDisplay = `${node.sessionPackage.title}, ${node.sessionPackage.durationLowMinutes}-${node.sessionPackage.durationLowMinutes} minutes, $${node.sessionPackage.price}`
          const formatCoupon = (couponNode) => {
            if (couponNode?.id) {
              const name = couponNode.code
              let amountOff
              if (couponNode.dollarSavings) {
                amountOff = `$${couponNode.dollarSavings}`
              } else {
                amountOff = `${couponNode.percentSavings}%`
              }
              return `${name}, ${amountOff}`
            } else {
              return ''
            }
          }

          const ogCouponDisplay = formatCoupon(node.coupon)
          const orgGroups = node.organization?.subjectGroups
            ? node.organization.subjectGroups.edges.map((g) => {
                return {
                  subjectGroup: {
                    id: g.node.id,
                    name: g.node.name,
                  },
                }
              })
            : []

          const organizationSubjectGroups = node.subjectGroup?.id
            ? [
                ...orgGroups.filter(
                  (g) => g.subjectGroup.id !== node.subjectGroup.id
                ),
                {
                  subjectGroup: {
                    id: node.subjectGroup.id,
                    name: node.subjectGroup.name,
                  },
                },
              ]
            : orgGroups
          let metadata
          if (node.subjectGroupSubject) {
            metadata = node.subjectGroupSubject.metadata
              ? JSON.parse(node.subjectGroupSubject.metadata)
              : {}
          } else {
            metadata = node.metadata ? JSON.parse(node.metadata) : {}
          }
          return {
            id: copyJob ? null : node.id,
            customSessionPackage: node.sessionPackage.customPriceAndDuration,
            orgContacts:
              !node.subject && node.organization?.contacts
                ? node.organization.contacts.edges.map((contact) => ({
                    id: contact.node.id,
                    email: contact.node.email,
                    fullName: contact.node.fullName,
                    phoneNumber: contact.node.phoneNumber
                      ? contact.node.phoneNumber
                      : '',
                  }))
                : [],
            allPreviousSessions: node.allPreviousSessions,
            allFutureSessions: node.allFutureSessions,
            sessionId: copyJob ? null : node.id,
            newSession: copyJob ? true : false,
            subjectOrgContacts: node.subject?.organization?.contacts,
            stripePaymentMethodResource: stripePaymentMethodResource,
            subjectSession: node?.subject?.id ? true : false,
            subjectId: node?.subject?.id ?? '',
            codesToDisplay: [],
            couponSavings: node.couponSavings > 0 ? node.couponSavings : null,
            finalDollarAmount: node.price,
            salesTax: node.salesTax,
            sharedCanCreateFiles: node.sharedCanCreateFiles,
            sharedCanCreateFolders: node.sharedCanCreateFolders,
            sharedCanSeeFiles: node.sharedCanSeeFiles,
            sessionPackageDuration:
              node.sessionPackage?.durationHighMinutes ?? null,
            subjectGroupId: node.subjectGroup?.id ?? '',
            subjectGroupName: node.subjectGroup?.name ?? '',
            subjectName: node.subject?.gaiaUser?.fullName,
            organizationId: node.organization?.id ?? '',
            organizationName: node.organization?.name ?? '',
            ogFinalDollarAmount: node.price,
            sessionPackageId: node.sessionPackage.id,
            sessionPackageName: packageDisplay,
            sessionPackagePrice: node.sessionPackagePrice,
            displayPromo: false,
            couponId: '',
            completed: copyJob ? false : node.completed,
            ogCouponDisplay: ogCouponDisplay,
            startTime: startTime,
            ogStartTime: startTime,
            endTime: endTime,
            ogEndTime: endTime,
            billSubject: node.billSubject,
            metadata: metadata,
            organization: node.subject?.organization,
            subjectGroupsNotBooked: node.subject
              ? node.subject.subjectGroups.edges.map((edge) => {
                  return {
                    subjectGroup: {
                      id: edge.node.id,
                      name: edge.node.name,
                    },
                  }
                })
              : node.organization
              ? organizationSubjectGroups
              : [],
            subjectOrgName: node.subject?.organization?.name ?? '',
            subjectOrgId: node.subject?.organization?.id ?? '',
            sessionOrgId: node.organization?.id ?? '',
            sessionOrgName: node.organization?.name ?? '',
            ogSubjectOrgName: node.subject?.organization?.name ?? '',
            ogSubjectOrgId: node.subject?.organization?.id ?? '',
            waiveBookingFee: false,
            waiveRescheduleCancelFee: node.waiveRescheduleCancelFee,
            waiveNoShowFee: node.waiveNoShowFee,
            ogSeshPackageId: node.sessionPackage.id,
            ogSeshPackageName: packageDisplay,
            ogSeshPackagePrice: node.sessionPackage.price,
            ogCouponId: node.coupon?.id ?? '',
            ogbillSubject: node.billSubject,
            ogSubjectGroupId: node.subjectGroup?.id ?? '',
            ogSubjectGroupName: node.subjectGroup?.name ?? '',
            futureSession: node.futureSession,
            previousSession: node.previousSession,
            cancelled: copyJob ? false : node.cancelled,
            noShow: copyJob ? false : node.noShow,
            rescheduled: copyJob ? false : node.rescheduled,
            resitScheduled: copyJob ? false : node.resitScheduled,
            ogStripeResource: ogStripeResource,
            ogWaiveBookingFee: node.waiveBookingFee,
            ogWaiveNoShowFee: node.waiveNoShowFee,
            ogWaiveRescheduleCancelFee: node.waiveRescheduleCancelFee,
            ogBillSubject: node.billSubject,
            cancellingSession: false,
            noShowingSession: false,
            reschedulingSession: false,
            resittingSession: false,
            jobId: '',
            rescheduleDetails: '',
            fieldsChanged: {
              package: false,
              time: false,
              group: false,
              promo: false,
              billSubject: false,
              waiveBooking: false,
              waiveNoShow: false,
              waiveRescheduleCancel: false,
            },
            employeeId: node.employee?.id,
            frameNumber: node.frameNumber,
            subjectDescription: node.subjectDescription,
            stageId: node.stage?.id,
          }
        })
      : []

  const editJobDate = (dateString) => {
    if (dateString) {
      const lDate = DateTime.fromISO(dateString)
      return new Date(lDate.c.year, lDate.c.month - 1, lDate.c.day, 0, 0, 0)
    }
  }
  let multiDayJobDates = []
  if (
    editJobData?.job?.multiDayJob &&
    editJobData.job.multiDayJob?.jobs &&
    editJobData.job.multiDayJob.jobs.edges
  ) {
    editJobData.job.multiDayJob.jobs.edges?.forEach(({ node }) => {
      if (node.id !== editJobData.job.id) {
        multiDayJobDates.push({
          id: node.id,
          date: editJobDate(node.startDateTime),
        })
      }
    })
    multiDayJobDates.sort((a, b) => a.date - b.date)
  }

  const initialValues =
    jobId || parentJobId
      ? {
          name: editJobData.job?.name,
          sharedCanSeeFiles: editJobData.job?.sharedCanSeeFiles,
          sharedCanCreateFiles: editJobData.job?.sharedCanCreateFiles,
          sharedCanCreateFolders: editJobData.job?.sharedCanCreateFolders,
          sessionSharedCanSeeFiles: editJobData.job?.sessionSharedCanSeeFiles,
          sessionSharedCanCreateFiles:
            editJobData.job?.sessionSharedCanCreateFiles,
          sessionSharedCanCreateFolders:
            editJobData.job?.sessionSharedCanCreateFolders,
          multiDayJobCopySessions: editJobData.job?.multiDayJob?.copySessions,
          organizationContacts: editJobData.job?.contacts
            ? editJobData.job?.contacts.edges.map(
                (contactNode) => contactNode.node.id
              )
            : [],
          setups: editJobData.job?.setups,
          jobStartTime: copyJob
            ? DateTime.fromISO(editJobData.job?.startDateTime)
                .plus({ days: 1 })
                .toFormat('h:mma')
            : DateTime.fromISO(editJobData.job?.startDateTime).toFormat(
                'h:mma'
              ),
          jobEndTime: DateTime.fromISO(editJobData.job?.endDateTime).toFormat(
            'h:mma'
          ),
          adHocContacts: transformedAdHocContacts,
          locationId: editJobData.job?.location.archived
            ? ''
            : editJobData.job?.location.id,
          locationStateSalexTax: editJobData.job?.location.archived
            ? null
            : editJobData.job?.location.salesTax,
          manualLocation: {
            addressLineOne: '',
            addressLineTwo: '',
            city: '',
            state: '',
            zipCode: '',
            latitude: '',
            longitude: '',
          },
          locationValue: editJobData.job?.location.archived
            ? ''
            : formatAddress(editJobData.job?.location),
          jobDate: editJobDate(editJobData.job?.startDateTime),
          bookingStartDate: copyJob
            ? initialJobDate
            : editJobDate(editJobData.job?.bookingStartDateTime),
          bookingEndDate: editJobDate(editJobData.job?.bookingEndDateTime),
          ogJobDate: editJobDate(editJobData.job?.startDateTime),
          jobTypeId: editJobData.job?.jobType?.id ?? '',
          employeeJobs: transformedEmployeeJobs,
          jobNotes: editJobData.job?.notes ?? '',
          jobSubjectNotes: editJobData.job?.subjectNotes ?? '',
          buffers: transformedBreaks,
          packageCategories: transformedCategories,
          subjectGroups: transformedPackageGroupJobs,
          notificationsEnabled: editJobData.job?.notificationsEnabled,
          jobEquipmentBagTypes: editJobData.job?.jobEquipmentBagTypes.edges.map(
            (edge) => {
              return {
                id: edge.node.id,
                requiredQuantity: edge.node.requiredQuantity,
                includedQuantity: edge.node.includedQuantity,
                equipmentBagTypeId: edge.node.equipmentBagType.id,
                equipmentBagTypeName: edge.node.equipmentBagType.name,
              }
            }
          ),
          jobEquipmentItemTypes:
            editJobData.job?.jobEquipmentItemTypes.edges.map((edge) => {
              return {
                id: edge.node.id,
                requiredQuantity: edge.node.requiredQuantity,
                includedQuantity: edge.node.includedQuantity,
                equipmentItemTypeId: edge.node.equipmentItemType.id,
                equipmentItemTypeName: edge.node.equipmentItemType.name,
              }
            }),
          sessions:
            (editJobData && !copyJob) ||
            (copyJob &&
              !editJobData?.job?.packageCategoryJobs.edges.length &&
              !editJobData?.job?.packageGroupJobs.edges.length)
              ? transformedSessions.filter(
                  (session) => session.futureSession?.job.id !== jobId
                )
              : [],
          metadata: JSON.parse(editJobData.job?.metadata) ?? {},
          multiDay: editJobData?.job?.multiDayJob,
          multiDayJobDates: multiDayJobDates,
          applyToAllJobs: editJobData.job?.multiDayJob?.copySessions,
          stageId: editJobData?.job?.stage?.id,
          sessionStageId: '',
        }
      : {
          name: '',
          setups: 1,
          notificationsEnabled: true,
          multiDayJobCopySessions: false,
          multiDay: false,
          sharedCanSeeFiles: folderConfiguration?.jobSharedCanSeeFiles,
          sharedCanCreateFiles: folderConfiguration?.jobSharedCanCreateFiles,
          sharedCanCreateFolders:
            folderConfiguration?.jobSharedCanCreateFolders,
          sessionSharedCanSeeFiles:
            folderConfiguration?.sessionSharedCanSeeFiles,
          sessionSharedCanCreateFiles:
            folderConfiguration?.sessionSharedCanCreateFiles,
          sessionSharedCanCreateFolders:
            folderConfiguration?.sessionSharedCanCreateFolders,
          multiDayJobDates: [],
          jobStartTime: '',
          jobEndTime: '',
          manualLocation: {
            addressLineOne: '',
            addressLineTwo: '',
            city: '',
            state: '',
            zipCode: '',
            latitude: '',
            longitude: '',
          },
          locationId: '',
          locationValue: '',
          adHocContacts: [],
          jobEquipmentBagTypes: [],
          jobEquipmentItemTypes: [],
          jobDate: initialJobDate,
          bookingStartDate: initialJobDate,
          bookingEndDate: null,
          sessions: initialSessionsValue,
          jobTypeId:
            jobTypeData?.jobTypes && jobTypeData.jobTypes.edges.length > 0
              ? jobTypeData.jobTypes.edges[0].node.id
              : '',
          employeeJobs: [
            {
              id: null,
              sendNotification: false,
              sendEquipmentNotification: false,
              roleId: '',
              employeeId: '',
              empJobEndTime: '',
              empJobStartTime: '',
              equipmentPickupArea: '',
              equipmentBags: [],
              equipmentItems: [],
            },
          ],
          jobNotes: '',
          jobSubjectNotes: '',
          buffers: [],
          packageCategories: [],
          subjectGroups: [],
          organizationContacts: [],
          metadata: {},
          stageId: '',
          sessionStageId: '',
        }

  const getUtcDt = (jobDateDt, timeDt) => {
    return DateTime.utc(
      jobDateDt.getFullYear(),
      jobDateDt.getMonth() + 1,
      jobDateDt.getDate(),
      timeDt.hour,
      timeDt.minute
    )
  }
  const validationSchema = schema()
  let multiDayJobTable
  if (editJobData?.job?.multiDayJob) {
    multiDayJobTable = (
      <>
        <Row>
          <Col>
            <SortableTable
              columns={[
                {
                  Header: 'Job',
                  accessor: 'node.name',
                },
                {
                  Header: 'Location',
                  accessor: 'node.location.fullAddress',
                },
                {
                  Header: 'Occurs',
                  accessor: (row) => {
                    const start = DateTime.fromISO(
                      row.node.startDateTime
                    ).toFormat('LLLL d yyyy h:mma')
                    const end = DateTime.fromISO(row.node.endDateTime).toFormat(
                      'h:mma'
                    )
                    return (
                      <>
                        {start}-{end}
                      </>
                    )
                  },
                },
                {
                  disableSortBy: true,
                  Header: (
                    <>
                      <Form.Group
                        as={ButtonGroup}
                        className="align-items-center"
                      >
                        <Form.Check
                          className="ml-2 mt-2"
                          type="checkbox"
                          onChange={(e) => {
                            if (e.target.checked) {
                              const appendIds = []
                              editJobData.job.multiDayJob.jobs.edges
                                .filter(
                                  (edge) => edge.node.id !== editJobData.job.id
                                )
                                .forEach((job) => {
                                  if (
                                    !checkedMultiDayJobs.includes(job.node.id)
                                  ) {
                                    appendIds.push(job.node.id)
                                  }
                                })
                              setCheckedMultiDayJobs((prevState) => {
                                return [...prevState, ...appendIds]
                              })
                            } else {
                              setCheckedMultiDayJobs([])
                            }
                          }}
                        />
                        {checkedMultiDayJobs.length > 0 && (
                          <span style={{ fontSize: '14px', marginTop: '5px' }}>
                            ({checkedMultiDayJobs.length})
                          </span>
                        )}
                      </Form.Group>
                    </>
                  ),
                  id: 'actions',
                  accessor: (row) => {
                    return (
                      <>
                        <Form.Group
                          as={ButtonGroup}
                          className="align-items-center"
                        >
                          <Form.Check
                            className="ml-2 mt-2"
                            type="checkbox"
                            checked={checkedMultiDayJobs.includes(row.node.id)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setCheckedMultiDayJobs((prevState) => [
                                  ...prevState,
                                  row.node.id,
                                ])
                              } else {
                                setCheckedMultiDayJobs((prevState) =>
                                  prevState.filter((id) => id !== row.node.id)
                                )
                              }
                            }}
                          />
                        </Form.Group>
                      </>
                    )
                  },
                },
              ]}
              data={editJobData.job.multiDayJob.jobs.edges.filter(
                (edge) => edge.node.id !== editJobData.job.id
              )}
            />
          </Col>
        </Row>
      </>
    )
  }
  if (updateFromJobDetailLoading) {
    return <Loading />
  }
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
        validate={(values) => {
          return validationSchema
            .validate(values, { abortEarly: false })
            .then(() => {
              return null
            })
            .catch((yupError) => {
              let errors = {}
              yupError.inner.forEach((error) => {
                if (!errors[error.path]) {
                  errors[error.path] = error.message
                }
              })
              toast.error('Form Fields Invalid')
              return errors
            })
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, action) => {
          setSubmitting(true)
          const jobStartTimeToLuxon = DateTime.fromFormat(
            `${values.jobStartTime}`,
            'h:ma'
          )
          const newJobStartDateTime = getUtcDt(
            values.jobDate,
            jobStartTimeToLuxon
          ).toISO()

          const jobEndTimeToLuxon = DateTime.fromFormat(
            `${values.jobEndTime}`,
            'h:ma'
          )

          const newJobEndDateTime = getUtcDt(
            values.jobDate,
            jobEndTimeToLuxon
          ).toISO()

          const newBuffers = values.buffers.map((buffer) => {
            const startTimeToLuxon = DateTime.fromFormat(
              `${buffer.startDateTime}`,
              'h:ma'
            )
            const newStartDateTime = getUtcDt(
              values.jobDate,
              startTimeToLuxon
            ).toISO()

            const endTimeToLuxon = DateTime.fromFormat(
              `${buffer.endDateTime}`,
              'h:ma'
            )

            const newEndDateTime = getUtcDt(
              values.jobDate,
              endTimeToLuxon
            ).toISO()
            return {
              startDateTime: newStartDateTime,
              endDateTime: newEndDateTime,
            }
          })

          const createSessions = []
          let updateSessions = []
          const validateSessions = []

          values.sessions.map((session) => {
            const startTimeToLuxon = DateTime.fromFormat(
              `${session.startTime}`,
              'h:ma'
            )
            const newStartDateTime = getUtcDt(values.jobDate, startTimeToLuxon)
            const newStartDateTimeToUtc = newStartDateTime.toISO()
            let newEndDateTimeToUtc
            if (session.endTime) {
              const endTimeToLuxon = DateTime.fromFormat(
                `${session.endTime}`,
                'h:ma'
              )
              const newEndDateTime = getUtcDt(values.jobDate, endTimeToLuxon)
              newEndDateTimeToUtc = newEndDateTime.toISO()
            }
            if ('' in session.metadata) {
              delete session.metadata['']
            }
            const sessionObj = {
              startDateTime: newStartDateTimeToUtc,
              endDateTime: newEndDateTimeToUtc,
              billSubject: session.billSubject,
              couponId: session.couponId,
              sharedCanCreateFiles: session.sharedCanCreateFiles,
              sharedCanCreateFolders: session.sharedCanCreateFolders,
              sharedCanSeeFiles: session.sharedCanSeeFiles,
              packageCategoryId: session.packageCategoryId,
              metadata: JSON.stringify(session.metadata),
              notes: session.notes,
              organizationId: session.subjectSession
                ? session.sessionOrgId
                : session.organizationId,
              sessionPackageId: session.sessionPackageId,
              stripePaymentMethodId: session.stripePaymentMethodResource?.id
                ? session.stripePaymentMethodResource.id
                : '',
              subjectGroupId: session.subjectGroupId,
              subjectId: session.subjectId,
              waiveRescheduleCancelFee: session.waiveRescheduleCancelFee,
              waiveNoShowFee: session.waiveNoShowFee,
              waiveBookingFee: session.waiveBookingFee,
              cancelled: session.cancellingSession,
              noShow: session.noShow ? true : session.noShowingSession,
              employeeId: session.employeeId,
              stageId: values.sessionStageId
                ? values.sessionStageId
                : session.stageId,
              frameNumber: session.frameNumber,
              subjectDescription: session.subjectDescription,
            }
            sessionObj.sessionPackagePrice = session.sessionPackagePrice
            if (session.bookingFlowRescheduleSessionId) {
              sessionObj.previousSessionId = bookingFlowRescheduleSessionId
              sessionObj.previousSessionRescheduled = true
            }
            if (session.bookingFlowReschedulingNoShow) {
              sessionObj.reschedulingNoShow = true
            }
            if (session.bookingFlowChangePackageSessionId) {
              sessionObj.previousSessionId = bookingFlowChangePackageSessionId
              sessionObj.previousSessionPackageChange = true
            }

            if (session.bookingFlowResitSessionId) {
              sessionObj.previousSessionId = bookingFlowResitSessionId
              sessionObj.previousSessionResit = true
              sessionObj.previousSessionResitReasonId = bookingFlowResitReasonId
            }
            if (session.newSession) {
              createSessions.push(sessionObj)
            } else {
              if (session.ogSeshPackageId !== session.sessionPackageId) {
                sessionObj.previousSessionPackageChange = true
                sessionObj.previousSessionId = session.sessionId
              }

              if (session.reschedulingSession) {
                sessionObj.previousSessionId = session.sessionId
                sessionObj.previousSessionRescheduled = true
                sessionObj.jobId = session.jobId
              }

              if (session.resittingSession) {
                sessionObj.previousSessionId = session.sessionId
                sessionObj.previousSessionResit = true
                sessionObj.jobId = session.jobId
              }
              if (
                session.ogWaiveBookingFee &&
                session.ogSeshPackageId === session.sessionPackageId
              ) {
                sessionObj.waiveBookingFee = true
              }
            }
            if (!session.noShow && !session.cancelled) {
              session.uuid = uuidv4()
              const validateSession = {
                uuid: session.uuid,
                sessionPackageId: sessionObj.sessionPackageId,
                startDateTime: sessionObj.startDateTime,
              }
              if (session.customSessionPackage) {
                validateSession.endDateTime = newEndDateTimeToUtc
              }
              validateSessions.push(validateSession)
            }
            if (
              sessionObj.previousSessionId &&
              !session.bookingFlowRescheduleSessionId &&
              !session.bookingFlowChangePackageSessionId &&
              !session.bookingFlowResitSessionId
            ) {
              createSessions.push(sessionObj)
            } else if (!session.newSession) {
              sessionObj.id = session.sessionId
              updateSessions.push(sessionObj)
            }
          })
          const { data } = await client.query({
            query: gql`
              query ScheduleSessionAvailableSessionsQuery(
                $input: JobSessionsAvailableInputType!
              ) {
                jobSessionsAvailable(jobSessionsAvailable: $input) {
                  sessions {
                    available
                    uuid
                  }
                  job {
                    id
                  }
                }
              }
            `,
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            variables: {
              input: {
                sessions: validateSessions,
                setups: values.setups,
                jobStartDateTime: newJobStartDateTime,
                jobEndDateTime: newJobEndDateTime,
                breaks: newBuffers,
              },
            },
          })
          const unavailableSessionIndexes = []
          data.jobSessionsAvailable.sessions.forEach((availableSession) => {
            if (!availableSession.available) {
              unavailableSessionIndexes.push(
                values.sessions.indexOf(
                  values.sessions.filter(
                    (session) => session.uuid === availableSession.uuid
                  )[0]
                )
              )
            }
          })
          if (unavailableSessionIndexes.length > 0) {
            unavailableSessionIndexes.forEach((index) => {
              setConflictingTimesErrors((prevState) => ({
                ...prevState,
                [index]:
                  'Session Must Occur Between Job Start and End Time, It Must Not Occur During a Break or Cause More Sessions Than Setups Allow',
              }))
            })
            toast.error(
              'Sessions Must Occur Between The Job Start And End Time, Must Not Occur During A Break Or Cause More Sessions Than Setups Allow'
            )
            setSubmitting(false)
            if (!showSessionSetupError) {
              setShowSessionSetupError(true)
            }
          } else {
            if (updateFromJobDetail) {
              setUpdateFromJobDetailLoading(true)
            }
            if (showSessionSetupError) {
              setShowSessionSetupError(false)
            }
            setConflictingTimesErrors({})
            const newSubjectGroups = values.subjectGroups.map(
              (subjectGroup) => {
                let id = subjectGroup.id
                if ('subjectGroupId' in subjectGroup) {
                  id = subjectGroup.subjectGroupId
                }
                return {
                  subjectGroupId: id,
                  sessionPackages: subjectGroup.packages.map((p) => p.id),
                }
              }
            )
            const newPackageCategories = values.packageCategories.map(
              (pCat) => ({
                packageCategoryId: pCat.id,
                sessionPackages: pCat.packages.map((p) => p.id),
              })
            )

            const transformedEmpJobs = () => {
              const updatingJob = (jobId || parentJobId) && !copyJob

              const empJobs = updatingJob
                ? {
                    addEmployeeJobs: [],
                    updateEmployeeJobs: [],
                  }
                : []

              values.employeeJobs.forEach((empJob) => {
                let newEmpJobStartDateTime
                if (empJob.empJobStartTime) {
                  const empJobStartTimeToLuxon = DateTime.fromFormat(
                    `${empJob.empJobStartTime}`,
                    'h:ma'
                  )
                  newEmpJobStartDateTime = getUtcDt(
                    values.jobDate,
                    empJobStartTimeToLuxon
                  ).toISO()
                }
                let newEmpJobEndDateTime
                if (empJob.empJobEndTime) {
                  const empJobEndTimeToLuxon = DateTime.fromFormat(
                    `${empJob.empJobEndTime}`,
                    'h:ma'
                  )
                  newEmpJobEndDateTime = getUtcDt(
                    values.jobDate,
                    empJobEndTimeToLuxon
                  ).toISO()
                }
                const newEmpJob = {
                  roleId: empJob.roleId,
                  hourlyPay: empJob.hourlyPay,
                  confirmed: empJob.confirmed,
                  equipmentItemIds: empJob.equipmentItems.map((item) => {
                    return item.id
                  }),
                  equipmentBagIds: empJob.equipmentBags.map((bag) => {
                    return bag.id
                  }),
                  startDateTime: newEmpJobStartDateTime,
                  endDateTime: newEmpJobEndDateTime,
                  employeeId: empJob.employeeId,
                  equipmentPickupArea: empJob.equipmentPickupArea,
                }

                if (empJob.employeeId) {
                  newEmpJob.sendNotification = empJob.sendNotification
                  newEmpJob.sendEquipmentNotification =
                    empJob.sendEquipmentNotification
                }

                if (!updatingJob) {
                  empJobs.push(newEmpJob)
                } else {
                  if (empJob.isNewEmpJob) {
                    empJobs.addEmployeeJobs.push(newEmpJob)
                  } else {
                    newEmpJob.id = empJob.id
                    empJobs.updateEmployeeJobs.push(newEmpJob)
                  }
                }
              })
              return empJobs
            }
            const manualLocation = {
              addressLineOne: values.manualLocation.addressLineOne,
              name: values.manualLocation.addressName,
              addressLineTwo: values.manualLocation.addressLineTwo,
              city: values.manualLocation.city,
              zipCode: values.manualLocation.zipCode,
              state: values.manualLocation.state,
            }
            if (
              values.manualLocation.latitude &&
              values.manualLocation.longitude
            ) {
              manualLocation.latitude = values.manualLocation.latitude
              manualLocation.longitude = values.manualLocation.longitude
            }
            if ((!jobId && !parentJobId) || copyJob) {
              if (createSessions.length > 10) {
                setDelayCreateSessions(true)
              }
              let multiDayJobDates = []
              if (values.multiDay) {
                multiDayJobDates = values.multiDayJobDates.map(({ date }) => {
                  return {
                    startDateTime: getUtcDt(date, jobStartTimeToLuxon).toISO(),
                    endDateTime: getUtcDt(date, jobEndTimeToLuxon).toISO(),
                  }
                })
              }
              createJob({
                variables: {
                  input: {
                    jobInput: {
                      name: values.name,
                      sharedCanSeeFiles: values.sharedCanSeeFiles,
                      sharedCanCreateFiles: values.sharedCanCreateFiles,
                      sharedCanCreateFolders: values.sharedCanCreateFolders,
                      sessionSharedCanSeeFiles: values.sessionSharedCanSeeFiles,
                      sessionSharedCanCreateFiles:
                        values.sessionSharedCanCreateFiles,
                      sessionSharedCanCreateFolders:
                        values.sessionSharedCanCreateFolders,
                      multiDayJobCopySessions: values.multiDayJobCopySessions,
                      jobEquipmentBagTypes: values.jobEquipmentBagTypes.map(
                        (jobEquipmentBagType) => {
                          return {
                            id: jobEquipmentBagType.id,
                            requiredQuantity:
                              jobEquipmentBagType.requiredQuantity,
                            equipmentBagTypeId:
                              jobEquipmentBagType.equipmentBagTypeId,
                          }
                        }
                      ),
                      jobEquipmentItemTypes: values.jobEquipmentItemTypes.map(
                        (jobEquipmentItemType) => {
                          return {
                            id: jobEquipmentItemType.id,
                            requiredQuantity:
                              jobEquipmentItemType.requiredQuantity,
                            equipmentItemTypeId:
                              jobEquipmentItemType.equipmentItemTypeId,
                          }
                        }
                      ),
                      bookingStartDateTime: values.bookingStartDate,
                      bookingEndDateTime: newJobEndDateTime,
                      contactIds: values.organizationContacts,
                      jobTypeId: values.jobTypeId,
                      packageGroupJobs: newSubjectGroups,
                      startDateTime: newJobStartDateTime,
                      endDateTime: newJobEndDateTime,
                      locationId: values.locationId,
                      setups: values.setups,
                      breaks: newBuffers,
                      employeeJobs: transformedEmpJobs(),
                      notes: values.jobNotes,
                      subjectNotes: values.jobSubjectNotes,
                      packageCategoryJobs: newPackageCategories,
                      notificationsEnabled: values.notificationsEnabled,
                      createSessions: createSessions,
                      adHocContacts: values.adHocContacts,
                      manualLocation,
                      metadata: JSON.stringify(values.metadata),
                      multiDayJob: values.multiDay,
                      multiDayJobDates: multiDayJobDates,
                      stageId: values.stageId,
                    },
                  },
                },
              })
            } else {
              if (createSessions.length + updateSessions.length > 10) {
                setDelayCreateSessions(true)
              }
              let removeJobs = []
              let updateJobDates = []
              let addNewJobs = []
              values.multiDayJobDates.map((jobDate) => {
                if (jobDate.id) {
                  const job = editJobData.job.multiDayJob.jobs.edges?.find(
                    ({ node }) => node.id === jobDate.id
                  )
                  if (
                    job.node &&
                    editJobDate(job.node.startDateTime).toString() !==
                      jobDate.date.toString()
                  ) {
                    updateJobDates.push({
                      startDateTime: getUtcDt(
                        jobDate.date,
                        jobStartTimeToLuxon
                      ).toISO(),
                      endDateTime: getUtcDt(
                        jobDate.date,
                        jobEndTimeToLuxon
                      ).toISO(),
                      id: job.node.id,
                    })
                  }
                } else {
                  addNewJobs.push({
                    startDateTime: getUtcDt(
                      jobDate.date,
                      jobStartTimeToLuxon
                    ).toISO(),
                    endDateTime: getUtcDt(
                      jobDate.date,
                      jobEndTimeToLuxon
                    ).toISO(),
                  })
                }
              })
              editJobData.job?.multiDayJob?.jobs.edges?.map(({ node }) => {
                const job = values.multiDayJobDates.find(
                  (jobDate) => jobDate?.id === node.id
                )
                if (!job && node.id !== editJobData.job.id) {
                  removeJobs.push(node.id)
                }
              })
              updateJob({
                variables: {
                  input: {
                    jobInput: {
                      id: jobId ? jobId : parentJobId,
                      name: values.name,
                      sharedCanSeeFiles: values.sharedCanSeeFiles,
                      sharedCanCreateFiles: values.sharedCanCreateFiles,
                      sharedCanCreateFolders: values.sharedCanCreateFolders,
                      sessionSharedCanSeeFiles: values.sessionSharedCanSeeFiles,
                      sessionSharedCanCreateFiles:
                        values.sessionSharedCanCreateFiles,
                      sessionSharedCanCreateFolders:
                        values.sessionSharedCanCreateFolders,
                      multiDayJobCopySessions: values.multiDayJobCopySessions,
                      jobEquipmentBagTypes: values.jobEquipmentBagTypes.map(
                        (jobEquipmentBagType) => {
                          return {
                            id: jobEquipmentBagType.id,
                            requiredQuantity:
                              jobEquipmentBagType.requiredQuantity,
                            equipmentBagTypeId:
                              jobEquipmentBagType.equipmentBagTypeId,
                          }
                        }
                      ),
                      jobEquipmentItemTypes: values.jobEquipmentItemTypes.map(
                        (jobEquipmentItemType) => {
                          return {
                            id: jobEquipmentItemType.id,
                            requiredQuantity:
                              jobEquipmentItemType.requiredQuantity,
                            equipmentItemTypeId:
                              jobEquipmentItemType.equipmentItemTypeId,
                          }
                        }
                      ),
                      bookingStartDateTime: values.bookingStartDate,
                      bookingEndDateTime: newJobEndDateTime,
                      contactIds: values.organizationContacts,
                      jobTypeId: values.jobTypeId,
                      packageGroupJobs: newSubjectGroups,
                      startDateTime: newJobStartDateTime,
                      endDateTime: newJobEndDateTime,
                      locationId: values.locationId,
                      setups: values.setups,
                      breaks: newBuffers,
                      employeeJobs: transformedEmpJobs(),
                      notes: values.jobNotes,
                      subjectNotes: values.jobSubjectNotes,
                      packageCategoryJobs: newPackageCategories,
                      notificationsEnabled: values.notificationsEnabled,
                      createSessions: createSessions,
                      updateSessions: updateSessions,
                      adHocContacts: values.adHocContacts,
                      manualLocation,
                      metadata: JSON.stringify(values.metadata),
                      applyToAllJobs: values.applyToAllJobs,
                      removeJobs: removeJobs,
                      updateJobs: updateJobDates,
                      addNewJobs: addNewJobs,
                      stageId: values.stageId,
                    },
                  },
                },
              })
            }
          }
        }}
      >
        {(formik) => {
          let displayedSessionLength = 0
          let areNoShowSessions = false
          let areCancelledSessions = false
          formik.values.sessions.forEach((session) => {
            if (!areNoShowSessions && session.noShow) {
              areNoShowSessions = true
            }
            if (!areCancelledSessions && session.cancelled) {
              areCancelledSessions = true
            }
            if (
              (!session.noShow && !session.cancelled) ||
              (session.noShow && displayNoShowSessions) ||
              (session.cancelled && displayCancelledSessions)
            ) {
              displayedSessionLength += 1
            }
          })
          return (
            <>
              <Form onSubmit={formik.handleSubmit} className="mt-4">
                {!employeeAssignments && !jobDetailSessions && (
                  <>
                    <div className="mb-2">
                      <h1 className="mb-1">
                        {jobId && !copyJob
                          ? `${formik.values.name} Job`
                          : formik.values.name
                          ? `${formik.values.name} Job`
                          : 'New Job'}
                        {jobId && !copyJob ? (
                          <>
                            <button
                              type="button"
                              className="pl-3 btn-link ml-3 small"
                              onClick={toggleJobHistoryModal}
                            >
                              <ClockHistory size={16} className="mr-1" />{' '}
                              History
                            </button>
                            <JobHistoryModal
                              job={editJobData?.job}
                              showModal={showJobHistoryModal}
                              toggleModal={toggleJobHistoryModal}
                            />
                          </>
                        ) : null}
                      </h1>
                      <div className="d-flex align-items-center mt-2">
                        {editJobData && !copyJob && (
                          <>
                            {editJobData.job.cancelled && (
                              <Badge
                                className="text-white mr-1"
                                style={{
                                  backgroundColor: 'red',
                                }}
                              >
                                canceled
                              </Badge>
                            )}
                            {editJobData.job.completed && (
                              <Badge
                                className="text-white"
                                style={{
                                  backgroundColor: 'green',
                                }}
                              >
                                complete
                              </Badge>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <>
                      <Form.Row>
                        <Form.Group as={Col} md={4}>
                          <Form.Label>Name</Form.Label>
                          <Field
                            name="name"
                            disabled={!canMutate}
                            className="form-control form-control-sm"
                          />
                          <ErrorMessage name="name">
                            {(msg) => (
                              <span className="text-danger">{msg}</span>
                            )}
                          </ErrorMessage>
                        </Form.Group>
                        <LocationField
                          formik={formik}
                          md={4}
                          canMutate={canMutate}
                          initialLocationData={editJobData?.job?.location ?? {}}
                        />
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} md={2}>
                          <Form.Label className="d-block">Date</Form.Label>
                          <DatePicker
                            name="jobDate"
                            disabled={!canMutate}
                            className="form-control form-control-sm"
                            showPopperArrow={false}
                            popperPlacement="auto"
                            selected={formik.values.jobDate}
                            onChange={(date) =>
                              formik.setFieldValue('jobDate', date)
                            }
                            popperModifiers={{
                              flip: {
                                behavior: ['bottom'],
                              },
                              preventOverflow: {
                                enabled: false,
                              },
                              hide: {
                                enabled: false,
                              },
                            }}
                          />
                        </Form.Group>
                        {(formik.values.subjectGroups.length > 0 ||
                          formik.values.packageCategories.length > 0) && (
                          <>
                            <Form.Group as={Col} md={2}>
                              <Form.Label className="d-block">
                                Booking Start Date
                              </Form.Label>
                              <DatePicker
                                name="bookingStartDate"
                                disabled={!canMutate}
                                className="form-control form-control-sm"
                                showPopperArrow={false}
                                popperPlacement="bottom-start"
                                selected={formik.values.bookingStartDate}
                                onChange={(date) =>
                                  formik.setFieldValue('bookingStartDate', date)
                                }
                                popperModifiers={{
                                  flip: {
                                    behavior: ['bottom'],
                                  },
                                  preventOverflow: {
                                    enabled: false,
                                  },
                                  hide: {
                                    enabled: false,
                                  },
                                }}
                              />
                              <ErrorMessage name="bookingStartDate">
                                {(msg) => (
                                  <Form.Label className="text-danger">
                                    {msg}
                                  </Form.Label>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                          </>
                        )}
                        <Form.Group as={Col} md={1}>
                          <Form.Label>Start Time</Form.Label>
                          <Form.Control
                            placeholder="9:00am"
                            className="form-control-sm"
                            disabled={!canMutate}
                            value={formik.values.jobStartTime}
                            onChange={(e) => handleJobStartChange(e, formik)}
                            onBlur={() =>
                              formik.setFieldTouched('jobStartTime', true)
                            }
                          />
                          <ErrorMessage name="jobStartTime">
                            {(msg) => (
                              <span className="text-danger">{msg}</span>
                            )}
                          </ErrorMessage>
                        </Form.Group>
                        <Form.Group as={Col} md={1}>
                          <Form.Label>End Time</Form.Label>
                          <Field
                            className="form-control form-control-sm"
                            name="jobEndTime"
                            disabled={!canMutate}
                            placeholder="5:00pm"
                          />
                          <ErrorMessage name="jobEndTime">
                            {(msg) => (
                              <span className="text-danger">{msg}</span>
                            )}
                          </ErrorMessage>
                        </Form.Group>
                        <Form.Group as={Col} md={1}>
                          <Form.Label>Setups</Form.Label>
                          <Field
                            name="setups"
                            disabled={!canMutate || schedulingAnalystCreating}
                            type="number"
                            className="form-control form-control-sm"
                          />
                        </Form.Group>
                        <Form.Group as={Col} md={1}>
                          <Form.Label>Notifications</Form.Label>
                          <Form.Check
                            type="checkbox"
                            className="mx-3"
                            disabled={!canMutate}
                            checked={formik.values.notificationsEnabled}
                            onChange={(e) => {
                              formik.setFieldValue(
                                'notificationsEnabled',
                                e.target.checked
                              )
                            }}
                          />
                        </Form.Group>
                        {!(jobId || parentJobId) && (
                          <>
                            <Form.Group as={Col} md={1}>
                              <Form.Label>Multi Day</Form.Label>
                              <Form.Check
                                type="checkbox"
                                className="mx-3"
                                disabled={!canMutate}
                                checked={formik.values.multiDay}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    'multiDay',
                                    e.target.checked
                                  )
                                  if (
                                    e.target.checked &&
                                    !(jobId || parentJobId)
                                  ) {
                                    formik.setFieldValue(
                                      'multiDayJobCopySessions',
                                      false
                                    )
                                    formik.setFieldValue('multiDayJobDates', [])
                                  }
                                }}
                              />
                            </Form.Group>
                            {formik.values.multiDay && (
                              <Form.Group as={Col} md={2}>
                                <Form.Label style={{ fontSize: '13px' }}>
                                  Multi Day Include Sessions
                                </Form.Label>
                                <Form.Check
                                  type="checkbox"
                                  className="mx-3"
                                  disabled={!canMutate}
                                  checked={
                                    formik.values.multiDayJobCopySessions
                                  }
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      'multiDayJobCopySessions',
                                      e.target.checked
                                    )
                                  }}
                                />
                              </Form.Group>
                            )}
                          </>
                        )}
                        {(jobId || parentJobId) &&
                          formik.values.multiDayJobDates.length > 0 && (
                            <Form.Group as={Col} md={1}>
                              <Form.Label style={{ fontSize: '13px' }}>
                                Sync All Jobs
                              </Form.Label>
                              <Form.Check
                                type="checkbox"
                                className="mx-3"
                                disabled={
                                  !canMutate ||
                                  editJobData.job?.multiDayJob?.copySessions
                                }
                                checked={formik.values.applyToAllJobs}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    'applyToAllJobs',
                                    e.target.checked
                                  )
                                  if (!e.target.checked) {
                                    formik.setFieldValue(
                                      'multiDayJobCopySessions',
                                      false
                                    )
                                  }
                                }}
                              />
                            </Form.Group>
                          )}
                        {(jobId || parentJobId) &&
                          formik.values.multiDay &&
                          formik.values.applyToAllJobs &&
                          editJobData.job?.multiDayJob?.copySessions && (
                            <Form.Group as={Col} md={2}>
                              <Form.Label style={{ fontSize: '13px' }}>
                                Sync All Jobs Sessions
                              </Form.Label>
                              <Form.Check
                                type="checkbox"
                                className="mx-3"
                                disabled={
                                  !canMutate ||
                                  editJobData.job?.multiDayJob?.copySessions
                                }
                                checked={formik.values.multiDayJobCopySessions}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    'multiDayJobCopySessions',
                                    e.target.checked
                                  )
                                }}
                              />
                            </Form.Group>
                          )}
                      </Form.Row>
                      {formik.values.multiDay && (
                        <MultiDayJob formik={formik} />
                      )}
                      <Row>
                        <Form.Group as={Col} md={3}>
                          <Form.Label>
                            Job Type
                            {canMutate && !schedulingAnalystCreating && (
                              <button
                                type="button"
                                className="p-0 ml-2 btn-link"
                                onClick={jobTypeModalShowChange}
                              >
                                <PlusCircle />
                              </button>
                            )}
                          </Form.Label>
                          <select
                            className="form-control-sm form-select"
                            name="jobTypeId"
                            disabled={!canMutate}
                            onChange={formik.handleChange}
                            value={formik.values.jobTypeId}
                          >
                            <option value="">- - -</option>
                            {jobTypeData.jobTypes.edges.map((jType) => (
                              <option key={jType.node.id} value={jType.node.id}>
                                {jType.node.name}
                              </option>
                            ))}
                          </select>
                          <ErrorMessage name="jobTypeId">
                            {(msg) => (
                              <span className="text-danger">{msg}</span>
                            )}
                          </ErrorMessage>
                          <JobTypeModal
                            jobTypeModalShowChange={jobTypeModalShowChange}
                            showJobTypeModal={showJobTypeModal}
                          />
                        </Form.Group>
                        {settings.jobStages && (
                          <Form.Group as={Col} md={2}>
                            <Form.Label>
                              Stage
                              <span
                                type="button"
                                className="p-0 ml-2 btn-link"
                                onClick={() => {
                                  setShowJobStageModal(true)
                                }}
                              >
                                <PlusCircle />
                              </span>
                            </Form.Label>
                            <JobStageSearchInput
                              formik={formik}
                              formikValue={'stage'}
                              dropdown
                              error={formik.errors.stageId}
                            />
                            <JobStage
                              showModal={showJobStageModal}
                              toggleMogal={setShowJobStageModal}
                            />
                          </Form.Group>
                        )}
                      </Row>

                      <Row className="mt-1">
                        <Col>
                          <Form.Label>
                            Notes
                            <button
                              type="button"
                              onClick={() => setDisplayNotes(!displayNotes)}
                              className="px-0 btn-link ml-1"
                            >
                              <>
                                {displayNotes ? (
                                  <>
                                    <CaretDown size={18} />
                                  </>
                                ) : (
                                  <>
                                    <CaretRight size={18} />
                                  </>
                                )}
                              </>
                            </button>
                          </Form.Label>
                        </Col>
                      </Row>
                      {displayNotes && (
                        <>
                          <Form.Row>
                            <Form.Group as={Col} md={8}>
                              <Form.Label>Job Notes</Form.Label>
                              <Field
                                name="jobNotes"
                                as="textarea"
                                disabled={!canMutate}
                                rows="1"
                                className="form-control"
                              />
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} md={8}>
                              <Form.Label>Subject Notes</Form.Label>
                              <Field
                                name="jobSubjectNotes"
                                as="textarea"
                                disabled={!canMutate}
                                rows="1"
                                className="form-control"
                              />
                            </Form.Group>
                          </Form.Row>
                        </>
                      )}

                      <Row className="mt-1">
                        <Col>
                          <Form.Label>
                            Files
                            <button
                              type="button"
                              onClick={() => setDisplayFiles(!displayFiles)}
                              className="px-0 btn-link ml-1"
                            >
                              <>
                                {displayFiles ? (
                                  <>
                                    <CaretDown size={18} />
                                  </>
                                ) : (
                                  <>
                                    <CaretRight size={18} />
                                  </>
                                )}
                              </>
                            </button>
                          </Form.Label>
                        </Col>
                      </Row>
                      {displayFiles && (
                        <>
                          <Row style={{ marginLeft: '-10px' }}>
                            <Col md={3}>
                              <Form.Group>
                                <Form.Check
                                  name="sharedCanSeeFiles"
                                  type="checkbox"
                                  label="Contacts Can See Files"
                                  disabled={!canMutate}
                                  checked={formik.values.sharedCanSeeFiles}
                                  onChange={(e) => {
                                    formik?.setFieldValue(
                                      `sharedCanSeeFiles`,
                                      e.target.checked
                                    )
                                  }}
                                />
                              </Form.Group>
                            </Col>
                            {formik.values.sharedCanSeeFiles && (
                              <>
                                <Col md={3}>
                                  <Form.Group>
                                    <Form.Check
                                      name="sharedCanCreateFiles"
                                      type="checkbox"
                                      label="Contacts Can Create Files"
                                      disabled={!canMutate}
                                      checked={
                                        formik.values.sharedCanCreateFiles
                                      }
                                      onChange={(e) => {
                                        formik?.setFieldValue(
                                          `sharedCanCreateFiles`,
                                          e.target.checked
                                        )
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group>
                                    <Form.Check
                                      name="sharedCanCreateFolders"
                                      type="checkbox"
                                      label="Contacts Can Create Folders"
                                      disabled={!canMutate}
                                      checked={
                                        formik.values.sharedCanCreateFolders
                                      }
                                      onChange={(e) => {
                                        formik?.setFieldValue(
                                          `sharedCanCreateFolders`,
                                          e.target.checked
                                        )
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                              </>
                            )}
                          </Row>
                          <Row style={{ marginLeft: '-10px' }}>
                            <Col md={3}>
                              <Form.Group>
                                <Form.Check
                                  name="sessionSharedCanSeeFiles"
                                  type="checkbox"
                                  label="Subjects Can See SessionFiles"
                                  disabled={!canMutate}
                                  checked={
                                    formik.values.sessionSharedCanSeeFiles
                                  }
                                  onChange={(e) => {
                                    formik?.setFieldValue(
                                      `sessionSharedCanSeeFiles`,
                                      e.target.checked
                                    )
                                  }}
                                />
                              </Form.Group>
                            </Col>
                            {formik.values.sessionSharedCanSeeFiles && (
                              <>
                                <Col md={3}>
                                  <Form.Group>
                                    <Form.Check
                                      name="sessionSharedCanCreateFiles"
                                      type="checkbox"
                                      label="Subjects Can Create Session Files"
                                      disabled={!canMutate}
                                      checked={
                                        formik.values
                                          .sessionSharedCanCreateFiles
                                      }
                                      onChange={(e) => {
                                        formik?.setFieldValue(
                                          `sessionSharedCanCreateFiles`,
                                          e.target.checked
                                        )
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group>
                                    <Form.Check
                                      name="sessionSharedCanCreateFolders"
                                      type="checkbox"
                                      label="Subjects Can Create Session Folders"
                                      disabled={!canMutate}
                                      checked={
                                        formik.values
                                          .sessionSharedCanCreateFolders
                                      }
                                      onChange={(e) => {
                                        formik?.setFieldValue(
                                          `sessionSharedCanCreateFolders`,
                                          e.target.checked
                                        )
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                              </>
                            )}
                          </Row>
                        </>
                      )}
                    </>
                  </>
                )}

                {!jobDetailSessions && updateFromJobDetail && (
                  <Form.Row>
                    <Form.Group as={Col} md={2}>
                      <Form.Label className="d-block">Job Date</Form.Label>
                      <DatePicker
                        name="jobDate"
                        disabled={true}
                        className="form-control form-control-sm"
                        showPopperArrow={false}
                        popperPlacement="auto"
                        selected={formik.values.jobDate}
                        onChange={(date) =>
                          formik.setFieldValue('jobDate', date)
                        }
                        popperModifiers={{
                          flip: {
                            behavior: ['bottom'],
                          },
                          preventOverflow: {
                            enabled: false,
                          },
                          hide: {
                            enabled: false,
                          },
                        }}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={2}>
                      <Form.Label>Start Time</Form.Label>
                      <Form.Control
                        placeholder="9:00am"
                        className="form-control-sm"
                        disabled={true}
                        value={formik.values.jobStartTime}
                        onChange={(e) => handleJobStartChange(e, formik)}
                        onBlur={() =>
                          formik.setFieldTouched('jobStartTime', true)
                        }
                      />
                      <ErrorMessage name="jobStartTime">
                        {(msg) => <span className="text-danger">{msg}</span>}
                      </ErrorMessage>
                    </Form.Group>
                    <Form.Group as={Col} md={2}>
                      <Form.Label>End Time</Form.Label>
                      <Field
                        className="form-control form-control-sm"
                        name="jobEndTime"
                        disabled={true}
                        placeholder="5:00pm"
                      />
                      <ErrorMessage name="jobEndTime">
                        {(msg) => <span className="text-danger">{msg}</span>}
                      </ErrorMessage>
                    </Form.Group>
                    <Form.Group as={Col} md={1}>
                      <Form.Label>Setups</Form.Label>
                      <Field
                        name="setups"
                        disabled={true}
                        type="number"
                        className="form-control form-control-sm"
                      />
                    </Form.Group>
                  </Form.Row>
                )}

                {isBookableJob && !jobDetailSessions && !employeeAssignments ? (
                  <>
                    {(jobId || parentJobId) && (
                      <Row className="mt-1">
                        <Col>
                          <Form.Label>
                            Bookable
                            <button
                              type="button"
                              onClick={() =>
                                setDisplayBookable(!displayBookable)
                              }
                              className="px-0 btn-link ml-1"
                            >
                              <>
                                {displayBookable ? (
                                  <>
                                    <CaretDown size={18} />
                                  </>
                                ) : (
                                  <>
                                    <CaretRight size={18} />
                                  </>
                                )}
                              </>
                            </button>
                          </Form.Label>
                        </Col>
                      </Row>
                    )}

                    {(displayBookable || !(jobId || parentJobId)) && (
                      <div>
                        <Bookable formik={formik} />
                      </div>
                    )}
                  </>
                ) : null}

                {(displaySessionSection &&
                  !employeeAssignments &&
                  !jobDetailSessions) ||
                (jobDetailSessions && updateFromJobDetail) ? (
                  <>
                    {initialSessionQueryRun && !getSessionData && (
                      <>
                        <Row className="justify-content-md-center">
                          <Col
                            md={jobDetailSessions ? 12 : 3}
                            className="text-center d-flex justify-content-center"
                          >
                            <Loading />
                          </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                          <Col
                            md={jobDetailSessions ? 12 : 3}
                            className="text-center d-flex justify-content-center"
                          >
                            Loading Sessions...
                          </Col>
                        </Row>
                      </>
                    )}
                    <div>
                      <JobSessions
                        showSessionSetupError={showSessionSetupError}
                        setApplyToAllJobs={setApplyToAllJobs}
                        bookingFlowPackageCategoryId={
                          bookingFlowPackageCategoryId
                        }
                        bookingFlowReschedulingNoShow={
                          bookingFlowReschedulingNoShow
                        }
                        bookingFlowChangePackageSessionId={
                          bookingFlowChangePackageSessionId
                        }
                        bookingFlowRescheduleSessionId={
                          bookingFlowRescheduleSessionId
                        }
                        conflictingTimesErrors={conflictingTimesErrors}
                        bookingFlowResitSessionId={bookingFlowResitSessionId}
                        bookingFlowResitReasonId={bookingFlowResitReasonId}
                        bookingFlowPackageId={bookingFlowPackageId}
                        bookingFlowSubjectId={bookingFlowSubjectId}
                        bookingFlowSubjectGroupId={bookingFlowSubjectGroupId}
                        jobDetailSessions={jobDetailSessions}
                        displaySessions={displaySessions}
                        setDisplaySessions={setDisplaySessions}
                        jobCompleted={editJobData?.completed}
                        isBookableJob={isBookableJob}
                        jobFormType={jobFormType}
                        settings={settings}
                        newSessionValues={newSessionValues}
                        jobId={jobId}
                        copyJob={copyJob}
                        formik={formik}
                        makeManualJob={makeManualJob}
                        displayedSessionLength={displayedSessionLength}
                        sessionData={getSessionData}
                        handleFetchMoreSessions={handleFetchMoreSessions}
                      />
                    </div>
                  </>
                ) : null}
                {!jobDetailSessions && (
                  <>
                    <div>
                      <EmployeeAssignments
                        formik={formik}
                        jobId={jobId ? jobId : parentJobId}
                        schedulingAnalystCreating={schedulingAnalystCreating}
                        displayStaff={displayStaff}
                        setDisplayStaff={setDisplayStaff}
                        employeeAssignments={employeeAssignments}
                        isEditJob={isEditJob}
                        copyJob={copyJob}
                      />
                    </div>
                  </>
                )}
                {!jobDetailSessions &&
                  !employeeAssignments &&
                  canSeeRequiredBagTypes && (
                    <>
                      <JobEquipmentBagTypes formik={formik} />

                      <JobEquipmentItemTypes formik={formik} />
                    </>
                  )}
                {isBookableJob && !jobDetailSessions && !employeeAssignments ? (
                  <>
                    <Buffer
                      formik={formik}
                      displayBuffers={displayBuffers}
                      setDisplayBuffers={setDisplayBuffers}
                    />
                  </>
                ) : null}

                {!employeeAssignments &&
                !jobDetailSessions &&
                (formik.values.sessions.some(
                  (session) => !session.subjectSession
                ) ||
                  makeManualJob ||
                  jobFormType === 'manual') ? (
                  <>
                    <OrgContacts formik={formik} />
                  </>
                ) : null}

                {!employeeAssignments && !jobDetailSessions && (
                  <AdHocContacts
                    schedulingAnalystCreating={schedulingAnalystCreating}
                    formik={formik}
                  />
                )}

                {canMutate && !jobDetailSessions && (
                  <div
                    className={
                      employeeAssignments ? 'mt-4 mb-5 ml-5' : 'mt-4 mb-5'
                    }
                  >
                    <Row>
                      <Col md={2}>
                        <Button
                          type="submit"
                          variant="outline-primary"
                          block
                          disabled={loadingJobCreate || loadingJobData}
                        >
                          <PlusCircle className="mr-2" />
                          {(jobId || parentJobId) && !copyJob ? (
                            <>Save</>
                          ) : (
                            'Create'
                          )}
                        </Button>
                      </Col>
                      {!employeeAssignments && (
                        <Col md={2}>
                          <Button
                            variant="outline-primary"
                            block
                            onClick={() => {
                              if (bookingFlowSubjectId) {
                                history.push(`/subject/${bookingFlowSubjectId}`)
                              } else {
                                history.push('/schedule')
                              }
                            }}
                          >
                            <ArrowLeft className="mr-2" />
                            Back
                          </Button>
                        </Col>
                      )}
                      {!employeeAssignments && editJobData && !copyJob && (
                        <>
                          {!editJobData.job.completed &&
                            !editJobData.job.cancelled && (
                              <Col md={2}>
                                <Button
                                  block
                                  size="sm"
                                  variant="outline-primary"
                                  onClick={toggleShowCompleteConfirm}
                                >
                                  <CheckCircle className="mr-2" />
                                  Complete
                                </Button>
                              </Col>
                            )}
                          {editJobData.job.completed && (
                            <Col md={2}>
                              <Button
                                block
                                size="sm"
                                variant="outline-primary"
                                onClick={toggleShowUncompleteConfirm}
                              >
                                <CheckCircle className="mr-2" />
                                Not Complete
                              </Button>
                            </Col>
                          )}
                          {editJobData.job.cancelled &&
                            !editJobData.job.completed && (
                              <Col md={2}>
                                <Button
                                  block
                                  size="sm"
                                  variant="outline-primary"
                                  onClick={() => toggleShowUnCancelConfirm()}
                                >
                                  <SlashCircle className="mr-2" />
                                  Uncancel
                                </Button>
                              </Col>
                            )}
                          {!editJobData.job.cancelled &&
                            !editJobData.job.completed && (
                              <Col md={2}>
                                <Button
                                  block
                                  size="sm"
                                  variant="outline-danger"
                                  onClick={() => toggleShowCancelConfirm()}
                                >
                                  <SlashCircle className="mr-2" />
                                  Cancel
                                </Button>
                              </Col>
                            )}
                          <Col md={2}>
                            <Button
                              block
                              size="sm"
                              variant="outline-danger"
                              onClick={() => toggleShowDeleteConfirm()}
                            >
                              <Trash className="mr-2" />
                              Delete
                            </Button>
                          </Col>
                        </>
                      )}
                    </Row>

                    {loadingJobCreate || loadingJobData ? (
                      <Row className="mt-3">
                        <Col>
                          <Loading message="Saving Job..." />
                        </Col>
                      </Row>
                    ) : null}
                  </div>
                )}
                {canMutate && jobDetailSessions && (
                  <div className="">
                    {updateFromJobDetail && (
                      <>
                        <Button type="submit" variant="link">
                          <PlusCircle />
                          <span className="ml-2">Save Sessions</span>
                        </Button>
                        <Button
                          type="submit"
                          variant="link"
                          onClick={() => setUpdateFromJobDetail(false)}
                        >
                          <SlashCircle />
                          <span className="ml-2">Hide Update Sessions</span>
                        </Button>
                      </>
                    )}
                    {!updateFromJobDetail && (
                      <Button
                        type="submit"
                        variant="link"
                        onClick={() => setUpdateFromJobDetail(true)}
                      >
                        <Camera />
                        <span className="ml-2">Update Sessions</span>
                      </Button>
                    )}
                  </div>
                )}
              </Form>
            </>
          )
        }}
      </Formik>
      <div className="jobDetailModal">
        <Modal
          show={
            showDeleteConfirm ||
            showCancelConfirm ||
            showUnCancelConfirm ||
            showUncompleteConfirm ||
            showCompleteConfirm
          }
          onHide={() => {
            if (showDeleteConfirm) {
              setShowDeleteConfirm(false)
            } else if (showCancelConfirm) {
              setShowCancelConfirm(false)
            } else if (showUnCancelConfirm) {
              setShowUnCancelConfirm(false)
            } else if (showUncompleteConfirm) {
              setShowUncompleteConfirm(false)
            } else if (showCompleteConfirm) {
              setShowCompleteConfirm(false)
            }
            setCheckedMultiDayJobs([])
          }}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title className="d-flex align-items-center">
              {showDeleteConfirm && <>Delete Job</>}
              {showCancelConfirm && <>Cancel Job</>}
              {showUnCancelConfirm && <>Uncancel Job</>}
              {showCompleteConfirm && <>Complete Job</>}
              {showUncompleteConfirm && <>Not Complete Job</>}
            </Modal.Title>
          </Modal.Header>
          {showDeleteConfirm && (
            <>
              <Modal.Body>
                <Row>
                  <Col>
                    <p>
                      Are you sure you want to delete the job? All sessions
                      associated to the job will be deleted. Subjects with
                      upcoming sessions on the job will be notified of the
                      cancellation and refunded.
                    </p>
                  </Col>
                </Row>

                {multiDayJobTable && (
                  <>
                    <Row>
                      <Col>
                        <p style={{ fontSize: '16px' }}>
                          Select Other Multi Day Jobs To Delete
                        </p>
                      </Col>
                    </Row>
                    {multiDayJobTable}
                  </>
                )}
                <Row>
                  <Col md={4}>
                    <Button
                      className="mt-2"
                      block
                      variant="outline-danger"
                      onClick={() => handleDeleteJobConfirmClick()}
                    >
                      <Trash className="mr-2" />
                      Delete
                    </Button>
                  </Col>
                </Row>
              </Modal.Body>
            </>
          )}
          {showCancelConfirm && (
            <>
              <Modal.Body>
                <Row>
                  <Col>
                    <p>
                      Are you sure you want to cancel the job? All sessions
                      associated to the job will be canceled. Subjects with
                      upcoming sessions will be notified of the cancellation and
                      refunded. Employees will be removed from employee
                      assignments associated to the job.
                    </p>
                  </Col>
                </Row>

                {multiDayJobTable && (
                  <>
                    <Row>
                      <Col>
                        <p style={{ fontSize: '16px' }}>
                          Select Other Multi Day Jobs To Cancel
                        </p>
                      </Col>
                    </Row>
                    {multiDayJobTable}
                  </>
                )}
                <Row>
                  <Col md={4}>
                    <Button
                      className="mt-2"
                      block
                      variant="outline-danger"
                      onClick={() => handleCancelJobConfirmClick()}
                    >
                      <SlashCircle className="mr-2" />
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Modal.Body>
            </>
          )}
          {showUnCancelConfirm && (
            <>
              <Modal.Body>
                <Row>
                  <Col>
                    <p>
                      Are you sure you want to uncancel the job? Subjects will
                      be able to book on the job if there are subject groups or
                      package categories associated.
                    </p>
                  </Col>
                </Row>

                {multiDayJobTable && (
                  <>
                    <Row>
                      <Col>
                        <p style={{ fontSize: '16px' }}>
                          Select Other Multi Day Jobs To Uncancel
                        </p>
                      </Col>
                    </Row>
                    {multiDayJobTable}
                  </>
                )}
                <Row>
                  <Col md={4}>
                    <Button
                      className="mt-2"
                      block
                      variant="outline-primary"
                      onClick={() => handleUncancelJobConfirmClick()}
                    >
                      <SlashCircle className="mr-2" />
                      Uncancel
                    </Button>
                  </Col>
                </Row>
              </Modal.Body>
            </>
          )}
          {showUncompleteConfirm && (
            <>
              <Modal.Body>
                <Row>
                  <Col>
                    <p>Are you sure you want to set the job not complete?</p>
                  </Col>
                </Row>

                {multiDayJobTable && (
                  <>
                    <Row>
                      <Col>
                        <p style={{ fontSize: '16px' }}>
                          Select Other Multi Day Jobs To Uncomplete
                        </p>
                      </Col>
                    </Row>
                    {multiDayJobTable}
                  </>
                )}
                <Row>
                  <Col md={4}>
                    <Button
                      className="mt-2"
                      variant="outline-primary"
                      onClick={() => handleJobNotComplete()}
                    >
                      <CheckCircle className="mr-2" />
                      Not Complete
                    </Button>
                  </Col>
                </Row>
              </Modal.Body>
            </>
          )}
          {showCompleteConfirm && (
            <>
              <Modal.Body>
                <Row>
                  <Col>
                    <p>
                      Are you sure you want to complete the job? Session
                      complete notifications will be sent.
                    </p>
                  </Col>
                </Row>

                {multiDayJobTable && (
                  <>
                    <Row>
                      <Col>
                        <p style={{ fontSize: '16px' }}>
                          Select Other Multi Day Jobs To Complete
                        </p>
                      </Col>
                    </Row>
                    {multiDayJobTable}
                  </>
                )}
                <Row>
                  <Col md={4}>
                    <Button
                      className="mt-2"
                      variant="outline-primary"
                      onClick={() => handleJobComplete()}
                    >
                      <CheckCircle className="mr-2" />
                      Complete
                    </Button>
                  </Col>
                </Row>
              </Modal.Body>
            </>
          )}
        </Modal>
      </div>
    </>
  )
}
