import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Form, Badge } from 'react-bootstrap'
import { useLazyQuery, useQuery, gql, useMutation } from '@apollo/client'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import AdminSessionDetailModal from '../sessions/AdminSessionDetailModal'
import SessionDetailModal from '../subject_sessions/SessionDetailModal'
import { formatDateTimeToString } from '../../libs/utils'
import {
  Cash,
  CloudArrowDown,
  BarChartLine,
  Funnel,
} from 'react-bootstrap-icons'
import RefundPaymentModal from './RefundPaymentModal'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar, settingsVar } from '../../libs/apollo'
import Loading from '../common/Loading'
import toast from 'react-hot-toast'
import DateFilter from '../common/DateFilter'
import { useParams } from 'react-router-dom'
import PaymentChartModal from './PaymentChartModal'
import ChargePaymentModal from './ChargePaymentModal'
import InvoiceModal from './InvoiceModal'
import { useDownloadAndDeleteFile } from '../../libs/downloadAndDeleteFile'

const PaymentsTable = (props) => {
  const {
    organizationId,
    subjectId,
    subjectGroupId,
    sessionId,
    stripeInvoiceId,
    invoiceModal,
    jobId,
    tableHeight,
    fetchPolicy,
    subjectViewing,
  } = props
  const { contact } = useParams()
  const { downloadAndDeleteFile } = useDownloadAndDeleteFile()
  const settings = useReactiveVar(settingsVar)
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const admin = ['Administrator'].includes(loggedInUser?.permissions?.group)
  const canRefund = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)
  const canDownloadPayments = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
    'Organization Contact',
  ].includes(loggedInUser?.permissions?.group)
  const defaultOrderBy = '-created'
  const [orderBy, setOrderBy] = useState(defaultOrderBy)
  const [stripePayments, setStripePayments] = useState({})
  const [hasMoreStripePaymentIntents, setHasMoreStripePaymentIntents] =
    useState(true)
  const [cursor, setCursor] = useState()
  const [showSubjectSessionDetailModal, setShowSubjectSessionDetailModal] =
    useState(false)
  const [searchText, setSearchText] = useState('Search')
  const [startDateFilter, setStartDateFilter] = useState()
  const [endDateFilter, setEndDateFilter] = useState()
  const [searchTerm, setSearchTerm] = useState('')
  const [downloadingReceipt, setDownloadingReceipt] = useState()
  const [showChargeModal, setShowChargeModal] = useState()
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [showSessionDetailModal, setShowSessionDetailModal] = useState()
  const [sessionDetailId, setSessionDetailId] = useState()
  const [showSessionRefundModal, setShowSessionRefundModal] = useState()
  const [paymentId, setPayementId] = useState()
  const [loading, setLoading] = useState(true)
  const [invoiceModalId, setInvoiceModalId] = useState()
  const [filterDeclined, setFilterDecline] = useState(false)
  const [filterInvoice, setFilterInvoice] = useState(false)
  const [filterSession, setFilterSession] = useState(false)
  const [downloadingExcel, setDownloadingExcel] = useState(false)
  const [downloadingPdf, setDownloadingPdf] = useState(false)
  const [showReport, setShowReport] = useState(false)

  const [downloadPayments] = useMutation(
    gql`
      mutation DownloadStripePaymentIntents(
        $input: DownloadStripePaymentIntentsInput!
      ) {
        downloadStripePaymentIntents(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        downloadAndDeleteFile(
          data.downloadStripePaymentIntents.file.fileName,
          data.downloadStripePaymentIntents.file.displayName,
          data.downloadStripePaymentIntents.file.id,
          () => {
            if (downloadingPdf) {
              toast.success(`PDF Downloaded`)
              setDownloadingPdf(false)
            }
            if (downloadingExcel) {
              toast.success(`Excel Downloaded`)
              setDownloadingExcel(false)
            }
            if (downloadingReceipt) {
              setDownloadingReceipt(false)
            }
          }
        )
      },
      errorPolicy: 'all',
    }
  )

  const [stripeExpressDashboardUrlQuery] = useLazyQuery(
    gql`
      query StripeExpressDashboardQuery {
        stripeExpressDashboardUrl {
          url
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      onCompleted: (data) => {
        window.open(data.stripeExpressDashboardUrl.url, '_blank')
      },
    }
  )

  const [
    stripePaymentIntentsQuery,
    {
      error: stripePaymentIntentsQueryError,
      data: stripePaymentIntentsQueryData,
      fetchMore: stripePaymentIntentsQueryFetchMore,
    },
  ] = useLazyQuery(
    gql`
      query StripePaymentIntentsQuery(
        $cursor: String
        $stripeInvoice: ID
        $job: String
        $declined: Boolean
        $searchTerm: String
        $orderBy: String
        $startDateGte: DateTime
        $startDateLte: DateTime
        $organization: String
        $session: String
        $sessions: Boolean
        $invoices: Boolean
        $subjectGroup: ID
        $subject: String
      ) {
        stripePaymentIntents(
          first: 25
          after: $cursor
          declined: $declined
          invoices: $invoices
          job: $job
          sessions: $sessions
          subject: $subject
          stripeInvoice: $stripeInvoice
          organization: $organization
          session: $session
          session_SubjectGroup_Id: $subjectGroup
          created_Gte: $startDateGte
          created_Lte: $startDateLte
          search_Icontains: $searchTerm
          orderBy: $orderBy
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              recordId
              declined
              processing
              refunded
              refundedBy {
                fullName
              }
              status
              stripePaymentMethod {
                paymentType
                stripeCustomer {
                  organization {
                    id
                    name
                  }
                  gaiaUser {
                    id
                    fullName
                    subject {
                      id
                    }
                  }
                }
              }
              paidManually
              taxAmount
              description
              refundedBySystem
              amount
              netAmount
              fee
              declinedStripeInvoices {
                edges {
                  node {
                    paid
                  }
                }
              }
              stripeInvoice {
                id
                archived
                amountDue
                stripeInvoiceId
                paidManuallyBy
                stripeCustomer {
                  organization {
                    id
                    name
                  }
                  gaiaUser {
                    id
                    fullName
                    subject {
                      id
                    }
                  }
                }
              }
              session {
                id
                billSubject
                subject {
                  id
                  gaiaUser {
                    fullName
                  }
                }
                organization {
                  name
                  id
                }
                subjectGroup {
                  name
                  id
                }
                job {
                  id
                  name
                }
              }
              updated
              created
            }
          }
        }
      }
    `,
    {
      fetchPolicy: fetchPolicy
        ? fetchPolicy
        : stripeInvoiceId
        ? 'no-cache'
        : 'network-only',
      errorPolicy: 'all',
      // pollInterval: 5000,
    }
  )
  const { error: stripeConnectorQueryError, data: stripeConnectorQueryData } =
    useQuery(
      gql`
        query StripeConnectorQuery {
          stripeConnectors {
            edges {
              node {
                id
                connectedAccountId
              }
            }
          }
        }
      `,
      {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      }
    )

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      const variables = {
        orderBy: defaultOrderBy,
      }
      if (organizationId) {
        variables.organization = organizationId
      }
      if (subjectGroupId) {
        variables.subjectGroup = subjectGroupId
      }
      if (stripeInvoiceId) {
        variables.stripeInvoice = stripeInvoiceId
      }
      if (sessionId) {
        variables.session = sessionId
      }
      if (subjectId) {
        variables.subject = subjectId
      }
      if (jobId) {
        variables.job = jobId
      }
      stripePaymentIntentsQuery({ variables })
    }
  }, [initialQueryRun])

  useEffect(() => {
    if (stripePaymentIntentsQueryData?.stripePaymentIntents) {
      setLoading(false)
      if (
        stripePaymentIntentsQueryData.stripePaymentIntents.pageInfo.endCursor
      ) {
        setCursor(
          stripePaymentIntentsQueryData.stripePaymentIntents.pageInfo.endCursor
        )
      }
      setHasMoreStripePaymentIntents(
        stripePaymentIntentsQueryData.stripePaymentIntents.pageInfo.hasNextPage
      )
      let text = 'Search 0 Payments'
      if (stripePaymentIntentsQueryData?.stripePaymentIntents.nodeCount > 0) {
        text = `Search ${stripePaymentIntentsQueryData?.stripePaymentIntents.nodeCount} Payments`
      }
      setSearchText(text)
      setStripePayments(stripePaymentIntentsQueryData)
    }
  }, [stripePaymentIntentsQueryData])

  const handleStripeDashboardClick = () => {
    toast.success('Opening Stripe')
    stripeExpressDashboardUrlQuery()
  }

  useEffect(() => {
    if (initialQueryRun) {
      setLoading(true)
      const variables = queryVariables()
      variables.variables.cursor = null
      variables.variables.startDateGte = startDateFilter
      variables.variables.startDateLte = endDateFilter
      stripePaymentIntentsQuery(variables)
    }
  }, [startDateFilter, endDateFilter])

  const queryVariables = () => {
    const variables = {
      searchTerm,
      cursor,
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    if (startDateFilter) {
      variables.startDateGte = startDateFilter
    }
    if (endDateFilter) {
      variables.startDateLte = endDateFilter
    }
    if (organizationId) {
      variables.organization = organizationId
    }
    if (subjectGroupId) {
      variables.subjectGroup = subjectGroupId
    }
    if (sessionId) {
      variables.session = sessionId
    }
    if (stripeInvoiceId) {
      variables.stripeInvoice = stripeInvoiceId
    }
    if (subjectId) {
      variables.subject = subjectId
    }
    if (jobId) {
      variables.job = jobId
    }
    if (filterDeclined) {
      variables.declined = true
    }
    if (filterSession) {
      variables.sessions = true
    }
    if (filterInvoice) {
      variables.invoices = true
    }
    return { variables }
  }

  useEffect(() => {
    if (initialQueryRun) {
      setLoading(true)
      const variables = queryVariables()
      variables.variables.cursor = null
      stripePaymentIntentsQuery(variables)
    }
  }, [filterDeclined, filterSession, filterInvoice])

  const handleSearchTermChange = (event) => {
    setLoading(true)
    const currentSearchTerm = event.target.value
    const variables = queryVariables()
    variables.variables.cursor = null
    variables.variables.searchTerm = currentSearchTerm
    setSearchTerm(currentSearchTerm)
    stripePaymentIntentsQuery(variables)
  }

  const handleSortByChange = (currentOrderBy) => {
    if (currentOrderBy === '' && orderBy === defaultOrderBy) return
    setLoading(true)
    currentOrderBy = currentOrderBy ? currentOrderBy : defaultOrderBy
    setOrderBy(currentOrderBy)
    const variables = queryVariables()
    variables.variables.cursor = null
    variables.variables.orderBy = currentOrderBy
    stripePaymentIntentsQuery(variables)
  }

  const fetchMore = () => {
    stripePaymentIntentsQueryFetchMore(queryVariables())
  }

  const toggleSessionDetailModal = () => {
    if (showSessionDetailModal) {
      setSessionDetailId(null)
    }
    setShowSessionDetailModal(!showSessionDetailModal)
  }

  const toggleSubjectSessionDetailModal = () => {
    if (showSubjectSessionDetailModal) {
      setSessionDetailId(null)
    }
    setShowSubjectSessionDetailModal(!showSubjectSessionDetailModal)
  }

  const toggleSessionRefundModal = () => {
    setShowSessionRefundModal(!showSessionRefundModal)
  }

  const onTdClick = (cell) => {
    if (!invoiceModal && cell.row.original.node?.session?.id) {
      setSessionDetailId(cell.row.original.node.session.id)
      if (contact) {
        toggleSubjectSessionDetailModal()
      } else {
        toggleSessionDetailModal()
      }
    } else if (!invoiceModal && cell.row.original.node?.stripeInvoice) {
      setInvoiceModalId(cell.row.original.node?.stripeInvoice.id)
    }
  }

  const tableColumns = [
    {
      Header: 'ID',
      id: 'id',
      serverSort: true,
      accessor: 'node.recordId',
    },
    {
      Header: 'Subject',
      id: 'subject',
      serverSort: true,
      orderBy: 'subject',
      accessor: (row) => {
        let user = row.node?.stripePaymentMethod?.stripeCustomer?.gaiaUser
        if (!user) {
          user = row.node?.stripeInvoice?.stripeCustomer?.gaiaUser
        }
        if (user) {
          return <>{user.fullName}</>
        }
      },
    },
    {
      Header: 'Job',
      id: 'Job',
      serverSort: true,
      orderBy: 'job',
      accessor: (row) => {
        if (row.node?.session?.job) {
          if (contact) {
            return row.node.session.job.name
          } else {
            return (
              <div
                className="sessions-buttons"
                onClick={() => {
                  if (!invoiceModal && row.node.session.job) {
                    setSessionDetailId(row.node.session.id)
                    if (contact) {
                      toggleSubjectSessionDetailModal()
                    } else {
                      toggleSessionDetailModal()
                    }
                  }
                }}
              >
                {row.node.session.job.name}
              </div>
            )
          }
        }
      },
    },
    {
      Header: 'Organization',
      id: 'organization',
      serverSort: true,
      orderBy: 'organization',
      accessor: (row) => {
        let organization = row.node?.session?.organization
        if (!organization) {
          organization = row.node?.stripeInvoice?.stripeCustomer?.organization
        }
        if (!organization) {
          organization =
            row.node?.stripePaymentMethod?.stripeCustomer?.organization
        }
        if (organization) {
          return <>{organization.name}</>
        }
      },
    },
    {
      Header: 'Subject Group',
      id: 'subjectGroup',
      serverSort: true,
      orderBy: 'subject_group',
      accessor: (row) => {
        if (row.node?.session?.subjectGroup) {
          return <>{row.node.session.subjectGroup.name}</>
        }
      },
    },
    {
      Header: 'Description',
      id: 'description',
      serverSort: true,
      accessor: (row) => {
        let description = row.node.description
        if (row.node?.stripeInvoice?.archived) {
          description = `Deleted ${description}`
        }
        return <>{description}</>
      },
    },
    {
      Header: 'Payment Method',
      id: 'paymentMethod',
      serverSort: true,
      orderBy: 'stripe_payment_method',
      accessor: (row) => {
        return (
          <>
            {row.node.stripePaymentMethod?.paymentType && (
              <>{row.node.stripePaymentMethod?.paymentType}</>
            )}
            {row.node.paidManually &&
              row.node.stripeInvoice?.paidManuallyBy && (
                <>{row.node.stripeInvoice?.paidManuallyBy}</>
              )}
          </>
        )
      },
    },
    {
      Header: 'Amount',
      id: 'amount',
      serverSort: true,
      accessor: (row) => {
        return (
          <>
            {((!row.node.processing &&
              row.node.paidManually &&
              row.node.stripeInvoice) ||
              (row.node.processing && row.node.stripeInvoice)) && (
              <>${(row.node.stripeInvoice.amountDue / 100).toFixed(2)}</>
            )}
            {!row.node.processing && !row.node.paidManually && (
              <>${(row.node.amount / 100).toFixed(2)}</>
            )}
          </>
        )
      },
    },
    {
      Header: 'Status',
      id: 'status',
      serverSort: true,
      accessor: (row) => {
        let declinedStripeInvoicePaid = false
        if (
          row.node.declinedStripeInvoices.edges.length &&
          row.node.declinedStripeInvoices.edges[0].node.paid
        ) {
          declinedStripeInvoicePaid = true
        }
        return (
          <div
            className={
              row.node.status === 'Declined' && !declinedStripeInvoicePaid
                ? 'btn-link sessions-buttons'
                : 'sessions-buttons'
            }
            onClick={() => {
              if (
                row.node.status == 'Declined' &&
                !row.node.stripeInvoice &&
                !declinedStripeInvoicePaid
              ) {
                setShowChargeModal({
                  sessionId: row.node.session.id,
                  gaiaUserId:
                    row.node.stripePaymentMethod.stripeCustomer.gaiaUser.id,
                  amount: row.node.amount / 100,
                  description: row.node.description,
                  declinedStripePaymentIntentId: row.node.id,
                })
              } else {
                if (!invoiceModal && row.node.session) {
                  setSessionDetailId(row.node.session.id)
                  if (contact) {
                    toggleSubjectSessionDetailModal()
                  } else {
                    toggleSessionDetailModal()
                  }
                } else if (row.node?.stripeInvoice) {
                  setInvoiceModalId(row.node?.stripeInvoice.id)
                }
              }
            }}
          >
            {row.node.paidManually ? 'Paid Manually' : row.node.status}
          </div>
        )
      },
    },
    {
      Header: 'On',
      id: 'on',
      serverSort: true,
      orderBy: 'created',
      accessor: (row) => {
        const createdDt = formatDateTimeToString(new Date(row.node.created))
        return <>{createdDt}</>
      },
    },
    {
      Header: 'Receipt',
      id: 'receipt',
      accessor: (row) => {
        return (
          <Button
            className="btn-link"
            disabled={downloadingReceipt}
            onClick={() => {
              setDownloadingReceipt(row.node.id)
              downloadPayments({
                variables: {
                  input: {
                    fileType: 'receipt',
                    stripePaymentIntentId: row.node.id,
                  },
                },
              })
            }}
          >
            {downloadingReceipt === row.node.id ? (
              <Loading height={'20'} />
            ) : (
              <span style={{ fontSize: '20px' }}>
                <CloudArrowDown />
              </span>
            )}
          </Button>
        )
      },
    },
  ]
  if (canRefund) {
    const columns = [
      {
        Header: 'Stripe Fee',
        id: 'stripeFee',
        serverSort: true,
        orderBy: 'fee',
        accessor: (row) => {
          return (
            <>
              {!row.node.paidManually && !row.node.processing && (
                <>${(row.node.fee / 100).toFixed(2)}</>
              )}
              {row.node.processing && row.node.stripeInvoice && <>N/A</>}
            </>
          )
        },
      },
      {
        Header: 'Net Amount',
        id: 'netAmount',
        serverSort: true,
        orderBy: 'net_amount',
        accessor: (row) => {
          return (
            <>
              {((!row.node.processing &&
                row.node.paidManually &&
                row.node.stripeInvoice) ||
                (row.node.processing && row.node.stripeInvoice)) && (
                <>${(row.node.stripeInvoice.amountDue / 100).toFixed(2)}</>
              )}
              {!row.node.processing && !row.node.paidManually && (
                <>${(row.node.netAmount / 100).toFixed(2)}</>
              )}
            </>
          )
        },
      },
    ]
    tableColumns.splice(8, 0, ...columns)
    tableColumns.push({
      Header: 'Refund',
      id: 'refund',
      serverSort: true,
      orderBy: 'refunded_by__full_name',
      accessor: (row) => {
        if (!row.node.paidManually) {
          if (!row.node.refunded && !row.node.declined) {
            return (
              <div
                className="sessions-buttons btn-link"
                onClick={() => {
                  setPayementId(row.node.id)
                  toggleSessionRefundModal()
                }}
              >
                Refund
              </div>
            )
          } else if (row.node.refundedBy?.fullName) {
            return `By ${row.node.refundedBy?.fullName}`
          } else if (row.node.refundedBySystem) {
            return 'By Airstudio'
          }
        }
      },
    })
  }
  if (subjectId) {
    tableColumns.splice(1, 1)
  }
  if (organizationId) {
    tableColumns.splice(3, 1)
  }
  if (subjectViewing) {
    tableColumns.splice(1, 4)
  }

  if (!initialQueryRun || !stripeConnectorQueryData) return <></>

  if (stripePaymentIntentsQueryError || stripeConnectorQueryError)
    return <>Error loading payments</>
  return (
    <>
      {invoiceModalId && (
        <InvoiceModal
          showModal={invoiceModalId}
          toggleModal={() => setInvoiceModalId()}
          stripeInvoiceId={invoiceModalId}
          hideOrganization={organizationId ? true : false}
        />
      )}
      {showChargeModal && (
        <ChargePaymentModal
          showModal={true}
          toggleModal={() => setShowChargeModal()}
          sessionId={showChargeModal.sessionId}
          amount={showChargeModal.amount}
          gaiaUserId={showChargeModal.gaiaUserId}
          description={showChargeModal.description}
          declinedStripePaymentIntentId={
            showChargeModal.declinedStripePaymentIntentId
          }
          chargeGaiaUserPaymentMethod
        />
      )}
      {showReport && (
        <PaymentChartModal
          showModal={showReport}
          startDateTime={startDateFilter}
          organizationId={organizationId}
          subjectGroupId={subjectGroupId}
          sessionId={sessionId}
          subjectId={subjectId}
          jobId={jobId}
          endDataTime={endDateFilter}
          toggleModal={() => {
            setShowReport()
          }}
        />
      )}
      {!stripeConnectorQueryData.stripeConnectors.edges[0].node
        .connectedAccountId ? (
        <>
          <Row className="mb-2 mt-1">
            <Col>
              <Badge
                className="ml-2"
                style={{ fontSize: '12px' }}
                variant="danger"
              >
                Stripe Disabled
              </Badge>
            </Col>
          </Row>
        </>
      ) : null}
      <>
        {!stripeInvoiceId && (
          <>
            <Row>
              <Col>
                {stripeConnectorQueryData.stripeConnectors.edges[0].node
                  .connectedAccountId &&
                  admin && (
                    <Button variant="link" onClick={handleStripeDashboardClick}>
                      <span>
                        <Cash className="mr-2" />
                        Stripe Dashboard
                      </span>
                    </Button>
                  )}
                {canRefund && !stripeInvoiceId && (
                  <Button
                    variant="link"
                    onClick={() => {
                      setShowReport(true)
                    }}
                  >
                    <span>
                      <BarChartLine className="mr-2" />
                      Report
                    </span>
                  </Button>
                )}
                {!stripeInvoiceId && (
                  <>
                    <Button
                      variant="link"
                      onClick={() => {
                        setFilterDecline(!filterDeclined)
                      }}
                    >
                      <span>
                        <Funnel className="mr-2" />
                        {!filterDeclined ? <>Declined</> : <>All</>}
                      </span>
                    </Button>
                    <Button
                      variant="link"
                      onClick={() => {
                        setFilterInvoice(!filterInvoice)
                      }}
                    >
                      <span>
                        <Funnel className="mr-2" />
                        {!filterInvoice ? <>Invoice</> : <>All</>}
                      </span>
                    </Button>
                    <Button
                      variant="link"
                      onClick={() => {
                        setFilterSession(!filterSession)
                      }}
                    >
                      <span>
                        <Funnel className="mr-2" />
                        {!filterSession ? <>Session</> : <>All</>}
                      </span>
                    </Button>
                  </>
                )}
                {startDateFilter && endDateFilter && canDownloadPayments && (
                  <>
                    <Button
                      variant="link"
                      disabled={downloadingPdf}
                      onClick={() => {
                        setDownloadingPdf(true)
                        downloadPayments({
                          variables: {
                            input: {
                              fileType: 'pdf',
                              startDate: startDateFilter,
                              endDate: endDateFilter,
                              subjectId,
                              organizationId,
                              subjectGroupId,
                              sessionId,
                              sessions: filterSession,
                              invoices: filterInvoice,
                              declined: filterDeclined,
                            },
                          },
                        })
                      }}
                    >
                      <>
                        {downloadingPdf && <Loading height={'20'} />}
                        {!downloadingPdf && !downloadingExcel && (
                          <>
                            <CloudArrowDown className="mr-2" />
                            Download PDF
                          </>
                        )}
                      </>
                    </Button>
                    <Button
                      variant="link"
                      disabled={downloadingExcel}
                      onClick={() => {
                        setDownloadingExcel(true)
                        downloadPayments({
                          variables: {
                            input: {
                              fileType: 'xlsx',
                              startDate: startDateFilter,
                              endDate: endDateFilter,
                              subjectId,
                              organizationId,
                              subjectGroupId,
                              sessionId,
                              sessions: filterSession,
                              invoices: filterInvoice,
                              declined: filterDeclined,
                            },
                          },
                        })
                      }}
                    >
                      <>
                        {downloadingExcel && <Loading height={'20'} />}
                        {!downloadingPdf && !downloadingExcel && (
                          <>
                            <CloudArrowDown className="mr-2" />
                            Download Excel
                          </>
                        )}
                      </>
                    </Button>
                  </>
                )}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} md={startDateFilter && endDateFilter ? 3 : 4}>
                <Form.Group>
                  <Form.Control
                    type="text"
                    name="searchTerm"
                    placeholder={searchText}
                    className="form-control-sm"
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                  />
                </Form.Group>
              </Col>
              <Col md={4} style={{ marginTop: '-8px', marginLeft: '-10px' }}>
                <DateFilter
                  startDateFilter={startDateFilter}
                  setStartDateFilter={setStartDateFilter}
                  endDateFilter={endDateFilter}
                  setEndDateFilter={setEndDateFilter}
                  placeholderStart="Payments From"
                  placeholderEnd={'Payment Until'}
                />
              </Col>
            </Row>
          </>
        )}
        {(!invoiceModal ||
          stripePayments?.stripePaymentIntents?.edges.length > 0) && (
          <Row>
            <Col>
              <SortableInfiniteTable
                tableData={
                  stripePayments?.stripePaymentIntents?.edges
                    ? stripePayments?.stripePaymentIntents?.edges
                    : []
                }
                tableColumns={tableColumns}
                loading={loading}
                fetchMoreTableData={fetchMore}
                hasMoreTableData={hasMoreStripePaymentIntents}
                tableHeight={
                  tableHeight ? tableHeight : stripeInvoiceId ? 130 : 700
                }
                onTdClicks={{
                  id: onTdClick,
                  subject: onTdClick,
                  organization: onTdClick,
                  subjectGroup: onTdClick,
                  description: onTdClick,
                  paymentMethod: onTdClick,
                  amount: onTdClick,
                  on: onTdClick,
                  stripeFee: onTdClick,
                  netAmount: onTdClick,
                  tax: onTdClick,
                }}
                hideGlobalFilter
                rowPointer={invoiceModal ? false : true}
                handleSortByChange={handleSortByChange}
              />
            </Col>
          </Row>
        )}
        {stripeInvoiceId &&
          stripePaymentIntentsQueryData?.stripePaymentIntents?.edges.length ===
            0 && <p>- - -</p>}
        <AdminSessionDetailModal
          sessionId={sessionDetailId}
          showModal={showSessionDetailModal}
          toggleModal={toggleSessionDetailModal}
        />
        <RefundPaymentModal
          stripePaymentId={paymentId}
          showModal={showSessionRefundModal}
          toggleModal={toggleSessionRefundModal}
        />
        {showSubjectSessionDetailModal && (
          <SessionDetailModal
            sessionId={sessionDetailId}
            showModal={showSubjectSessionDetailModal}
            toggleModal={toggleSubjectSessionDetailModal}
          />
        )}
      </>
    </>
  )
}

export default PaymentsTable
