import React, { useState, useEffect } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { Row, Col, Button, Form, ButtonGroup } from 'react-bootstrap'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import PromoCodeModal from './PromoCodeModal'
import { DateTime } from 'luxon'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../libs/apollo'
import { Cash, PlusCircle, Trash } from 'react-bootstrap-icons'
import { useHistory } from 'react-router-dom'
import Loading from '../common/Loading'
import DeletePromoModal from './DeletePromoModal'

const PromoCodes = () => {
  const history = useHistory()
  const loggedInUser = useReactiveVar(loggedInUserVar)
  useEffect(() => {
    if (
      ['General Staff', 'Equipment Manager'].includes(
        loggedInUser?.permissions?.group
      )
    ) {
      history.push('/schedule')
    }
  })
  const defaultOrderBy = 'code'
  const [orderBy, setOrderBy] = useState(defaultOrderBy)

  const canMutate = ['Scheduling Manager', 'Administrator'].includes(
    loggedInUser?.permissions?.group
  )
  const [searchText, setSearchText] = useState()
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [editCoupon, setEditCoupon] = useState({})
  const [searchTerm, setSearchTerm] = useState('')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [checkedcoupons, setCheckedCoupons] = useState([])
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [coupons, setCoupons] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const [
    query,
    { data: couponData, error: couponError, fetchMore: fetchMoreCoupons },
  ] = useLazyQuery(
    gql`
      query CouponsQuery(
        $cursor: String
        $searchTerm: String
        $orderBy: String
      ) {
        coupons(
          first: 25
          after: $cursor
          orderBy: $orderBy
          code_Icontains: $searchTerm
        ) {
          pageInfo {
            hasNextPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              totalSessionRevenue
              totalSessionSavings
              totalSessionRevenueAfterSavings
              code
              contentType {
                model
                id
              }
              dollarSavings
              percentSavings
              expiresOn
              usableCount
              created
              usedCount
              archived
              enabled
              sessionPackages {
                edges {
                  node {
                    id
                    title
                  }
                }
              }
              subjectGroups {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              organizations {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
          nodeCount
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      // pollInterval: 30000,
    }
  )

  const handleFetchMoreCoupons = () => {
    const variables = {
      cursor: couponData.coupons.pageInfo.endCursor,
      orderBy,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    fetchMoreCoupons({ variables })
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    setLoadingSearch(true)
    query({
      variables: {
        searchTerm: currentSearchTerm,
        orderBy,
      },
    })
  }
  const handleCouponCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedCoupons((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedCoupons((prevState) =>
        prevState.filter((id) => id !== row.node.id)
      )
    }
  }

  const toggleShowAddPromoCodeModal = () => {
    setShowModal((prevState) => !prevState)
  }

  const handlePromoCodeDetailClick = (row) => {
    const couponDataNode = couponData.coupons.edges.filter(
      (couponNode) => couponNode.node.id === row.original.node.id
    )[0].node
    setEditCoupon(couponDataNode)
    setShowModal(true)
  }

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query({
        variables: {
          orderBy,
        },
      })
    }
  }, [initialQueryRun, setInitialQueryRun])

  const handleSortByChange = (currentOrderBy) => {
    if (currentOrderBy === '' && orderBy === defaultOrderBy) return

    currentOrderBy = currentOrderBy ? currentOrderBy : defaultOrderBy
    setOrderBy(currentOrderBy)
    const variables = {
      orderBy: currentOrderBy,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    query({ variables })
  }

  useEffect(() => {
    if (couponData?.coupons) {
      if (loadingSearch) {
        setLoadingSearch(false)
      }
      setCoupons(couponData.coupons.edges)
      setHasMore(couponData.coupons.pageInfo.hasNextPage)
      let text = 'Search 0 Coupons'
      if (couponData.coupons.nodeCount > 0) {
        text = `Search ${couponData.coupons.nodeCount} Coupons`
      }
      setSearchText(text)
    }
  }, [couponData])
  if (couponError) return <>Error loading promo codes</>
  if (!initialQueryRun && !couponData)
    return (
      <Row className="mt-3">
        <Col>
          <Loading message="Loading Coupons..." />
        </Col>
      </Row>
    )
  return (
    <>
      <div>
        <Row className="mt-4">
          <Col md={4}>
            <Form.Group>
              <Form.Control
                type="text"
                name="searchTerm"
                className="form-control-sm"
                placeholder={searchText}
                value={searchTerm}
                onChange={handleSearchTermChange}
              />
            </Form.Group>
          </Col>
          {canMutate && (
            <Col className="d-md-flex justify-content-md-end">
              <div>
                <Button
                  className="ml-2"
                  variant="link"
                  onClick={toggleShowAddPromoCodeModal}
                >
                  <PlusCircle className="mr-2 mb-1" />
                  New Coupon
                </Button>
              </div>
              {checkedcoupons.length > 0 && (
                <Button
                  variant="link"
                  onClick={() => {
                    setShowDeleteModal(true)
                  }}
                >
                  <Trash className="mr-2" />
                  {checkedcoupons.length === 1 ? (
                    <>Delete Coupon</>
                  ) : (
                    <>Delete Coupons</>
                  )}
                </Button>
              )}
            </Col>
          )}
        </Row>
        <Row className="mt-4">
          <Col>
            <SortableInfiniteTable
              tableData={coupons}
              loading={loadingSearch || !couponData?.coupons?.edges}
              loadingMessage="Loading Coupons..."
              tableHeight={700}
              tableColumns={[
                {
                  Header: 'Code',
                  id: 'Code',
                  serverSort: true,
                  orderBy: 'code',
                  accessor: (row) => (
                    <>
                      {row.node.code}
                      {!row.node.enabled && (
                        <span className="ml-1">(disabled)</span>
                      )}
                    </>
                  ),
                },
                {
                  Header: 'Amount',
                  id: 'Amount',
                  accessor: (row) =>
                    row.node.dollarSavings
                      ? `$${row.node.dollarSavings}`
                      : `${row.node.percentSavings}%`,
                },
                {
                  Header: 'End Date',
                  id: 'EndDate',
                  serverSort: true,
                  orderBy: 'expires_on',
                  accessor: (row) =>
                    row.node.expiresOn != null
                      ? DateTime.fromISO(row.node.expiresOn).toFormat(
                          'LLL dd, yyyy'
                        )
                      : 'Never',
                },
                {
                  Header: 'Organizations',
                  id: 'Organizations',
                  accessor: (row) =>
                    row.node.organizations.edges.length > 0 ? (
                      <>
                        <ul>
                          {row.node.organizations.edges.map((organization) => (
                            <li key={organization.node.id}>
                              {organization.node.name}
                            </li>
                          ))}
                        </ul>
                      </>
                    ) : (
                      <>
                        {row.node.subjectGroups.edges.length > 0 ? '' : 'All'}
                      </>
                    ),
                },
                {
                  Header: 'Subject Groups',
                  id: 'SubjectGroups',
                  accessor: (row) =>
                    row.node.subjectGroups.edges.length > 0 ? (
                      <>
                        <ul>
                          {row.node.subjectGroups.edges.map((sg) => (
                            <li key={sg.node.id}>{sg.node.name}</li>
                          ))}
                        </ul>
                      </>
                    ) : (
                      <>
                        {row.node.organizations.edges.length > 0 ? '' : 'All'}
                      </>
                    ),
                },
                {
                  Header: 'Session Packages',
                  id: 'SessionPackages',
                  accessor: (row) =>
                    row.node.sessionPackages.edges.length > 0 ? (
                      <>
                        <ul>
                          {row.node.sessionPackages.edges.map(
                            (sessionPackage) => (
                              <li key={sessionPackage.node.id}>
                                {sessionPackage.node.title}
                              </li>
                            )
                          )}
                        </ul>
                      </>
                    ) : (
                      'All'
                    ),
                },
                {
                  Header: 'Limit',
                  id: 'Limit',
                  accessor: (row) =>
                    row.node.usableCount ? row.node.usableCount : 'Unlimited',
                },
                {
                  Header: 'Used',
                  id: 'Used',
                  accessor: 'node.usedCount',
                },
                {
                  disableSortBy: true,
                  Header: (
                    <>
                      <Form.Group
                        as={ButtonGroup}
                        className="align-items-center"
                      >
                        <Form.Check
                          className="ml-2 mt-2"
                          type="checkbox"
                          onChange={(e) => {
                            if (e.target.checked) {
                              const appendIds = []
                              coupons.forEach((coupon) => {
                                if (!checkedcoupons.includes(coupon.node.id)) {
                                  appendIds.push(coupon.node.id)
                                }
                              })
                              setCheckedCoupons((prevState) => {
                                return [...prevState, ...appendIds]
                              })
                            } else {
                              setCheckedCoupons([])
                            }
                          }}
                        />
                        {checkedcoupons.length > 0 && (
                          <span style={{ fontSize: '14px', marginTop: '5px' }}>
                            ({checkedcoupons.length})
                          </span>
                        )}
                      </Form.Group>
                    </>
                  ),
                  id: 'delete',
                  accessor: (row) => {
                    return (
                      <>
                        <Form.Group
                          as={ButtonGroup}
                          className="align-items-center"
                        >
                          <Form.Check
                            className="ml-2 mt-2"
                            type="checkbox"
                            checked={checkedcoupons.includes(row.node.id)}
                            onChange={(e) => handleCouponCheck(e, row)}
                          />
                        </Form.Group>
                      </>
                    )
                  },
                },
              ]}
              fetchMoreTableData={handleFetchMoreCoupons}
              hasMoreTableData={hasMore}
              handleSortByChange={handleSortByChange}
              onTdClicks={{
                Code: (cell) => handlePromoCodeDetailClick(cell.row),
                Amount: (cell) => handlePromoCodeDetailClick(cell.row),
                EndDate: (cell) => handlePromoCodeDetailClick(cell.row),
                Organizations: (cell) => handlePromoCodeDetailClick(cell.row),
                SubjectGroups: (cell) => handlePromoCodeDetailClick(cell.row),
                SessionPackages: (cell) => handlePromoCodeDetailClick(cell.row),
                Limit: (cell) => handlePromoCodeDetailClick(cell.row),
                Used: (cell) => handlePromoCodeDetailClick(cell.row),
              }}
              rowPointer
              hideGlobalFilter
            />
          </Col>
        </Row>
        <PromoCodeModal
          showModal={showModal}
          editCoupon={editCoupon}
          setEditCoupon={setEditCoupon}
          toggleModal={toggleShowAddPromoCodeModal}
        />
        <DeletePromoModal
          showModal={showDeleteModal}
          toggleModal={() => {
            setShowDeleteModal(false)
          }}
          setCheckedCoupons={setCheckedCoupons}
          ids={checkedcoupons.length > 1 ? checkedcoupons : null}
          id={checkedcoupons.length === 1 ? checkedcoupons[0] : null}
        />
      </div>
    </>
  )
}
export default PromoCodes
