import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { Row, Col, Form, Button, ButtonGroup } from 'react-bootstrap'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import { Cart3, PlusCircle, Trash } from 'react-bootstrap-icons'
import ProductModal from './ProductModal'
import DeleteProductModal from './DeleteProductModal'
import Loading from '../../common/Loading'

const Products = () => {
  const [searchText, setSearchText] = useState()
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [hasMoreData, setHasMoreData] = useState(false)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [products, setProducts] = useState([])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [checkedProducts, setCheckedProducts] = useState([])
  const [editProduct, setEditProduct] = useState()
  const defaultOrderBy = 'name'
  const [orderBy, setOrderBy] = useState(defaultOrderBy)

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query Products($cursor: String, $searchTerm: String, $orderBy: String) {
        products(
          first: 20
          after: $cursor
          name_Icontains: $searchTerm
          orderBy: $orderBy
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              name
              system
              chargeSalesTax
              salesTaxRate
              description
              defaultPrice
              contentType {
                model
                id
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      // pollInterval: 30000,
    }
  )

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query({
        variables: {
          orderBy,
        },
      })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.products) {
      if (loadingSearch) {
        setLoadingSearch(false)
      }
      setHasMoreData(queryData?.products?.pageInfo?.hasNextPage)
      setProducts(queryData.products?.edges)
      let text = 'Search 0 Products'
      if (queryData.products.nodeCount > 0) {
        text = `Search ${queryData.products.nodeCount} Products`
      }
      setSearchText(text)
    }
  }, [queryData])

  const fetchMore = () => {
    const variables = {
      cursor: queryData?.products?.pageInfo?.endCursor,
      orderBy,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    queryFetchMore({
      variables,
    })
  }

  const handleSortByChange = (currentOrderBy) => {
    if (currentOrderBy === '' && orderBy === defaultOrderBy) return

    currentOrderBy = currentOrderBy ? currentOrderBy : defaultOrderBy
    setOrderBy(currentOrderBy)
    const variables = {
      orderBy: currentOrderBy,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    query({ variables })
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    setLoadingSearch(true)
    query({
      variables: {
        searchTerm: currentSearchTerm,
        orderBy,
      },
    })
  }

  const handleProductCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedProducts((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedProducts((prevState) =>
        prevState.filter((id) => id !== row.node.id)
      )
    }
  }

  const onTdClick = (row) => {
    toggleModal(row)
  }

  const toggleModal = (node = null) => {
    if (node) {
      setEditProduct(node)
    } else {
      setEditProduct()
    }
    if (showForm) {
      setShowForm(false)
    } else {
      setShowForm(true)
    }
  }

  if (!initialQueryRun) return <></>
  if (queryError) return <>Error loading</>
  return (
    <>
      <div>
        <>
          <Row>
            <Col md={4}>
              <Form.Group>
                <Form.Control
                  type="text"
                  name="searchTerm"
                  className="form-control-sm"
                  placeholder={searchText}
                  value={searchTerm}
                  onChange={handleSearchTermChange}
                />
              </Form.Group>
            </Col>
            <Col className="d-flex justify-content-end align-items-center mb-1">
              <div>
                <Button
                  variant="link"
                  onClick={() => {
                    toggleModal()
                  }}
                >
                  <PlusCircle className="mr-2" />
                  New Product
                </Button>
              </div>
              {checkedProducts.length > 0 && (
                <Button
                  variant="link"
                  onClick={() => {
                    setShowDeleteModal(true)
                  }}
                >
                  <Trash className="mr-2" />
                  {checkedProducts.length === 1 ? (
                    <>Delete Product</>
                  ) : (
                    <>Delete Products</>
                  )}
                </Button>
              )}
            </Col>
          </Row>
          <Row className="mt-2 mb-3">
            <Col md="12">
              <SortableInfiniteTable
                loading={loadingSearch || !queryData?.products?.edges}
                tableData={products}
                tableColumns={[
                  {
                    Header: 'Name',
                    accessor: 'node.name',
                    id: 'name',
                    serverSort: true,
                  },
                  {
                    Header: 'Default Price',
                    accessor: (row) =>
                      row.node.defaultPrice ? `$${row.node.defaultPrice}` : '',
                    id: 'defaultPrice',
                    serverSort: true,
                    orderBy: 'default_price',
                  },
                  {
                    Header: 'Default Charge Sales Tax',
                    accessor: (row) => {
                      if (!row.node.system) {
                        return row.node.chargeSalesTax ? 'Yes' : 'No'
                      }
                    },
                    id: 'chargeSalesTax',
                    serverSort: true,
                    orderBy: 'charge_sales_tax',
                  },
                  {
                    Header: 'Default Sales Tax Rate',
                    accessor: (row) =>
                      row.node.chargeSalesTax ? `${row.node.salesTaxRate}` : '',
                    id: 'salesTaxRate',
                    serverSort: true,
                    orderBy: 'sales_tax_rate',
                  },
                  {
                    Header: 'Description',
                    accessor: 'node.description',
                    id: 'description',
                    serverSort: true,
                    orderBy: 'description',
                  },
                  {
                    disableSortBy: true,
                    Header: (
                      <>
                        <Form.Group
                          as={ButtonGroup}
                          className="align-items-center"
                        >
                          <Form.Check
                            className="ml-2 mt-2"
                            type="checkbox"
                            onChange={(e) => {
                              if (e.target.checked) {
                                const appendIds = []
                                products.forEach((product) => {
                                  if (
                                    !checkedProducts.includes(product.node.id)
                                  ) {
                                    appendIds.push(product.node.id)
                                  }
                                })
                                setCheckedProducts((prevState) => {
                                  return [...prevState, ...appendIds]
                                })
                              } else {
                                setCheckedProducts([])
                              }
                            }}
                          />
                          {checkedProducts.length > 0 && (
                            <span
                              style={{ fontSize: '14px', marginTop: '5px' }}
                            >
                              ({checkedProducts.length})
                            </span>
                          )}
                        </Form.Group>
                      </>
                    ),
                    id: 'delete',
                    accessor: (row) => {
                      return (
                        <>
                          <Form.Group
                            as={ButtonGroup}
                            className="align-items-center"
                          >
                            <Form.Check
                              className="ml-2 mt-2"
                              type="checkbox"
                              checked={checkedProducts.includes(row.node.id)}
                              onChange={(e) => handleProductCheck(e, row)}
                            />
                          </Form.Group>
                        </>
                      )
                    },
                  },
                ]}
                fetchMoreTableData={fetchMore}
                loadingMessage="Loading Products..."
                hasMoreTableData={hasMoreData}
                onTdClicks={{
                  name: (cell) => onTdClick(cell.row.original.node),
                  defaultPrice: (cell) => onTdClick(cell.row.original.node),
                  chargeSalesTax: (cell) => onTdClick(cell.row.original.node),
                  salesTaxRate: (cell) => onTdClick(cell.row.original.node),
                  description: (cell) => onTdClick(cell.row.original.node),
                }}
                tableHeight={700}
                rowPointer
                handleSortByChange={handleSortByChange}
                hideGlobalFilter
              />
            </Col>
          </Row>
        </>
        {showForm && (
          <ProductModal
            showModal={showForm}
            product={editProduct}
            toggleModal={toggleModal}
          />
        )}
        <DeleteProductModal
          showModal={showDeleteModal}
          toggleModal={() => {
            setShowDeleteModal(false)
          }}
          setCheckedProducts={setCheckedProducts}
          ids={checkedProducts.length > 1 ? checkedProducts : null}
          id={checkedProducts.length === 1 ? checkedProducts[0] : null}
        />
      </div>
    </>
  )
}
export default Products
