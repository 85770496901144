import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql, useMutation, useReactiveVar } from '@apollo/client'
import { Row, Col, Form, Button, ButtonGroup, Table } from 'react-bootstrap'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import {
  QrCode,
  FileEarmarkText,
  Trash,
  Tag,
  Funnel,
  CloudArrowDown,
  PlusCircle,
  Dot,
} from 'react-bootstrap-icons'
import BagModal from './BagModal'
import toast from 'react-hot-toast'
import DeleteBagModal from './DeleteBagModal'
import { loggedInUserVar } from '../../../libs/apollo'
import { useDownloadAndDeleteFile } from '../../../libs/downloadAndDeleteFile'
import { formatDateTime } from '../../../libs/utils'

const Bags = (props) => {
  const { employeeId, employeeName, equipmentBagTypeId } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const defaultOrderBy = 'name'
  const [orderBy, setOrderBy] = useState(defaultOrderBy)
  const [searchText, setSearchText] = useState()
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [hasMoreData, setHasMoreData] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [editBag, setEditBag] = useState()
  const [downloading, setDownloading] = useState(false)
  const [checkedBagIds, setCheckedBags] = useState([])
  const [downloadSheetsLoading, setDownloadSheetsLoading] = useState(false)
  const [downloadingLabels, setDownloadingLabels] = useState(false)
  const [filterReturned, setFilterReturned] = useState(
    employeeId ? 'checkedOut' : 'all'
  )
  const { downloadAndDeleteFile, downloadFile } = useDownloadAndDeleteFile()

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query EquipmentBags(
        $cursor: String
        $searchTerm: String
        $orderBy: String
        $employeeId: String
        $returned: Boolean
        $equipmentBagType: ID
      ) {
        equipmentBags(
          first: 20
          after: $cursor
          returned: $returned
          name_Icontains: $searchTerm
          employeeAssignments: $employeeId
          orderBy: $orderBy
          equipmentBagType: $equipmentBagType
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              name
              contentType {
                model
                id
              }
              employeeEmployeeJobs {
                job {
                  name
                }
                startDateTime
                endDateTime
              }
              returned
              notes
              equipmentCategory {
                id
                name
              }
              qrCodeFile {
                fileName
                displayName
                fileUrl
              }
              equipmentBagType {
                id
                name
                equipmentItemTypesList {
                  id
                  name
                  miscellaneous
                }
              }
              nextEmployeeJob {
                job {
                  name
                }
                startDateTime
                endDateTime
                employee {
                  gaiaUser {
                    fullName
                  }
                }
              }
              employee {
                gaiaUser {
                  fullName
                }
                id
              }
              equipmentItems {
                edges {
                  node {
                    id
                    name
                    serialNumber
                    equipmentBag {
                      id
                      name
                    }
                    equipmentItemType {
                      name
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: equipmentBagTypeId ? 'no-cache' : 'network-only',
      errorPolicy: 'all',
      // pollInterval: 30000,
      variables: {
        employeeId,
        returned: employeeId ? false : null,
      },
    }
  )
  const determineReturnedVariable = () => {
    let returned
    if (filterReturned === 'checkedOut') {
      returned = false
    } else if (filterReturned === 'checkedIn') {
      returned = true
    }
    return returned
  }

  useEffect(() => {
    if (initialQueryRun) {
      const variables = constructQueryVariables()
      variables.cursor = null
      query({ variables })
    }
  }, [filterReturned])

  const [downloadBagsPdf] = useMutation(
    gql`
      mutation DownloadEquipmentBag($input: DownloadEquipmentBagInput!) {
        downloadEquipmentBag(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        if (data?.downloadEquipmentBag?.file?.fileName) {
          downloadAndDeleteFile(
            data.downloadEquipmentBag.file.fileName,
            data.downloadEquipmentBag.file.displayName,
            data.downloadEquipmentBag.file.id,
            () => {
              setDownloading(false)
              if (downloadingLabels) {
                toast.success(`Labels Downloaded`)
                setDownloadingLabels(false)
              } else {
                toast.success(`Bags Downloaded`)
                setDownloadSheetsLoading(false)
              }
            }
          )
        }
      },
      errorPolicy: 'all',
    }
  )

  const constructQueryVariables = () => {
    const variables = {
      employeeId,
      returned: determineReturnedVariable(),
      cursor: queryData?.equipmentBags?.pageInfo?.endCursor,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    if (equipmentBagTypeId) {
      variables.equipmentBagType = equipmentBagTypeId
    }
    return variables
  }

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      const variables = constructQueryVariables()
      query({ variables })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.equipmentBags) {
      if (loadingSearch) {
        setLoadingSearch(false)
      }
      setHasMoreData(queryData?.equipmentBags?.pageInfo?.hasNextPage)
      let text = 'Search 0 Bags'
      if (queryData.equipmentBags.nodeCount > 0) {
        text = `Search ${queryData.equipmentBags.nodeCount} Bags`
      }
      setSearchText(text)
    }
  }, [queryData])

  const fetchMore = () => {
    const variables = constructQueryVariables()
    queryFetchMore({
      variables,
    })
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    const variables = constructQueryVariables()
    variables.cursor = null
    variables.searchTerm = currentSearchTerm
    setLoadingSearch(true)
    query({ variables })
  }

  const handleSortByChange = (currentOrderBy) => {
    if (currentOrderBy === '' && orderBy === defaultOrderBy) return

    currentOrderBy = currentOrderBy ? currentOrderBy : defaultOrderBy
    setOrderBy(currentOrderBy)
    const variables = constructQueryVariables()
    variables.cursor = null
    variables.orderBy = currentOrderBy
    query({ variables })
  }

  const onTdClick = (cell) => {
    toggleModal(cell.row.original.node)
  }

  const toggleModal = (node = null) => {
    if (node) {
      setEditBag(node)
    } else {
      setEditBag()
    }
    if (showForm) {
      setShowForm(false)
    } else {
      setShowForm(true)
    }
  }

  const handleBagCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedBags((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedBags((prevState) =>
        prevState.filter((id) => id !== row.node.id)
      )
    }
  }
  const tableColumns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: 'node.name',
      serverSort: true,
    },
    {
      Header: 'Items',
      id: 'items',
      accessor: (row) => {
        if (row.node.equipmentItems.edges.length > 0) {
          return (
            <div
              style={{
                maxHeight: '75px',
                overflowY: 'scroll',
                overflowX: 'hidden',
                fontSize: '12px',
              }}
              className="mt-2"
            >
              <Table className="compact-table table-bordered" responsive>
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody>
                  {row.node.equipmentItems.edges.map((edge, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          {edge.node.name}
                          {edge.node.serialNumber && (
                            <> #{edge.node.serialNumber}</>
                          )}
                        </td>
                        <td>
                          {edge.node.equipmentItemType && (
                            <>{edge.node.equipmentItemType.name}</>
                          )}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          )
        }
      },
    },
    {
      Header: 'Checked In',
      id: 'returned',
      accessor: (row) => {
        return row.node.returned ? 'Yes' : 'No'
      },
      serverSort: true,
    },
    {
      Header: 'Long Term Assignment',
      id: 'longTermAssignment',
      accessor: (row) => {
        if (row.node.employee && !employeeId) {
          return row.node.employee.gaiaUser.fullName
        } else if (employeeId) {
          if (row.node.employee) {
            return 'Yes'
          } else {
            return 'No'
          }
        }
      },
      serverSort: true,
      orderBy: 'employee__gaia_user__full_name',
    },
    {
      Header: 'Next Job Assignment',
      id: 'nextJobAssignment',
      accessor: (row) => {
        if (row.node.nextEmployeeJob) {
          return (
            <div style={{ fontSize: '12px' }}>
              <Dot className="mr-2" />
              Employee -{' '}
              {row.node.nextEmployeeJob.employee ? (
                <>{row.node.nextEmployeeJob.employee.gaiaUser.fullName}</>
              ) : (
                <>Unassigned</>
              )}
              <br />
              <Dot className="mr-2" />
              Job - {row.node.nextEmployeeJob.job.name}
              <br />
              <Dot className="mr-2" />
              Shift - {formatDateTime(
                row.node.nextEmployeeJob.startDateTime
              )} -{' '}
              {formatDateTime(
                row.node.nextEmployeeJob.endDateTime,
                false,
                true
              )}
            </div>
          )
        }
      },
    },
    {
      disableSortBy: true,
      Header: 'Download',
      id: 'downloads',
      accessor: (row) => {
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Button
                className="ml-3"
                variant="link"
                disabled={downloading}
                onClick={() => {
                  setDownloadingLabels(true)
                  setDownloading(true)
                  downloadBagsPdf({
                    variables: {
                      input: {
                        equipmentBagIds: [row.node.id],
                        type: 'labels',
                      },
                    },
                  })
                }}
              >
                <Tag />
              </Button>
              <Button
                className="ml-1"
                variant="link"
                disabled={downloading}
                onClick={() => {
                  setDownloading(true)
                  downloadFile(
                    row.node.qrCodeFile.fileName,
                    row.node.qrCodeFile.displayName,
                    () => {
                      setDownloading(false)
                      toast.success(`QR Code Downloaded`)
                    }
                  )
                }}
              >
                <QrCode />
              </Button>
              <Button
                className="ml-1"
                variant="link"
                disabled={downloading}
                onClick={() => {
                  setDownloading(true)
                  downloadBagsPdf({
                    variables: {
                      input: {
                        equipmentBagIds: [row.node.id],
                        type: 'pdf',
                      },
                    },
                  })
                }}
              >
                <FileEarmarkText /> {!canMutate && <>Download</>}
              </Button>
            </Form.Group>
          </>
        )
      },
    },
    {
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  if (queryData?.equipmentBags) {
                    queryData?.equipmentBags?.edges.forEach((bag) => {
                      if (!checkedBagIds.includes(bag.node.id)) {
                        appendIds.push(bag.node.id)
                      }
                    })
                    setCheckedBags((prevState) => {
                      return [...prevState, ...appendIds]
                    })
                  }
                } else {
                  setCheckedBags([])
                }
              }}
            />
            {checkedBagIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedBagIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'delete',
      accessor: (row) => {
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                checked={checkedBagIds.includes(row.node.id)}
                onChange={(e) => handleBagCheck(e, row)}
              />
            </Form.Group>
          </>
        )
      },
    },
  ]
  if (canMutate) {
    tableColumns.splice(1, 0, {
      Header: 'Bag Type',
      id: 'bagType',
      accessor: (row) => {
        if (row.node.equipmentBagType) {
          return row.node.equipmentBagType.name
        }
      },
      serverSort: true,
      orderBy: 'equipment_bag_type__name',
    })
  }
  if (employeeId) {
    tableColumns.splice(6, 0, {
      Header: `${employeeName} Job Assignments`,
      id: 'jobAssignments',
      accessor: (row) => {
        if (row.node.employeeEmployeeJobs.length > 0) {
          return (
            <>
              <div
                style={{
                  maxHeight: '75px',
                  maxWidth: '300px',
                  overflowY: 'scroll',
                  overflowX: 'hidden',
                  fontSize: '12px',
                }}
                className="mt-2"
              >
                <Table className="compact-table table-bordered" responsive>
                  <thead>
                    <tr>
                      <th>Job</th>
                      <th>Shift</th>
                    </tr>
                  </thead>
                  <tbody>
                    {row.node.employeeEmployeeJobs.map((empJob, i) => (
                      <tr key={i}>
                        <td>{empJob.job.name}</td>
                        <td>
                          {formatDateTime(empJob.startDateTime)} -{' '}
                          {formatDateTime(empJob.endDateTime, false, true)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </>
          )
        }
      },
    })
  }

  if (!initialQueryRun) return <></>
  if (queryError) return <>Error loading</>
  return (
    <>
      <div>
        <>
          <Row>
            <Col md={4}>
              <Form.Group>
                <Form.Control
                  type="text"
                  name="searchTerm"
                  className="form-control-sm"
                  placeholder={searchText}
                  value={searchTerm}
                  onChange={handleSearchTermChange}
                />
              </Form.Group>
            </Col>
            <Col className="d-flex justify-content-end align-items-center">
              {canMutate && !equipmentBagTypeId && (
                <Button
                  variant="link"
                  onClick={() => {
                    toggleModal()
                  }}
                >
                  <PlusCircle className="mr-2" />
                  New Bag
                </Button>
              )}
              <Button
                variant="link"
                onClick={() => {
                  if (filterReturned === 'all') {
                    setFilterReturned('checkedOut')
                  } else if (filterReturned === 'checkedOut') {
                    setFilterReturned('checkedIn')
                  } else if (filterReturned === 'checkedIn') {
                    setFilterReturned('all')
                  }
                }}
              >
                <span>
                  <Funnel className="mr-2" />
                  {filterReturned === 'all' && <>All</>}
                  {filterReturned === 'checkedOut' && <>Checked Out</>}
                  {filterReturned === 'checkedIn' && <>Checked In</>}
                </span>
              </Button>
              {checkedBagIds.length > 0 && (
                <>
                  <Button
                    variant="link"
                    disabled={downloadingLabels}
                    onClick={() => {
                      setDownloadingLabels(true)
                      setDownloading(true)
                      downloadBagsPdf({
                        variables: {
                          input: {
                            equipmentBagIds: checkedBagIds,
                            type: 'labels',
                          },
                        },
                      })
                    }}
                  >
                    <>
                      <CloudArrowDown className="mr-2" />
                      Download Labels
                    </>
                  </Button>
                  <Button
                    variant="link"
                    disabled={downloadSheetsLoading}
                    onClick={() => {
                      setDownloadSheetsLoading(true)
                      setDownloading(true)
                      downloadBagsPdf({
                        variables: {
                          input: {
                            equipmentBagIds: checkedBagIds,
                            type: 'pdf',
                          },
                        },
                      })
                    }}
                  >
                    <CloudArrowDown className="mr-2" />
                    {canMutate ? <>Download Info Sheets</> : <>Download</>}
                  </Button>

                  {canMutate && (
                    <Button
                      variant="link"
                      onClick={() => {
                        setShowDeleteModal(true)
                      }}
                    >
                      <Trash className="mr-2" />
                      {checkedBagIds.length === 1 ? (
                        <>Delete Bag</>
                      ) : (
                        <>Delete Bags</>
                      )}
                    </Button>
                  )}
                </>
              )}
            </Col>
          </Row>
          <Row className="mt-2 mb-3">
            <Col md="12">
              <SortableInfiniteTable
                tableData={
                  queryData?.equipmentBags?.edges
                    ? queryData?.equipmentBags?.edges
                    : []
                }
                loadingMessage="Loading Bags..."
                tableColumns={tableColumns}
                loading={loadingSearch || !queryData}
                fetchMoreTableData={fetchMore}
                hasMoreTableData={hasMoreData}
                onTdClicks={{
                  longTermAssignment: (cell) => onTdClick(cell),
                  returned: (cell) => onTdClick(cell),
                  items: (cell) => onTdClick(cell),
                  bagType: (cell) => onTdClick(cell),
                  category: (cell) => onTdClick(cell),
                  jobAssignments: (cell) => onTdClick(cell),
                  nextJobAssignment: (cell) => onTdClick(cell),
                  name: (cell) => onTdClick(cell),
                }}
                tableHeight={700}
                rowPointer
                hideGlobalFilter
                handleSortByChange={handleSortByChange}
              />
            </Col>
          </Row>
        </>
        {showForm ? (
          <BagModal
            showModal={showForm}
            bag={editBag}
            employeeId={employeeId}
            employeeName={employeeName}
            toggleModal={toggleModal}
          />
        ) : null}
        <DeleteBagModal
          showModal={showDeleteModal}
          toggleModal={() => {
            setShowDeleteModal(false)
          }}
          setCheckedBags={setCheckedBags}
          ids={checkedBagIds.length > 1 ? checkedBagIds : null}
          id={checkedBagIds.length === 1 ? checkedBagIds[0] : null}
        />
      </div>
    </>
  )
}
export default Bags
