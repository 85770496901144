import React, { useState } from 'react'
import { Row, Col, Table, Button } from 'react-bootstrap'
import { useQuery, gql, useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import { DateTime } from 'luxon'
import { useReactiveVar } from '@apollo/client'
import { CaretRight, CaretDown, Alarm, Dot } from 'react-bootstrap-icons'
import { loggedInUserVar } from '../../libs/apollo'
import toast from 'react-hot-toast'

const JobDetailEmployeeAssignments = (props) => {
  const { jobId, toggleModal } = props
  let hasMoreEmployeeAssignments = true
  let employeeAssignmentsCursor
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const [display, setDisplay] = useState(false)

  const [createTimesheetEventMutation] = useMutation(
    gql`
      mutation CreateTimesheetEvent($input: CreateTimesheetEventInput!) {
        createTimesheetEvent(input: $input) {
          timesheetEvent {
            id
            eventType
          }
        }
      }
    `,
    {
      refetchQueries: ['EmployeeAssignmentsQuery'],
      onCompleted: (data) => {
        const eventType = data.createTimesheetEvent.timesheetEvent.eventType
        if (eventType === 'CLOCK_IN') {
          toast.success('Clocked In')
        } else if (eventType === 'CLOCK_OUT') {
          toast.success('Clocked Out')
        }
      },
    }
  )

  const canOpenEmployees = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)
  const history = useHistory()
  const tableColumns = React.useMemo(
    () => [
      {
        Header: 'Employee',
        accessor: 'employee',
      },
      {
        Header: 'Role',
        accessor: 'role',
      },
      {
        Header: 'Shift',
        accessor: (row) => {
          let shift
          if (row.startDateTime) {
            shift = `${row.startDateTime}`
          }
          if (row.endDateTime) {
            if (shift) {
              shift = `${shift} - ${row.endDateTime}`
            } else {
              shift = `End ${row.endDateTime}`
            }
          } else if (shift) {
            shift = `Start ${shift}`
          }
          return shift
        },
      },
      {
        Header: 'Clocked In / Out',
        id: 'clockedInOut',
        accessor: (row) => {
          return (
            <>
              {row.timesheetEvents.length > 0 && (
                <div
                  style={{
                    maxHeight: '80px',
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    fontSize: '13px',
                  }}
                  className="mt-2"
                >
                  <Table className="compact-table table-bordered" responsive>
                    <thead>
                      <tr>
                        <th>Clocked</th>
                        <th>Time</th>
                      </tr>
                    </thead>
                    {row.timesheetEvents.map((timesheetEvent, i) => (
                      <tr key={i} style={{ borderTop: i !== 0 ? 'none' : '' }}>
                        <td>
                          {timesheetEvent.eventType === 'CLOCK_OUT' ? (
                            <>Out</>
                          ) : (
                            <>In</>
                          )}
                        </td>
                        <td>{timesheetEvent.dateTime}</td>
                      </tr>
                    ))}
                  </Table>
                </div>
              )}
            </>
          )
        },
      },
      {
        Header: 'Equipment',
        accessor: 'equipment',
      },
      {
        Header: 'Equipment Pickup Area',
        accessor: 'equipmentPickupArea',
      },
    ],
    []
  )
  const {
    loading: employeeAssignmentsQueryLoading,
    error: employeeAssignmentsQueryError,
    data: employeeAssignmentsQueryData,
    fetchMore: employeeAssignmentsQueryFetchMore,
  } = useQuery(
    gql`
      query EmployeeAssignmentsQuery($sessionsJob: ID, $cursor: String) {
        employeeJobs(first: 250, after: $cursor, job: $sessionsJob) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              employee {
                id
                gaiaUser {
                  firstName
                  lastName
                }
              }
              role {
                name
              }
              startDateTime
              endDateTime
              equipmentPickupArea
              equipmentItems {
                edges {
                  node {
                    name
                    serialNumber
                    returned
                  }
                }
              }
              equipmentBags {
                edges {
                  node {
                    name
                    returned
                    employee {
                      id
                    }
                  }
                }
              }
              timesheet {
                id
                timesheetEvents(orderBy: "created") {
                  edges {
                    node {
                      id
                      dateTime
                      eventType
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      variables: {
        sessionsJob: jobId,
      },
    }
  )
  const handleEmployeeAssignmentsQueryData = () => {
    hasMoreEmployeeAssignments =
      employeeAssignmentsQueryData.employeeJobs.pageInfo.hasNextPage
    if (employeeAssignmentsQueryData.employeeJobs.pageInfo.endCursor) {
      employeeAssignmentsCursor =
        employeeAssignmentsQueryData.employeeJobs.pageInfo.endCursor
    }
    const currentEmployeeAssignments = []
    employeeAssignmentsQueryData.employeeJobs.edges.forEach((employeeJob) => {
      const employeeJobNode = employeeJob.node
      const timesheetEvents = []
      employeeJobNode?.timesheet?.timesheetEvents.edges.forEach(
        (timesheetEvent) => {
          const timesheetEventNode = timesheetEvent.node
          timesheetEvents.push({
            dateTime: timesheetEventNode.dateTime
              ? DateTime.fromISO(timesheetEventNode.dateTime).toFormat('h:mma')
              : '',
            eventType: timesheetEventNode.eventType,
            id: timesheetEventNode.id,
          })
        }
      )
      const equipmentRows = []
      employeeJobNode.equipmentBags.edges.forEach((equipmentBag) => {
        equipmentRows.push({
          type: 'Bag',
          name: equipmentBag.node.name,
          checkedOut: equipmentBag.node.returned ? 'No' : 'Yes',
          longTerm: equipmentBag.node.employee && 'Yes',
        })
      })
      employeeJobNode.equipmentItems.edges.forEach((equipmentItem) => {
        equipmentRows.push({
          type: 'Item',
          name: equipmentItem.node.name,
          checkedOut: equipmentItem.node.returned ? 'No' : 'Yes',
          longTerm: null,
        })
      })
      const equipment = (
        <>
          {equipmentRows.length > 0 && (
            <Table className="compact-table table-bordered" responsive>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Name</th>
                  <th>Checked Out</th>
                  <th>Long Term</th>
                </tr>
              </thead>
              {equipmentRows.map((equipmentRow, index) => {
                return (
                  <tr
                    key={index}
                    style={{ borderTop: index !== 0 ? 'none' : '' }}
                  >
                    <td>{equipmentRow.type}</td>
                    <td>{equipmentRow.name}</td>
                    <td>{equipmentRow.checkedOut}</td>
                    <td>{equipmentRow.longTerm}</td>
                  </tr>
                )
              })}
            </Table>
          )}
        </>
      )
      let employeeAssignment = 'Unassigned'
      if (employeeJobNode.employee) {
        const gaiaUserNode = employeeJobNode.employee.gaiaUser
        employeeAssignment = `${gaiaUserNode.firstName} ${gaiaUserNode.lastName}`
      }
      const role = employeeJobNode.role
        ? employeeJobNode.role.name
        : 'Unassigned'
      let employeeId
      if (employeeJobNode.employee) {
        employeeId = employeeJobNode.employee.id
      }
      const startDateTime = employeeJobNode.startDateTime
        ? DateTime.fromISO(employeeJobNode.startDateTime).toFormat('h:mma')
        : ''
      const endDateTime = employeeJobNode.endDateTime
        ? DateTime.fromISO(employeeJobNode.endDateTime).toFormat('h:mma')
        : ''
      currentEmployeeAssignments.push({
        employee: employeeAssignment,
        equipmentPickupArea: employeeJobNode.equipmentPickupArea,
        role: role,
        equipment,
        employeeId,
        startDateTime,
        endDateTime,
        id: employeeJobNode.id,
        timesheetEvents,
      })
    })
    return currentEmployeeAssignments
  }
  const fetchMoreEmployeeEquipment = () => {
    employeeAssignmentsQueryFetchMore({
      variables: {
        sessionsJob: jobId,
        cursor: employeeAssignmentsCursor,
      },
    })
  }
  if (employeeAssignmentsQueryLoading || !loggedInUser) return <></>
  if (employeeAssignmentsQueryError)
    return <>Error loading employee equipment</>
  const employeeAssignments = handleEmployeeAssignmentsQueryData()
  let loggedInUserAssignment = employeeAssignments.filter(
    (employeeAssignment) =>
      employeeAssignment.employeeId === loggedInUser.employee.id
  )
  if (loggedInUserAssignment) {
    loggedInUserAssignment = loggedInUserAssignment[0]
  }
  return (
    <>
      <div className="equipment mt-3">
        <Row className={display ? '' : 'mb-3'}>
          <Col className="d-flex align-items-center">
            <button
              type="button"
              onClick={() => setDisplay(!display)}
              className="px-0 btn-link mr-1"
              style={{ marginTop: '-10px' }}
            >
              <>
                {display ? (
                  <>
                    <CaretDown size={20} />
                  </>
                ) : (
                  <>
                    <CaretRight size={20} />
                  </>
                )}
              </>
            </button>
            <h5 className="mb-1">Employee Assignments</h5>
          </Col>
        </Row>
        {display && (
          <>
            {loggedInUserAssignment && (
              <>
                <Row>
                  <Col>
                    {loggedInUserAssignment.timesheetEvents &&
                    (loggedInUserAssignment.timesheetEvents.length === 0 ||
                      loggedInUserAssignment.timesheetEvents[
                        loggedInUserAssignment.timesheetEvents.length - 1
                      ].eventType === 'CLOCK_OUT') ? (
                      <Button
                        variant="link"
                        onClick={() => {
                          createTimesheetEventMutation({
                            variables: {
                              input: {
                                employeeJobId: loggedInUserAssignment.id,
                                clockIn: true,
                              },
                            },
                          })
                        }}
                      >
                        <Alarm className="mr-2" />
                        Clock In
                      </Button>
                    ) : (
                      <Button
                        variant="link"
                        onClick={() => {
                          createTimesheetEventMutation({
                            variables: {
                              input: {
                                employeeJobId: loggedInUserAssignment.id,
                                clockIn: false,
                              },
                            },
                          })
                        }}
                      >
                        <Alarm className="mr-2" />
                        Clock Out
                      </Button>
                    )}
                  </Col>
                </Row>
              </>
            )}
            {employeeAssignments.length > 0 ? (
              <Row className="mt-2">
                <Col md={12}>
                  <SortableInfiniteTable
                    tableData={employeeAssignments}
                    tableColumns={tableColumns}
                    fetchMoreTableData={fetchMoreEmployeeEquipment}
                    hasMoreTableData={hasMoreEmployeeAssignments}
                    tableHeight={300}
                  />
                </Col>
              </Row>
            ) : (
              <span className="text-secondary">- - -</span>
            )}
          </>
        )}
      </div>
    </>
  )
}
export default JobDetailEmployeeAssignments
