import React from 'react'
import { Modal } from 'react-bootstrap'
import SessionReport from './SessionReport'

const SessionReportModal = (props) => {
  const {
    showModal,
    toggleModal,
    organizationId,
    couponId,
    subjectGroupId,
    sessionPackageId,
  } = props

  return (
    <div className="sessionModal">
      <Modal size={'xl'} show={showModal} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title id="new-title">Sessions Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SessionReport
            showModal={showModal}
            couponId={couponId}
            sessionPackageId={sessionPackageId}
            organizationId={organizationId}
            subjectGroupId={subjectGroupId}
          />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default SessionReportModal
