import React from 'react'
import { gql } from '@apollo/client'
import SearchInput from './SearchInput'

const EmployeeSearchInput = (props) => {
  const {
    formik,
    disabled,
    formikValue,
    searchDescription,
    setAdditionalFields,
    error,
    variables,
    dropdown,
  } = props
  return (
    <SearchInput
      formik={formik}
      nodeName="employee"
      searchDescription={searchDescription ? searchDescription : 'employees'}
      nodeNamePlural="employees"
      formikValue={formikValue}
      disabled={disabled}
      error={error}
      setAdditionalFields={setAdditionalFields}
      variables={variables}
      dropdown={dropdown}
      gql={gql`
        query Employees(
          $first: Int
          $after: String
          $search: String
          $jobId: String
        ) {
          employees(
            first: $first
            after: $after
            orderBy: "gaia_user__full_name"
            gaiaUser_FullName_Icontains: $search
            employeeJobsJob: $jobId
          ) {
            nodeCount
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                gaiaUser {
                  fullName
                }
              }
            }
          }
        }
      `}
      formatDescription={(node) => {
        return node.gaiaUser.fullName
      }}
    />
  )
}

export default EmployeeSearchInput
