import React from 'react'
import { gql } from '@apollo/client'
import SearchInput from './SearchInput'

const EquipmentBagSearchInput = (props) => {
  const {
    formik,
    disabled,
    formikValue,
    searchDescription,
    setAdditionalFields,
    error,
  } = props
  return (
    <SearchInput
      formik={formik}
      nodeName="bag"
      searchDescription={searchDescription ? searchDescription : 'Bags'}
      nodeNamePlural="equipmentBags"
      formikValue={formikValue}
      disabled={disabled}
      error={error}
      setAdditionalFields={setAdditionalFields}
      gql={gql`
        query SearchBags($first: Int, $after: String, $search: String) {
          equipmentBags(
            first: $first
            after: $after
            orderBy: "name"
            name_Icontains: $search
          ) {
            nodeCount
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                name
                lastEmployeeJob {
                  job {
                    name
                  }
                  startDateTime
                  endDateTime
                  employee {
                    gaiaUser {
                      fullName
                    }
                  }
                }
              }
            }
          }
        }
      `}
      formatDescription={(node) => {
        return node.name
      }}
    />
  )
}

export default EquipmentBagSearchInput
