import React from 'react'
import { useState, useEffect } from 'react'
import { Row, Col, Tab } from 'react-bootstrap'
import { useQuery, gql } from '@apollo/client'
import SubjectForm from './SubjectForm'
import './SubjectSettings.css'
import SubjectPaymentsTable from '../payments/SubjectPaymentsTable'
import { CaretDown, CaretRight } from 'react-bootstrap-icons'
import InvoiceTable from '../payments/InvoiceTable'
import BoxFolder from '../files/box/BoxFolder'
import Tabs from '../common/Tabs'
import { useBoxEnabled } from '../../libs/box'
import FilesTable from '../files/FilesTable'
import { loggedInUserVar } from '../../libs/apollo'

const SubjectSettings = (props) => {
  const { userId } = props
  const [displayFiles, setDisplayFiles] = useState(false)
  const [displayInvoices, setDisplayInvoices] = useState(false)
  const boxEnabled = useBoxEnabled()
  const [subjectTempId, setTempSubjectId] = useState('')
  const [count, setCount] = useState(0)
  let managedSubjects = []
  let subject_id

  const {
    loading: gaiaUserQueryLoading,
    error: gaiaUserQueryError,
    data: gaiaUserQueryData,
  } = useQuery(
    gql`
      query GaiaUserQuery($gaiaUserId: ID!) {
        gaiaUser(id: $gaiaUserId) {
          id
          firstName
          lastName
          fullName
          phoneNumber
          email
          secondaryEmail
          secondaryPhoneNumber
          emailConfirmed
          emailNotificationsEnabled
          smsNotificationsEnabled
          subject {
            id
            organization {
              id
            }
            sharedCanCreateFiles
            sharedCanCreateFolders
            sharedCanSeeFiles
            studentId
            notes
            sharedBoxFolderId
            sharedFolder {
              id
            }
            children {
              edges {
                node {
                  id
                  organization {
                    id
                  }
                  city
                  state
                  zipCode
                  addressLineOne
                  addressLineTwo
                  notes
                  sharedBoxFolderId
                  gaiaUser {
                    id
                    firstName
                    lastName
                    fullName
                    phoneNumber
                    email
                    secondaryEmail
                    secondaryPhoneNumber
                    emailConfirmed
                    emailNotificationsEnabled
                    smsNotificationsEnabled
                  }
                  locations {
                    edges {
                      node {
                        city
                        state
                        zipCode
                        addressLineOne
                        addressLineTwo
                      }
                    }
                  }
                }
              }
            }
            city
            state
            zipCode
            addressLineOne
            addressLineTwo
            notes
            locations {
              edges {
                node {
                  city
                  state
                  zipCode
                  addressLineOne
                  addressLineTwo
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        gaiaUserId: userId,
      },
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    if (managedSubjects && managedSubjects.length > 0 && count == 0) {
      setTempSubjectId(managedSubjects[0].id)
    }
  }, [managedSubjects])

  const handleClick = (e) => {
    setCount(1)
    setTempSubjectId(e)
  }

  const handleQueryData = () => {
    let managedSubjects = []
    if (gaiaUserQueryData && gaiaUserQueryData.gaiaUser.subject) {
      subject_id = gaiaUserQueryData.gaiaUser.subject.id
      managedSubjects.push({
        studentViewing: false,
        id: gaiaUserQueryData.gaiaUser.subject.id,
        gaiaUserId: gaiaUserQueryData.gaiaUser.id,
        name: gaiaUserQueryData.gaiaUser.fullName,
        notes: gaiaUserQueryData.gaiaUser.subject.notes,
        sharedBoxFolderId: gaiaUserQueryData.gaiaUser.subject.sharedBoxFolderId,
      })
    }
    if (
      gaiaUserQueryData &&
      gaiaUserQueryData.gaiaUser.subject.children &&
      gaiaUserQueryData.gaiaUser.subject.children.edges.length
    ) {
      gaiaUserQueryData.gaiaUser.subject.children.edges.forEach((subject) => {
        managedSubjects.push({
          studentViewing: true,
          id: subject.node.id,
          gaiaUserId: subject.node.gaiaUser.id,
          name: subject.node.gaiaUser.fullName,
          notes: subject.node.notes,
          sharedBoxFolderId: subject.node.sharedBoxFolderId,
        })
      })
    }
    return managedSubjects
  }
  if (gaiaUserQueryLoading || !gaiaUserQueryData) return <></>
  managedSubjects = handleQueryData()
  if (gaiaUserQueryError) return <p>Error loading settings</p>
  const { gaiaUser } = gaiaUserQueryData
  const { subject } = gaiaUser
  return (
    <>
      <div className="mt-4">
        {managedSubjects.length > 1 ? (
          <>
            <Tabs
              defaultActiveKey={managedSubjects[0].id}
              className="mb-3"
              onSelect={handleClick}
            >
              {managedSubjects.map((manageSubject) => {
                return (
                  <Tab
                    key={manageSubject.id}
                    eventKey={manageSubject.id}
                    title={manageSubject.name}
                  >
                    <>
                      {subjectTempId == manageSubject.id && (
                        <>
                          <SubjectForm
                            displayBilling
                            subjectId={
                              subjectTempId
                                ? subjectTempId
                                : managedSubjects[0].id
                            }
                            studentViewing={manageSubject.studentViewing}
                            subjectViewing
                          />
                          {subject.notes && (
                            <Row className="mt-5">
                              <Col>
                                <h5 className="d-block">Studio Notes</h5>
                                {subject.notes}
                              </Col>
                            </Row>
                          )}
                          <SubjectPaymentsTable subjectId={subjectTempId} />

                          <Row className="mt-4 mb-3">
                            <Col md={12} className="d-flex align-items-center">
                              <button
                                type="button"
                                onClick={() =>
                                  setDisplayInvoices(!displayInvoices)
                                }
                                className="px-0 btn-link mr-1"
                                style={{ marginTop: '-10px' }}
                              >
                                <>
                                  {displayInvoices ? (
                                    <>
                                      <CaretDown size={20} />
                                    </>
                                  ) : (
                                    <>
                                      <CaretRight size={20} />
                                    </>
                                  )}
                                </>
                              </button>
                              <h5>Invoices</h5>
                            </Col>
                          </Row>
                          {displayInvoices && (
                            <Row className="mb-3 ml-1">
                              <Col>
                                <InvoiceTable
                                  gaiaUserId={manageSubject.gaiaUserId}
                                />
                              </Col>
                            </Row>
                          )}
                          {boxEnabled && subject.sharedBoxFolderId && (
                            <Row className="mt-4 mb-5">
                              <Col
                                md={12}
                                className="d-flex align-items-center"
                              >
                                <button
                                  type="button"
                                  onClick={() => setDisplayFiles(!displayFiles)}
                                  className="px-0 btn-link mr-1"
                                  style={{ marginTop: '-10px' }}
                                >
                                  <>
                                    {displayFiles ? (
                                      <>
                                        <CaretDown size={20} />
                                      </>
                                    ) : (
                                      <>
                                        <CaretRight size={20} />
                                      </>
                                    )}
                                  </>
                                </button>
                                <h5>Files</h5>
                                {displayFiles && (
                                  <BoxFolder
                                    rootBoxFolderId={subject.sharedBoxFolderId}
                                  />
                                )}
                              </Col>
                            </Row>
                          )}
                          {!boxEnabled && subject.sharedCanSeeFiles && (
                            <Row className="mt-4 mb-5">
                              <Col
                                md={12}
                                className="d-flex align-items-center"
                              >
                                <button
                                  type="button"
                                  onClick={() => setDisplayFiles(!displayFiles)}
                                  className="px-0 btn-link mr-1"
                                  style={{ marginTop: '-10px' }}
                                >
                                  <>
                                    {displayFiles ? (
                                      <>
                                        <CaretDown size={20} />
                                      </>
                                    ) : (
                                      <>
                                        <CaretRight size={20} />
                                      </>
                                    )}
                                  </>
                                </button>
                                <h5>Files</h5>
                                {displayFiles && (
                                  <FilesTable
                                    folderId={subject?.sharedFolder.id}
                                    subjectId={subject?.id}
                                    sharedCanCreateFiles={
                                      subject?.sharecCanCreateFiles
                                    }
                                    sharecCanCreateFolders={
                                      subject?.sharecCanCreateFolders
                                    }
                                    organizationSubject={subject.organization}
                                  />
                                )}
                              </Col>
                            </Row>
                          )}
                        </>
                      )}
                    </>
                  </Tab>
                )
              })}
            </Tabs>
          </>
        ) : (
          <>
            <div className="mt-3" />
            <SubjectForm
              subjectId={subject_id}
              studentViewing={subject.studentId !== null}
              subjectViewing
              displayBilling
            />
            {subject.notes && (
              <Row className="mt-5">
                <Col>
                  <h5 className="d-block">Studio Notes</h5>
                  {subject.notes}
                </Col>
              </Row>
            )}
            <SubjectPaymentsTable subjectId={subject_id} />
            <Row className="mt-4 mb-3">
              <Col md={12} className="d-flex align-items-center">
                <button
                  type="button"
                  onClick={() => setDisplayInvoices(!displayInvoices)}
                  className="px-0 btn-link mr-1"
                  style={{ marginTop: '-10px' }}
                >
                  <>
                    {displayInvoices ? (
                      <>
                        <CaretDown size={20} />{' '}
                      </>
                    ) : (
                      <>
                        <CaretRight size={20} />{' '}
                      </>
                    )}
                  </>
                </button>
                <h5>Invoices</h5>
              </Col>
            </Row>
            {displayInvoices && <InvoiceTable gaiaUserId={gaiaUser.id} />}
            {boxEnabled && subject.sharedBoxFolderId && (
              <>
                <Row className="mt-4 mb-5">
                  <Col md={12} className="d-flex align-items-center">
                    <button
                      type="button"
                      onClick={() => setDisplayFiles(!displayFiles)}
                      className="px-0 btn-link mr-1"
                      style={{ marginTop: '-10px' }}
                    >
                      <>
                        {displayFiles ? (
                          <>
                            <CaretDown size={20} />
                          </>
                        ) : (
                          <>
                            <CaretRight size={20} />
                          </>
                        )}
                      </>
                    </button>
                    <h5>Files</h5>
                  </Col>
                </Row>
                {displayFiles && (
                  <BoxFolder
                    canDelete={false}
                    canRename={false}
                    rootBoxFolderId={subject.sharedBoxFolderId}
                  />
                )}
              </>
            )}
            {!boxEnabled && subject.sharedCanSeeFiles && (
              <>
                <Row className="mt-4 mb-2">
                  <Col md={12} className="d-flex align-items-center">
                    <button
                      type="button"
                      onClick={() => setDisplayFiles(!displayFiles)}
                      className="px-0 btn-link mr-1"
                      style={{ marginTop: '-10px' }}
                    >
                      <>
                        {displayFiles ? (
                          <>
                            <CaretDown size={20} />
                          </>
                        ) : (
                          <>
                            <CaretRight size={20} />
                          </>
                        )}
                      </>
                    </button>
                    <h5>Files</h5>
                  </Col>
                </Row>
                {displayFiles && (
                  <Row>
                    <Col>
                      <FilesTable
                        folderId={subject?.sharedFolder.id}
                        subjectId={subject?.id}
                        sharedCanCreateFiles={subject.sharedCanCreateFiles}
                        sharedCanCreateFolders={subject.sharedCanCreateFolders}
                        organizationSubject={subject.organization}
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default SubjectSettings
