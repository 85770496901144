import React, { useState } from 'react'
import { Row, Col, Button, Badge } from 'react-bootstrap'
import { useQuery, gql } from '@apollo/client'
import { Gear, CaretRight, CaretDown } from 'react-bootstrap-icons'
import EditFTP from './EditFPT'

const FTP = () => {
  const { data, error } = useQuery(
    gql`
      query FTPConnector {
        ftpConnectors {
          edges {
            node {
              name
              host
              port
              user
              password
              ssl
              tls
              valid
              rateLimit
              rateLimitSecondDelta
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )
  const [showEditModal, setShowEditModal] = useState(false)
  const [displayFTP, setDisplayFTP] = useState(false)

  const toggleNewModal = () => {
    setShowEditModal((prevState) => !prevState)
  }

  if (!data) return <></>
  const ftpSetting = data.ftpConnectors.edges[0].node
  return (
    <>
      <div className="studioInfo">
        <Row className="mb-1 mt-4">
          <Col className="d-flex align-items-center">
            <button
              type="button"
              onClick={() => setDisplayFTP(!displayFTP)}
              className="px-0 btn-link mr-2"
            >
              <>
                {displayFTP ? (
                  <>
                    <CaretDown size={20} />
                  </>
                ) : (
                  <>
                    <CaretRight size={20} />
                  </>
                )}
              </>
            </button>
            <h4 className="mb-0">
              <img
                className="img-fluid mr-2"
                src={window.location.origin + '/ftp.jpg'}
                style={{ height: '25px' }}
                alt="Organization logo"
              />
              FTP
            </h4>
          </Col>
        </Row>
        {displayFTP && (
          <>
            <Row className="mt-3">
              <Col>
                {!ftpSetting.host &&
                  !ftpSetting.port &&
                  !ftpSetting.user &&
                  !ftpSetting.password && (
                    <Badge
                      className="ml-2"
                      style={{ fontSize: '12px' }}
                      variant="danger"
                    >
                      Disabled
                    </Badge>
                  )}
                {(ftpSetting.host ||
                  ftpSetting.port ||
                  ftpSetting.user ||
                  ftpSetting.password) &&
                  ftpSetting.valid === false && (
                    <Badge
                      className="ml-2"
                      style={{ fontSize: '12px' }}
                      variant="danger"
                    >
                      Invalid Credentials
                    </Badge>
                  )}
                {ftpSetting.valid === true && (
                  <Badge
                    className="ml-2"
                    style={{ fontSize: '12px' }}
                    variant="success"
                  >
                    Enabled
                  </Badge>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div>
                  <Button variant="link" onClick={toggleNewModal}>
                    <Gear className="mr-2" />
                    Configure FTP Server
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
        <EditFTP
          ftpSetting={ftpSetting}
          showModal={showEditModal}
          toggleModal={toggleNewModal}
        />
      </div>
    </>
  )
}

export default FTP
