import React, { useState } from 'react'
import { Row, Col, Button, Modal } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import toast from 'react-hot-toast'
import Loading from '../common/Loading'
import { ArrowCounterclockwise } from 'react-bootstrap-icons'

const RefundPaymentModal = (props) => {
  const { showModal, toggleModal, sessionId, description, stripePaymentId } =
    props
  let mSize = 'md'
  const [loading, setLoading] = useState(false)

  const [mutation] = useMutation(
    gql`
      mutation RefundPayment(
        $refundPaymentInput: RefundStripePaymentIntentInput!
      ) {
        refundStripePaymentIntent(input: $refundPaymentInput) {
          refunded
        }
      }
    `,
    {
      onCompleted: () => {
        setLoading(false)
        toggleModal()
        toast.success('Payment Refunded')
      },
      errorPolicy: 'all',
      refetchQueries: ['StripePaymentIntentsQuery', 'SessionQuery'],
    }
  )

  const handleRefundClick = () => {
    setLoading(true)
    mutation({
      variables: {
        refundPaymentInput: {
          sessionId,
          paymentIntentId: stripePaymentId,
          description,
        },
      },
    })
  }
  if (!showModal) return <></>
  return (
    <>
      <div className="editSessionResitReasonModal">
        <Modal
          size={mSize}
          show={showModal}
          onHide={() => toggleModal()}
          aria-labelledby="newResitReason"
          className="invmodal detail"
        >
          <Modal.Header closeButton>
            <Modal.Title id="new-title">Refund Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <p style={{ fontSize: '16px' }} className="text-center">
                  Are you sure you want to refund the payment?
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={{ offset: 1, span: 10 }}>
                <Button
                  variant="outline-primary"
                  size="sm"
                  block
                  disabled={loading}
                  onClick={handleRefundClick}
                >
                  <ArrowCounterclockwise className="mr-2" />
                  Refund
                </Button>
              </Col>
            </Row>
            {loading && (
              <Row className="mt-3">
                <Col>
                  <Loading message="Refunding Payment..." />
                </Col>
              </Row>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default RefundPaymentModal
