import React, { useState } from 'react'
import { Tab } from 'react-bootstrap'
import Page from '../layout/Page'
import Tabs from '../common/Tabs'
import PromoCodes from './PromoCodes'
import InvoiceTable from './InvoiceTable'
import Products from './product/Products'
import PaymentsTable from './PaymentsTable'
import { Receipt, Cart3, Cash, Gift } from 'react-bootstrap-icons'

const Billing = () => {
  const [activeTab, setActiveTab] = useState('Payments')
  return (
    <>
      <Page title="Billing">
        <Tabs onSelect={(tab) => setActiveTab(tab)} activeKey={activeTab}>
          <Tab
            eventKey="Payments"
            title={
              <b>
                <Cash className="mr-2" />
                Payments
              </b>
            }
          >
            <PaymentsTable />
          </Tab>
          <Tab
            eventKey="Invoices"
            title={
              <b>
                <Receipt className="mr-2" />
                Invoices
              </b>
            }
          >
            <InvoiceTable />
          </Tab>
          <Tab
            eventKey="Products"
            title={
              <b>
                <Cart3 className="mr-2" />
                Products
              </b>
            }
          >
            <div className="mt-4">
              <Products />
            </div>
          </Tab>
          <Tab
            eventKey="Items"
            title={
              <b>
                <Gift className="mr-2" />
                Coupons
              </b>
            }
          >
            <PromoCodes />
          </Tab>
        </Tabs>
      </Page>
    </>
  )
}

export default Billing
