import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { Row, Col, Form, Button } from 'react-bootstrap'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import EmployeeScheduleModal from './EmployeeScheduleModal'
import { DateTime } from 'luxon'
import DateFilter from '../../common/DateFilter'
import EmployeeScheduleCalendar from './EmployeeScheduleCalendar'
import { CalendarWeek, List, PlusCircle } from 'react-bootstrap-icons'
import Loading from '../../common/Loading'
import { formatDateTime } from '../../../libs/utils'

const EmployeeSchedule = (props) => {
  const { employeeNode } = props
  const now = new Date()
  let initialStartDateFilter = new Date(
    Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0)
  )

  let initialEndDateFilter = new Date(
    Date.UTC(now.getFullYear(), now.getMonth(), now.getDate() + 7, 23, 59, 59)
  )

  if (
    initialEndDateFilter &&
    initialEndDateFilter.getMonth() !== now.getMonth()
  ) {
    initialEndDateFilter = new Date(
      Date.UTC(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59)
    )
  }

  const getUtcDt = (jobDateDt) => {
    return DateTime.utc(
      jobDateDt.getFullYear(),
      jobDateDt.getMonth() + 1,
      jobDateDt.getDate(),
      jobDateDt.hour,
      jobDateDt.minute
    )
  }

  const [cursor, setCursor] = useState()
  const [searchTerm, setSearchTerm] = useState('')
  const [searchText, setSearchText] = useState()
  const [employeeSchedules, setEmployeeSchedules] = useState([])
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [showNewEditModal, setShowNewEditModal] = useState(false)
  const [employeeEditing, setEmployeeEditing] = useState(false)
  const [hasMoreEmployeesSchedule, setHasMoreEmployeesSchedule] = useState(true)
  const [view, setView] = useState('list')
  const [editNode, setEditNode] = useState()
  const [startDateFilter, setStartDateFilter] = useState(
    getUtcDt(initialStartDateFilter)
  )
  const [endDateFilter, setEndDateFilter] = useState(
    getUtcDt(initialEndDateFilter)
  )
  const [calendarStartDateFilter, setCalendarStartDateFilter] = useState()
  const [calendarEndDateFilter, setCalendarEndDateFilter] = useState()
  const [calendarDefaultDate, setCalendarDefaultDate] = useState()
  const [calendarDefaultView, setCalendarDefaultView] = useState('week')
  const [queryFirst, setQueryFirst] = useState(25)

  const adminTableColumns = React.useMemo(
    () => [
      {
        Header: 'Employee',
        accessor: 'employee',
      },
      {
        Header: 'Event Type',
        accessor: 'eventType',
      },
      {
        Header: 'Location',
        accessor: (row) => {
          let locationDescr = ''
          if (row.node.location) {
            const location = row.node.location
            if (location.name) {
              locationDescr = `${location.name} ${location.fullAddress}`
            } else {
              locationDescr = location.fullAddress
            }
            return locationDescr
          }
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Start',
        accessor: 'start',
      },
      {
        Header: 'End',
        accessor: 'end',
      },
      {
        Header: 'Submitted',
        accessor: 'submitted',
      },
    ],
    []
  )

  const employeeTableColumns = React.useMemo(
    () => [
      {
        Header: 'Event Type',
        accessor: 'eventType',
      },
      {
        Header: 'Location',
        accessor: (row) => {
          let locationDescr = ''
          if (row.node.location) {
            const location = row.node.location
            if (location.name) {
              locationDescr = `${location.name} ${location.fullAddress}`
            } else {
              locationDescr = location.fullAddress
            }
            return locationDescr
          }
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Start',
        accessor: 'start',
      },
      {
        Header: 'End',
        accessor: 'end',
      },
      {
        Header: 'Submitted',
        accessor: 'submitted',
      },
    ],
    []
  )

  const [query, { error, data, fetchMore }] = useLazyQuery(
    gql`
      query EmployeeSchedule(
        $cursor: String
        $searchTerm: String
        $employeeId: ID
        $startDateGte: DateTime
        $endDateLte: DateTime
        $first: Int
      ) {
        employeeSchedules(
          orderBy: "-start_date_time"
          first: $first
          search: $searchTerm
          employee: $employeeId
          after: $cursor
          startDateTime_Gte: $startDateGte
          endDateTime_Lte: $endDateLte
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              startDateTime
              endDateTime
              hourlyPay
              eventType
              approvalStatus
              created
              notes
              contentType {
                model
                id
              }
              employee {
                id
                gaiaUser {
                  id
                  fullName
                }
              }
              location {
                id
                name
                fullAddress
                addressLineTwo
                addressLineOne
                city
                state
                zipCode
                organization {
                  name
                }
              }
              approvingEmployee {
                gaiaUser {
                  fullName
                  id
                }
              }
              timesheet {
                id
                timesheetEvents(orderBy: "created") {
                  edges {
                    node {
                      id
                      dateTime
                      eventType
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )
  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query({
        variables: {
          employeeId: employeeNode?.id,
          first: queryFirst,
        },
      })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (data?.employeeSchedules) {
      if (loadingSearch) {
        setLoadingSearch(false)
      }
      if (data.employeeSchedules.pageInfo.endCursor)
        setCursor(data.employeeSchedules.pageInfo.endCursor)
      setHasMoreEmployeesSchedule(data.employeeSchedules.pageInfo.hasNextPage)
      const currentSchedules = data.employeeSchedules.edges.map((each_node) => {
        let status
        if (each_node.node.approvalStatus === 'PENDING') {
          status = 'Pending'
        } else if (each_node.node.approvalStatus === 'APPROVED') {
          status = 'Approved'
        } else if (each_node.node.approvalStatus === 'DENIED') {
          status = 'Denied'
        }
        let eventType
        if (each_node.node.eventType === 'EMPLOYEE_WORKING') {
          eventType = 'Working'
        } else if (each_node.node.eventType === 'EMPLOYEE_PTO') {
          eventType = 'Time off'
        } else if (each_node.node.eventType === 'EMPLOYEE_SICK') {
          eventType = 'Sick'
        }
        let calendarName
        if (employeeNode) {
          calendarName = `${status} ${eventType}`
        } else {
          calendarName = `${each_node.node.employee?.gaiaUser?.fullName} ${status} ${eventType}`
        }
        if (editNode && editNode.id === each_node.node.id) {
          setEditNode(each_node.node)
        }
        return {
          node: each_node.node,
          id: each_node.node.id,
          start: formatDateTime(each_node.node.startDateTime),
          end: formatDateTime(each_node.node.endDateTime),
          employee: each_node.node.employee?.gaiaUser.fullName,
          submitted: formatDateTime(each_node.node.created, true),
          status,
          eventType,
          startDateTime: new Date(each_node.node.startDateTime),
          endDateTime: new Date(each_node.node.endDateTime),
          calendarName,
        }
      })
      setEmployeeSchedules(currentSchedules)
      let text = 'Search 0 Events'
      if (data.employeeSchedules.nodeCount > 0) {
        text = `Search ${data.employeeSchedules.nodeCount} Events`
      }
      setSearchText(text)
    }
  }, [data])

  const fetchMoreResults = () => {
    fetchMore({
      variables: constructQueryVariables(),
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        const newEmployeesSchedules = fetchMoreResult.employeeSchedules.edges
        const currentSchedules = newEmployeesSchedules.map((each_node) => {
          let status
          if (each_node.node.approvalStatus === 'PENDING') {
            status = 'Pending'
          } else if (each_node.node.approvalStatus === 'APPROVED') {
            status = 'Approved'
          } else if (each_node.node.approvalStatus === 'DENIED') {
            status = 'Approved'
          }
          let eventType
          if (each_node.node.eventType === 'EMPLOYEE_WORKING') {
            eventType = 'Working'
          } else if (each_node.node.eventType === 'EMPLOYEE_PTO') {
            eventType = 'Time off'
          } else if (each_node.node.eventType === 'EMPLOYEE_SICK') {
            eventType = 'Sick'
          }
          let calendarName
          if (employeeNode) {
            calendarName = `${status} ${eventType}`
          } else {
            calendarName = `${each_node?.node?.employee?.gaiaUser.fullName} ${status} ${eventType}`
          }
          return {
            node: each_node.node,
            id: each_node.node.id,
            start: formatDateTime(each_node.node.startDateTime),
            end: formatDateTime(each_node.node.endDateTime),
            employee: each_node.node.employee.gaiaUser.fullName,
            submitted: formatDateTime(each_node.node.created, true),
            status,
            eventType,
            startDateTime: new Date(each_node.node.startDateTime),
            endDateTime: new Date(each_node.node.endDateTime),
            calendarName,
          }
        })

        const pageInfo = fetchMoreResult.employeeSchedules.pageInfo
        setCursor(fetchMoreResult.employeeSchedules.pageInfo.endCursor)
        setHasMoreEmployeesSchedule(pageInfo.hasNextPage)
        setEmployeeSchedules((prevState) => {
          const currentIds = new Set(currentSchedules.map((item) => item.id))
          const filteredPrevState = prevState.filter(
            (item) => !currentIds.has(item.id)
          )
          return [...filteredPrevState, ...currentSchedules]
        })
      },
    })
  }

  const constructQueryVariables = () => {
    const variables = {
      cursor: cursor,
      first: queryFirst,
      searchTerm: searchTerm,
      employeeId: employeeNode?.id,
    }
    if (startDateFilter) {
      const startDate = startDateFilter.startOf('day')
      variables.startDateGte = startDate
    } else if (view === 'calendar' && calendarStartDateFilter) {
      const startDate = getUtcDt(calendarStartDateFilter).endOf('day')
      variables.startDateGte = startDate
    }
    if (endDateFilter) {
      const endDate = endDateFilter.endOf('day')
      variables.endDateLte = endDate
    } else if (view === 'calendar' && calendarEndDateFilter) {
      const endDate = getUtcDt(calendarEndDateFilter).endOf('day')
      variables.endDateLte = endDate
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    return variables
  }

  const toggleNewModal = () => {
    setShowNewEditModal(!showNewEditModal)
  }

  const toggleEditModal = (node = null) => {
    setEditNode(node)
    setShowNewEditModal(!showNewEditModal)
  }

  const onRowClick = (row) => {
    if (employeeNode) {
      setEmployeeEditing(true)
    }
    toggleEditModal(row.original.node)
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value || undefined
    setSearchTerm(currentSearchTerm)
    const variables = constructQueryVariables()
    variables.searchTerm = currentSearchTerm
    setLoadingSearch(true)
    query({ variables })
  }

  const calendarDateTimes = () => {
    let currentCalendarDefaultDate
    if (!calendarDefaultDate) {
      currentCalendarDefaultDate = new Date()
    } else {
      currentCalendarDefaultDate = calendarDefaultDate
    }
    const month = currentCalendarDefaultDate.getMonth()
    const year = currentCalendarDefaultDate.getFullYear()
    const currentCalendarStartDateFilter = new Date(year, month - 1)
    const currentCalendarEndDateFilter = new Date(year, month + 2)
    return {
      currentCalendarDefaultDate,
      currentCalendarStartDateFilter,
      currentCalendarEndDateFilter,
    }
  }

  useEffect(() => {
    if (initialQueryRun) {
      if (view === 'calendar' && (!startDateFilter || !endDateFilter)) {
        const currentCalendarDateTimes = calendarDateTimes()
        if (!startDateFilter) {
          setCalendarStartDateFilter(
            currentCalendarDateTimes.currentCalendarStartDateFilter
          )
        }
        if (!endDateFilter) {
          setCalendarEndDateFilter(
            currentCalendarDateTimes.currentCalendarEndDateFilter
          )
        }
      }
      setCursor(null)
      const variables = constructQueryVariables()
      variables.cursor = null
      query({
        variables,
      })
    }
  }, [startDateFilter, endDateFilter])

  useEffect(() => {
    setCursor(null)
    const variables = constructQueryVariables()
    variables.cursor = null
    query({
      variables,
    })
  }, [calendarEndDateFilter, calendarStartDateFilter])

  const handleViewChange = () => {
    if (view === 'list') {
      const currentCalendarDateTimes = calendarDateTimes()
      if (!startDateFilter || !endDateFilter) {
        setCursor(null)
        setQueryFirst(25)
        if (!startDateFilter) {
          setCalendarStartDateFilter(
            currentCalendarDateTimes.currentCalendarStartDateFilter
          )
        }
        if (!endDateFilter) {
          setCalendarEndDateFilter(
            currentCalendarDateTimes.currentCalendarEndDateFilter
          )
        }
      }
      setView('calendar')
    } else {
      setCalendarStartDateFilter(null)
      setCalendarEndDateFilter(null)
      setQueryFirst(25)
      query({
        variables: {
          first: queryFirst,
        },
      })
      setView('list')
    }
  }

  if (!initialQueryRun && !data)
    return (
      <Row>
        <Col>
          <Loading message="Loading Work Schedule..." />
        </Col>
      </Row>
    )

  if (error) return <>Error loading employee schedule</>
  return (
    <>
      <div>
        {employeeNode ? (
          <>
            <Row className="mb-3">
              <Col className="d-flex justify-content-end align-items-center">
                <Button
                  variant="link"
                  onClick={handleViewChange}
                  className="mr-2"
                >
                  <div className="d-flex align-items-center">
                    {view === 'calendar' ? (
                      <CalendarWeek className="text-primary mr-1" />
                    ) : (
                      <List className="text-primary mr-1" />
                    )}

                    {view === 'calendar' ? (
                      <span>Calendar</span>
                    ) : (
                      <span>List</span>
                    )}
                  </div>
                </Button>
                <Button variant="link" onClick={toggleNewModal}>
                  <PlusCircle className="mr-2" />
                  New Work Event
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col>
                <Button
                  variant="link"
                  className="mr-2"
                  onClick={handleViewChange}
                >
                  <div className="d-flex align-items-center">
                    {view === 'calendar' ? (
                      <CalendarWeek className="text-primary mr-1" />
                    ) : (
                      <List className="text-primary mr-1" />
                    )}

                    {view === 'calendar' ? (
                      <span>Calendar</span>
                    ) : (
                      <span>List</span>
                    )}
                  </div>
                </Button>
                <Button variant="link" onClick={toggleNewModal}>
                  <PlusCircle className="mr-2" />
                  New Event
                </Button>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={4}>
                <Form.Group>
                  <Form.Control
                    type="text"
                    name="searchTerm"
                    className="form-control-sm"
                    placeholder={searchText}
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                  />
                </Form.Group>
              </Col>
              <Col style={{ marginTop: '-8px' }}>
                <DateFilter
                  startDateFilter={startDateFilter}
                  setStartDateFilter={setStartDateFilter}
                  endDateFilter={endDateFilter}
                  setEndDateFilter={setEndDateFilter}
                  placeholderStart="Event From"
                  placeholderEnd="Event Until"
                />
              </Col>
            </Row>
          </>
        )}
        <Row className="mt-4">
          <Col>
            {view === 'list' ? (
              <>
                <SortableInfiniteTable
                  loading={loadingSearch}
                  tableData={employeeSchedules}
                  tableColumns={
                    employeeNode ? employeeTableColumns : adminTableColumns
                  }
                  fetchMoreTableData={fetchMoreResults}
                  hasMoreTableData={hasMoreEmployeesSchedule}
                  tableHeight={700}
                  loadingMessage="Loading Work Schedule..."
                  hideGlobalFilter
                  onRowClick={onRowClick}
                  rowPointer
                />
              </>
            ) : (
              <>
                <EmployeeScheduleCalendar
                  employeeSchedules={employeeSchedules}
                  startDateFilter={startDateFilter}
                  endDateFilter={endDateFilter}
                  calendarStartDateFilter={calendarStartDateFilter}
                  calendarEndDateFilter={calendarEndDateFilter}
                  setCalendarStartDateFilter={setCalendarStartDateFilter}
                  setCalendarEndDateFilter={setCalendarEndDateFilter}
                  setCalendarDefaultDate={setCalendarDefaultDate}
                  calendarDefaultDate={calendarDefaultDate}
                  calendarDefaultView={calendarDefaultView}
                  setCalendarDefaultView={setCalendarDefaultView}
                  toggleEditModal={toggleEditModal}
                />
              </>
            )}
          </Col>
        </Row>
      </div>
      {showNewEditModal && (
        <EmployeeScheduleModal
          showModal={showNewEditModal}
          toggleModal={toggleEditModal}
          editNode={editNode}
          employeeNode={employeeNode}
          employeeEditing={employeeEditing}
          setEmployeeEditing={setEmployeeEditing}
        />
      )}
    </>
  )
}
export default EmployeeSchedule
