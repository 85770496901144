import React, { useState } from 'react'
import { useMutation, gql } from '@apollo/client'
import { Row, Col, Button, Modal } from 'react-bootstrap'
import { DateTime } from 'luxon'
import toast from 'react-hot-toast'
import { SlashCircle } from 'react-bootstrap-icons'
import { formatCurrency } from '../../libs/utils'
import Loading from '../common/Loading'

const CancelSessionModal = (props) => {
  const {
    showModal,
    toggleModal,
    session,
    sessionStartDateTime,
    sessionPackagePrice,
    adminViewing,
  } = props
  const [submitting, setSubmitting] = useState(false)
  const millisecondsPerHour = 3600000

  const [updateSessionMutation] = useMutation(
    gql`
      mutation UpdateSession($updateSessionInput: UpdateSessionInput!) {
        updateSession(input: $updateSessionInput) {
          session {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        toggleModal()
        toast.success('Session Canceled')
        setSubmitting(false)
      },
      onError: () => {
        // TODO
        setSubmitting(false)
      },
      refetchQueries: [
        'SubjectSessionsQuery',
        'SubjectGroupsNotBookedQuery',
        'StripePaymentIntentsQuery',
      ],
    }
  )

  const handleCancelSessionClick = () => {
    setSubmitting(true)
    updateSessionMutation({
      variables: {
        updateSessionInput: {
          sessionInput: {
            id: session.id,
            cancelled: true,
          },
        },
      },
    })
  }
  const handleCancelSessionWaiveBookingFeeClick = () => {
    setSubmitting(true)
    updateSessionMutation({
      variables: {
        updateSessionInput: {
          sessionInput: {
            id: session.id,
            cancelled: true,
            waiveRescheduleCancelFee: true,
          },
        },
      },
    })
  }
  if (!showModal) return <></>
  let schedulingPolicies = session.schedulingPolicies
  let bill = true
  if (session.waiveRescheduleCancelFee) {
    bill = false
  } else if (schedulingPolicies.refundPolicy === 'ALWAYS') {
    bill = false
  } else if (schedulingPolicies.refundPolicy === 'TIME') {
    const localNow = DateTime.now()
    const studioNow = DateTime.fromISO(localNow)
    const previousSessionStart = DateTime.fromISO(sessionStartDateTime)
    const millisecondsBeforeCharge =
      millisecondsPerHour * schedulingPolicies.timeRefundHours
    if (previousSessionStart - studioNow > millisecondsBeforeCharge) {
      bill = false
    }
  }
  let cancelReschedulePolicy = null
  const refundPolicyRef = schedulingPolicies.refundPolicy
  if (refundPolicyRef === 'NEVER') {
    if (!schedulingPolicies.timeRefundSessionPackageCost) {
      if (
        (sessionPackagePrice == 0 && schedulingPolicies.applyPolicyFree) ||
        (sessionPackagePrice > 0 && schedulingPolicies.applyPolicyPaid)
      ) {
        cancelReschedulePolicy = `A $${schedulingPolicies.timeRefundFee} fee will be charged for cancelling or rescheduling.`
      } else {
        cancelReschedulePolicy =
          'Canceled and rescheduled sessions are refunded.'
      }
    } else if (sessionPackagePrice == 0 && schedulingPolicies.applyPolicyFree) {
      cancelReschedulePolicy = `A $${schedulingPolicies.timeRefundFee} fee will be charged for cancelling or rescheduling the session.`
    } else if (sessionPackagePrice > 0 && schedulingPolicies.applyPolicyPaid) {
      cancelReschedulePolicy = `The session package cost will not be refunded when cancelling or rescheduling.`
    } else {
      cancelReschedulePolicy = 'Canceled and rescheduled sessions are refunded.'
    }
  } else if (refundPolicyRef === 'TIME') {
    if (schedulingPolicies.timeRefundSessionPackageCost) {
      if (
        (sessionPackagePrice == 0 && schedulingPolicies.applyPolicyFree) ||
        (sessionPackagePrice > 0 && schedulingPolicies.applyPolicyPaid)
      ) {
        cancelReschedulePolicy = `Sessions canceled or rescheduled less than ${
          schedulingPolicies.timeRefundHours
        } hours before they start are not refunded. If a free session is canceled or rescheduled less than ${
          schedulingPolicies.timeRefundHours
        } hours before it starts, a ${formatCurrency(
          schedulingPolicies.timeRefundFee
        )} fee is charged.`
      }
    } else {
      if (
        (sessionPackagePrice == 0 && schedulingPolicies.applyPolicyFree) ||
        (sessionPackagePrice > 0 && schedulingPolicies.applyPolicyPaid)
      ) {
        cancelReschedulePolicy = `If a session is changed, rescheduled, or cancelled less than ${
          schedulingPolicies.timeRefundHours
        } hours prior to its start, a fee of ${formatCurrency(
          schedulingPolicies.timeRefundFee
        )} will be charged. If the cost of the session exceeds ${formatCurrency(
          schedulingPolicies.timeRefundFee
        )}, the remaining balance will be refunded.`
      }
    }
  } else if (refundPolicyRef === 'ALWAYS') {
    cancelReschedulePolicy =
      'Canceled sessions are refunded and there is no fee to reschedule.'
  }
  return (
    <>
      <div className="cancelSessionModal">
        <Modal
          size="md"
          show={showModal}
          onHide={() => toggleModal()}
          aria-labelledby="new-title"
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Session Cancellation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <p className="ml-2">{bill && <>{cancelReschedulePolicy}</>}</p>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col md={{ span: 10, offset: 1 }}>
                <Button
                  variant="outline-danger"
                  size="small"
                  block
                  disabled={submitting}
                  onClick={() => handleCancelSessionClick()}
                >
                  <SlashCircle className="mr-2" />
                  Cancel Session
                </Button>
              </Col>
            </Row>
            {adminViewing && bill ? (
              <Row className="mt-2">
                <Col md={{ span: 10, offset: 1 }}>
                  <Button
                    block
                    disabled={submitting}
                    onClick={() => handleCancelSessionWaiveBookingFeeClick()}
                    variant="outline-danger"
                  >
                    <SlashCircle className="mr-2" />
                    Cancel Session and Waive Fee
                  </Button>
                </Col>
              </Row>
            ) : null}
            {submitting && (
              <div className="mt-2">
                <Loading />
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default CancelSessionModal
