import React, { useState, useEffect } from 'react'
import { Cloud, QuestionCircle } from 'react-bootstrap-icons'
import {
  Row,
  Col,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import { DNA } from 'react-loader-spinner'
import validator from 'validator'
import { gql, useMutation, useLazyQuery, useReactiveVar } from '@apollo/client'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import './book_view_home/Homepage.css'
import { client } from '../libs/apollo'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import config from '../config'
import { useInterval } from '../libs/utils'
import { loggedInUserVar } from '../libs/apollo'

const SignUp = () => {
  const stripe = useStripe()
  const stripeElements = useElements()
  const [createTenantId, setCreateTenantId] = useState()
  const [loading, setLoading] = useState(false)
  const [stripeCardElementErrors, setStripeCardElementErrors] = useState()
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0)
  const loggedInUser = useReactiveVar(loggedInUserVar)

  useEffect(() => {
    let intervalId
    if (createTenantId) {
      intervalId = setInterval(() => {
        setLoadingMessageIndex(
          (prevIndex) => (prevIndex + 1) % loadingMessages.length
        )
      }, 20000)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [createTenantId])

  const [getCreateTenant] = useLazyQuery(
    gql`
      query CreateTenant($id: ID!) {
        createTenant(id: $id) {
          creating
          tenantDomain
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      onCompleted: (data) => {
        if (!data.createTenant.creating) {
          setLoading(false)
          let tenantDomain = data.createTenant.tenantDomain
          if (config.ENVIRONMENT === 'development') {
            tenantDomain = `http://${tenantDomain}:3000/?created`
          } else if (['qa', 'prod'].includes(config.ENVIRONMENT)) {
            tenantDomain = `https://${tenantDomain}/?created`
          }
          window.location.href = tenantDomain
        }
      },
    }
  )

  useInterval(() => {
    if (createTenantId) {
      getCreateTenant({
        variables: {
          id: createTenantId,
        },
      })
    }
  }, 1000 * 5)

  const [create] = useMutation(
    gql`
      mutation CreateTenant($createTenantInput: CreateTenantInput!) {
        createTenant(input: $createTenantInput) {
          createTenant {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setCreateTenantId(data.createTenant.createTenant.id)
      },
      errorPolicy: 'all',
    }
  )

  const formik = useFormik({
    initialValues: {
      tenantName: '',
      tenantDomain: '',
      adminUserEmail: '',
      adminUserFirstName: '',
      adminUserLastName: '',
      adminUserPassword: '',
      adminUserConfirmPassword: '',
    },
    validationSchema: Yup.object().shape({
      tenantName: Yup.string().required('Required'),
      adminUserEmail: Yup.string()
        .required('Required')
        .test('isEmail', 'Invalid email', (value) => {
          let valid = true
          if (value && !validator.isEmail(value)) {
            valid = false
          }
          return valid
        }),
      adminUserFirstName: Yup.string().required('Required'),
      adminUserLastName: Yup.string().required('Required'),
      adminUserPassword: Yup.string().required('Required'),
      stripePaymentMethod: Yup.string().nullable(),
      adminUserConfirmPassword: Yup.string()
        .required('Required')
        .oneOf([Yup.ref('adminUserPassword')], 'Passwords do not match'),
      tenantDomain: Yup.string()
        .required('Required')
        .matches(/^[a-zA-Z0-9]+$/, 'Only characters are allowed')
        .test('isUnique', 'URL prefix already in use', async (value) => {
          let tenantDomain
          if (config.ENVIRONMENT === 'development') {
            tenantDomain = `${value}.localhost`
          } else if (config.ENVIRONMENT === 'srp') {
            tenantDomain = `${value}.airstudio.io`
          } else if (config.ENVIRONMENT === 'qa') {
            tenantDomain = `${value}.sbx.airstudio.io`
          } else {
            return false
          }
          const { data } = await client.query({
            query: gql`
              query TenantDomainAvailable($tenantDomain: String!) {
                tenantDomainAvailable(tenantDomain: $tenantDomain) {
                  available
                }
              }
            `,
            fetchPolicy: 'no-cache',
            variables: {
              tenantDomain: tenantDomain,
            },
          })
          return data.tenantDomainAvailable.available
        }),
    }),
    validateOnChange: false,
    onSubmit: async (values) => {
      setLoading(true)
      const cardElement = stripeElements.getElement(CardElement)
      const stripeResponse = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      })
      if (stripeResponse.error) {
        setStripeCardElementErrors(stripeResponse.error.message)
      } else {
        create({
          variables: {
            createTenantInput: {
              tenantInput: {
                tenantName: values.tenantName,
                tenantDomain: values.tenantDomain,
                adminUserEmail: values.adminUserEmail,
                adminUserPassword: values.adminUserPassword,
                adminUserFirstName: values.adminUserFirstName,
                adminUserLastName: values.adminUserLastName,
                stripePaymentMethodId: stripeResponse.paymentMethod.id,
              },
            },
          },
        })
      }
    },
  })

  const loadingMessages = [
    `Hang tight... Constructing your digital workspace may take around five minutes. We'll email ${formik?.values?.adminUserEmail} when its ready. 🚀`,
    'Crafting your workspace with a sprinkle of magic and a dash of tech! ✨🔧',
    "Brewing a custom concoction for your workspace... It's almost potion time! 🧙‍♂️🔮",
    "Knitting together the fabric of your digital realm. It's cozy in here! 🧶🖼️",
    "Summoning the internet gremlins to tailor your environment. They're friendly, we promise! 🐾",
    'Assembling the pixels and bytes into your new digital playground. 🎲🖥️',
    'Rolling out the red carpet in your new digital domain. All eyes on you! 🌟👑',
    'Chiseling away at the digital marble to sculpt your perfect workspace. 🗿🎨',
    'Cultivating a fertile digital landscape for your ideas to grow. 🌱🌳',
    'Weaving the web threads for a seamless digital tapestry. Your masterpiece awaits! 🕸️🎭',
    'Laying the digital bricks to build the fortress of your productivity. Strong and steady! 🧱🏰',
    "Setting the stage for your digital debut. The spotlight's on you! 💡🎤",
    'Tuning the strings of the digital harp for a symphony of efficiency. Music to your ears! 🎵🎶',
    'Igniting the forge to hammer out the details of your custom workspace. Sparks are flying! 🔨🔥',
  ]

  return (
    <>
      {createTenantId && (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
              textAlign: 'center',
            }}
          >
            <DNA
              visible={true}
              height="400"
              width="400"
              ariaLabel="dna-loading"
            />
            <div
              style={{
                marginTop: '20px',
                fontSize: '20px',
                fontWeight: 'bold',
                color: '#000',
              }}
            >
              <Row>
                <Col md={{ span: 10, offset: 1 }}>
                  {loadingMessages[loadingMessageIndex]}
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}
      {!createTenantId && (
        <Form onSubmit={formik.handleSubmit}>
          <Row className="mt-5">
            <Col md={{ span: 8, offset: 2 }}>
              <h1 className="homepage-header">Sign Up for Airstudio</h1>
              <Row className="mt-5">
                <Col md={{ span: 8, offset: 2 }}>
                  <p style={{ fontSize: '20px' }}>
                    Sign up today and enjoy a 7-day free trial period!
                  </p>
                </Col>
              </Row>
              <Form.Group as={Row}>
                <Col md={6}>
                  <Form.Label sm="3">Studio Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="tenantName"
                    value={formik.values.tenantName}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.tenantName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.tenantName}
                  </Form.Control.Feedback>
                </Col>
                <Col md={6}>
                  <Form.Label sm="4">Email</Form.Label>

                  <Form.Control
                    type="text"
                    name="adminUserEmail"
                    value={formik.values.adminUserEmail}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.adminUserEmail}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.adminUserEmail}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Col md={6}>
                  <Form.Label sm="3">First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="adminUserFirstName"
                    value={formik.values.adminUserFirstName}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.adminUserFirstName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.adminUserFirstName}
                  </Form.Control.Feedback>
                </Col>
                <Col md={6}>
                  <Form.Label sm="3">Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="adminUserLastName"
                    value={formik.values.adminUserLastName}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.adminUserLastName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.adminUserLastName}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Col md={6}>
                  <Form.Label sm="3">Password</Form.Label>

                  <Form.Control
                    type="password"
                    name="adminUserPassword"
                    value={formik.values.adminUserPassword}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.adminUserPassword}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.adminUserPassword}
                  </Form.Control.Feedback>
                </Col>
                <Col md={6}>
                  <Form.Label sm="3">Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="adminUserConfirmPassword"
                    value={formik.values.adminUserConfirmPassword}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.adminUserConfirmPassword}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.adminUserConfirmPassword}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Col md={6}>
                  <Form.Label sm="3">
                    URL Prefix
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <p>
                            The URL prefix will be prepended to your Airstudio
                            account URL. For example, if you choose studio, your
                            account URL will be studio.airstudio.io.
                          </p>
                        </Tooltip>
                      }
                    >
                      <QuestionCircle
                        className="ml-1"
                        style={{ fontSize: '14px' }}
                      />
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="tenantDomain"
                    value={formik.values.tenantDomain}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.tenantDomain}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.tenantDomain}
                  </Form.Control.Feedback>
                </Col>
                <Col md={6} style={{ marginTop: '40px' }}>
                  <CardElement
                    options={{
                      style: {
                        base: {
                          fontSize: '18px',
                        },
                      },
                    }}
                  />
                  {stripeCardElementErrors && (
                    <Row className="mt-3">
                      <Col xs={12} md={{ span: 8 }}>
                        <p
                          style={{
                            color: '#dc3545',
                          }}
                        >
                          {stripeCardElementErrors}
                        </p>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Form.Group>
              <Row>
                <Col>
                  <table
                    className="table"
                    style={{ fontSize: '14px', tableLayout: 'fixed' }}
                  >
                    <thead>
                      <tr>
                        <th style={{ padding: '5px' }}>
                          Monthly Active Employee Accounts
                        </th>
                        <th style={{ padding: '5px' }}>
                          Price + $0.10 Per Subject a Month
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ padding: '5px' }}>Up to 5 employees</td>
                        <td style={{ padding: '5px' }}>$100</td>
                      </tr>
                      <tr>
                        <td style={{ padding: '5px' }}>6 to 10 employees</td>
                        <td style={{ padding: '5px' }}>$200</td>
                      </tr>
                      <tr>
                        <td style={{ padding: '5px' }}>11 to 15 employees</td>
                        <td style={{ padding: '5px' }}>$300</td>
                      </tr>
                      <tr>
                        <td style={{ padding: '5px' }}>16 to 20 employees</td>
                        <td style={{ padding: '5px' }}>$400</td>
                      </tr>
                      <tr>
                        <td style={{ padding: '5px' }}>
                          More than 20 employees
                        </td>
                        <td style={{ padding: '5px' }}>
                          $450 + $50 for each additional 5 employees
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={{ span: 8, offset: 2 }}>
                  <Button
                    type="submit"
                    block
                    disabled={loading}
                    variant="outline-primary"
                    className="mt-2"
                  >
                    <Cloud className="mr-2" />
                    Sign Up
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      )}
    </>
  )
}

export default SignUp
