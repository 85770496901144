import React, { useEffect, useState } from 'react'
import {
  useQuery,
  gql,
  useMutation,
  useReactiveVar,
  useLazyQuery,
} from '@apollo/client'
import { Row, Col, Form, Modal, Table, Button } from 'react-bootstrap'
import { Trash, SlashCircle, PlusCircle } from 'react-bootstrap-icons'
import * as yup from 'yup'
import Loading from '../common/Loading'
import { client, loggedInUserVar } from '../../libs/apollo'
import {
  fotomerchantClientAdminUrl,
  fotomerchantClientSessionAdminUrl,
  fotomerchantClientSessionTemplateAdminUrl,
  useFotomerchantEnabled,
} from '../../libs/fotomerchant'
import { toast } from 'react-hot-toast'
import { useFormik } from 'formik'

const CategoriesModal = (props) => {
  const { showModal, toggleModal } = props
  const fotomerchantEnabled = useFotomerchantEnabled()
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = ['Scheduling Manager', 'Administrator'].includes(
    loggedInUser?.permissions?.group
  )
  const [displayCategoryField, setDisplayCategoryField] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [categoryFieldValue, setCategoryFieldValue] = useState('')
  const [validationError, setValidationError] = useState('')
  const [categoryFmIDFieldValue, setCategoryFmIdFieldValue] = useState('')
  const [allowPromoCode, setAllowPromoCode] = useState(true)
  const [allowResits, setAllowResits] = useState(true)
  const [internal, setInternal] = useState(false)
  const [updatingPackageCategoryIndex, setUpdatingPackageCateogryIndex] =
    useState()
  const [fmClientSessionTemplatesPage, setFmClientSessionTemplatesPage] =
    useState(1)
  const [fmApiClientSessionTemplates, setFmApiClientSessionTemplates] =
    useState([])
  const [
    fetchedAllFmClientSessionTemplates,
    setFetchedAllFmClientSessionTemplates,
  ] = useState(false)

  const formik = useFormik({
    initialValues: {
      packageCategory: [],
    },
    onSubmit: () => {},
    validationSchema: yup.object().shape({
      packageCategory: yup.array().of(
        yup
          .object()
          .shape({
            node: yup
              .object()
              .shape({
                name: yup.string().required('Required'),
              })
              .required('Required'),
            stateChange: yup.bool(),
          })
          .required('Required')
      ),
    }),
  })

  const uniqueCategoryNameCheck = async (name, nodeId) => {
    const { data } = await client.query({
      query: gql`
        query PackageCategories($name_Iexact: String) {
          packageCategories(name_Iexact: $name_Iexact) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
      variables: {
        name_Iexact: name,
      },
    })

    let error = false
    data?.packageCategories?.edges?.forEach((edge) => {
      const { node } = edge
      if (
        !error &&
        !validationError &&
        node.id !== nodeId &&
        node.name.toLowerCase() === name.toLowerCase()
      ) {
        setValidationError(true)
        error = true
      }
    })
    if (!error) {
      setValidationError()
    }
  }

  const { data: getCategoryData, loading: getCategoryLoading } = useQuery(
    gql`
      query PackageCategories {
        packageCategories {
          edges {
            node {
              id
              name
              hidePromoCodes
              resitsAvailable
              archived
              internal
              fotomerchantClientSessions {
                edges {
                  node {
                    fotomerchantId
                    fotomerchantClientSessionTemplate {
                      fotomerchantId
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const { data: fotomerchantClient } = useQuery(
    gql`
      query FotomerchantClient {
        fotomerchantClients(packageCategory: true) {
          edges {
            node {
              fotomerchantId
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const [
    getFmApiClientSessionTemplates,
    { data: fmApiClientSessionTemplatesData },
  ] = useLazyQuery(
    gql`
      query FotomerchantApiClientSessionTemplates($page: Int!) {
        fotomerchantApiClientSessionTemplates(page: $page, perPage: 25) {
          fotomerchantApiClientSessionTemplates
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const [createCategory] = useMutation(
    gql`
      mutation CreatePackageCategory($input: CreatePackageCategoryInput!) {
        createPackageCategory(input: $input) {
          packageCategory {
            id
            name
          }
        }
      }
    `,
    {
      refetchQueries: ['PackageCategories'],
      fetchPolicy: 'network-only',
      onCompleted: () => {
        setSubmitting(false)
        setCategoryFieldValue('')
        setDisplayCategoryField(false)
        toast.success('Session Package Category Saved')
      },
      onError: () => {
        toast.error('Session Package Category Names Must Be Unique')
      },
    }
  )

  const [updateCategory] = useMutation(
    gql`
      mutation UpdatePackageCategory($input: UpdatePackageCategoryInput!) {
        updatePackageCategory(input: $input) {
          packageCategory {
            id
            name
          }
        }
      }
    `,
    {
      refetchQueries: ['PackageCategories'],
      onCompleted: () => {
        toast.success('Session Package Category Saved')
        formik.setFieldValue(
          `packageCategory[${updatingPackageCategoryIndex}].stateChange`,
          true
        )
        setUpdatingPackageCateogryIndex()
      },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    }
  )

  const [deleteCategory] = useMutation(
    gql`
      mutation DeletePackageCategory($input: DeletePackageCategoryInput!) {
        deletePackageCategory(input: $input) {
          deleted
        }
      }
    `,
    {
      refetchQueries: ['PackageCategories'],
      onCompleted: (data) => {
        toast.success('Session Package Category Deleted')
      },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    }
  )

  const handleFieldValueChange = (e) => {
    setCategoryFieldValue(e.target.value)
  }

  useEffect(() => {
    if (getCategoryData?.packageCategories?.edges) {
      formik.setValues({
        packageCategory: getCategoryData?.packageCategories?.edges.map(
          (edge) => {
            let node = { ...edge.node }
            if (node.fotomerchantClientSessions.edges.length > 0) {
              node.fotomerchantClientSession =
                node.fotomerchantClientSessions.edges[0].node
            }
            return {
              node,
              stateChange: false,
            }
          }
        ),
      })
    }
  }, [getCategoryData])

  useEffect(() => {
    if (fotomerchantEnabled) {
      getFmApiClientSessionTemplates({
        variables: {
          page: fmClientSessionTemplatesPage,
        },
      })
    }
  }, [fotomerchantEnabled])

  useEffect(() => {
    if (
      !fetchedAllFmClientSessionTemplates &&
      fmApiClientSessionTemplatesData?.fotomerchantApiClientSessionTemplates
        ?.fotomerchantApiClientSessionTemplates
    ) {
      const currentTemplates = JSON.parse(
        fmApiClientSessionTemplatesData.fotomerchantApiClientSessionTemplates
          .fotomerchantApiClientSessionTemplates
      )
      setFmApiClientSessionTemplates((prevState) => {
        return [...prevState, ...currentTemplates.clientSessionTemplates]
      })
      if (
        (currentTemplates.paging.page - 1) * currentTemplates.paging.limit +
          currentTemplates.count <
        currentTemplates.paging.total
      ) {
        getFmApiClientSessionTemplates({
          variables: {
            page: fmClientSessionTemplatesPage + 1,
          },
        })
        setFmClientSessionTemplatesPage((prevState) => (prevState += 1))
      } else {
        setFetchedAllFmClientSessionTemplates(true)
      }
    }
  }, [fmApiClientSessionTemplatesData, fetchedAllFmClientSessionTemplates])

  const handleSaveClick = (e) => {
    e.preventDefault()
    setSubmitting(true)
    createCategory({
      variables: {
        input: {
          packageCategoryInput: {
            name: categoryFieldValue,
            fotomerchantClientSessionTemplateId: categoryFmIDFieldValue,
            hidePromoCodes: !allowPromoCode,
            resitsAvailable: allowResits,
            internal,
          },
        },
      },
    })
  }

  const handleDeleteClick = (catId) => {
    deleteCategory({
      variables: {
        input: {
          packageCategoryIds: [catId],
        },
      },
    })
  }

  const handleUpdatePackageCategory = (value, i) => {
    if (!validationError) {
      setUpdatingPackageCateogryIndex(i)
      updateCategory({
        variables: {
          input: {
            packageCategoryInput: {
              name: value.name,
              hidePromoCodes: value.hidePromoCodes,
              resitsAvailable: value.resitsAvailable,
              id: value.id,
              internal: value.internal,
              fotomerchantClientSessionTemplateId:
                value.fotomerchantClientSession
                  ?.fotomerchantClientSessionTemplate.fotomerchantId,
            },
          },
        },
      })
    } else {
      toast.error('Session Package Category Names Must Be Unique')
    }
  }

  const innerToggle = () => {
    toggleModal(false)
    setDisplayCategoryField(false)
    setValidationError(false)
    setCategoryFmIdFieldValue('')
    setCategoryFieldValue('')
    setAllowPromoCode(true)
    setAllowResits(true)
    setInternal(false)
  }

  if (fotomerchantEnabled && !fotomerchantClient) return <></>
  return (
    <Modal size={'xl'} show={showModal} onHide={innerToggle}>
      <Modal.Header closeButton>
        <Row>
          <Col>
            <h3>Session Package Categories</h3>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Row className="mt-3">
          <Col>
            {getCategoryLoading ? (
              <Loading />
            ) : (
              <>
                {getCategoryData?.packageCategories?.edges.length > 0 && (
                  <Table className="table-bordered" size="sm">
                    <thead>
                      <tr>
                        <th>
                          <small className="font-weight-bold">Name</small>
                        </th>
                        {fotomerchantEnabled && (
                          <>
                            <th>
                              <small className="font-weight-bold">
                                Fotomerchant
                              </small>
                            </th>
                            <th>
                              <small className="font-weight-bold">
                                Fotomerchant
                              </small>
                            </th>
                          </>
                        )}

                        <th>
                          <small className="font-weight-bold">
                            Coupons Available
                          </small>
                        </th>
                        <th>
                          <small className="font-weight-bold">
                            Resits Available
                          </small>
                        </th>
                        <th>
                          <small className="font-weight-bold">Internal</small>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {formik.values.packageCategory &&
                        formik?.values?.packageCategory?.map((catNode, i) => {
                          const { node } = catNode
                          const apiNode =
                            getCategoryData?.packageCategories?.edges.filter(
                              (edge) => edge.node.id === node.id
                            )[0]
                          const fmClientSessionTemplateId =
                            node.fotomerchantClientSession
                              ?.fotomerchantClientSessionTemplate
                              ?.fotomerchantId
                          return (
                            <tr key={node?.id}>
                              <td>
                                <Form.Control
                                  type="text"
                                  name="packageCategory"
                                  value={node?.name}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      `packageCategory[${i}].node.name`,
                                      e.target.value
                                    )
                                    formik.setFieldValue(
                                      `packageCategory[${i}].stateChange`,
                                      true
                                    )
                                    uniqueCategoryNameCheck(
                                      e.target.value,
                                      node.id
                                    )
                                  }}
                                />
                              </td>
                              {fotomerchantEnabled && (
                                <>
                                  <td>
                                    {node.fotomerchantClientSession
                                      ?.fotomerchantId && (
                                      <Button variant="link">
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href={fotomerchantClientSessionAdminUrl(
                                            fotomerchantClient
                                              .fotomerchantClients.edges[0].node
                                              .fotomerchantId,
                                            node.fotomerchantClientSession
                                              ?.fotomerchantId
                                          )}
                                        >
                                          <img
                                            className="mr-2"
                                            src={
                                              window.location.origin +
                                              '/fotomerchant.svg'
                                            }
                                            style={{ height: '20px' }}
                                            alt="Organization logo"
                                          />
                                          <span style={{ fontSize: '14px' }}>
                                            Client Session
                                          </span>
                                        </a>
                                      </Button>
                                    )}
                                  </td>
                                  <td>
                                    <>
                                      {fmClientSessionTemplateId && (
                                        <Button variant="link">
                                          <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={fotomerchantClientSessionTemplateAdminUrl(
                                              fmClientSessionTemplateId
                                            )}
                                          >
                                            <img
                                              className="mr-2"
                                              src={
                                                window.location.origin +
                                                '/fotomerchant.svg'
                                              }
                                              style={{ height: '20px' }}
                                              alt="Organization logo"
                                            />
                                            <span style={{ fontSize: '14px' }}>
                                              Client Session Template
                                            </span>
                                          </a>
                                        </Button>
                                      )}
                                      {apiNode?.node?.fotomerchantClientSessions
                                        ?.edges.length === 0 && (
                                        <Form.Control
                                          name={`packageCategory[${i}].node.fotomerchantClientSession.fotomerchantClientSessionTemplate.fotomerchantId`}
                                          as="select"
                                          className="form-control form-control-sm mb-3"
                                          disabled={!canMutate}
                                          value={fmClientSessionTemplateId}
                                          onChange={(e) => {
                                            formik.setFieldValue(
                                              `packageCategory[${i}].node.fotomerchantClientSession.fotomerchantClientSessionTemplate.fotomerchantId`,
                                              e.target.value
                                            )
                                            formik.setFieldValue(
                                              `packageCategory[${i}].stateChange`,
                                              true
                                            )
                                          }}
                                        >
                                          <option value={''}>- - -</option>
                                          {fmApiClientSessionTemplates.map(
                                            (fmApiClientSessionTemplate) => {
                                              return (
                                                <option
                                                  value={
                                                    fmApiClientSessionTemplate.id
                                                  }
                                                  key={
                                                    fmApiClientSessionTemplate.id
                                                  }
                                                >
                                                  {
                                                    fmApiClientSessionTemplate.label
                                                  }
                                                </option>
                                              )
                                            }
                                          )}
                                        </Form.Control>
                                      )}
                                    </>
                                  </td>
                                </>
                              )}
                              <td>
                                <Form.Check
                                  type="checkbox"
                                  className="mx-3"
                                  checked={!node?.hidePromoCodes}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      `packageCategory[${i}].node.hidePromoCodes`,
                                      !e.target.checked
                                    )
                                    formik.setFieldValue(
                                      `packageCategory[${i}].stateChange`,
                                      true
                                    )
                                  }}
                                />
                              </td>
                              <td>
                                <Form.Check
                                  type="checkbox"
                                  className="mx-3"
                                  checked={node?.resitsAvailable}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      `packageCategory[${i}].node.resitsAvailable`,
                                      e.target.checked
                                    )
                                    formik.setFieldValue(
                                      `packageCategory[${i}].stateChange`,
                                      true
                                    )
                                  }}
                                />
                              </td>
                              <td>
                                <Form.Check
                                  type="checkbox"
                                  className="mx-3"
                                  checked={node?.internal}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      `packageCategory[${i}].node.internal`,
                                      e.target.checked
                                    )
                                    formik.setFieldValue(
                                      `packageCategory[${i}].stateChange`,
                                      true
                                    )
                                  }}
                                />
                              </td>
                              <td>
                                {canMutate && (
                                  <Trash
                                    className="text-danger mr-3 hover"
                                    onClick={() => handleDeleteClick(node.id)}
                                  />
                                )}{' '}
                                {catNode.stateChange ? (
                                  <PlusCircle
                                    className="mr-2 hover ml-3"
                                    style={{
                                      color: 'rgb(13, 110, 253)',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                      handleUpdatePackageCategory(node, i)
                                    }
                                  />
                                ) : (
                                  ''
                                )}
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </Table>
                )}
              </>
            )}
          </Col>
        </Row>
        <Form className={displayCategoryField ? '' : 'd-none'}>
          <Row>
            <Col md={3}>
              <Form.Label>Name</Form.Label>
              <Form.Control
                size="sm"
                onChange={handleFieldValueChange}
                value={categoryFieldValue}
              />
            </Col>
            {fotomerchantEnabled &&
              fotomerchantClient?.fotomerchantClients.edges.length > 0 && (
                <Col md={3}>
                  <Form.Label>
                    <img
                      className="mr-2"
                      src={window.location.origin + '/fotomerchant.svg'}
                      style={{ height: '20px' }}
                      alt="Organization logo"
                    />{' '}
                    Client Session Template
                  </Form.Label>
                  <Form.Control
                    as="select"
                    className="form-control form-control-sm"
                    disabled={!canMutate}
                    value={categoryFmIDFieldValue}
                    onChange={(e) => {
                      setCategoryFmIdFieldValue(e.target.value)
                    }}
                  >
                    <option value={''}>- - -</option>
                    {fmApiClientSessionTemplates.map(
                      (fmApiClientSessionTemplate) => {
                        return (
                          <option
                            value={fmApiClientSessionTemplate.id}
                            key={fmApiClientSessionTemplate.id}
                          >
                            {fmApiClientSessionTemplate.label}
                          </option>
                        )
                      }
                    )}
                  </Form.Control>
                </Col>
              )}
            <Col md={2} className="d-flex mt-4">
              <Form.Label>Coupons Available</Form.Label>
              <Form.Check
                type="checkbox"
                className="mx-3"
                checked={allowPromoCode}
                onChange={(e) => {
                  setAllowPromoCode(e.target.checked)
                }}
              />
            </Col>
            <Col md={2} className="d-flex mt-4">
              <Form.Label>Resits Available</Form.Label>
              <Form.Check
                type="checkbox"
                className="mx-3"
                checked={allowResits}
                onChange={(e) => {
                  setAllowResits(e.target.checked)
                }}
              />
            </Col>
            <Col md={2} className="d-flex mt-4">
              <Form.Label>Internal</Form.Label>
              <Form.Check
                type="checkbox"
                className="mx-3"
                checked={internal}
                onChange={(e) => {
                  setInternal(e.target.checked)
                }}
              />
            </Col>
          </Row>
          {categoryFieldValue && (
            <Button
              variant="link"
              className="p-0 btn-link"
              onClick={handleSaveClick}
            >
              <span style={{ fontSize: '14px' }}>
                <PlusCircle className="mr-2" />
                Save
              </span>
            </Button>
          )}
          <Button
            variant="link"
            className={
              categoryFieldValue
                ? 'pl-2 p-0 btn-link ml-3'
                : 'pl-2 p-0 btn-link'
            }
            onClick={() => {
              setDisplayCategoryField(false)
              setCategoryFmIdFieldValue('')
              setAllowResits(true)
              setAllowPromoCode(true)
              setCategoryFieldValue('')
            }}
          >
            <span style={{ fontSize: '14px' }}>
              <SlashCircle className="mr-2" />
              Cancel
            </span>
          </Button>
        </Form>
        <Row>
          <Col>
            {fotomerchantEnabled &&
              fotomerchantClient?.fotomerchantClients.edges.length > 0 && (
                <Button
                  variant="link"
                  style={
                    canMutate && !displayCategoryField
                      ? { marginLeft: '10px' }
                      : { marginLeft: '2px' }
                  }
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={fotomerchantClientAdminUrl(
                      fotomerchantClient.fotomerchantClients.edges[0].node
                        .fotomerchantId
                    )}
                  >
                    <img
                      className="mr-2"
                      src={window.location.origin + '/fotomerchant.svg'}
                      style={{ height: '20px' }}
                      alt="Organization logo"
                    />
                    <span style={{ fontSize: '14px' }}>Client</span>
                  </a>
                </Button>
              )}
            {canMutate && (
              <Button
                variant="link"
                className={!displayCategoryField ? 'p-0' : 'd-none'}
                onClick={() => {
                  setDisplayCategoryField(true)
                }}
              >
                <span style={{ fontSize: '14px' }}>
                  <PlusCircle className="mr-1" />
                  New Category
                </span>
              </Button>
            )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}
export default CategoriesModal
