import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Badge, Table, Tab } from 'react-bootstrap'
import {
  Trash,
  Person,
  CloudArrowDown,
  CalendarDate,
  InfoCircle,
  Camera,
  Cash,
  Receipt,
  PlusCircle,
  Bell,
  ClockHistory,
  FileEarmarkText,
} from 'react-bootstrap-icons'
import { useParams } from 'react-router-dom'
import { useQuery, gql, useReactiveVar, useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'
import SubjectGroupSubjectsTable from './SubjectGroupSubjectsTable'
import ProgressBar from 'react-bootstrap/ProgressBar'
import DeleteSubjectGroupModal from './DeleteSubjectGroupModal'
import toast from 'react-hot-toast'
import Notifications from '../notifications/Notifications'
import { loggedInUserVar } from '../../libs/apollo'
import { formatCurrency } from '../../libs/utils'
import SessionList from '../sessions/Sessions'
import Schedule from '../schedule/Schedule'
import { useBoxEnabled } from '../../libs/box'
import {
  fotomerchantClientAdminUrl,
  fotomerchantClientSessionAdminUrl,
  fotomerchantClientSessionTemplateAdminUrl,
  useFotomerchantEnabled,
} from '../../libs/fotomerchant'
import Loading from '../common/Loading'
import AuditLog from '../audit_log/AuditLog'
import Tabs from '../common/Tabs'
import MetaDataTable from '../common/MetaData'
import SubjectGroupModal from './SubjectGroupModal'
import ESignRequests from '../files/box/ESignRequests'
import Policies from '../common/Policies'
import InvoiceTable from '../payments/InvoiceTable'
import PaymentsTable from '../payments/PaymentsTable'
import StatusCircle from '../common/StatusCircle'
import BoxFolder from '../files/box/BoxFolder'
import NotificationsTable from '../notifications/NotificationsTable'
import FilesTable from '../files/FilesTable'
import { useDownloadAndDeleteFile } from '../../libs/downloadAndDeleteFile'
import PieChart from '../chart/PieChart'

const SubjectGroupDetail = () => {
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = ['Administrator', 'Scheduling Manager'].includes(
    loggedInUser?.permissions?.group
  )
  const canSeeHistory = ![
    'Subject',
    'Organization Contact',
    'General Staff',
  ].includes(loggedInUser?.permissions?.group)
  const cantSeePayments = ['General Staff', 'Equipment Manager'].includes(
    loggedInUser?.permissions?.group
  )
  const boxEnabled = useBoxEnabled()
  const { downloadAndDeleteFile } = useDownloadAndDeleteFile()
  const { subjectGroupId, contact } = useParams()
  const [currentBoxFolderId, setCurrentBoxFolderId] = useState()
  const [processingSubjects, setProcessingSubjects] = useState(false)
  const [displayFiles, setDisplayFiles] = useState(false)
  const fotomerchantEnabled = useFotomerchantEnabled()
  const [showEditModal, setShowEditModal] = useState(false)
  const [downloadingPdf, setDownloadingPdf] = useState(false)
  const [downloadingExcel, setDownloadingExcel] = useState(false)
  const [queriesLoading, setQueriesLoading] = useState({
    subjectGroup: true,
    jobs: true,
    subjects: true,
  })
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [activeTab, setActiveTab] = useState('Schedule')
  const [activeFilesTab, setActiveFilesTab] = useState('Files')
  const [activeBillingTab, setActiveBillingTab] = useState('Payments')

  const handleTabSelect = (selectedTab) => {
    setActiveTab(selectedTab)
    if (selectedTab === 'Files') {
      setDisplayFiles(!displayFiles)
    }
  }
  const toggleDeleteSubjectGroupModal = () => {
    setShowDeleteModal(!showDeleteModal)
  }
  const history = useHistory()

  const [downloadSubjects] = useMutation(
    gql`
      mutation DownloadSubjectGroupSubjects(
        $input: DownloadSubjectGroupSubjectsInput!
      ) {
        downloadSubjectGroupSubjects(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        downloadAndDeleteFile(
          data.downloadSubjectGroupSubjects.file.fileName,
          data.downloadSubjectGroupSubjects.file.displayName,
          data.downloadSubjectGroupSubjects.file.id,
          () => {
            if (downloadingPdf) {
              toast.success(`PDF Downloaded`)
              setDownloadingPdf(false)
            }
            if (downloadingExcel) {
              toast.success(`Excel Downloaded`)
              setDownloadingExcel(false)
            }
          }
        )
      },
      errorPolicy: 'all',
    }
  )

  const { data: settings } = useQuery(
    gql`
      query AppSettingsQuery {
        settings {
          edges {
            node {
              freePackageResitFee
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const {
    loading: subjectGroupQueryLoading,
    error: subjectGroupQueryError,
    data: subjectGroupQueryData,
  } = useQuery(
    gql`
      query SubjectGroupsQuery($subjectGroupId: ID!) {
        subjectGroup(id: $subjectGroupId) {
          name
          bookingName
          hidePromoCodes
          subjectsPaying
          resitFee
          category
          resitsAvailable
          processingSubjects
          processingSubjectsProgress
          refundPolicy
          applyNoShowPolicyFree
          applyNoShowPolicyPaid
          applyPolicyFree
          applyPolicyPaid
          resitFeePaidSessions
          timeRefundHours
          timeRefundFee
          sharedCanSeeFiles
          sharedCanCreateFiles
          sharedCanCreateFolders
          syncingNotifications
          timeRefundSessionPackageCost
          subjectsSessionCompleteCount
          resitsIncludeFreeSessions
          noShowFee
          freePackageResitFee
          sharedCanSeeFiles
          sharedCanCreateFiles
          sharedCanCreateFolders
          usingNoShowPolicy
          usingCancellationPolicy
          usingResitPolicy
          rootBoxFolderId
          sharedBoxFolderId
          subjectsBookedCount
          subjectsNotBookedCount
          id
          notes
          lastDateToPickPose
          resitFeeFreeSessions
          lastDateForRetouch
          lastDateToBePhotographed
          folder {
            id
          }
          sharedFolder {
            id
          }
          staffSharedFolder {
            id
          }
          metadata
          canDelete
          organization {
            id
            name
            category
            freePackageResitFee
            subjectSharedCanSeeFiles
            subjectSharedCanCreateFiles
            subjectSharedCanCreateFolders
            sessionPackageBackgrounds {
              edges {
                node {
                  sessionPackage {
                    id
                  }
                  backgroundColor
                }
              }
            }
          }
          startDateTime
          endDateTime
          subjects {
            nodeCount
          }
          contentType {
            model
          }
          sessionPackages {
            edges {
              node {
                id
                title
                price
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      pollInterval: 5000,
      onCompleted: () => {
        setQueriesLoading((prevState) => ({
          ...prevState,
          subjectGroup: false,
        }))
      },
      variables: {
        subjectGroupId,
      },
      errorPolicy: 'all',
    }
  )

  const handleOrgClick = () => {
    if (subjectGroup.organization) {
      if (contact) {
        history.push(`/organization/${subjectGroup.organization.id}/contact`)
      } else {
        history.push(`/organization/${subjectGroup.organization.id}`)
      }
    }
  }

  useEffect(() => {
    if (subjectGroupQueryData?.subjectGroup?.processingSubjects === true) {
      setProcessingSubjects(true)
    } else if (
      subjectGroupQueryData?.subjectGroup?.processingSubjects === false &&
      processingSubjects === true
    ) {
      setProcessingSubjects(false)
      toast.success('CSV Subject Upload Complete')
    } else if (
      subjectGroupQueryData?.subjectGroup?.processingSubjects === false &&
      processingSubjects === false
    ) {
      setProcessingSubjects(false)
    }
  }, [subjectGroupQueryData])

  if (subjectGroupQueryLoading || !settings) {
    return (
      <Row className="mt-3">
        <Col>
          <Loading />
        </Col>
      </Row>
    )
  }
  if (subjectGroupQueryError) return <p>Error loading subject group</p>
  const { subjectGroup } = subjectGroupQueryData
  if (!subjectGroup) return <></>
  return (
    <>
      <div>
        <DeleteSubjectGroupModal
          showModal={showDeleteModal}
          toggleModal={toggleDeleteSubjectGroupModal}
          subjectGroupId={subjectGroup.id}
        />
        <Row className="mt-3">
          <Col>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h1 className="mb-0" style={{ marginRight: '10px' }}>
                {subjectGroup.name}
              </h1>
              {subjectGroup.syncingNotifications && !contact && (
                <span style={{ marginTop: '5px' }}>
                  <StatusCircle message="Syncing Notifications" />
                </span>
              )}
            </div>
          </Col>
        </Row>
        {(canMutate || contact) && (
          <Row>
            <Col md={2} className="d-md-flex mb-3">
              <div>
                <Button variant="link" onClick={() => setShowEditModal(true)}>
                  {contact ? (
                    <>
                      <Person className="mr-2" />
                      Update Subjects
                    </>
                  ) : (
                    <>
                      <PlusCircle className="mr-2" />
                      Edit
                    </>
                  )}
                </Button>
              </div>
              {!contact && (
                <>
                  <div className="ml-3  mr-1">
                    <Button
                      variant="link"
                      onClick={() => toggleDeleteSubjectGroupModal()}
                    >
                      <Trash className="mr-2" />
                      <span>Delete</span>
                    </Button>
                  </div>
                </>
              )}
              <Button
                variant="link"
                disabled={downloadingPdf}
                onClick={() => {
                  setDownloadingPdf(true)
                  downloadSubjects({
                    variables: {
                      input: {
                        subjectGroupIds: [subjectGroup.id],
                        fileType: 'pdf',
                      },
                    },
                  })
                }}
              >
                <>
                  {downloadingPdf && <Loading />}
                  {!downloadingPdf && !downloadingExcel && (
                    <>
                      <CloudArrowDown className="mr-2" />
                      Download PDF
                    </>
                  )}
                </>
              </Button>
              <Button
                variant="link"
                disabled={downloadingExcel}
                onClick={() => {
                  setDownloadingExcel(true)
                  downloadSubjects({
                    variables: {
                      input: {
                        subjectGroupIds: [subjectGroup.id],
                        fileType: 'xlsx',
                      },
                    },
                  })
                }}
              >
                <>
                  {downloadingExcel && <Loading />}
                  {!downloadingPdf && !downloadingExcel && (
                    <>
                      <CloudArrowDown className="mr-2" />
                      Download Excel
                    </>
                  )}
                </>
              </Button>
            </Col>
          </Row>
        )}
        <Tabs onSelect={(key) => handleTabSelect(key)} activeKey={activeTab}>
          <Tab
            eventKey="Schedule"
            title={
              <b>
                <CalendarDate className="mr-2" />
                Schedule
              </b>
            }
            style={{ marginTop: '10px' }}
          >
            <>
              <Schedule subjectGroupId={subjectGroup.id} />
            </>
          </Tab>
          <Tab
            eventKey="Details"
            title={
              <b>
                <InfoCircle className="mr-2" />
                Details
              </b>
            }
            style={{ marginTop: '10px' }}
          >
            <Row>
              <Col md={8}>
                <Table size="sm">
                  <tbody>
                    <tr>
                      <td className="border-top-0">Organization</td>
                      <td
                        className="border-top-0"
                        style={
                          subjectGroup.organization?.name
                            ? { cursor: 'pointer' }
                            : {}
                        }
                        onClick={handleOrgClick}
                      >
                        {subjectGroup.organization?.name && (
                          <Button variant="link">
                            {subjectGroup.organization?.name}
                          </Button>
                        )}
                        {!subjectGroup.organization?.name && <>None</>}
                      </td>
                    </tr>
                    <tr>
                      <td className="border-top-0">Category</td>
                      <td className="border-top-0">{subjectGroup.category}</td>
                    </tr>
                    <tr>
                      <td>Subjects</td>
                      <td>
                        {subjectGroup.processingSubjects && (
                          <Badge variant="primary" className="mb-3 mr-2">
                            Processing CSV Import
                          </Badge>
                        )}
                        {subjectGroupQueryData.subjectGroup.subjects.nodeCount}
                      </td>
                    </tr>
                    <tr>
                      <td>Booking Available Between</td>
                      <td>
                        {DateTime.fromISO(subjectGroup.startDateTime).toFormat(
                          'cccc, MMMM dd, yyyy'
                        )}{' '}
                        -{' '}
                        {DateTime.fromISO(subjectGroup.endDateTime).toFormat(
                          'cccc, MMMM dd, yyyy'
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Primary Photo Deadline</td>
                      <td>
                        {subjectGroup.lastDateForRetouch ? (
                          <>
                            {DateTime.fromISO(
                              subjectGroup.lastDateForRetouch
                            ).toFormat('cccc, MMMM dd, yyyy')}
                          </>
                        ) : (
                          <span>- - -</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Last Date to Pick Pose</td>
                      <td>
                        {subjectGroup.lastDateToPickPose ? (
                          <>
                            {DateTime.fromISO(
                              subjectGroup.lastDateToPickPose
                            ).toFormat('cccc, MMMM dd, yyyy')}
                          </>
                        ) : (
                          <span>- - -</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Final Photo Deadline</td>
                      <td>
                        {subjectGroup.lastDateToBePhotographed ? (
                          <>
                            {DateTime.fromISO(
                              subjectGroup.lastDateToBePhotographed
                            ).toFormat('cccc, MMMM dd, yyyy')}
                          </>
                        ) : (
                          <span>- - -</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Session Packages</td>
                      <td>
                        {subjectGroup.sessionPackages.edges.length > 0 ? (
                          subjectGroup.sessionPackages.edges.map(
                            (pack, index) => {
                              const { node } = pack
                              if (index > 0) {
                                return <span key={index}>, {node.title}</span>
                              }
                              return <span key={index}>{node.title}</span>
                            }
                          )
                        ) : (
                          <span>- - -</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Resits Available</td>
                      <td>
                        {subjectGroup.resitsAvailable ? (
                          <span>Yes</span>
                        ) : (
                          <span>No</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Billing</td>
                      <td>
                        {subjectGroup.subjectsPaying ? (
                          <span>Subjects</span>
                        ) : (
                          <span>Organization</span>
                        )}
                      </td>
                    </tr>
                    {!contact && (
                      <>
                        {subjectGroup.resitsAvailable &&
                          !subjectGroup.resitsIncludeFreeSessions &&
                          subjectGroup.freePackageResitFee !== null && (
                            <tr>
                              <td>Free Package Resit Cost</td>
                              <td>
                                Charge the subject groups{' '}
                                {formatCurrency(
                                  subjectGroup.freePackageResitFee
                                )}{' '}
                                fee for free package resits
                              </td>
                            </tr>
                          )}
                        {subjectGroup.resitsAvailable &&
                          !subjectGroup.resitsIncludeFreeSessions &&
                          subjectGroup.freePackageResitFee == null &&
                          subjectGroup.organization.freePackageResitFee !==
                            null && (
                            <tr>
                              <td>Free Package Resit Cost</td>
                              <td>
                                Charge the organizations{' '}
                                {formatCurrency(
                                  subjectGroup.organization.freePackageResitFee
                                )}{' '}
                                fee for free package resits
                              </td>
                            </tr>
                          )}
                        {subjectGroup.resitsAvailable &&
                          !subjectGroup.resitsIncludeFreeSessions &&
                          subjectGroup.freePackageResitFee == null &&
                          subjectGroup.organization.freePackageResitFee ==
                            null && (
                            <tr>
                              <td>Free Package Resit Cost</td>
                              <td>
                                Charge the studio settings{' '}
                                {formatCurrency(
                                  settings.settings.edges[0].node
                                    .freePackageResitFee
                                )}{' '}
                                fee for free package resits
                              </td>
                            </tr>
                          )}
                        {subjectGroup.resitsAvailable && (
                          <tr>
                            <td>No Resit Fee for Free Packages</td>
                            <td>
                              {subjectGroup.resitsIncludeFreeSessions ? (
                                <span>Yes</span>
                              ) : (
                                <span>No</span>
                              )}
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td className="border-top-0">Coupons</td>
                          <td className="border-top-0">
                            {subjectGroup.hidePromoCodes ? 'No' : 'Yes'}
                          </td>
                        </tr>
                        <tr>
                          <td className="border-top-0">Metadata</td>
                          <td className="border-top-0">
                            <MetaDataTable
                              metaData={JSON.parse(subjectGroup.metadata)}
                              header
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="border-top-0">Notes</td>
                          <td className="border-top-0">{subjectGroup.notes}</td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </Table>
              </Col>
              {subjectGroupQueryData.subjectGroup.subjects.nodeCount > 0 && (
                <Col md={4}>
                  <PieChart
                    colors={['#FF9999', '#FFCC66', '#99CC99']}
                    labels={[
                      'Subjects Not Booked',
                      'Subjects Booked',
                      'Subjects Photographed',
                    ]}
                    series={[
                      subjectGroupQueryData.subjectGroup.subjectsNotBookedCount,
                      subjectGroupQueryData.subjectGroup.subjectsBookedCount,
                      subjectGroupQueryData.subjectGroup
                        .subjectsSessionCompleteCount,
                    ]}
                  />
                </Col>
              )}
            </Row>
            {!contact && (
              <Policies nodeType="subjectGroup" node={subjectGroup} />
            )}
          </Tab>
          <Tab
            eventKey="Sessions"
            title={
              <b>
                <Camera className="mr-2" />
                Sessions
              </b>
            }
            style={{ marginTop: '10px' }}
          >
            <Row className="mt-3 mb-3">
              <>
                <SessionList subjectGroupId={subjectGroup.id} detailComponent />
              </>
            </Row>
          </Tab>

          <Tab
            eventKey="Subjects"
            title={
              <b>
                <Person className="mr-2" />
                Subjects
              </b>
            }
            style={{ marginTop: '10px' }}
          >
            <Row className="mt-3 mb-3">
              <>
                <>
                  {subjectGroup.processingSubjects && (
                    <>
                      <div className="mb-3">
                        <ProgressBar
                          now={`${subjectGroup.processingSubjectsProgress}`}
                          label={`${subjectGroup.processingSubjectsProgress}% Complete Processing Import`}
                        />
                      </div>
                    </>
                  )}
                  <SubjectGroupSubjectsTable
                    subjectGroupId={subjectGroup.id}
                    subjectGroupCategory={subjectGroup.category}
                    subjectGroupName={subjectGroup.name}
                    organizationId={subjectGroup.organization?.id}
                    organizationName={subjectGroup.organization?.name}
                    organizationCategory={subjectGroup.organization?.category}
                    organization={subjectGroup.organization}
                    setQueriesLoading={setQueriesLoading}
                    queriesLoading={queriesLoading}
                    contact={contact}
                  />
                </>
              </>
            </Row>
          </Tab>
          {!cantSeePayments && (
            <Tab
              key="Billing"
              eventKey="Billing"
              style={{ marginTop: '10px' }}
              title={
                <b>
                  <Cash className="mr-2" />
                  Billing
                </b>
              }
            >
              <Tabs
                activeKey={activeBillingTab}
                onSelect={(tab) => setActiveBillingTab(tab)}
              >
                <Tab
                  key="Payments"
                  eventKey="Payments"
                  style={{ marginTop: '10px' }}
                  title={
                    <b>
                      <Cash className="mr-2" />
                      Payments
                    </b>
                  }
                >
                  <PaymentsTable subjectGroupId={subjectGroup.id} />
                </Tab>
                <Tab
                  key="Invoices"
                  eventKey="Invoices"
                  style={{ marginTop: '10px' }}
                  title={
                    <b>
                      <Receipt className="mr-2" />
                      Invoices
                    </b>
                  }
                >
                  <InvoiceTable subjectGroupId={subjectGroup.id} />
                </Tab>
              </Tabs>
            </Tab>
          )}

          {(canSeeHistory || contact) && (
            <Tab
              eventKey="Notifications"
              title={
                <b>
                  <Bell className="mr-2" />
                  Notifications
                </b>
              }
              style={{ marginTop: '10px' }}
            >
              <Row className="mb-2">
                <Col>
                  {contact && activeTab === 'Notifications' && (
                    <NotificationsTable subjectGroup={subjectGroup} />
                  )}
                  {!contact && activeTab === 'Notifications' && (
                    <Notifications
                      subjectGroup={subjectGroup}
                      organization={subjectGroup.organization}
                    />
                  )}
                </Col>
              </Row>
            </Tab>
          )}
          <Tab
            eventKey="Files"
            title={
              <b>
                <FileEarmarkText className="mr-2" />
                Files
              </b>
            }
            style={{ marginTop: '10px' }}
          >
            {activeTab === 'Files' && (
              <>
                {boxEnabled && (
                  <Tabs activeKey={activeFilesTab} onSelect={setActiveFilesTab}>
                    <Tab
                      key="Files"
                      eventKey="Files"
                      style={{ marginTop: '10px' }}
                      title={<b>Files</b>}
                    >
                      <BoxFolder
                        canDelete={!contact}
                        canRename={!contact}
                        rootBoxFolderId={
                          contact
                            ? subjectGroup.sharedBoxFolderId
                            : subjectGroup.rootBoxFolderId
                        }
                        setCurrentBoxFolderId={setCurrentBoxFolderId}
                      />
                    </Tab>
                    <Tab
                      key="Sign Requests"
                      eventKey="Sign Requests"
                      style={{ marginTop: '10px' }}
                      title={<b>Sign Requests</b>}
                    >
                      <ESignRequests
                        currentBoxFolderId={currentBoxFolderId}
                        subjectGroupId={subjectGroup.id}
                        organizationId={subjectGroup?.organization?.id}
                        contact={contact}
                      />
                    </Tab>
                  </Tabs>
                )}
                {!boxEnabled && subjectGroup?.folder?.id && (
                  <div className="mt-4">
                    <FilesTable
                      subjectGroupId={subjectGroup.id}
                      subjectGroupDescription={subjectGroup.name}
                      sharedCanCreateFiles={subjectGroup.sharedCanCreateFiles}
                      sharedCanCreateFolders={
                        subjectGroup.sharedCanCreateFolders
                      }
                      folderId={
                        contact
                          ? subjectGroup.sharedFolder.id
                          : loggedInUser.permissions.group === 'General Staff'
                          ? subjectGroup.staffSharedFolder.id
                          : subjectGroup.folder.id
                      }
                    />
                  </div>
                )}
              </>
            )}
          </Tab>
          {canSeeHistory && (
            <Tab
              eventKey="History"
              title={
                <b>
                  <ClockHistory className="mr-2" />
                  History
                </b>
              }
              style={{ marginTop: '10px' }}
            >
              <Row className="mt-3 mb-2">
                <Col md={12}>
                  <AuditLog
                    contentType={subjectGroup.contentType.model}
                    id={subjectGroup.id}
                  />
                </Col>
              </Row>
            </Tab>
          )}
        </Tabs>
      </div>
      <SubjectGroupModal
        showModal={showEditModal}
        toggleModal={setShowEditModal}
        subjectGroupId={subjectGroup.id}
      />
    </>
  )
}

export default SubjectGroupDetail
