import React, { useEffect, useState } from 'react'
import { gql, useMutation, useLazyQuery } from '@apollo/client'
import { Row, Col, Button, Modal, Badge, Form } from 'react-bootstrap'
import { Trash } from 'react-bootstrap-icons'
import toast from 'react-hot-toast'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import './SubjectGroupAddSubjectsModal.css'

const SubjectGroupAddSubjectsModal = (props) => {
  const {
    subjectGroupId,
    organizationId,
    organizationName,
    showModal,
    toggleModal,
  } = props
  const [hasMoreSubjects, setHasMoreSearchResults] = useState(true)
  const [cursor, setCursor] = useState()
  const [subjects, setSubjects] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [addSubjects, setAddSubjects] = useState({})

  const UPDATE_SUBJECT_GROUP_MUTATION = gql`
    mutation UpdateSubjectGroup(
      $updateSubjectGroupInput: UpdateSubjectGroupInput!
    ) {
      updateSubjectGroup(input: $updateSubjectGroupInput) {
        subjectGroup {
          id
        }
      }
    }
  `
  const SUBJECTS_NOT_IN_SUBJECT_GROUP_QUERY = gql`
    query SubejctsNotInSubjectGroupsQuery(
      $subjectGroupId: ID!
      $organizationId: ID!
      $searchTerm: String
      $cursor: String
    ) {
      organizationSubjectsNotInSubjectGroup(
        subjectGroupId: $subjectGroupId
        organizationId: $organizationId
        fullNameContains: $searchTerm
        after: $cursor
      ) {
        subjects {
          id
          studentId
          gaiaUser {
            email
            firstName
            lastName
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  `
  const SUBJECTS_QUERY = gql`
    query SubjectsQuery($subjectGroup: [ID]) {
      subjects(first: 10, subjectGroups: $subjectGroup) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            studentId
            gaiaUser {
              email
              firstName
              lastName
            }
          }
        }
      }
    }
  `
  const tableColumns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Student ID',
        accessor: 'studentId',
      },
    ],
    []
  )
  const [updateSubjectGroupMutation] = useMutation(
    UPDATE_SUBJECT_GROUP_MUTATION,
    {
      onCompleted: () => {
        closeModal()
        toast.success('Subjects Added')
      },
      refetchQueries: [
        {
          query: SUBJECTS_QUERY,
          fetchPolicy: 'network-only',
          variables: {
            subjectGroup: subjectGroupId,
          },
        },
      ],
    }
  )
  const [query, { data, error, fetchMore }] = useLazyQuery(
    SUBJECTS_NOT_IN_SUBJECT_GROUP_QUERY,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )
  useEffect(() => {
    if (showModal && subjectGroupId && organizationId) {
      query({
        variables: {
          subjectGroupId,
          organizationId,
        },
      })
    }
  }, [showModal, subjectGroupId, organizationId])
  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    setCursor(null)
    setSubjects([])
    query({
      variables: {
        organizationId,
        subjectGroupId,
        searchTerm: currentSearchTerm,
      },
    })
  }
  const closeModal = () => {
    setSearchTerm('')
    setSubjects([])
    setAddSubjects({})
    toggleModal()
  }
  const handleSubjectRowClick = (row) => {
    const nodeId = row.original.id
    if (Object.keys(addSubjects).includes(nodeId)) {
      setAddSubjects((prevState) => {
        const prevAddSubjects = { ...prevState }
        delete prevAddSubjects[nodeId]
        return prevAddSubjects
      })
    } else {
      setAddSubjects((prevState) => {
        const prevAddSubjects = { ...prevState }
        prevAddSubjects[row.original.id] = row.original
        return prevAddSubjects
      })
    }
  }
  const handleRemoveSubjectClick = (addSubject) => {
    setAddSubjects((prevState) => {
      const prevAddSubjects = { ...prevState }
      delete prevAddSubjects[addSubject[1].id]
      return prevAddSubjects
    })
  }
  useEffect(() => {
    if (data) {
      setHasMoreSearchResults(
        data.organizationSubjectsNotInSubjectGroup.hasNextPage
      )
      if (data.organizationSubjectsNotInSubjectGroup.endCursor) {
        setCursor(data.organizationSubjectsNotInSubjectGroup.endCursor)
      }
      const currentSubjects =
        data.organizationSubjectsNotInSubjectGroup.subjects.map((subject) => {
          return {
            name: `${subject.gaiaUser.firstName} ${subject.gaiaUser.lastName}`,
            email: subject.gaiaUser.email,
            studentId: subject.studentId,
            id: subject.id,
          }
        })
      setSubjects(currentSubjects)
    }
  }, [data])
  const fetchMoreSubjects = () => {
    fetchMore({
      variables: {
        searchTerm,
        subjectGroupId,
        organizationId,
        cursor,
      },
    })
  }
  const handleAddSelectedSubjectsClick = () => {
    const addSubjectIds = Object.keys(addSubjects)
    updateSubjectGroupMutation({
      variables: {
        updateSubjectGroupInput: {
          subjectGroupInput: {
            id: subjectGroupId,
            addSubjectIds,
          },
        },
      },
    })
  }
  if (!showModal) return <></>
  if (error) return <>Error loading subjects</>
  return (
    <>
      <div className="subjectGroupAddSubject">
        <Modal size="lg" show={showModal} onHide={() => closeModal()}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h4>Add Subjects to Subject Group</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={12} md={9}>
                <Form.Group>
                  <Form.Control
                    type="text"
                    name="searchTerm"
                    placeholder={`Search ${organizationName} organization subjects`}
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={12}>
                <SortableInfiniteTable
                  tableData={subjects}
                  tableColumns={tableColumns}
                  fetchMoreTableData={fetchMoreSubjects}
                  hasMoreTableData={hasMoreSubjects}
                  onRowClick={handleSubjectRowClick}
                />
              </Col>
            </Row>
            {Object.keys(addSubjects).length > 0 ? (
              <div className="selectedSubjects mt-5">
                {Object.entries(addSubjects).map((addSubject, index) => {
                  if (index === 0) {
                    return (
                      <div key={addSubject[0]}>
                        <Row>
                          <Col xs={12} md={3}>
                            <h6>Selected subjects</h6>
                          </Col>
                          <Col xs={12} md={4}>
                            <h5>
                              <Badge
                                variant="secondary"
                                style={{
                                  display: 'inline-block',
                                }}
                              >
                                {addSubject[1].name}
                              </Badge>
                              <Trash
                                className="text-danger ml-2"
                                onClick={() =>
                                  handleRemoveSubjectClick(addSubject)
                                }
                              />
                            </h5>
                          </Col>
                        </Row>
                      </div>
                    )
                  }
                  return (
                    <div key={addSubject[0]}>
                      <Row>
                        <Col
                          xs={12}
                          md={{
                            span: 4,
                            offset: 3,
                          }}
                        >
                          <h5>
                            <Badge
                              variant="secondary"
                              style={{
                                display: 'inline-block',
                              }}
                            >
                              {addSubject[1].name}
                            </Badge>
                            <Trash
                              className="text-danger ml-2"
                              onClick={() =>
                                handleRemoveSubjectClick(addSubject)
                              }
                            />
                          </h5>
                        </Col>
                      </Row>
                    </div>
                  )
                })}
              </div>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            {Object.entries(addSubjects).length > 0 ? (
              <Button
                variant="outline-primary"
                block
                onClick={handleAddSelectedSubjectsClick}
              >
                Add Selected Subjects to Subject Group
              </Button>
            ) : null}
            <Button variant="outline-primary" block onClick={closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}

export default SubjectGroupAddSubjectsModal
