import React, { useState } from 'react'
import { Row, Col, Button, Badge } from 'react-bootstrap'
import { useQuery, gql } from '@apollo/client'
import { Plug, CaretDown, CaretRight } from 'react-bootstrap-icons'
import EditGoogleCalendarConnector from './EditGoogleCalendarConnector'

const GoogleCalendarConnector = () => {
  const { data } = useQuery(
    gql`
      query GoogleCalendarConnector {
        googleCalendarConnectors {
          edges {
            node {
              id
              auth
              authValid
              authAdded
              adminCalendarId
              serviceAccount
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )
  const [showEditModal, setShowEditModal] = useState(false)
  const [displayGcal, setDisplayGcal] = useState(false)

  const toggleModal = () => {
    setShowEditModal((prevState) => !prevState)
  }

  if (!data) return <></>
  const gCalConnector = data.googleCalendarConnectors.edges[0].node
  return (
    <>
      <div className="studioInfo">
        <Row className="mb-2 mt-4">
          <Col className="d-flex align-items-center">
            <button
              type="button"
              onClick={() => setDisplayGcal(!displayGcal)}
              className="px-0 btn-link mr-2"
            >
              <>
                {displayGcal ? (
                  <>
                    <CaretDown size={20} />
                  </>
                ) : (
                  <>
                    <CaretRight size={20} />
                  </>
                )}
              </>
            </button>
            <h4 className="mb-0">
              <img
                className="img-fluid mr-2"
                src={window.location.origin + '/google-calendar.png'}
                style={{ height: '25px' }}
                alt="Organization logo"
              />
              Google Calendar
            </h4>
          </Col>
        </Row>
        {displayGcal && (
          <>
            <Row>
              <Col>
                {!gCalConnector.authAdded && (
                  <Badge
                    className="ml-2"
                    style={{ fontSize: '12px' }}
                    variant="danger"
                  >
                    Disabled
                  </Badge>
                )}
                {!gCalConnector.authValid && gCalConnector.authAdded && (
                  <Badge
                    className="ml-2"
                    style={{ fontSize: '12px' }}
                    variant="danger"
                  >
                    Invalid Credentials
                  </Badge>
                )}
                {gCalConnector.authValid && (
                  <Badge
                    className="ml-2"
                    style={{ fontSize: '12px' }}
                    variant="success"
                  >
                    Enabled
                  </Badge>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  <Button variant="link" onClick={toggleModal}>
                    <Plug className="mr-2" />
                    Configure Google Calendar
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
        <EditGoogleCalendarConnector
          gCalConnector={gCalConnector}
          showModal={showEditModal}
          toggleModal={toggleModal}
        />
      </div>
    </>
  )
}

export default GoogleCalendarConnector
