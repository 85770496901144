import React, { useState } from 'react'
import { gql, useMutation, useLazyQuery, useReactiveVar } from '@apollo/client'
import { Form, Button, Row, Col, Table } from 'react-bootstrap'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import {
  XCircle,
  CaretDown,
  CaretRight,
  Download,
  PlusCircle,
  Trash,
} from 'react-bootstrap-icons'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loading from '../../common/Loading'
import * as Yup from 'yup'
import EquipmentJobTable from '../EquipmentJobTable'
import Repairs from '../Repair/Repairs'
import AuditLog from '../../audit_log/AuditLog'
import { loggedInUserVar, settingsVar } from '../../../libs/apollo'
import { useDownloadAndDeleteFile } from '../../../libs/downloadAndDeleteFile'

const ItemForm = (props) => {
  const { item, afterSubmit, employeeId, employeeName } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)
  const settings = useReactiveVar(settingsVar)

  const [submitting, setSubmitting] = useState(false)
  const [displayItemTypeResults, setDisplayItemTypeResults] = useState(false)
  const [displayEmployeeResults, setDisplayEmployeeResults] = useState(false)
  const [showAssignedJobs, setShowAssignedJobs] = useState(false)
  const [showRepairs, setShowRepairs] = useState(false)
  const [showQrCode, setShowQrCode] = useState(false)
  const [displayAuditLog, setDisplayAuditLog] = useState(false)
  const { downloadFile } = useDownloadAndDeleteFile()

  const [deleteEquipmentItem] = useMutation(
    gql`
      mutation DeleteEquipmentItem(
        $deleteEquipmentItemInput: DeleteEquipmentItemInput!
      ) {
        deleteEquipmentItem(input: $deleteEquipmentItemInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Item Deleted`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: [
        'ScanItems',
        'ScanBags',
        'EquipmentRepairs',
        'EquipmentBags',
        'EquipmentBagTypes',
        'EquipmentCategories',
        'EquipmentItems',
        'EquipmentItemTypes',
      ],
    }
  )

  const [createEquipmentItem] = useMutation(
    gql`
      mutation CreateEquipmentItem($input: CreateEquipmentItemInput!) {
        createEquipmentItem(input: $input) {
          equipmentItem {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Item Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: [
        'ScanItems',
        'ScanBags',
        'EquipmentBags',
        'EquipmentBagTypes',
        'EquipmentCategories',
        'EquipmentItems',
        'EquipmentItemTypes',
      ],
    }
  )

  const [updateEquipmentItem] = useMutation(
    gql`
      mutation UpdateEquipmentItem($input: UpdateEquipmentItemInput!) {
        updateEquipmentItem(input: $input) {
          equipmentItem {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Item Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: [
        'ScanItems',
        'ScanBags',
        'EquipmentBags',
        'EquipmentBagTypes',
        'EquipmentCategories',
        'EquipmentItems',
        'EquipmentItemTypes',
      ],
    }
  )

  const [
    searchEmployees,
    { data: employeesData, fetchMore: fetchMoreEmployees },
  ] = useLazyQuery(gql`
    query EquipmentBagEmployees(
      $after: String
      $first: Int
      $nameIcontains: String
    ) {
      employees(
        after: $after
        first: $first
        gaiaUser_FullName_Icontains: $nameIcontains
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            gaiaUser {
              fullName
            }
          }
        }
      }
    }
  `)

  const [
    searchItemTypes,
    { data: itemTypesData, fetchMore: fetchMoreItemTypes },
  ] = useLazyQuery(
    gql`
      query EquipmentItemTypes($after: String, $first: Int, $name: String) {
        equipmentItemTypes(
          after: $after
          first: $first
          name_Icontains: $name
          miscellaneous: false
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )

  const formik = useFormik({
    initialValues: item
      ? {
          name: item.name,
          notes: item.notes,
          underRepair: item.underRepair,
          categoryName: item.equipmentCategory
            ? item.equipmentCategory.name
            : '',
          categoryId: item.equipmentCategory ? item.equipmentCategory.id : null,
          itemTypeName: item.equipmentItemType
            ? item.equipmentItemType.name
            : '',
          itemTypeId: item.equipmentItemType ? item.equipmentItemType.id : null,
          itemTypeMiscellaneous: item.equipmentItemType
            ? item.equipmentItemType.itemTypeMiscellaneous
            : null,
          returned: item.returned,
          employeeName: item.employee ? item.employee.gaiaUser.fullName : '',
          employeeId: item.employee ? item.employee.id : null,
          serialNumber: item.serialNumber,
          vendor: item.vendor ? item.vendor : null,
          price: item.price ? item.price : null,
          invoiceNumber: item.invoiceNumber ? item.invoiceNumber : null,
        }
      : {
          name: '',
          notes: '',
          categoryName: '',
          categoryId: null,
          returned: true,
          itemTypeName: '',
          itemTypeId: null,
          employeeName: employeeName ? employeeName : '',
          employeeId: employeeId ? employeeId : null,
          serialNumber: '',
          vendor: '',
          price: '',
          invoiceNumber: '',
        },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('required'),
      serialNumber: Yup.string().nullable(),
      vendor: Yup.string().nullable(),
      price: Yup.string().nullable(),
      invoiceNumber: Yup.string().nullable(),
      notes: Yup.string().nullable(),
      categoryName: Yup.string().nullable(),
      categoryId: Yup.string().nullable(),
      employeeName: Yup.string().nullable(),
      employeeId: Yup.string().nullable(),
      itemTypeName: Yup.string().nullable(),
      itemTypeId: Yup.string().nullable(),
      itemTypeMiscellaneous: Yup.boolean().nullable(),
      returned: Yup.boolean().nullable(),
    }),
    validateOnChange: true,
    onSubmit: (values) => {
      setSubmitting(true)
      if (item) {
        updateEquipmentItem({
          variables: {
            input: {
              equipmentItemInput: {
                id: item.id,
                name: values.name,
                notes: values.notes,
                equipmentCategoryId: values.categoryId,
                equipmentItemTypeId: values.itemTypeId,
                returned: values.returned,
                underRepair: values.underRepair,
                employeeId: values.employeeId,
                vendor: values.vendor,
                invoiceNumber: values.invoiceNumber,
                price: values.price ? Number(values.price) : null,
                serialNumber: values.serialNumber,
              },
            },
          },
        })
      } else {
        createEquipmentItem({
          variables: {
            input: {
              equipmentItemInput: {
                name: values.name,
                notes: values.notes,
                equipmentCategoryId: values.categoryId,
                equipmentItemTypeId: values.itemTypeId,
                returned: values.returned,
                employeeId: values.employeeId,
                vendor: values.vendor,
                underRepair: values.underRepair,
                invoiceNumber: values.invoiceNumber,
                price: values.price ? Number(values.price) : null,
                serialNumber: values.serialNumber,
              },
            },
          },
        })
      }
    },
  })

  const handleDelete = () => {
    setSubmitting(true)
    deleteEquipmentItem({
      variables: {
        deleteEquipmentItemInput: {
          equipmentItemIds: item.id,
        },
      },
    })
  }

  const handleEmployeeBlur = () => {
    setDisplayEmployeeResults(false)
    formik.setFieldTouched(`employeeId`, true)
  }

  const handleEmployeeChange = (e) => {
    if (!e.target.value) {
      setDisplayEmployeeResults(false)
    } else {
      setDisplayEmployeeResults(true)
    }
    formik.setFieldValue(`employeeName`, e.target.value)
    searchEmployees({
      variables: {
        after: null,
        first: 10,
        nameIcontains: e.target.value,
      },
    })
  }

  const handleEmployeeXClick = () => {
    formik.setFieldValue(`employeeName`, '')
    formik.setFieldValue(`employeeId`, '')
  }

  const handleEmployeeClick = (node) => {
    setDisplayEmployeeResults(false)
    formik.setFieldValue(`employeeName`, node.gaiaUser.fullName)
    formik.setFieldValue(`employeeId`, node.id)
  }

  const handleItemTypeBlur = () => {
    setDisplayItemTypeResults(false)
    formik.setFieldTouched(`itemTypeId`, true)
  }

  const handleItemTypeChange = (e) => {
    if (!e.target.value) {
      setDisplayItemTypeResults(false)
    } else {
      setDisplayItemTypeResults(true)
    }
    formik.setFieldValue(`itemTypeName`, e.target.value)
    searchItemTypes({
      variables: {
        after: null,
        first: 10,
        name: e.target.value,
      },
    })
  }

  const handleItemTypeXClick = () => {
    formik.setFieldValue(`itemTypeName`, '')
    formik.setFieldValue(`itemTypeId`, '')
    formik.setFieldValue(`itemTypeMiscellaneous`, '')
  }

  const handleItemTypeClick = (node) => {
    formik.setFieldValue(`itemTypeName`, node.name)
    formik.setFieldValue(`itemTypeId`, node.id)
    formik.setFieldValue(`itemTypeMiscellaneous`, node.miscellaneous)
  }
  let itemTypeSeachInput = formik.values.itemTypeName
  if (formik.values.itemTypeId && formik.values.itemTypeMiscellaneous) {
    itemTypeSeachInput = `${itemTypeSeachInput} (misc)`
  }
  return (
    <>
      <div id="equipmentBagTypeForm">
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group as={Row}>
            <Col md={3}>
              <Form.Label column sm="12" md="auto">
                Name
              </Form.Label>
            </Col>
            <Col sm="12" md={6}>
              <Form.Control
                name="name"
                disabled={!canMutate}
                value={formik.values.name}
                onChange={formik.handleChange}
                isInvalid={formik.errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col md={3}>
              <Form.Label column sm="12" md="auto">
                Serial Number
              </Form.Label>
            </Col>
            <Col sm="12" md={6}>
              <Form.Control
                name="serialNumber"
                disabled={!canMutate}
                value={formik.values.serialNumber}
                onChange={formik.handleChange}
                isInvalid={formik.errors.serialNumber}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.serialNumber}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          {canMutate && (
            <>
              <Form.Group as={Row}>
                <Col md={3}>
                  <Form.Label column sm="12" md="auto">
                    Item Type
                  </Form.Label>
                </Col>
                <Col sm="12" md={6}>
                  <Form.Control
                    placeholder="search"
                    value={itemTypeSeachInput}
                    onBlur={handleItemTypeBlur}
                    onChange={(e) => handleItemTypeChange(e)}
                    readOnly={Boolean(formik.values.itemTypeId)}
                    className={
                      formik.values.itemTypeId
                        ? ' border border-success form-control form-control-sm'
                        : 'form-control form-control-sm'
                    }
                  />
                  {itemTypesData && displayItemTypeResults && (
                    <InfiniteScroll
                      height={100}
                      dataLength={itemTypesData.equipmentItemTypes.edges.length}
                      next={() => {
                        fetchMoreItemTypes({
                          variables: {
                            after:
                              itemTypesData.equipmentItemTypes.pageInfo
                                .endCursor,
                            first: 10,
                            nameIcontains: formik.values.itemTypeName,
                          },
                        })
                      }}
                      hasMore={
                        itemTypesData?.equipmentItemTypes.pageInfo.hasNextPage
                      }
                      loader={<Loading />}
                    >
                      <Table size="sm" hover>
                        <tbody>
                          {itemTypesData.equipmentItemTypes.edges.map(
                            (itemType) => {
                              const { node } = itemType
                              return (
                                <tr
                                  onMouseDown={() => handleItemTypeClick(node)}
                                  key={node.id}
                                  className="hover text-decoration-none"
                                >
                                  <td>
                                    <small>
                                      {node.name}{' '}
                                      {node.miscellaneous && (
                                        <span className="ml-1">(misc)</span>
                                      )}
                                    </small>
                                  </td>
                                </tr>
                              )
                            }
                          )}
                        </tbody>
                      </Table>
                    </InfiniteScroll>
                  )}
                </Col>
                <Col>
                  {formik.values.itemTypeId && (
                    <button
                      type="button"
                      className="p-0 mr-1 btn-link"
                      onClick={handleItemTypeXClick}
                    >
                      <XCircle />
                    </button>
                  )}
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col md={3}>
                  <Form.Label column sm="12" md="auto">
                    Vendor
                  </Form.Label>
                </Col>
                <Col sm="12" md={6}>
                  <Form.Control
                    name="vendor"
                    value={formik.values.vendor}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.vendor}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.vendor}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col md={3}>
                  <Form.Label column sm="12" md="auto">
                    Invoice Number
                  </Form.Label>
                </Col>
                <Col sm="12" md={6}>
                  <Form.Control
                    name="invoiceNumber"
                    value={formik.values.invoiceNumber}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.invoiceNumber}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.invoiceNumber}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col md={3}>
                  <Form.Label column sm="12" md="auto">
                    Price
                  </Form.Label>
                </Col>
                <Col sm="12" md={6}>
                  <Form.Control
                    name="price"
                    value={formik.values.price}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.price}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.price}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col md={3}>
                  <Form.Label column sm="12" md="auto">
                    Long Term Employee Assignment
                  </Form.Label>
                </Col>
                <Col sm="12" md={6}>
                  <Form.Control
                    placeholder="search"
                    value={formik.values.employeeName}
                    onBlur={handleEmployeeBlur}
                    onChange={(e) => handleEmployeeChange(e)}
                    readOnly={Boolean(formik.values.employeeId)}
                    className={
                      formik.values.employeeId
                        ? ' border border-success form-control form-control-sm'
                        : 'form-control form-control-sm'
                    }
                  />
                  {employeesData && displayEmployeeResults && (
                    <InfiniteScroll
                      height={100}
                      dataLength={employeesData.employees.edges.length}
                      next={() => {
                        fetchMoreEmployees({
                          variables: {
                            after: employeesData.employees.pageInfo.endCursor,
                            first: 10,
                            nameIcontains: formik.values.employeeName,
                          },
                        })
                      }}
                      hasMore={employeesData?.employees.pageInfo.hasNextPage}
                      loader={<Loading />}
                    >
                      <Table size="sm" hover>
                        <tbody>
                          {employeesData.employees.edges.map((org) => {
                            const { node } = org
                            return (
                              <tr
                                onMouseDown={() => handleEmployeeClick(node)}
                                key={node.id}
                                className="hover text-decoration-none"
                              >
                                <td>
                                  <small>{node.gaiaUser.fullName}</small>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </InfiniteScroll>
                  )}
                </Col>
                <Col>
                  {formik.values.employeeId && (
                    <button
                      type="button"
                      className="p-0 mr-1btn-link"
                      onClick={handleEmployeeXClick}
                    >
                      <Trash />
                    </button>
                  )}
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col md={3}>
                  <Form.Label column sm="12" md="auto">
                    Under Repair
                  </Form.Label>
                </Col>
                <Col sm="12" md={6}>
                  <Form.Check
                    type="checkbox"
                    name="underRepair"
                    checked={formik.values.underRepair}
                    onChange={() => {
                      formik.setFieldValue(
                        'underRepair',
                        !formik.values.underRepair
                      )
                    }}
                    isInvalid={formik.errors.underRepair}
                    feedback={formik.errors.underRepair}
                  />
                </Col>
              </Form.Group>
            </>
          )}

          <Form.Group as={Row}>
            <Col md={3}>
              <Form.Label column sm="12" md="auto">
                Checked In
              </Form.Label>
            </Col>
            <Col sm="12" md={6}>
              <Form.Check
                type="checkbox"
                name="returned"
                disabled={!canMutate}
                checked={formik.values.returned}
                onChange={() => {
                  formik.setFieldValue('returned', !formik.values.returned)
                }}
                isInvalid={formik.errors.returned}
                feedback={formik.errors.returned}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col md={3}>
              <Form.Label column sm="12" md="auto">
                Notes
              </Form.Label>
            </Col>
            <Col sm="12" md={6}>
              <Form.Control
                as="textarea"
                name="notes"
                disabled={!canMutate}
                value={formik.values.notes}
                onChange={formik.handleChange}
                isInvalid={formik.errors.notes}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.notes}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          {item && (
            <>
              {canMutate && (
                <Form.Group as={Row}>
                  <Col md={3} className="d-flex align-items-center">
                    <button
                      type="button"
                      onClick={() => setShowQrCode(!showQrCode)}
                      className="px-0 btn-link mr-1"
                    >
                      <>
                        {showQrCode ? (
                          <CaretDown size={17} />
                        ) : (
                          <CaretRight size={17} />
                        )}
                      </>
                    </button>
                    <Form.Label column sm={12} md="auto">
                      QR Code
                    </Form.Label>
                  </Col>
                </Form.Group>
              )}
              {showQrCode && (
                <Row>
                  <Col>
                    <div className="mt-2">
                      <button
                        type="button"
                        onClick={() =>
                          downloadFile(
                            item.qrCodeFile.fileName,
                            item.qrCodeFile.displayName,
                            () => toast.success(`QR Code Downloaded`)
                          )
                        }
                        className="btn btn-link btn-sm"
                      >
                        Download <Download className="text-primary ml-2" />
                      </button>
                    </div>
                  </Col>
                  <Col md={9} style={{ width: '50%', marginLeft: '25%' }}>
                    <div style={{ position: 'relative' }}>
                      <img
                        src={item.qrCodeFile.fileUrl}
                        alt="QR Code"
                        style={{
                          width: '50%',
                          transform: 'scale(0.6)',
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              )}

              <Form.Group as={Row} className="">
                <Col md={3} className="d-flex align-items-center">
                  <button
                    type="button"
                    onClick={() => setShowAssignedJobs(!showAssignedJobs)}
                    className="px-0 btn-link mr-1"
                  >
                    <>
                      {showAssignedJobs ? (
                        <>
                          <CaretDown size={17} />
                        </>
                      ) : (
                        <>
                          <CaretRight size={17} />
                        </>
                      )}
                    </>
                  </button>
                  <Form.Label column sm="12" md="auto">
                    Assigned Jobs
                  </Form.Label>
                </Col>
              </Form.Group>
              {showAssignedJobs && (
                <Form.Group as={Row}>
                  <Col>
                    <EquipmentJobTable itemId={item.id} />
                  </Col>
                </Form.Group>
              )}
              {canMutate && (
                <>
                  <Form.Group as={Row} className="">
                    <Col md={3} className="d-flex align-items-center">
                      <button
                        type="button"
                        onClick={() => setShowRepairs(!showRepairs)}
                        className="px-0 btn-link mr-1"
                      >
                        <>
                          {showRepairs ? (
                            <>
                              <CaretDown size={17} />
                            </>
                          ) : (
                            <>
                              <CaretRight size={17} />
                            </>
                          )}
                        </>
                      </button>
                      <Form.Label column sm="12" md="auto">
                        Repairs
                      </Form.Label>
                    </Col>
                  </Form.Group>

                  {showRepairs && (
                    <Form.Group as={Row}>
                      <Col>
                        <Repairs
                          itemId={item.id}
                          showRepairTableOnlyforItemTable={true}
                          prefilledRepairDetail={item}
                        />
                      </Col>
                    </Form.Group>
                  )}
                  <>
                    <Form.Group as={Row} className="">
                      <Col md={3} className="d-flex align-items-center">
                        <button
                          type="button"
                          onClick={() => setDisplayAuditLog(!displayAuditLog)}
                          className="px-0 btn-link mr-1"
                        >
                          <>
                            {displayAuditLog ? (
                              <>
                                <CaretDown size={17} />
                              </>
                            ) : (
                              <>
                                <CaretRight size={17} />
                              </>
                            )}
                          </>
                        </button>
                        <Form.Label column sm="12" md="auto">
                          History
                        </Form.Label>
                      </Col>
                    </Form.Group>
                    {displayAuditLog && (
                      <Form.Group as={Row} className="">
                        <Col md={12}>
                          <AuditLog
                            contentType={item.contentType.model}
                            id={item.id}
                          />
                        </Col>
                      </Form.Group>
                    )}
                  </>
                </>
              )}
            </>
          )}
          {canMutate && (
            <Form.Row className="mt-2">
              <Col md={3}>
                <Button
                  type="submit"
                  block
                  variant="outline-primary"
                  disabled={submitting}
                >
                  <PlusCircle className="mr-2" />
                  Save
                </Button>
              </Col>

              {item && (
                <Col md={3}>
                  <Button
                    variant="outline-danger"
                    block
                    onClick={handleDelete}
                    disabled={submitting}
                  >
                    <Trash className="mr-2" />
                    Delete
                  </Button>
                </Col>
              )}
            </Form.Row>
          )}
          {submitting && (
            <Row className="mt-3">
              <Col>
                <Loading message="Saving Item..." />
              </Col>
            </Row>
          )}
        </Form>
      </div>
    </>
  )
}

export default ItemForm
