import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Form, Row, Col, Button, Modal } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import { client } from '../../libs/apollo'
import { PlusCircle } from 'react-bootstrap-icons'
import Loading from '../common/Loading'

const NewCustomTriggerModal = (props) => {
  const {
    showModal,
    toggleModal,
    setCurrentNotificationTrigger,
    setCurrentTriggerCustom,
    parentFormik,
  } = props

  const [submitting, setSubmitting] = useState(false)

  const NAME_UNIQUE = gql`
    query TriggerNameUniqueQuery($nameIexact: String) {
      notificationTriggers(name_Iexact: $nameIexact) {
        nodeCount
      }
    }
  `

  const [create] = useMutation(
    gql`
      mutation CreateTriggerMutation(
        $createNotificationTriggerInput: CreateNotificationTriggerInput!
      ) {
        createNotificationTrigger(input: $createNotificationTriggerInput) {
          notificationTrigger {
            id
            name
            custom
            enabled
            smsEnabled
            emailEnabled
            recurring
            scheduledTrigger
            startDelta
            startDeltaCount
            occurrenceDelta
            occurrenceDeltaCount
            lastSentAt
            notificationTemplateChannels {
              edges {
                node {
                  id
                  channelType
                  notificationTemplate {
                    id
                    emailSubject
                    emailTemplateDesign
                    smsContent
                    dynamicContext
                    images
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        innerToggleModel()
        parentFormik.setFieldValue(`subjectGroupName`, '')
        parentFormik.setFieldValue(`subjectGroupId`, '')
        parentFormik.setFieldValue(`organizationName`, '')
        parentFormik.setFieldValue(`organizationId`, '')
        setCurrentNotificationTrigger(
          data.createNotificationTrigger.notificationTrigger
        )
        setCurrentTriggerCustom(true)
        setSubmitting(false)
        toast.success('Notification Template Created')
      },
      errorPolicy: 'all',
      refetchQueries: ['NotificationTriggers'],
    }
  )
  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(2, 'Name is too short')
        .max(100, 'Name is too long')
        .required('Name is required')
        .test('isUnique', 'Name must be unique', async (value) => {
          const { data } = await client.query({
            query: NAME_UNIQUE,
            fetchPolicy: 'network-only',
            variables: {
              nameIexact: value,
              custom: true,
            },
          })
          return data.notificationTriggers.nodeCount === 0
        }),
    }),
    onSubmit: (values) => {
      setSubmitting(true)
      create({
        variables: {
          createNotificationTriggerInput: {
            notificationTriggerInput: {
              name: values.name,
            },
          },
        },
      })
    },
  })
  const innerToggleModel = () => {
    formik.resetForm()
    toggleModal()
  }
  if (!showModal) return <></>
  return (
    <>
      <div className="customNotificationTrigger">
        <Modal
          size="md"
          show={showModal}
          onHide={innerToggleModel}
          aria-labelledby="new-title"
          className="invmodal detail"
          closeButton
        >
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>New Notification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Form.Group as={Col} xs={12} md={{ span: 8, offset: 2 }}>
                  <Form.Label className="mb-2">Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Col md={{ span: 8, offset: 2 }}>
                  <Button
                    type="submit"
                    variant="outline-primary"
                    className="mt-2"
                    block
                    disabled={submitting}
                  >
                    <PlusCircle className="mr-2" />
                    Save
                  </Button>
                </Col>
              </Row>

              {submitting && (
                <Row className="mt-3">
                  <Col>
                    <Loading />
                  </Col>
                </Row>
              )}
            </Modal.Body>
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default NewCustomTriggerModal
