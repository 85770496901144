import React, { useEffect, useRef, useState } from 'react'
import { Row, Col, Modal, Button, Table } from 'react-bootstrap'
import { gql, useLazyQuery } from '@apollo/client'
import { Dot } from 'react-bootstrap-icons'
import QrReader from 'react-qr-reader'
import { Trash, Search } from 'react-bootstrap-icons'
import { toast } from 'react-hot-toast'
import BagModal from './Bag/BagModal'
import ItemModal from './Item/ItemModal'
import Loading from '../common/Loading'
import EquipmentBagSearchInput from '../common/node_search_input/EquipmentBagSearchInput'
import EquipmentItemSearchInput from '../common/node_search_input/EquipmentItemSearchInput'
import { useFormik } from 'formik'
import Sound from '../Sound'
import { formatDateTime } from '../../libs/utils'

export default function ScanModal(props) {
  const {
    showModal,
    submitModal,
    toggleModal,
    setScannedItems,
    setScannedBags,
    scannedItems,
    scannedBags,
    submitButtonMessage,
    addItemValidation,
    addBagValidation,
    validateBagReturned,
    validateBagNoEmployee,
    validateItemReturned,
    validateItemUnderRepair,
    validateItemRetire,
    validateItemNoBag,
    validateItemNoEmployee,
    showSubmitValidation,
    disabledSubmitShowLoading,
    itemQuery,
    bagQuery,
    otherButtons,
  } = props
  const modalRef = useRef(null)
  const [currentScan, setCurrentScan] = useState()
  const [currentSearch, setCurrentSearch] = useState()
  const [editItem, setEditItem] = useState()
  const [editbag, setEditBag] = useState()
  const [searchResult, setSearchResult] = useState()
  const [playSound, setPlaySound] = useState(false)
  const [scanItem] = useLazyQuery(
    itemQuery
      ? itemQuery
      : gql`
          query ScanItems($equipmentItemId: [ID]) {
            equipmentItems(id: $equipmentItemId) {
              edges {
                node {
                  id
                  name
                  retire
                  employee {
                    gaiaUser {
                      fullName
                    }
                    id
                  }
                  equipmentBag {
                    id
                  }
                  underRepair
                  returned
                  qrCodeFile {
                    fileName
                    displayName
                    fileUrl
                  }
                  lastEmployeeJob {
                    job {
                      name
                    }
                    startDateTime
                    endDateTime
                    employee {
                      gaiaUser {
                        fullName
                      }
                    }
                  }
                  employeeJobs {
                    edges {
                      node {
                        job {
                          name
                          startDateTime
                          endDateTime
                        }
                      }
                    }
                  }
                  serialNumber
                  equipmentBag {
                    id
                    name
                  }
                  equipmentCategory {
                    id
                    name
                  }
                  equipmentItemType {
                    name
                    id
                  }
                }
              }
            }
          }
        `,
    {
      fetchPolicy: 'no-cache',
      onCompleted(data) {
        if (data.equipmentItems.edges.length > 0) {
          const node = data.equipmentItems.edges[0].node
          if ([currentScan, currentSearch].includes(node.id)) {
            if (currentScan) {
              setCurrentScan()
            }
            if (currentSearch) {
              setCurrentSearch()
            }
            if (!validateItemUnderRepair && node.underRepair) {
              toast.error('Item Under Repair')
            } else if (validateItemRetire && node.retire) {
              toast.error('Item Retired')
            } else if (validateItemNoBag && node.equipmentBag) {
              toast.error('Item Assigned In Bag')
            } else if (validateItemNoEmployee && node.employee) {
              toast.error('Item Assigned to Employee')
            } else if (validateItemReturned && !node.returned) {
              toast.error('Item Not Returned')
            } else if (
              !addItemValidation ||
              addItemValidation(data.equipmentItems.edges)
            ) {
              if (
                !scannedItems.some(
                  (item) => item.id === data.equipmentItems.edges[0].node.id
                )
              ) {
                if (!searchResult) {
                  setPlaySound(true)
                }
                setScannedItems((items) => [
                  ...items,
                  data.equipmentItems.edges[0].node,
                ])
                if (searchResult) {
                  setSearchResult(false)
                } else {
                  toast.success('Item Scanned')
                }
              } else {
                const updatedId = data.equipmentItems.edges[0].node.id
                setScannedItems((preItems) =>
                  preItems.map((item) =>
                    item.id === updatedId
                      ? data.equipmentItems.edges[0].node
                      : item
                  )
                )
              }
            }
          }
        }
      },
      errorPolicy: 'all',
    }
  )

  const [scanBag] = useLazyQuery(
    bagQuery
      ? bagQuery
      : gql`
          query ScanBags($equipmentBagId: [ID]) {
            equipmentBags(id: $equipmentBagId) {
              edges {
                node {
                  id
                  name
                  returned
                  notes
                  equipmentCategory {
                    id
                    name
                  }
                  qrCodeFile {
                    fileName
                    displayName
                    fileUrl
                  }
                  equipmentBagType {
                    id
                    name
                    equipmentItemTypesList {
                      id
                      name
                      equipmentCategory {
                        id
                        name
                      }
                    }
                  }
                  lastEmployeeJob {
                    job {
                      name
                    }
                    startDateTime
                    endDateTime
                    employee {
                      gaiaUser {
                        fullName
                      }
                    }
                  }
                  employee {
                    gaiaUser {
                      fullName
                    }
                    id
                  }
                  employeeJobs {
                    edges {
                      node {
                        job {
                          name
                          startDateTime
                          endDateTime
                        }
                      }
                    }
                  }
                  equipmentItems {
                    edges {
                      node {
                        id
                        name
                        serialNumber
                        equipmentBag {
                          id
                          name
                        }
                        equipmentCategory {
                          id
                          name
                        }
                        equipmentItemType {
                          name
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
    {
      fetchPolicy: 'no-cache',
      onCompleted(data) {
        if (data.equipmentBags.edges.length > 0) {
          const node = data.equipmentBags.edges[0].node
          if ([currentScan, currentSearch].includes(node.id)) {
            if (currentScan) {
              setCurrentScan()
            }
            if (currentSearch) {
              setCurrentSearch()
            }
            if (validateBagReturned && !node.returned) {
              toast.error('Bag Not Returned')
            } else if (validateBagNoEmployee && node.employee) {
              toast.error('Bag Assigned to Employee')
            } else if (
              !addBagValidation ||
              addBagValidation(data.equipmentBags.edges)
            ) {
              if (
                !scannedBags.some(
                  (item) => item.id === data.equipmentBags.edges[0].node.id
                )
              ) {
                if (!searchResult) {
                  setPlaySound(true)
                }
                setScannedBags((bags) => [
                  ...bags,
                  data.equipmentBags.edges[0].node,
                ])
                if (searchResult) {
                  setSearchResult(false)
                } else {
                  toast.success('Bag Scanned')
                }
              } else {
                const updatedId = data.equipmentBags.edges[0].node.id
                setScannedBags((prevBags) =>
                  prevBags.map((bag) =>
                    bag.id === updatedId
                      ? data.equipmentBags.edges[0].node
                      : bag
                  )
                )
              }
            }
          }
        }
      },
      errorPolicy: 'all',
    }
  )

  const handleScan = (value) => {
    if (value) {
      setCurrentScan(value)
      scanBag({
        variables: {
          equipmentBagId: [value],
        },
      })
      scanItem({
        variables: {
          equipmentItemId: [value],
        },
      })
    } else {
      setCurrentScan()
    }
  }

  const formik = useFormik({
    initialValues: {
      bagId: '',
      itemId: '',
    },
  })

  useEffect(() => {
    if (formik.values.bagId) {
      setSearchResult(true)
      setCurrentSearch(formik.values.bagId)
      scanBag({
        variables: {
          equipmentBagId: [formik.values.bagId],
        },
      })
      formik.setFieldValue('bagId', '')
      formik.setFieldValue('bagDescription', '')
    }
    if (formik.values.itemId) {
      setSearchResult(true)
      setCurrentSearch(formik.values.itemId)
      scanItem({
        variables: {
          equipmentItemId: [formik.values.itemId],
        },
      })
      formik.setFieldValue('itemId', '')
      formik.setFieldValue('itemDescription', '')
    }
  }, [formik.values.bagId, formik.values.itemId])

  useEffect(() => {
    if (playSound) {
      setTimeout(() => setPlaySound(false), 500)
    }
  }, [playSound])

  return (
    <>
      {playSound && <Sound sound="success" />}
      <Modal show={showModal} onHide={toggleModal} animation={false} size="lg">
        <div ref={modalRef}>
          <Modal.Header closeButton>
            <Modal.Title>Scan Equipment</Modal.Title>
          </Modal.Header>
          <Modal.Body ref={modalRef}>
            <Row>
              <Col md={{ span: 6, offset: 3 }}>
                <QrReader
                  delay={300}
                  onScan={handleScan}
                  style={{ width: '100%' }}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Row>
                  <Col md={6}>
                    <EquipmentBagSearchInput
                      formik={formik}
                      error={formik.errors.bagId}
                    />
                  </Col>
                </Row>
                {scannedBags && scannedBags.length > 0 && (
                  <Row>
                    <Col>
                      <div
                        style={{
                          maxHeight: '300px',
                          overflowY: 'scroll',
                          overflowX: 'hidden',
                        }}
                        className="mt-2"
                      >
                        <Table className="table-bordered compact-table ">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Type</th>
                              <th>Checked In</th>
                              <th>Last Assignment</th>
                              <th>View</th>
                              <th>Remove</th>
                            </tr>
                          </thead>
                          <tbody>
                            {scannedBags.map((bag, index) => (
                              <tr key={index}>
                                <td>{bag.name}</td>
                                <td>
                                  {bag.equipmentBagType?.name ? (
                                    <span>{bag.equipmentBagType.name}</span>
                                  ) : null}
                                </td>
                                <td>
                                  <span>{bag.returned ? 'Yes' : 'No'}</span>
                                </td>
                                <td>
                                  {bag.lastEmployeeJob && (
                                    <div style={{ fontSize: '12px' }}>
                                      <Dot className="mr-2" />
                                      Job - {bag.lastEmployeeJob.job.name}
                                      <br />
                                      <Dot className="mr-2" />
                                      Employee -{' '}
                                      {bag.lastEmployeeJob.employee ? (
                                        <>
                                          {
                                            bag.lastEmployeeJob.employee
                                              .gaiaUser.fullName
                                          }
                                        </>
                                      ) : (
                                        <>Unassigned</>
                                      )}
                                      <br />
                                      <Dot className="mr-2" />
                                      Shift -{' '}
                                      {formatDateTime(
                                        bag.lastEmployeeJob.startDateTime
                                      )}{' '}
                                      -{' '}
                                      {formatDateTime(
                                        bag.lastEmployeeJob.endDateTime,
                                        false,
                                        true
                                      )}
                                    </div>
                                  )}
                                </td>
                                <td>
                                  <Button
                                    variant="link"
                                    className="ml-2"
                                    onClick={() => {
                                      setEditBag(bag)
                                    }}
                                  >
                                    <Search />
                                  </Button>
                                </td>
                                <td>
                                  <Button
                                    variant="link"
                                    className="ml-1"
                                    onClick={() => {
                                      setScannedBags((prevBags) => {
                                        const newBags = [...prevBags]
                                        newBags.splice(index, 1)
                                        return newBags
                                      })
                                    }}
                                  >
                                    <Trash />
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Row>
                  <Col md={6}>
                    <EquipmentItemSearchInput
                      formik={formik}
                      error={formik.errors.itemId}
                    />
                  </Col>
                </Row>
                {scannedItems && scannedItems.length > 0 && (
                  <Row>
                    <Col>
                      <div
                        style={{
                          maxHeight: '300px',
                          overflowY: 'scroll',
                          overflowX: 'hidden',
                        }}
                        className="mt-2"
                      >
                        <Table className="table-bordered compact-table ">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Type</th>
                              <th>Checked In</th>
                              <th>Last Assignment</th>
                              <th>View</th>
                              <th>Remove</th>
                            </tr>
                          </thead>
                          <tbody>
                            {scannedItems.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  {item.name}
                                  {item.serialNumber && (
                                    <> #{item.serialNumber}</>
                                  )}
                                </td>
                                <td>
                                  {item.equipmentItemType?.name ? (
                                    <span>{item.equipmentItemType.name}</span>
                                  ) : null}
                                </td>
                                <td>
                                  <span>{item.returned ? 'Yes' : 'No'}</span>
                                </td>
                                <td>
                                  {item.lastEmployeeJob && (
                                    <div style={{ fontSize: '12px' }}>
                                      <Dot className="mr-2" />
                                      Job - {item.lastEmployeeJob.job.name}
                                      <br />
                                      <Dot className="mr-2" />
                                      Employee -{' '}
                                      {item.lastEmployeeJob.employee ? (
                                        <>
                                          {
                                            item.lastEmployeeJob.employee
                                              .gaiaUser.fullName
                                          }
                                        </>
                                      ) : (
                                        <>Unassigned</>
                                      )}
                                      <br />
                                      <Dot className="mr-2" />
                                      Shift -{' '}
                                      {formatDateTime(
                                        item.lastEmployeeJob.startDateTime
                                      )}{' '}
                                      -{' '}
                                      {formatDateTime(
                                        item.lastEmployeeJob.endDateTime,
                                        false,
                                        true
                                      )}
                                    </div>
                                  )}
                                </td>
                                <td>
                                  <Button
                                    variant="link"
                                    className="ml-2"
                                    onClick={() => {
                                      setEditItem(item)
                                    }}
                                  >
                                    <Search />
                                  </Button>
                                </td>
                                <td>
                                  <span style={{ color: 'red' }}>
                                    <Trash
                                      onClick={() => {
                                        setScannedItems((prevItems) => {
                                          const newItems = [...prevItems]
                                          newItems.splice(index, 1)
                                          return newItems
                                        })
                                      }}
                                      className="ml-1 mt-1"
                                    />
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
            {(!showSubmitValidation || showSubmitValidation()) && (
              <Row className="mt-3">
                <Col md={{ span: 6, offset: 3 }}>
                  <Button
                    block
                    disabled={disabledSubmitShowLoading}
                    variant="outline-primary"
                    onClick={submitModal}
                  >
                    {submitButtonMessage ? (
                      <>{submitButtonMessage}</>
                    ) : (
                      <>Done</>
                    )}
                  </Button>
                </Col>
              </Row>
            )}
            {otherButtons && otherButtons()}
            {disabledSubmitShowLoading && <Loading />}
          </Modal.Body>
        </div>
      </Modal>
      {editItem && (
        <ItemModal item={editItem} showModal={true} toggleModal={setEditItem} />
      )}
      {editbag && (
        <BagModal bag={editbag} showModal={true} toggleModal={setEditBag} />
      )}
    </>
  )
}
