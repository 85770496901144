import React, { useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import BusinessInfo from './business_info/BusinessInfo'
import BoxConnector from './box/BoxConnector'
import EmailSetting from './email/EmailSetting'
import OpenAI from './OpenAI'
import Blueprint from './blueprint/Blueprint'
import TwilioConnector from './twilio/TwilioConnector'
import Stripe from './Stripe'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../libs/apollo'
import Intercom from './intercom/Intercom'
import Tabs from '../common/Tabs'
import { Tab, Row, Col } from 'react-bootstrap'
import GoogleCalendarConnector from './google_calendar/GoogleCalendarConnector'
import TenantInvoiceTable from '../payments/tenant/TenantInvoiceTable'
import AuditLog from './AuditLog'
import API from './api/API'
import { Gear, Plug, Cash, ClockHistory } from 'react-bootstrap-icons'
import FTP from './ftp/FTP'
import Loading from '../common/Loading'
import { useBoxEnabled } from '../../libs/box'

const StudioSettings = () => {
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const [activeTab, setActiveTab] = useState('StudioInfo')
  const boxEnabled = useBoxEnabled()
  const admin = ['Administrator', 'Scheduling Manager'].includes(
    loggedInUser?.permissions?.group
  )
  const { loading, error, data } = useQuery(
    gql`
      query SettingsQuery {
        settings {
          edges {
            node {
              id
              name
              email
              colorScheme
              phoneNumber
              website
              facebookUrl
              twitterUrl
              linkedinUrl
              anonymousLogin
              auditLogEnabled
              instagramUrl
              intercomAppId
              instagramInFooter
              facebookInFooter
              twitterInFooter
              jobBookingEndHours
              jobBookingEndsEndOfJob
              linkedinInFooter
              openAiEnabled
              refundPolicy
              applyNoShowPolicyFree
              applyNoShowPolicyPaid
              applyPolicyFree
              applyPolicyPaid
              timeRefundHours
              autoCompleteJobs
              primaryContactEmail
              primaryContactName
              autoCompleteSessions
              timeRefundFee
              resitFeePaidSessions
              resitFeeFreeSessions
              timeRefundSessionPackageCost
              noShowFee
              showLastNameIdLogin
              resitFee
              productTaxRate
              freePackageResitFee
              resitsIncludeFreeSessions
              chargeStateSalesTax
              chargeProductStateSalesTax
              timezone
              displayLogoInNav
              displayNameInNav
              sessionStages
              jobStages
              logoImage {
                defaultImageName
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      // pollInterval: 30000,
    }
  )

  if (loading || !data)
    return (
      <div className="mt-3">
        <Loading />
      </div>
    )
  if (error) return <p>Error loading studio information</p>
  const settings = data.settings.edges[0].node
  return (
    <>
      <Row className="mt-3">
        <Col>
          <div
            style={{
              marginBottom: '10px',
            }}
          >
            <h1 className="mb-0" style={{ marginRight: '10px' }}>
              Settings
            </h1>
          </div>
        </Col>
      </Row>
      <Tabs onSelect={(tab) => setActiveTab(tab)} activeKey={activeTab}>
        <Tab
          eventKey="StudioInfo"
          title={
            <b>
              <Gear className="mr-2" />
              Studio Details
            </b>
          }
        >
          <BusinessInfo settings={settings} />
        </Tab>
        {admin && (
          <Tab
            eventKey="Integrations"
            title={
              <b>
                <Plug className="mr-2" />
                Integrations
              </b>
            }
          >
            {admin && (
              <>
                {' '}
                <API />
                <Blueprint />
                <GoogleCalendarConnector />
                <Intercom settings={settings} />
                <OpenAI settings={settings} />
                <TwilioConnector />
                <EmailSetting />
                <Stripe />
                <BoxConnector />
                <FTP />
              </>
            )}
          </Tab>
        )}
        {admin && (
          <Tab
            eventKey="Billing"
            title={
              <b>
                <Cash className="mr-2" />
                Airstudio Invoices
              </b>
            }
          >
            <TenantInvoiceTable />
          </Tab>
        )}
        {admin && (
          <Tab
            eventKey="History"
            title={
              <b>
                <ClockHistory className="mr-2" />
                History
              </b>
            }
          >
            <div className="mt-3">
              <AuditLog settingsNode={settings} />
            </div>
          </Tab>
        )}
      </Tabs>
    </>
  )
}

export default StudioSettings
