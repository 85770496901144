import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Button, Table } from 'react-bootstrap'
import { FilePond } from 'react-filepond'
import {
  CaretDown,
  CaretRight,
  ExclamationCircle,
  ExclamationTriangle,
  Image,
  XCircle,
  PlusCircle,
  ArrowCounterclockwise,
} from 'react-bootstrap-icons'
import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { Field, ErrorMessage, FieldArray } from 'formik'
import { ORGANIZATION_CATEGORY as organizations } from '../../libs/constant'
import { Trash, InfoCircle } from 'react-bootstrap-icons'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Loading from '../common/Loading'
import SubjectCsvImport from '../subject/SubjectCsvImport'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import { useInterval } from '../../libs/utils'
import AutocompleteAddress from '../location/AddressAutocomplete'
import Policies from '../common/Policies'
import OrganizationStageSearchInput from '../common/node_search_input/OrganizationStageSearchInput'
import OrganizationStage from './OrganizationStage'
import TagSearchInput from '../common/node_search_input/TagSeachInput'

export default function OrgForm(props) {
  const {
    formik,
    orgError,
    isOrgCreated,
    updatingOrg,
    logoImage,
    isSubmitting,
    setIsSubmitting,
    setCsvRecordSync,
    showModal,
    onComplete,
  } = props

  useInterval(() => {
    document
      .querySelectorAll('.CSVImporter_IconButton')
      .forEach((importerButton) => {
        if (!importerButton.hasAttribute('type')) {
          importerButton.setAttribute('type', 'button')
        }
      })
    document
      .querySelectorAll('.CSVImporter_TextButton')
      .forEach((importerTextButton) => {
        if (importerTextButton.innerHTML == 'Import') {
          importerTextButton.innerHTML = 'Validate Import'
        }
      })
    document
      .querySelectorAll('.CSVImporter_ProgressDisplay__status')
      .forEach((importerTextButton) => {
        if (importerTextButton.innerHTML == 'Complete') {
          importerTextButton.innerHTML = ''
        }
      })
  }, 100)

  useState(false)
  const [showAccountManagerResults, setShowAccountManagerResults] =
    useState(false)
  const [csvDataTransformed, setCsvDataTransformed] = useState([])
  const [importerKey, setImporterKey] = useState('5678')
  const [groupOptionValue, setGroupOptionValue] = useState('dash')
  const [rowsMissingInfo, setRowsMissingInfo] = useState([])
  const [rowFatalError, setRowFatalError] = useState(false)
  const [showOrganizationStageModal, setShowOrganizationStageModal] =
    useState(false)

  const [displayContacts, setDisplayContacts] = useState(false)
  const [displayLocation, setDisplayLocation] = useState(false)
  const [displayFiles, setDisplayFiles] = useState(false)

  const { data: sessionPackageData } = useQuery(
    gql`
      query SessionPackages {
        sessionPackages(orderBy: "title") {
          edges {
            node {
              id
              customPriceAndDuration
              durationHighMinutes
              durationLowMinutes
              price
              title
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const { data: groupsData } = useQuery(
    gql`
      query DefaultServiceItemGroupsQuery {
        defaultServiceItemGroups(orderBy: "name") {
          edges {
            node {
              id
              name
              default
            }
          }
        }
      }
    `,
    { fetchPolicy: 'network-only' }
  )

  const [searchEmployee, { data: employeesData }] = useLazyQuery(
    gql`
      query EmployeesQuery($cursor: String, $searchTerm: String) {
        employees(
          first: 20
          after: $cursor
          gaiaUser_FullName_Icontains: $searchTerm
          orderBy: "gaia_user__full_name"
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              gaiaUser {
                firstName
                lastName
                email
                phoneNumber
                fullName
              }
              defaultRole {
                name
              }
              internalEmployeeId
            }
          }
        }
      }
    `,
    {
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    if (!showModal) {
      setIsSubmitting(false)
      formik.resetForm()
    }
  }, [showModal])

  const handleFileUpload = (updateLogoImage) => {
    formik.setFieldValue('logoImage', updateLogoImage)
  }

  const handleAccManagerChange = (formik, e) => {
    searchEmployee({
      variables: {
        searchTerm: e.target.value,
      },
      fetchPolicy: 'network-only',
    })
    formik.setFieldValue(`accountManagerSearch`, e.target.value)
    setShowAccountManagerResults(true)
  }

  const handleGroupClick = (e) => {
    let defaultGroupData = groupsData.defaultServiceItemGroups.edges
    const index = defaultGroupData.findIndex(
      (x) => x.node.id === e.target.value
    )

    if (index > -1) {
      const currentGroup = formik.values.group
      currentGroup.push(defaultGroupData[index])
      formik.setFieldValue('group', currentGroup)

      setGroupOptionValue('dash')
    }
  }

  const handleRemoveGroup = (group) => {
    const currentGroup = formik.values.group
    currentGroup.splice(currentGroup.indexOf(group), 1)
    formik.setFieldValue('group', currentGroup)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace') {
      formik.setFieldValue(`accountManagerSearch`, '')
      formik.setFieldValue('accountManager', null)
      setShowAccountManagerResults(false)
    }
  }

  const updateCsvData = (transformedData) => {
    setCsvDataTransformed(transformedData)
    formik.setFieldValue('csvSubjects', transformedData)
  }

  const newUploadClick = () => {
    setImporterKey(Math.random())
    setRowsMissingInfo([])
    setRowFatalError(false)
    setCsvDataTransformed([])
  }

  const onAccountManagerClick = (row) => {
    formik.setFieldValue(
      'accountManagerSearch',
      row.original.node.gaiaUser.fullName
    )
    formik.setFieldValue('accountManager', row.original.node.id)
    setShowAccountManagerResults(false)
  }
  useEffect(() => {
    if (!orgError && isOrgCreated) {
      formik.handleReset()
    }
  }, [isOrgCreated])

  let availableBackgroundColorPackages = []
  if (sessionPackageData) {
    sessionPackageData.sessionPackages.edges.forEach((sp) => {
      let used = false
      formik.values.sessionPackageBackgrounds.forEach((spb) => {
        if (sp.node.id === spb.sessionPackageId) {
          used = true
        }
      })
      if (!used) {
        availableBackgroundColorPackages.push(sp.node)
      }
    })
  }
  const handleAddressSelect = (addressData) => {
    let currentLocations = [...formik.values.locations]
    // setSelectedAddress(addressData);

    let updatedLocations = currentLocations.map((location) => {
      if (location.isSelected === true) {
        location.addressLineOne = addressData?.name ? addressData?.name : ''
        location.addressLineTwo = addressData?.neighborhood
          ? addressData?.neighborhood
          : ''
        location.isSelected = false
        location.city = addressData.address?.locality
          ? addressData?.address?.locality
          : ''
        location.state = addressData?.address
          ? addressData?.address?.administrative_area_level_1
          : ''
        location.zipCode = addressData.address?.postal_code
          ? addressData?.address?.postal_code
          : ''
      }
      return location
    })

    formik.setFieldValue('locations', updatedLocations)
  }

  if (!sessionPackageData) return <></>
  return (
    <>
      <OrganizationStage
        showModal={showOrganizationStageModal}
        toggleMogal={setShowOrganizationStageModal}
      />
      <Form onSubmit={formik.handleSubmit}>
        <Form.Row>
          <Form.Group as={Col} md={3}>
            <Form.Label>Name</Form.Label>
            <Field name="name" className="form-control form-control-sm" />
            <ErrorMessage name="name">
              {(msg) => <span className="text-danger">{msg}</span>}
            </ErrorMessage>
          </Form.Group>
          <Form.Group as={Col} md={3}>
            <Form.Label>
              Account Manager
              {formik.values.accountManager && (
                <span
                  type="button"
                  className="p-0 ml-2 btn-link"
                  onClick={() => {
                    formik.setFieldValue(`accountManagerSearch`, '')
                    formik.setFieldValue('accountManager', null)
                  }}
                >
                  <Trash />
                </span>
              )}
            </Form.Label>
            <Form.Control
              className="form-control-sm"
              value={formik.values.accountManagerSearch}
              readOnly={Boolean(formik.values.accountManager)}
              onChange={(e) => handleAccManagerChange(formik, e)}
              placeholder="Search"
            />
            <div className={showAccountManagerResults ? '' : 'd-none'}>
              <div
                style={{
                  position: 'absolute',
                  backgroundColor: 'white',
                  zIndex: 3000,
                  width: '95%',
                  top: '100%',
                }}
              >
                <SortableInfiniteTable
                  hideGlobalFilter
                  tableData={
                    employeesData?.employees?.edges
                      ? employeesData?.employees?.edges
                      : []
                  }
                  tableColumns={[
                    {
                      Header: '',
                      accessor: 'node.gaiaUser.fullName',
                    },
                  ]}
                  hasMoreTableData={false}
                  rowPointer
                  onRowClick={onAccountManagerClick}
                  tableHeight={100}
                />
              </div>
            </div>
          </Form.Group>
          <Form.Group as={Col} md={2}>
            <Form.Label>Category</Form.Label>
            <select
              className="form-select form-control-sm"
              name={formik.values.category}
              value={formik.values.category}
              onChange={(e) => {
                formik.setFieldValue('category', e.target.value)
              }}
            >
              <>
                {organizations.map((org) => (
                  <>
                    <option key={org} value={org}>
                      {org}
                    </option>
                  </>
                ))}
              </>
            </select>
          </Form.Group>
          <Form.Group as={Col} md={2}>
            <Form.Label>
              Stage
              <span
                type="button"
                className="p-0 ml-2 btn-link"
                onClick={() => {
                  setShowOrganizationStageModal(true)
                }}
              >
                <PlusCircle />
              </span>
            </Form.Label>
            <OrganizationStageSearchInput
              formik={formik}
              dropdown
              error={formik.errors.organizationStageId}
            />
          </Form.Group>
          <Form.Group as={Col} md={2}>
            <Form.Label>Tags</Form.Label>
            <TagSearchInput formik={formik} multiple create />
          </Form.Group>
        </Form.Row>
        <Form.Group>
          <Row>
            <Col xs={12}>
              <Form.Label>Logo</Form.Label>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {updatingOrg ? (
                <>
                  {logoImage ? (
                    <img
                      className="d-block mx-auto img-fluid w-25"
                      src={`data:image/jpeg;base64,${logoImage}`}
                      alt="Organization logo"
                    />
                  ) : (
                    <div>
                      <Image className="d-block mx-auto w-25 h-25 text-secondary" />
                    </div>
                  )}
                </>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mt-3">
              <FilePond
                files={formik.values.logoImage}
                onupdatefiles={handleFileUpload}
                acceptedFileTypes={['image/png', 'image/jpeg']}
                allowFileSizeValidation
                maxFileSize="15MB"
                labelMaxFileSize="Maximum preview image size is {filesize}"
                maxFiles={1}
                name="files"
                labelIdle='Drag and drop a logo image or <span class="filepond--label-action">Browse</span>'
              />
            </Col>
          </Row>
        </Form.Group>
        <Row className="mt-3">
          <Form.Group as={Col} md={12}>
            <Form.Label>Notes</Form.Label>
            <Form.Control
              value={formik.values.notes}
              onChange={(e) => {
                formik.setFieldValue(`notes`, e.target.value)
              }}
              as="textarea"
              rows={6}
              className="form-text"
            />
            <ErrorMessage name={`notes`}>
              {(msg) => (
                <div>
                  <span className="text-danger">{msg}</span>
                </div>
              )}
            </ErrorMessage>
          </Form.Group>
        </Row>
        <Row className="mt-3">
          <Col>
            <p className="m-0">Subject CSV Import</p>
            <Row className="mt-3">
              <Col md={3}>
                <Form.Label>CSV Import Existing Record Sync Field</Form.Label>
                <Form.Group>
                  <select
                    className="form-control-sm form-select"
                    name="csvRecordSync"
                    value={formik.values.csvRecordSync}
                    onChange={(e) => {
                      setCsvRecordSync(e.target.value || undefined)
                    }}
                  >
                    <option value="">- - -</option>
                    <option value="subject full name">Subject Full Name</option>
                    <option value="subject id">Subject ID</option>
                    <option value="subject email">Subject Email</option>
                    <option value="custom one">Custom One</option>
                    <option value="custom two">Custom Two</option>
                    <option value="custom three">Custom Three</option>
                    <option value="custom four">Custom Four</option>
                    <option value="custom five">Custom Five</option>
                  </select>
                </Form.Group>
              </Col>
            </Row>
            {csvDataTransformed.length > 0 && (
              <Button
                className="mt-2 mb-1"
                onClick={newUploadClick}
                size="sm"
                variant="outline-primary"
              >
                <ArrowCounterclockwise className="mr-2" />
                New Upload
              </Button>
            )}

            <div className="mt-2">
              <SubjectCsvImport
                importerKey={importerKey}
                rowsMissingInfo={rowsMissingInfo}
                setRowsMissingInfo={setRowsMissingInfo}
                setRowFatalError={setRowFatalError}
                rowFatalError={rowFatalError}
                updateCsvData={updateCsvData}
              />
            </div>
          </Col>
        </Row>
        {rowsMissingInfo.length > 0 && (
          <>
            <Row className="mt-3">
              <Col>
                <h4>
                  <ExclamationTriangle className="mr-2" />
                  Import Warnings and Errors
                </h4>
              </Col>
            </Row>
            <Row
              className="mt-3"
              style={{
                overflowY: 'scroll',
                maxHeight: '200px',
                overflowX: 'hidden',
              }}
            >
              <Col>
                <Table responsive size="sm" bordered>
                  <thead>
                    <tr>
                      <th>
                        <small className="font-weight-bold">Type</small>
                      </th>
                      <th>
                        <small className="font-weight-bold">Row</small>
                      </th>
                      <th>
                        <small className="font-weight-bold">Column</small>
                      </th>
                      <th>
                        <small className="font-weight-bold">Error</small>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {rowsMissingInfo.map((csvError, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <small className="d-block">
                              {csvError.fatal ? <>Fatal</> : <>Warning</>}
                            </small>
                          </td>
                          <td>
                            <small
                              className="d-block"
                              style={{
                                width: '150px',
                              }}
                            >
                              {csvError.row}
                            </small>
                          </td>
                          <td>
                            <small
                              className="d-block"
                              style={{
                                width: '150px',
                              }}
                            >
                              {csvError.column}
                            </small>
                          </td>
                          <td>
                            <small className="d-block">{csvError.error}</small>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </>
        )}
        <Row
          className={
            csvDataTransformed.length > 0 && rowFatalError === false
              ? 'text-secondary'
              : 'd-none'
          }
        >
          <Col>
            <p
              className={
                csvDataTransformed.length > 0
                  ? 'text-secondary mt-3 mb-0'
                  : 'd-none'
              }
            >
              CSV Data
            </p>
            <small
              className={
                csvDataTransformed.length > 0 ? 'text-secondary' : 'd-none'
              }
            >
              {csvDataTransformed.length} rows
            </small>
            <div
              style={{
                overflowY: 'scroll',
                maxHeight: '300px',
                overflowX: 'hidden',
              }}
            >
              <Table
                responsive
                size="sm"
                bordered
                style={{ maxHeight: '400px' }}
                className={
                  csvDataTransformed.length > 0 ? 'mt-2 d-block' : 'd-none'
                }
              >
                <thead>
                  <tr>
                    <th>
                      <small className="font-weight-bold">
                        Subject First Name
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Last Name
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Job Title
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">ID Number</small>
                    </th>
                    <th>
                      <small className="font-weight-bold">Subject Email</small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Secondary Email
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Password
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">Subject Phone</small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Secondary Phone
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Metadata
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Address Line One
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Address Line Two
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">City</small>
                    </th>
                    <th>
                      <small className="font-weight-bold">State</small>
                    </th>
                    <th>
                      <small className="font-weight-bold">Zip Code</small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact One First Name
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact One Last Name
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact One Email
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact One Secondary Email
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact One Password
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact One Phone
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact One Secondary Phone
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact Two First Name
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact Two Last Name
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact Two Email
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact Two Secondary Email
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact Two Password
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact Two Phone
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact Two Secondary Phone
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Custom One
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Custom Two
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Custom Three
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Custom Four
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Custom Five
                      </small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {csvDataTransformed.map((row, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.subjectFirstName}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.subjectLastName}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.subjectJobTitle}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.studentId}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '200px' }}>
                            {row.subjectEmail}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '200px' }}>
                            {row.subjectSecondaryEmail}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '200px',
                            }}
                          >
                            {row.subjectPassword}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.subjectPhone}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.subjectSecondaryPhone}
                          </small>
                        </td>
                        <td>
                          <small className="d-block">{row.metadata}</small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '200px' }}>
                            {row.addressLineOne}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.addressLineTwo}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.city}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.state}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.zipCode}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.parentOneFirstName}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.parentOneLastName}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '200px' }}>
                            {row.parentOneEmail}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '200px' }}>
                            {row.parentOneSecondaryEmail}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '200px',
                            }}
                          >
                            {row.parentOnePassword}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.parentOnePhone}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.parentOneSecondaryPhone}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.parentTwoFirstName}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.parentTwoLastName}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '200px' }}>
                            {row.parentTwoEmail}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '200px',
                            }}
                          >
                            {row.parentTwoPassword}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '200px' }}>
                            {row.parentTwoSecondaryEmail}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.parentTwoPhone}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.parentTwoSecondaryPhone}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.subjectCustomOne}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.subjectCustomTwo}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.subjectCustomThree}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.subjectCustomFour}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.subjectCustomFive}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.subjectGroupCustomOne}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.subjectGroupCustomTwo}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.subjectGroupCustomThree}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.subjectGroupCustomFour}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.subjectGroupCustomFive}
                          </small>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="d-flex align-items-center">
            <button
              type="button"
              onClick={() => setDisplayFiles(!displayFiles)}
              className="px-0 btn-link mr-2"
              style={{ marginTop: '-10px' }}
            >
              <>
                {displayFiles ? (
                  <CaretDown size={17} />
                ) : (
                  <CaretRight size={17} />
                )}
              </>
            </button>
            <Form.Label>Files </Form.Label>
          </Col>
        </Row>
        {displayFiles && (
          <>
            <Row className="ml-2">
              <Col>
                <Form.Group>
                  <Form.Check
                    name="sharedCanSeeFiles"
                    type="checkbox"
                    label="Contacts Can See Files"
                    checked={formik.values.sharedCanSeeFiles}
                    onChange={(e) => {
                      formik?.setFieldValue(
                        `sharedCanSeeFiles`,
                        e.target.checked
                      )
                    }}
                  />
                </Form.Group>
              </Col>
              {formik.values.sharedCanSeeFiles && (
                <>
                  <Col>
                    <Form.Group>
                      <Form.Check
                        name="sharedCanCreateFiles"
                        type="checkbox"
                        label="Contacts Can Create Files"
                        checked={formik.values.sharedCanCreateFiles}
                        onChange={(e) => {
                          formik?.setFieldValue(
                            `sharedCanCreateFiles`,
                            e.target.checked
                          )
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Check
                        name="sharedCanCreateFolders"
                        type="checkbox"
                        label="Contacts Can Create Folders"
                        checked={formik.values.sharedCanCreateFolders}
                        onChange={(e) => {
                          formik?.setFieldValue(
                            `sharedCanCreateFolders`,
                            e.target.checked
                          )
                        }}
                      />
                    </Form.Group>
                  </Col>
                </>
              )}
            </Row>
            <Row className="ml-2 mt-3">
              <Col>
                <Form.Group>
                  <Form.Check
                    name="subjectGroupSharedCanSeeFiles"
                    type="checkbox"
                    label="Contacts Can See Subject Group Files"
                    checked={formik.values.subjectGroupSharedCanSeeFiles}
                    onChange={(e) => {
                      formik?.setFieldValue(
                        `subjectGroupSharedCanSeeFiles`,
                        e.target.checked
                      )
                    }}
                  />
                </Form.Group>
              </Col>
              {formik.values.subjectGroupSharedCanSeeFiles && (
                <>
                  <Col>
                    <Form.Group>
                      <Form.Check
                        name="subjectGroupSharedCanCreateFiles"
                        type="checkbox"
                        label="Contacts Can Create Subject Group Files"
                        checked={formik.values.subjectGroupSharedCanCreateFiles}
                        onChange={(e) => {
                          formik?.setFieldValue(
                            `subjectGroupSharedCanCreateFiles`,
                            e.target.checked
                          )
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Check
                        name="subjectGroupSharedCanCreateFolders"
                        type="checkbox"
                        label="Contacts Can Create Subject Group Folders"
                        checked={
                          formik.values.subjectGroupSharedCanCreateFolders
                        }
                        onChange={(e) => {
                          formik?.setFieldValue(
                            `subjectGroupSharedCanCreateFolders`,
                            e.target.checked
                          )
                        }}
                      />
                    </Form.Group>
                  </Col>
                </>
              )}
            </Row>
            <Row className="ml-2 mt-3">
              <Col>
                <Form.Group>
                  <Form.Check
                    name="subjectSharedCanSeeFiles"
                    type="checkbox"
                    label="Subjects Can See Files"
                    checked={formik.values.subjectSharedCanSeeFiles}
                    onChange={(e) => {
                      formik?.setFieldValue(
                        `subjectSharedCanSeeFiles`,
                        e.target.checked
                      )
                    }}
                  />
                </Form.Group>
              </Col>
              {formik.values.subjectSharedCanSeeFiles && (
                <>
                  <Col>
                    <Form.Group>
                      <Form.Check
                        name="subjectSharedCanCreateFiles"
                        type="checkbox"
                        label="Subjects Can Create Files"
                        checked={formik.values.subjectSharedCanCreateFiles}
                        onChange={(e) => {
                          formik?.setFieldValue(
                            `subjectSharedCanCreateFiles`,
                            e.target.checked
                          )
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Check
                        name="subjectSharedCanCreateFolders"
                        type="checkbox"
                        label="Subjects Can Create Folders"
                        checked={formik.values.subjectSharedCanCreateFolders}
                        onChange={(e) => {
                          formik?.setFieldValue(
                            `subjectSharedCanCreateFolders`,
                            e.target.checked
                          )
                        }}
                      />
                    </Form.Group>
                  </Col>
                </>
              )}
            </Row>
            <Row className="ml-2 mt-3">
              <Col>
                <Form.Group>
                  <Form.Check
                    name="serviceItemSharedCanSeeFiles"
                    type="checkbox"
                    label="Contacts Can See Service Item Files"
                    checked={formik.values.serviceItemSharedCanSeeFiles}
                    onChange={(e) => {
                      formik?.setFieldValue(
                        `serviceItemSharedCanSeeFiles`,
                        e.target.checked
                      )
                    }}
                  />
                </Form.Group>
              </Col>
              {formik.values.serviceItemSharedCanSeeFiles && (
                <>
                  <Col>
                    <Form.Group>
                      <Form.Check
                        name="serviceItemSharedCanCreateFiles"
                        type="checkbox"
                        label="Contacts Can Create Service Item Files"
                        checked={formik.values.serviceItemSharedCanCreateFiles}
                        onChange={(e) => {
                          formik?.setFieldValue(
                            `serviceItemSharedCanCreateFiles`,
                            e.target.checked
                          )
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Check
                        name="serviceItemSharedCanCreateFolders"
                        type="checkbox"
                        label="Contacts Can Create Service Item Folders"
                        checked={
                          formik.values.serviceItemSharedCanCreateFolders
                        }
                        onChange={(e) => {
                          formik?.setFieldValue(
                            `serviceItemSharedCanCreateFolders`,
                            e.target.checked
                          )
                        }}
                      />
                    </Form.Group>
                  </Col>
                </>
              )}
            </Row>
          </>
        )}

        <Row className="">
          <Col className="d-flex align-items-center">
            <button
              type="button"
              onClick={() => setDisplayContacts(!displayContacts)}
              className="px-0 btn-link mr-2"
              style={{ marginTop: '-10px' }}
            >
              <>
                {displayContacts ? (
                  <CaretDown size={17} />
                ) : (
                  <CaretRight size={17} />
                )}
              </>
            </button>
            <Form.Label>Contacts </Form.Label>
          </Col>
        </Row>
        {displayContacts && (
          <Form.Row>
            <Form.Group as={Col} md={12}>
              <FieldArray
                name="contacts"
                render={(arrayHelpers) => (
                  <div>
                    <div
                      style={
                        formik.values.contacts.length > 1
                          ? {
                              overflowY: 'scroll',
                              maxHeight: '450px',
                              overflowX: 'hidden',
                            }
                          : null
                      }
                      className={
                        formik.values.contacts.length > 1
                          ? 'border p-3 mt-2'
                          : ''
                      }
                    >
                      <div className="contact-form">
                        {formik.values.contacts.map((contact, i) => {
                          return (
                            <div
                              key={i}
                              className="border border-secondary border-rounded p-3 rounded mb-3"
                            >
                              <Form.Row>
                                <Form.Group as={Col} md={4}>
                                  <Form.Label>First Name</Form.Label>
                                  <Field
                                    name={`contacts[${i}].firstName`}
                                    className="form-control form-control-sm"
                                  />
                                  <ErrorMessage
                                    name={`contacts[${i}].firstName`}
                                  >
                                    {(msg) => (
                                      <span className="text-danger">{msg}</span>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                                <Form.Group as={Col} md={4}>
                                  <Form.Label>Last Name</Form.Label>
                                  <Field
                                    name={`contacts[${i}].lastName`}
                                    className="form-control form-control-sm"
                                  />
                                  <ErrorMessage
                                    name={`contacts[${i}].lastName`}
                                  >
                                    {(msg) => (
                                      <span className="text-danger">{msg}</span>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                                <Form.Group as={Col} md={4}>
                                  <div className="d-flex align-items-center">
                                    <Form.Label>Job Title</Form.Label>
                                  </div>

                                  <Field
                                    name={`contacts[${i}].jobTitle`}
                                    className="form-control form-control-sm"
                                    type="text"
                                  />

                                  <ErrorMessage
                                    name={`contacts[${i}].jobTitle`}
                                  >
                                    {(msg) => (
                                      <span className="text-danger">{msg}</span>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group as={Col} md={6}>
                                  <Form.Label>Email</Form.Label>
                                  <Field
                                    name={`contacts[${i}].email`}
                                    className="form-control form-control-sm"
                                  />
                                  <ErrorMessage name={`contacts[${i}].email`}>
                                    {(msg) => {
                                      return (
                                        <span className="text-danger">
                                          {msg}
                                        </span>
                                      )
                                    }}
                                  </ErrorMessage>
                                </Form.Group>
                                <Form.Group as={Col} md={6}>
                                  <Form.Label>Secondary Email</Form.Label>
                                  <Field
                                    name={`contacts[${i}].secondaryEmail`}
                                    className="form-control form-control-sm"
                                  />
                                  <ErrorMessage
                                    name={`contacts[${i}].secondaryEmail`}
                                  >
                                    {(msg) => (
                                      <span className="text-danger">{msg}</span>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group as={Col} md={6}>
                                  <Form.Label>Phone Number</Form.Label>
                                  <PhoneInput
                                    placeholder=""
                                    inputClass="w-100 form-control"
                                    country="us"
                                    name={`contacts[${i}].phoneNumber`}
                                    regions={['north-america']}
                                    value={
                                      formik.values.contacts[i].phoneNumber
                                    }
                                    onChange={(phone) =>
                                      formik.setFieldValue(
                                        `contacts[${i}].phoneNumber`,
                                        phone
                                      )
                                    }
                                  />
                                  <ErrorMessage
                                    name={`contacts[${i}].phoneNumber`}
                                  >
                                    {(msg) => (
                                      <div>
                                        <span className="text-danger">
                                          {msg}
                                        </span>
                                      </div>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                                <Form.Group as={Col} md={6}>
                                  <Form.Label>
                                    Secondary Phone Number
                                  </Form.Label>
                                  <PhoneInput
                                    placeholder=""
                                    inputClass="w-100 form-control"
                                    country="us"
                                    name={`contacts[${i}].secondaryPhoneNumber`}
                                    regions={['north-america']}
                                    value={
                                      formik.values.contacts[i]
                                        .secondaryPhoneNumber
                                    }
                                    onChange={(phone) =>
                                      formik.setFieldValue(
                                        `contacts[${i}].secondaryPhoneNumber`,
                                        phone
                                      )
                                    }
                                  />
                                  <ErrorMessage
                                    name={`contacts[${i}].secondaryPhoneNumber`}
                                  >
                                    {(msg) => (
                                      <div>
                                        <span className="text-danger">
                                          {msg}
                                        </span>
                                      </div>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group as={Col} md={6}>
                                  <div className="d-flex align-items-center">
                                    <Form.Label>Password</Form.Label>
                                  </div>

                                  <Field
                                    name={`contacts[${i}].password`}
                                    className="form-control form-control-sm"
                                    type="password"
                                  />

                                  <ErrorMessage
                                    name={`contacts[${i}].password`}
                                  >
                                    {(msg) => (
                                      <span className="text-danger">{msg}</span>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                                <Form.Group as={Col} md={6}>
                                  <Form.Label>Confirm Password</Form.Label>
                                  <Field
                                    name={`contacts[${i}].confirmPassword`}
                                    className="form-control form-control-sm"
                                    type="password"
                                  />
                                  <ErrorMessage
                                    name={`contacts[${i}].confirmPassword`}
                                  >
                                    {(msg) => (
                                      <span className="text-danger">{msg}</span>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Row>
                                  <Form.Group as={Col} md={12}>
                                    <Form.Label>
                                      Notification Settings
                                    </Form.Label>
                                  </Form.Group>
                                  <Form.Group as={Col} md={6}>
                                    <Form.Check
                                      type="checkbox"
                                      checked={
                                        formik.values.contacts[i]
                                          .smsNotificationsEnabled
                                      }
                                      label="Receive SMS"
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          `contacts[${i}].smsNotificationsEnabled`,
                                          e.target.checked
                                        )
                                      }}
                                    />
                                  </Form.Group>
                                  <Form.Group as={Col} md={6}>
                                    <Form.Check
                                      type="checkbox"
                                      checked={
                                        formik.values.contacts[i]
                                          .emailNotificationsEnabled
                                      }
                                      label="Receive Email"
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          `contacts[${i}].emailNotificationsEnabled`,
                                          e.target.checked
                                        )
                                      }}
                                    />
                                  </Form.Group>
                                  <Form.Group as={Col} md={12}>
                                    <Form.Label>Notes</Form.Label>
                                    <Form.Control
                                      name={`contacts[${i}].notes`}
                                      value={formik.values.contacts[i].notes}
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          `contacts[${i}].notes`,
                                          e.target.value
                                        )
                                      }}
                                      as="textarea"
                                      rows={6}
                                      className="form-text"
                                    />
                                    <ErrorMessage name={`contacts[${i}].notes`}>
                                      {(msg) => (
                                        <div>
                                          <span className="text-danger">
                                            {msg}
                                          </span>
                                        </div>
                                      )}
                                    </ErrorMessage>
                                  </Form.Group>
                                  <div
                                    className="d-flex align-items-center mt-2 hover btn-link"
                                    onClick={() => arrayHelpers.remove(i)}
                                  >
                                    <Trash className="mr-2" />
                                    <small>Remove</small>
                                  </div>
                                </Form.Row>
                              </Form.Row>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <Button
                      size="sm"
                      variant="link"
                      onClick={() => {
                        arrayHelpers.push({
                          id: '',
                          firstName: '',
                          lastName: '',
                          email: '',
                          phoneNumber: '',
                          emailNotificationsEnabled: true,
                          smsNotificationsEnabled: true,
                          password: '',
                          confirmPassword: '',
                          notes: '',
                        })
                        let element = document.querySelector('.contact-form')

                        element.lastChild?.scrollIntoView({
                          behavior: 'smooth',
                          block: 'end',
                          inline: 'nearest',
                        })
                      }}
                    >
                      <PlusCircle className="mr-2" />
                      Add Contact
                    </Button>
                  </div>
                )}
              />
            </Form.Group>
          </Form.Row>
        )}

        <Form.Row>
          <Col className="d-flex align-items-center">
            <button
              type="button"
              onClick={() => setDisplayLocation(!displayLocation)}
              className="px-0 btn-link mr-2"
              style={{ marginTop: '-10px' }}
            >
              <>
                {displayLocation ? (
                  <>
                    <CaretDown size={17} />
                  </>
                ) : (
                  <>
                    <CaretRight size={17} />
                  </>
                )}
              </>
            </button>
            <Form.Label>Locations </Form.Label>

            <ErrorMessage name="locations">
              {(msg) => {
                return msg ===
                  'Only One shipping address and one billing address across all locations is allowed' ? (
                  <span className="text-danger d-block mb-2">{msg}</span>
                ) : (
                  <></>
                )
              }}
            </ErrorMessage>
          </Col>
        </Form.Row>
        {displayLocation && (
          <FieldArray
            name="locations"
            render={(locationArrayHelpers) => (
              <>
                <div
                  style={
                    formik.values.locations.length > 1
                      ? {
                          overflowY: 'scroll',
                          maxHeight: '350px',
                          overflowX: 'hidden',
                        }
                      : null
                  }
                  className={
                    formik.values.locations.length > 1 ? 'border p-3 mt-2' : ''
                  }
                >
                  <div className="address-form">
                    {formik.values.locations.map((location, i) => (
                      <div
                        key={i}
                        className="border border-secondary rounded mb-3 p-2"
                      >
                        <Form.Row>
                          <Form.Group as={Col} md={4}>
                            <Form.Label>Name</Form.Label>
                            <Field
                              name={`locations[${i}].name`}
                              className="form-control form-control-sm"
                            />
                          </Form.Group>
                          <Form.Group as={Col} md={4}>
                            <Form.Label>Location</Form.Label>
                            <div className="d-flex">
                              {location.isSelected ? (
                                <AutocompleteAddress
                                  onSelect={handleAddressSelect}
                                  formik={formik}
                                />
                              ) : (
                                <Form.Control
                                  style={
                                    location && !location.isSelected
                                      ? {
                                          width: '80%',
                                        }
                                      : null
                                  }
                                  disabled={!location.isSelected}
                                  placeholder="search locations"
                                  className="form-control-sm "
                                  value={
                                    location.addressLineOne +
                                    ' ' +
                                    location.city +
                                    ' ' +
                                    location.state +
                                    ' ' +
                                    location.zipCode
                                  }
                                />
                              )}

                              {location && !location.isSelected && (
                                <>
                                  <button
                                    type="button"
                                    className="btn-link mb-1 ms-2"
                                    onClick={() =>
                                      formik.setFieldValue(`locations[${i}]`, {
                                        addressLineOne: '',
                                        name: location.name,
                                        addressLineTwo: '',
                                        isSelected: true,
                                        city: '',
                                        state: '',
                                        zipCode: '',
                                        billingAddress: location.billingAddress,
                                        shippingAddress:
                                          location.shippingAddress,
                                      })
                                    }
                                  >
                                    <XCircle />
                                  </button>
                                </>
                              )}
                            </div>
                          </Form.Group>
                          {location && !location.isSelected && (
                            <Form.Group as={Col} md={4}>
                              <Form.Label>Address Line Two</Form.Label>
                              <Field
                                name={`locations[${i}].addressLineTwo`}
                                className="form-control form-control-sm"
                              />
                            </Form.Group>
                          )}
                        </Form.Row>

                        <Form.Group>
                          <Row>
                            <Col xs="auto">
                              <Field
                                type="checkbox"
                                name={`locations[${i}].billingAddress`}
                              />
                              <Form.Label className="ml-2">
                                Billing Address
                              </Form.Label>
                            </Col>
                            <Col>
                              <Field
                                type="checkbox"
                                name={`locations[${i}].shippingAddress`}
                              />
                              <Form.Label className="ml-2">
                                Shipping Address
                              </Form.Label>
                            </Col>
                          </Row>
                        </Form.Group>
                        <div
                          className="d-flex align-items-center mt-2 btn-link hover"
                          onClick={() => locationArrayHelpers.remove(i)}
                        >
                          <Trash className="mr-2" />
                          <small>Remove</small>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <Button
                  size="sm"
                  className="mt-1"
                  variant="link"
                  onClick={() => {
                    locationArrayHelpers.push({
                      name: '',
                      addressLineOne: '',
                      addressLineTwo: '',
                      isSelected: false,
                      city: '',
                      state: '',
                      zipCode: '',
                      billingAddress: true,
                      shippingAddress: true,
                    })
                    let element = document.querySelector('.address-form')

                    element.lastChild?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'end',
                      inline: 'nearest',
                    })
                  }}
                >
                  <PlusCircle className="mr-2" />
                  Add Location
                </Button>
              </>
            )}
          />
        )}
        <Form.Row className="mb-3">
          <Col md={3}>
            <Form.Label>Stripe Customer Email</Form.Label>
            <Form.Control
              type="text"
              name="invoiceEmail"
              className="form-control-sm"
              value={formik.values.invoiceEmail}
              onChange={formik.handleChange}
              isInvalid={formik.errors.invoiceEmail}
            />
          </Col>
        </Form.Row>
        <Policies formik={formik} nodeType="organization" />
        <Row className="mt-3">
          <Col>
            <Form.Label>Session Packages Backgrounds</Form.Label>
            <FieldArray
              className="mt-3"
              name="sessionPackageBackgrounds"
              render={(arrayHelpers) => (
                <div>
                  {formik.values.sessionPackageBackgrounds.map(
                    (sessionPackageBackground, index) => {
                      const { node } =
                        sessionPackageData.sessionPackages.edges.find(
                          (el) =>
                            el.node.id ===
                            sessionPackageBackground.sessionPackageId
                        )
                      let className = ''
                      if (
                        formik.values.sessionPackageBackgrounds.length > 1 &&
                        index > 0
                      ) {
                        className = 'mt-2'
                      }
                      let description
                      if (node.customPriceAndDuration) {
                        description = `${node.title}  ⚙️ `
                      } else {
                        description = `${node.title}, $${node.price}, ${node.durationLowMinutes}-${node.durationHighMinutes} minutes`
                      }
                      return (
                        <>
                          <div key={index} className={className}>
                            <Row>
                              <Col
                                md="4"
                                className="border border-secondary p-2 ml-3"
                              >
                                <Form.Row>
                                  <Form.Group as={Col} md={12}>
                                    <Form.Label>Session Package</Form.Label>
                                    <Field
                                      name={`sessionPackageBackgrounds.${index}.sessionPackageId`}
                                      as="select"
                                      className="form-control-sm form-select mb-1"
                                    >
                                      <option value={node.id} key={node.id}>
                                        {description}
                                      </option>
                                      {availableBackgroundColorPackages.map(
                                        (node) => {
                                          let description
                                          if (node.customPriceAndDuration) {
                                            description = `${node.title}  ⚙️ `
                                          } else {
                                            description = `${node.title}, $${node.price}, ${node.durationLowMinutes}-${node.durationHighMinutes} minutes`
                                          }
                                          return (
                                            <option
                                              value={node.id}
                                              key={node.id}
                                            >
                                              {description}
                                            </option>
                                          )
                                        }
                                      )}
                                    </Field>
                                  </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                  <Form.Group as={Col} md={12}>
                                    <Form.Label>Color</Form.Label>
                                    <Field
                                      name={`sessionPackageBackgrounds.${index}.backgroundColor`}
                                      className="form-control"
                                    />
                                    <ErrorMessage
                                      name={`sessionPackageBackgrounds.${index}.backgroundColor`}
                                    >
                                      {(msg) => (
                                        <span className="text-danger">
                                          {msg}
                                        </span>
                                      )}
                                    </ErrorMessage>
                                  </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                  <Form.Group as={Col} md={12}>
                                    <Button
                                      className="p-0 m-0 mt-1"
                                      variant="link"
                                      onClick={() => {
                                        arrayHelpers.remove(index)
                                      }}
                                    >
                                      <Trash className="mr-1" /> remove
                                    </Button>
                                  </Form.Group>
                                </Form.Row>
                              </Col>
                            </Row>
                          </div>
                        </>
                      )
                    }
                  )}
                  {availableBackgroundColorPackages.length > 0 ? (
                    <Button
                      variant="link"
                      onClick={() => {
                        const firstNode = availableBackgroundColorPackages[0]
                        arrayHelpers.push({
                          sessionPackageId: firstNode.id,
                          backgroundColor: '',
                          id: '',
                        })
                      }}
                    >
                      <PlusCircle className="mr-2" />
                      Add Package Background
                    </Button>
                  ) : null}
                </div>
              )}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Form.Label>Add Tasks From Collection</Form.Label>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={4}>
            {groupsData &&
            groupsData.defaultServiceItemGroups.edges.length > 0 ? (
              <>
                <Form.Control
                  name="group"
                  as="select"
                  value={groupOptionValue}
                  onChange={(e) => handleGroupClick(e)}
                >
                  <option value="dash">- - -</option>
                  {groupsData.defaultServiceItemGroups.edges
                    .filter((groupData) => {
                      let show = true
                      formik.values.group.forEach((selected) => {
                        if (selected?.node.id === groupData?.node.id) {
                          show = false
                        }
                      })
                      return show
                    })
                    .map((data) => (
                      <option key={data.node.id} value={data.node.id}>
                        {data.node.name}
                      </option>
                    ))}
                </Form.Control>{' '}
                <div>
                  {formik.values.group.map((data, index) => (
                    <Row key={index}>
                      <Col md={12}>
                        <div
                          key={index}
                          className="p-3 border border-dark rounded mt-2 d-flex"
                        >
                          <div
                            className="hover btn-link px-2"
                            onClick={() => {
                              handleRemoveGroup(data)
                            }}
                          >
                            <Trash className="mr-2" />
                          </div>
                          <p className="mb-0">{data.node.name} Collection</p>
                        </div>
                      </Col>
                    </Row>
                  ))}
                </div>{' '}
              </>
            ) : (
              <Form.Label>
                No Task Groups Configured in Studio Settings
              </Form.Label>
            )}
          </Col>
        </Row>
        {rowFatalError && (
          <div className="text-danger mt-3">
            <ExclamationCircle className="mr-2" /> Resolve the fatal subject CSV
            import issues before saving
          </div>
        )}
        <Row>
          <Col md={2}>
            <Button
              className="mt-4"
              type="submit"
              block
              variant="outline-primary"
              disabled={isSubmitting || rowFatalError}
            >
              <PlusCircle className="mr-2" />
              Save
            </Button>
          </Col>
        </Row>
        {isSubmitting ? (
          <Row className="mt-3">
            <Col>
              <Loading message="Saving Organization..." />
            </Col>
          </Row>
        ) : null}
      </Form>
    </>
  )
}
